import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import BannerApi from "../../api/banner.api";
import { useTranslation } from "react-i18next";
import DelBtn from "../../../component/atoms/button/delBtn";
import EditBtn from "../../../component/atoms/button/editBtn";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import Modal from "../../../component/templates/Modal";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import BannerApi from "./_api/banner.api";
import ImgInput from "../../../component/atoms/input/imgInput";
import { isNull } from "../../../util/check";
import { fileUpload } from "../../../util/fileManagement";
import { fileUrl } from "../../../api/api";

const BannerServe = () => {
  const { t } = useTranslation();

  const [NEW, setNEW] = useState({});
  const [BEST, setBEST] = useState({});
  const [EVENT, setEVENT] = useState({});

  const getList = async () => {
    try {
      let responseData = (await BannerApi.getSubBanner()).data.data.content;

      const temp1 = responseData.filter((item) => item.subBannerType === "NEW")[0];
      setNEW({
        ...temp1,
        fileUrlPc: temp1.imageServerFileNamePc ? `${fileUrl}/${temp1.imageServerFileNamePc}` : "",
        fileUrlMo: temp1.imageServerFileNameMo ? `${fileUrl}/${temp1.imageServerFileNameMo}` : "",
      });

      const temp2 = responseData.filter((item) => item.subBannerType === "BEST")[0];
      setBEST({
        ...temp2,
        fileUrlPc: temp2.imageServerFileNamePc ? `${fileUrl}/${temp2.imageServerFileNamePc}` : "",
        fileUrlMo: temp2.imageServerFileNameMo ? `${fileUrl}/${temp2.imageServerFileNameMo}` : "",
      });

      const temp3 = responseData.filter((item) => item.subBannerType === "EVENT")[0];
      setEVENT({
        ...temp3,
        fileUrlPc: temp3.imageServerFileNamePc ? `${fileUrl}/${temp3.imageServerFileNamePc}` : "",
        fileUrlMo: temp3.imageServerFileNameMo ? `${fileUrl}/${temp3.imageServerFileNameMo}` : "",
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  //이미지 삭제
  const delImg = (ENUM, type) => {
    switch (ENUM) {
      case "NEW":
        if (type === "pc") {
          NEW.filePc = "";
          NEW.fileUrlPc = "";
          NEW.imageOriginFileNamePc = "";
          NEW.imageServerFileNamePc = "";
        } else {
          NEW.fileMo = "";
          NEW.fileUrlMo = "";
          NEW.imageOriginFileNameMo = "";
          NEW.imageServerFileNameMo = "";
        }
        setNEW({ ...NEW });
        break;
      case "BEST":
        if (type === "pc") {
          BEST.filePc = "";
          BEST.fileUrlPc = "";
          BEST.imageOriginFileNamePc = "";
          BEST.imageServerFileNamePc = "";
        } else {
          BEST.fileMo = "";
          BEST.fileUrlMo = "";
          BEST.imageOriginFileNameMo = "";
          BEST.imageServerFileNameMo = "";
        }
        setBEST({ ...BEST });
        break;
      case "EVENT":
        if (type === "pc") {
          EVENT.filePc = "";
          EVENT.fileUrlPc = "";
          EVENT.imageOriginFileNamePc = "";
          EVENT.imageServerFileNamePc = "";
        } else {
          EVENT.fileMo = "";
          EVENT.fileUrlMo = "";
          EVENT.imageOriginFileNameMo = "";
          EVENT.imageServerFileNameMo = "";
        }
        setEVENT({ ...EVENT });
        break;

      default:
        break;
    }
  };

  //이미지 추가
  const inputFile = (e, ENUM, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      switch (ENUM) {
        case "NEW":
          if (type === "pc") {
            NEW.filePc = file;
            NEW.fileUrlPc = value.target.result;
          } else {
            NEW.fileMo = file;
            NEW.fileUrlMo = value.target.result;
          }
          setNEW({ ...NEW });
          break;
        case "BEST":
          if (type === "pc") {
            BEST.filePc = file;
            BEST.fileUrlPc = value.target.result;
          } else {
            BEST.fileMo = file;
            BEST.fileUrlMo = value.target.result;
          }
          setBEST({ ...BEST });
          break;
        case "EVENT":
          if (type === "pc") {
            EVENT.filePc = file;
            EVENT.fileUrlPc = value.target.result;
          } else {
            EVENT.fileMo = file;
            EVENT.fileUrlMo = value.target.result;
          }
          setEVENT({ ...EVENT });
          break;

        default:
          break;
      }
    };

    e.target.value = "";
  };

  // 저장하기
  const [editENUM, setEditENUM] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);

  const editBtnHandler = (ENUM) => {
    let obj = undefined;
    switch (ENUM) {
      case "NEW":
        obj = NEW;
        break;
      case "BEST":
        obj = BEST;
        break;
      case "EVENT":
        obj = EVENT;
        break;

      default:
        break;
    }

    // if (isNull(obj.link)) {
    //   toast("링크를 입력해주세요.");
    //   return;
    // }
    if (isNull(obj.imageOriginFileNamePc) && isNull(obj.filePc)) {
      toast(t("toastMsg.pcImageAdd"));
      return;
    }
    if (isNull(obj.imageOriginFileNameMo) && isNull(obj.fileMo)) {
      toast(t("toastMsg.moImageAdd"));
      return;
    }

    setEditENUM(ENUM);
    setEditModalOpen(true);
  };

  const editFunc = async () => {
    try {
      let obj = undefined;

      switch (editENUM) {
        case "NEW":
          obj = { ...NEW };
          break;
        case "BEST":
          obj = { ...BEST };
          break;
        case "EVENT":
          obj = { ...EVENT };
          break;

        default:
          break;
      }

      const data = {
        imageOriginFileNamePc: obj.imageOriginFileNamePc,
        imageServerFileNamePc: obj.imageServerFileNamePc,
        imageOriginFileNameMo: obj.imageOriginFileNameMo,
        imageServerFileNameMo: obj.imageServerFileNameMo,
        link: obj.link,
      };

      // 이미지 처리
      if (obj.filePc) {
        const { imageOriginFileName, imageServerFileName } = await fileUpload(obj.filePc, "banner/");
        data.imageOriginFileNamePc = imageOriginFileName;
        data.imageServerFileNamePc = imageServerFileName;
      }

      if (obj.fileMo) {
        const { imageOriginFileName, imageServerFileName } = await fileUpload(obj.fileMo, "banner/");
        data.imageOriginFileNameMo = imageOriginFileName;
        data.imageServerFileNameMo = imageServerFileName;
      }

      await BannerApi.updateSubBanner(obj.id, data);

      toast(t("toastMsg.update"));
      setEditModalOpen(false);
      setEditENUM("");
    } catch (error) {
      errorHandler(error);
    }
  };

  // 활성/비활성
  const [activationENUM, setActivationENUM] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      let obj = undefined;

      switch (activationENUM) {
        case "NEW":
          obj = { ...NEW };
          break;
        case "BEST":
          obj = { ...BEST };
          break;
        case "EVENT":
          obj = { ...EVENT };
          break;

        default:
          break;
      }

      const data = {
        activation: !obj.activation,
      };

      await BannerApi.updateSubBannerActivation(obj.id, data);

      toast(t("toastMsg.update"));
      setActivationModalOpen(false);
      setActivationENUM("");
      getList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <TableTitle>{t("banner.title")}</TableTitle>
          </DetailTop>

          <Tab
            list={[
              { title: `${t("banner.tab1")}`, url: route.banner_main },
              { title: `${t("banner.tab2")}`, url: route.banner_serve },
            ]}
            style={{ marginBottom: "20px" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <DetailPage
              style={{
                minHeight: "475px",
              }}
            >
              <DetailPageTitle>{t("banner.new")}</DetailPageTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>
                    {t("banner.pc")}*
                    <br />
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "24.5px",
                        color: "#ADB4BA",
                      }}
                    >
                      ({t("banner.recommendSize")} 1080*380)
                    </span>
                  </DetailFormTitle>
                  {NEW.fileUrlPc ? (
                    <div style={{ position: "relative" }}>
                      <img
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "3px",
                        }}
                        src={NEW.fileUrlPc}
                        alt=""
                      />
                      <img
                        src="/assets/admin/icons/x.png"
                        alt=""
                        style={{
                          position: "absolute",
                          top: 6,
                          right: 6,
                          cursor: "pointer",
                        }}
                        onClick={() => delImg("NEW", "pc")}
                      />
                    </div>
                  ) : (
                    <ImgInput
                      id={`fileNewPc`}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "NEW", "pc");
                      }}
                    />
                  )}
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>
                    {t("banner.mobile")}*
                    <br />
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "24.5px",
                        color: "#ADB4BA",
                      }}
                    >
                      ({t("banner.recommendSize")} 335*220)
                    </span>
                  </DetailFormTitle>
                  {NEW.fileUrlMo ? (
                    <div style={{ position: "relative" }}>
                      <img
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "3px",
                        }}
                        src={NEW.fileUrlMo}
                        alt=""
                      />
                      <img
                        src="/assets/admin/icons/x.png"
                        alt=""
                        style={{
                          position: "absolute",
                          top: 6,
                          right: 6,
                          cursor: "pointer",
                        }}
                        onClick={() => delImg("NEW", "mo")}
                      />
                    </div>
                  ) : (
                    <ImgInput
                      id={`fileNewMo`}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "NEW", "mo");
                      }}
                    />
                  )}
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("banner.link")}</DetailFormTitle>
                  <MainInput
                    style={{ width: "338px" }}
                    type={"text"}
                    placeholder={t("placeholder.linkPlaceholder")}
                    value={NEW.link}
                    onChange={(e) => {
                      setNEW({ ...NEW, link: e.target.value });
                    }}
                  />
                </DetailPageForm>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 6,
                  marginTop: 30,
                }}
              >
                <DelBtn
                  text={!NEW.activation ? t("component.active") : t("banner.hide")}
                  onClick={() => {
                    setActivationENUM("NEW");
                    setActivationModalOpen(true);
                  }}
                />
                <EditBtn
                  text={t("component.save")}
                  onClick={() => {
                    editBtnHandler("NEW");
                  }}
                />
              </div>
            </DetailPage>

            <DetailPage
              style={{
                minHeight: "475px",
              }}
            >
              <DetailPageTitle>{t("banner.best")}</DetailPageTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>
                    {t("banner.pc")}*
                    <br />
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "24.5px",
                        color: "#ADB4BA",
                      }}
                    >
                      ({t("banner.recommendSize")} 1080*380)
                    </span>
                  </DetailFormTitle>
                  {BEST.fileUrlPc ? (
                    <div style={{ position: "relative" }}>
                      <img
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "3px",
                        }}
                        src={BEST.fileUrlPc}
                        alt=""
                      />
                      <img
                        src="/assets/admin/icons/x.png"
                        alt=""
                        style={{
                          position: "absolute",
                          top: 6,
                          right: 6,
                          cursor: "pointer",
                        }}
                        onClick={() => delImg("BEST", "pc")}
                      />
                    </div>
                  ) : (
                    <ImgInput
                      id={`fileNewPc`}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "BEST", "pc");
                      }}
                    />
                  )}
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>
                    {t("banner.mobile")}*
                    <br />
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "24.5px",
                        color: "#ADB4BA",
                      }}
                    >
                      ({t("banner.recommendSize")} 335*220)
                    </span>
                  </DetailFormTitle>
                  {BEST.fileUrlMo ? (
                    <div style={{ position: "relative" }}>
                      <img
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "3px",
                        }}
                        src={BEST.fileUrlMo}
                        alt=""
                      />
                      <img
                        src="/assets/admin/icons/x.png"
                        alt=""
                        style={{
                          position: "absolute",
                          top: 6,
                          right: 6,
                          cursor: "pointer",
                        }}
                        onClick={() => delImg("BEST", "mo")}
                      />
                    </div>
                  ) : (
                    <ImgInput
                      id={`fileNewMo`}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "BEST", "mo");
                      }}
                    />
                  )}
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("banner.link")}</DetailFormTitle>
                  <MainInput
                    style={{ width: "338px" }}
                    type={"text"}
                    placeholder={t("placeholder.linkPlaceholder")}
                    value={BEST.link}
                    onChange={(e) => {
                      setBEST({ ...BEST, link: e.target.value });
                    }}
                  />
                </DetailPageForm>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 6,
                  marginTop: 30,
                }}
              >
                <DelBtn
                  text={!BEST.activation ? t("component.active") : t("banner.hide")}
                  onClick={() => {
                    setActivationENUM("BEST");
                    setActivationModalOpen(true);
                  }}
                />
                <EditBtn
                  text={t("component.save")}
                  onClick={() => {
                    editBtnHandler("BEST");
                  }}
                />
              </div>
            </DetailPage>

            <DetailPage
              style={{
                minHeight: "475px",
              }}
            >
              <DetailPageTitle>{t("banner.event")}</DetailPageTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>
                    {t("banner.pc")}*
                    <br />
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "24.5px",
                        color: "#ADB4BA",
                      }}
                    >
                      ({t("banner.recommendSize")} 1080*380)
                    </span>
                  </DetailFormTitle>
                  {EVENT.fileUrlPc ? (
                    <div style={{ position: "relative" }}>
                      <img
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "3px",
                        }}
                        src={EVENT.fileUrlPc}
                        alt=""
                      />
                      <img
                        src="/assets/admin/icons/x.png"
                        alt=""
                        style={{
                          position: "absolute",
                          top: 6,
                          right: 6,
                          cursor: "pointer",
                        }}
                        onClick={() => delImg("EVENT", "pc")}
                      />
                    </div>
                  ) : (
                    <ImgInput
                      id={`fileNewPc`}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "EVENT", "pc");
                      }}
                    />
                  )}
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>
                    {t("banner.mobile")}*
                    <br />
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "24.5px",
                        color: "#ADB4BA",
                      }}
                    >
                      ({t("banner.recommendSize")} 335*220)
                    </span>
                  </DetailFormTitle>
                  {EVENT.fileUrlMo ? (
                    <div style={{ position: "relative" }}>
                      <img
                        style={{
                          width: "90px",
                          height: "90px",
                          borderRadius: "3px",
                        }}
                        src={EVENT.fileUrlMo}
                        alt=""
                      />
                      <img
                        src="/assets/admin/icons/x.png"
                        alt=""
                        style={{
                          position: "absolute",
                          top: 6,
                          right: 6,
                          cursor: "pointer",
                        }}
                        onClick={() => delImg("EVENT", "mo")}
                      />
                    </div>
                  ) : (
                    <ImgInput
                      id={`fileNewMo`}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "EVENT", "mo");
                      }}
                    />
                  )}
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("banner.link")}</DetailFormTitle>
                  <MainInput
                    style={{ width: "338px" }}
                    type={"text"}
                    placeholder={t("placeholder.linkPlaceholder")}
                    value={EVENT.link}
                    onChange={(e) => {
                      setEVENT({ ...EVENT, link: e.target.value });
                    }}
                  />
                </DetailPageForm>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 6,
                  marginTop: 30,
                }}
              >
                <DelBtn
                  text={!EVENT.activation ? t("component.active") : t("banner.hide")}
                  onClick={() => {
                    setActivationENUM("EVENT");
                    setActivationModalOpen(true);
                  }}
                />
                <EditBtn
                  text={t("component.save")}
                  onClick={() => {
                    editBtnHandler("EVENT");
                  }}
                />
              </div>
            </DetailPage>
          </div>
        </DetailPageLayout>
      </MainLayout>

      {activationModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          btnTitle={"수정하기"}
          setModalOpen={setEditModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}

      {editModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          btnTitle={"수정하기"}
          setModalOpen={setEditModalOpen}
          onClick={() => {
            editFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default BannerServe;
