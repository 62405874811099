import React from 'react';
import styled from 'styled-components';
import PretendardText from '../text/pretendardText';

function MainInput({
  type,
  placeholder,
  value,
  onChange,
  onClick,
  onError,
  disabled,
  style,
  dataPlaceholder,
  onKeyDown,
  step,
  helperText,
  maxlength,
}) {
  return (
    <>
      <MainInputWrapper
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        data-placeholder={dataPlaceholder}
        value={value || ''}
        onChange={onChange}
        onClick={onClick}
        onError={onError}
        onKeyDown={onKeyDown}
        maxlength={maxlength}
        step={step}
        style={{
          ...style,
          borderColor: `${onError ? '#FF003D' : '#B8BFC4'}`,
        }}
      />
      {onError ? <HelperText onError={onError}>{helperText}</HelperText> : ''}
    </>
  );
}

export default MainInput;

const MainInputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 48px;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #b8bfc4;
  background: #fff;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.25px;
  color: #262c31;
  caret: #0077e5;

  &::placeholder {
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.25px;
    color: #808991;
  }

  &:focus {
    outline: none;
    border: 1px solid #27beff;
  }

  &:disabled {
    outline: none;
    border: 1px solid #d7dbe0;
    background: #eef2f3;
    color: #b8bfc4;
  }
`;

const HelperText = styled(PretendardText).attrs((props) => {})`
  font-size: 14px;
  font-weight: 500;
  line-height: 22.75px;
  color: ${(props) => (props.onError ? '#FF003D' : '#808991')};
  margin-top: 3px;
`;
