import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
// import ExcelApi from "../../../api/excel.api";
// import MemberApi from "../../../api/memebr.api";
import styled from "styled-components";
import { fileUrl } from "../../../api/api";
import OrderApi from "../../../api/order.api";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import DateInput02 from "../../../component/atoms/input/dateInput02";
import MainInput from "../../../component/atoms/input/mainInput";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import PretendardText from "../../../component/atoms/text/pretendardText";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import SelectBox from "../../../component/molecules/selectBox";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import OrderModal from "../orderModal";
import ExcelApi from "../../../api/excel.api";
import { saveAs } from "file-saver";

const Shipping = () => {
  const { t, i18n } = useTranslation();
  const isVn = i18n.language === "VN";

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getShippingList();
  }, [location]);

  const [orderList, setOrderList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getShippingList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
        orderStatus: "ORDER",
        startDate,
        endDate,
      };
      let responseData = (await OrderApi.getMemberMerchList(data)).data.data;
      setOrderList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
            optionInfoString: getOptionInfoString(JSON.parse(item.optionInfo)),
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const getOptionInfoString = (optionInfo) => {
    let optionInfoString = "";

    for (let index = 0; index < optionInfo.length; index++) {
      const element = optionInfo[index];
      const option = element.option;
      const optionValue = element.optionValue;

      if (index != 0) {
        optionInfoString += " / ";
      }
      optionInfoString += `${option.title}: ${optionValue.title}, ${t("shipping.count")}: ${optionValue.quantity}`;
    }

    return optionInfoString;
  };

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  // ------------엑셀 생성
  const DELIVERY_STATUS_INFOS = [
    { value: "ORDER_COMPLETED", title: t("shipping.orderComplete") },
    { value: "PREPARING_SHIPMENT", title: t("shipping.ready") },
    { value: "SHIPPING", title: t("shipping.ing") },
    { value: "DELIVERED", title: t("shipping.complete") },
  ];

  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: t("shipping.orderNum"), key: "orderNumber", width: 40 },
      { header: t("shipping.name"), key: "name", width: 40 },
      { header: t("shipping.amount"), key: "payAmount", width: 40 },
      { header: t("shipping.state"), key: "state", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${t("shipping.title")}.xlsx`);
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetMemberMerchExcel({
          ids: [],
          allFlag: true,
          orderStatus: "ORDER",
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          orderNumber: `${item.order?.orderNumber}/${createDate}`,
          name: item.order?.name,
          payAmount: item.salePrice?.toLocaleString(),
          state: DELIVERY_STATUS_INFOS.find((info) => info.value === item.deliveryStatus)?.title,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = orderList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast(t("placeholder.selectItem"));
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetMemberMerchExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
          orderStatus: "ORDER",
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          orderNumber: `${item.orderNumber}/${createDate}`,
          payAmount: item.payAmount?.toLocaleString(),
          state: DELIVERY_STATUS_INFOS.find((info) => info.value === item.deliveryStatus)?.title,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  const [waybillInputOpen, setWaybillInputOpen] = useState(-1);

  const isAllCheck = useMemo(() => {
    const checkedList = orderList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === orderList.length;
  }, [orderList]);

  // ----- 배송상태
  const [orderfilterOpen, setOrderfilterOpen] = useState(-1);
  const [orderId, setOrderId] = useState(-1);
  const [orderType, setOrderType] = useState("");
  const [changeModalOpen, setChangeModalOpen] = useState(false);

  const changeOrder = async () => {
    try {
      const data = {
        deliveryStatus: orderType,
      };

      await OrderApi.changeMemberMerch(orderId, data);
      toast(t("toastMsg.update"));
      setOrderfilterOpen(-1);
      setChangeModalOpen(false);
      setOrderId(-1);
      setOrderType("");
      getShippingList();
    } catch (error) {
      errorHandler(error);
    }
  };

  const [selectedOrderData, setSelectedOrderData] = useState({});
  const [orderModalOpen, setOrderModalOpen] = useState(false);

  // ----- 운송장번호
  const [deliveryNumberIndex, setDeliveryNumberIndex] = useState(-1);
  const [deliveryNumberModal, setDeliveryNumberModal] = useState(false);

  const setDeliveryCompany = (company, index) => {
    const copyOrderList = [...orderList];
    copyOrderList[index].deliveryCompany = company;

    setOrderList(copyOrderList);
  };

  const setDeliveryNumber = (number, index) => {
    const copyOrderList = [...orderList];
    copyOrderList[index].deliveryNumber = number;

    setOrderList(copyOrderList);
  };

  const changeDeliveryNumber = async () => {
    try {
      const data = {
        deliveryCompany: orderList[deliveryNumberIndex].deliveryCompany,
        deliveryNumber: orderList[deliveryNumberIndex].deliveryNumber,
      };

      const response = await OrderApi.changeDeliveryNumber(orderList[deliveryNumberIndex].id, data);
      setDeliveryNumberModal(false);
      toast(t("toastMsg.update"));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{t("shipping.title")}</TableTitle>
          <TableTop style={{ marginBottom: "10px" }}>
            <TableCount text={t("shipping.shippingCount")} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.shipping}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.shipping}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <ExcelBtn onSelectClick={getExcelChecked} onAllClick={getExcelAll} />
            </TableSearchBtn>
          </TableTop>
          <TableTop>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <TopBtn>{t("shipping.cancelBtn")}</TopBtn>
              <TopBtn>{t("shipping.refundBtn")}</TopBtn>
              <TopBtn>{t("shipping.exchangeBtn")}</TopBtn>
              <TopBtn>{t("shipping.returnBtn")}</TopBtn> */}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <DateInput02
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
                <div
                  style={{
                    height: "1px",
                    width: "9px",
                    backgroundColor: "#7d7d7d",
                  }}
                />
                <DateInput02
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </div>
              <SearchBtn
                onClick={() => {
                  navigate(`${route.shipping}?page=1&keyword=${searchParams.get("keyword") || ""}&startDate=${startDate}&startDate=${endDate}`, {
                    replace: true,
                  });
                }}
              >
                {t("component.search")}
              </SearchBtn>
            </div>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 */}
              <col width={isVn ? "60px" : "73px"} />
              {/* 주문번호/일시 */}
              <col width={isVn ? "136px" : "150px"} />
              {/* 주문자명 */}
              <col width={isVn ? "116px" : "88px"} />
              {/* 상품명 */}
              <col width={isVn ? "194px" : "265px"} />
              {/* 주문 금액 */}
              <col width={isVn ? "141px" : "151px"} />
              {/* 반품 상태 */}
              <col width={isVn ? "120px" : "120px"} />
              {/* */}
              <col width={isVn ? "214px" : "204px"} />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setOrderList(
                          orderList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setOrderList(
                          orderList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t("shipping.orderNum")}</TableText>
                </Th>
                <Th>
                  <TableText style={{ textAlign: "center", paddingRight: "39px" }}>{t("shipping.name")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("shipping.productName")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("shipping.amount")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("shipping.state")}</TableText>
                </Th>
                <Th></Th>
              </Tr>
            </thead>
            <tbody>
              {orderList.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <Tr
                      style={{
                        height: "59px",
                        background: "#fff",
                      }}
                    >
                      <Td style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => {
                            item.checked = !item.checked;
                            setOrderList([...orderList]);
                          }}
                        />
                      </Td>
                      <Td>
                        <div>
                          <TableText style={{ textDecoration: "underline" }}>{item.order?.orderNumber}</TableText>
                          <TableText style={{ fontWeight: "300" }}>{moment(item.order?.createdAt).format("YYYY-MM-DD hh:mm")}</TableText>
                        </div>
                      </Td>
                      <Td>
                        <TableText
                          style={{
                            textAlign: isVn ? "left" : "center",
                            paddingRight: isVn ? "0" : "44px",
                          }}
                        >
                          {item.order?.name}
                        </TableText>
                      </Td>
                      <Td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <img
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "3px",
                              }}
                              src={`${fileUrl}/${item.imageServerFileName}`}
                              alt=""
                            />
                          </div>
                          <div>
                            <TableText
                              style={{
                                marginBottom: "4px",
                                width: isVn ? "140px" : "181px",
                              }}
                            >
                              {item.title}
                            </TableText>
                            <TableText
                              style={{
                                fontSize: "12px",
                                lineHeight: "16px",
                                width: isVn ? "140px" : "181px",
                              }}
                            >
                              {item.optionInfoString ? item.optionInfoString : `${t("shipping.count")}: ${item.noOptionQuantity}`}
                            </TableText>
                          </div>
                        </div>
                      </Td>
                      <Td>
                        <TableText>
                          {item.salePrice?.toLocaleString()}
                          {t("component.monetary")}
                        </TableText>
                      </Td>
                      <Td>
                        <div style={{ position: "relative" }}>
                          <TableFilter
                            style={{ width: "120px" }}
                            subStyle={{
                              width: isVn ? "220px" : "140px",
                            }}
                            onClick={() => {
                              orderfilterOpen === index ? setOrderfilterOpen(-1) : setOrderfilterOpen(index);
                            }}
                            isOpen={orderfilterOpen === index}
                            selectedValue={item.deliveryStatus}
                            list={[
                              {
                                value: "ORDER_COMPLETED",
                                title: `${t("shipping.orderComplete")}`,
                                onClick: () => {
                                  setOrderId(item.id);
                                  setOrderType("ORDER_COMPLETED");
                                  setChangeModalOpen(true);
                                },
                              },
                              {
                                value: "PREPARING_SHIPMENT",
                                title: `${t("shipping.ready")}`,
                                onClick: () => {
                                  setOrderId(item.id);
                                  setOrderType("PREPARING_SHIPMENT");
                                  setChangeModalOpen(true);
                                },
                              },
                              {
                                value: "SHIPPING",
                                title: `${t("shipping.ing")}`,
                                onClick: () => {
                                  setOrderId(item.id);
                                  setOrderType("SHIPPING");
                                  setChangeModalOpen(true);
                                },
                              },
                              {
                                value: "DELIVERED",
                                title: `${t("shipping.complete")}`,
                                onClick: () => {
                                  setOrderId(item.id);
                                  setOrderType("DELIVERED");
                                  setChangeModalOpen(true);
                                },
                              },
                            ]}
                            setFilterOpen={setOrderfilterOpen}
                          />
                        </div>
                      </Td>
                      <Td>
                        <TableText>{isVn ? item.deliveryDescriptionVN : item.deliveryDescriptionEN}</TableText>
                      </Td>
                    </Tr>
                    <Tr style={{ borderBottom: "1px solid #DFE1E4" }}>
                      <Td style={{}} colSpan={8}>
                        <div style={{ display: "flex", gap: "14.5px", justifyContent: "flex-end", padding: "0 28px" }}>
                          <DetailBtn
                            text={t("shipping.waybillNumber")}
                            imgHide
                            style={{
                              background: "#fff",
                              border: "1px solid #778088",
                              color: "#778088",
                            }}
                            onClick={() => {
                              if (waybillInputOpen === index) {
                                setWaybillInputOpen(-1);
                              } else {
                                setWaybillInputOpen(index);
                              }
                            }}
                          />
                          <DetailBtn
                            imgHide
                            onClick={() => {
                              setSelectedOrderData({
                                id: item.order?.id,
                              });
                              setOrderModalOpen(true);
                            }}
                          />
                        </div>
                      </Td>
                    </Tr>
                    {waybillInputOpen === index ? (
                      <Tr>
                        <Td style={{ background: "#f2f4f5" }} colSpan={8}>
                          <WayBillWrapper>
                            <PretendardText
                              style={{
                                fontSize: "14px",
                                fontWeight: 600,
                                color: "#7d7d7d",
                              }}
                            >
                              {t("shipping.waybillNumber")}
                            </PretendardText>
                            <SelectBox
                              style={{
                                width: "250px",
                                backgroundColor: "#fff",
                              }}
                              list={["Efulfillment"]}
                              customIcon={"/assets/admin/icons/ico_arrow_down.svg"}
                              iconStyle={{ width: "28px", height: "28px" }}
                              selectedItem={item.deliveryCompany}
                              itemIndex={index}
                              onChange={setDeliveryCompany}
                            />
                            <MainInput
                              style={{ width: "250px" }}
                              value={item.deliveryNumber}
                              onChange={(e) => {
                                setDeliveryNumber(e.target.value, index);
                              }}
                            />
                            <DetailBtn
                              imgHide
                              text={t("component.save")}
                              onClick={() => {
                                setDeliveryNumberIndex(index);
                                setDeliveryNumberModal(true);
                              }}
                            />
                          </WayBillWrapper>
                        </Td>
                      </Tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.shipping}
            queryString={`keyword=${searchParams.get("keyword") || ""}&startDate=${searchParams.get("startDate")}&endDate=${searchParams.get("endDate")}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
          />
        </TableLayout>
      </MainLayout>
      {orderModalOpen ? <OrderModal data={selectedOrderData} setModalOpen={setOrderModalOpen} /> : ""}

      {changeModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          setModalOpen={setChangeModalOpen}
          onClick={() => {
            changeOrder();
          }}
        />
      ) : (
        ""
      )}

      {deliveryNumberModal ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          setModalOpen={setDeliveryNumberModal}
          onClick={() => {
            changeDeliveryNumber();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Shipping;

const TopBtn = styled.button.attrs((props) => {})`
  display: flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid #778088;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.75px;
  color: #778088;
  cursor: pointer;
`;

const SearchBtn = styled.button.attrs((props) => {})`
  display: flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  padding: 3px 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.75px;
  color: #4b4d4c;
  cursor: pointer;
`;

const WayBillWrapper = styled.div.attrs((props) => {})`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
`;
