import api from "../../../../api/api";

export default class FaqApi {
  static async create(data) {
    return await api.post(`admin/faq`, data);
  }
  static async getList(params) {
    return await api.get(`admin/faq`, params);
  }
  static async getById(faqId, params) {
    return await api.get(`admin/faq/${faqId}`, params);
  }
  static async update(faqId, data) {
    return await api.patch(`admin/faq/${faqId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/faq`, data);
  }
}
