import api from "../../../../api/api";


export default class AlarmApi {
  static async create(data) {
    return await api.post(`admin/alarm`, data);
  }
  static async getList(params) {
    return await api.get(`admin/alarm`, params);
  }
  static async getById(alarmId, params) {
    return await api.get(`admin/alarm/${alarmId}`, params);
  }
  static async update(alarmId, data) {
    return await api.put(`admin/alarm/${alarmId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/alarm`, data);
  }
}
