import api from "./api.js";

export default class ManagerApi {
  // 관리자 목록 조회
  static async getList(params) {
    return await api.get(`admin/manager`, params);
  }

  // 관리자 삭제
  static async delete(data) {
    return await api.del(`admin/manager`, data);
  }

  // 관리자 단일 조회
  static async getById(managerId, params) {
    return await api.get(`admin/manager/${managerId}`, params);
  }

  // 관리자 수정
  static async update(managerId, data) {
    return await api.patch(`admin/manager/${managerId}`, data);
  }

  // 관리자 승인
  static async approve(managerId, data) {
    return await api.post(`admin/manager/approval/${managerId}`, data);
  }

  // 관리자 활성/비활성
  static async activation(managerId, data) {
    return await api.post(`admin/manager/activation/${managerId}`, data);
  }

  // 로그인 정보 조회
  static async getLoginInfo(params) {
    return await api.get(`admin/manager/login/info`, params);
  }

  // 관리자 회원가입
  static async sign(data) {
    return await api.post(`auth/admin/sign`, data);
  }

  // 관리자 로그인
  static async login(data) {
    return await api.post(`auth/admin/login`, data);
  }
}
