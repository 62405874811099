import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManagerApi from "../../api/manager.api";
import EditBtn from "../../component/atoms/button/editBtn";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../component/atoms/layout/mainLayout";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../component/molecules/goBack";
import Header from "../../component/organisms/header";
import Sidebar from "../../component/organisms/sidebar";
import { route } from "../../router/route";
import { errorHandler } from "../../util/errorHandler";
import { useTranslation } from "react-i18next";

function ManagerDetail(props) {
  const { t } = useTranslation();
  // import
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getManagerInfo();
  }, []);

  // ----- 정보 가져오기
  const [managerInfo, setManagerInfo] = useState({
    name: "",
    email: "",
    role: "",
  });

  const getManagerInfo = async () => {
    try {
      const response = await ManagerApi.getById(id);
      setManagerInfo({
        ...response.data.data.content,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <EditBtn
              onClick={() => {
                if (sessionStorage.getItem("role") === "ROOT") {
                  navigate(route.root_edit + `/${id}`);
                } else {
                  navigate(route.admin_edit + `/${id}`);
                }
              }}
            />
            {/* {sessionStorage.getItem("role") === "ROOT" ||
            Number(sessionStorage.getItem("id")) === Number(id) ? (
              <EditBtn
                onClick={() => {
                  if (sessionStorage.getItem("role") === "ROOT") {
                    navigate(route.manager_edit_root + `/${id}`);
                  } else {
                    navigate(route.manager_edit_admin + `/${id}`);
                  }
                }}
              />
            ) : null} */}
          </DetailTop>
          <DetailPage style={{ minHeight: "250px" }}>
            <DetailPageTitle>{t("manager.more")}</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>{t("manager.name")}</DetailFormTitle>
                <DetailFormText>{managerInfo.name}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("manager.email")}</DetailFormTitle>
                <DetailFormText>{managerInfo.email}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("manager.type")}</DetailFormTitle>
                <DetailFormText>{managerInfo.role}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
}

export default ManagerDetail;
