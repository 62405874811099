import api from "./api.js";

export default class PromotionApi {
  // 기획전 등록
  static async create(data) {
    return await api.post(`admin/promotion`, data);
  }

  // 기획전 목록 조회
  static async getList(params) {
    return await api.get(`admin/promotion`, params);
  }

  // 기획전 개별 조회
  static async getById(id, params) {
    return await api.get(`admin/promotion/${id}`, params);
  }

  // 기획전 삭제
  static async delete(data) {
    return await api.del(`admin/promotion`, data);
  }

  // 기획전 수정
  static async update(promotionId, data) {
    return await api.patch(`admin/promotion/${promotionId}`, data);
  }
}
