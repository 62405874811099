import React, { useEffect, useState } from "react";
// import BannerApi from "../../../api/banner.api";
import { useTranslation } from "react-i18next";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import PretendardText from "../../../component/atoms/text/pretendardText";
import Modal from "../../../component/templates/Modal";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { modalMsg } from "../../../util/modalMsg";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import TableTitle from "./../../../component/atoms/text/table/tableTitle";
import Header from "./../../../component/organisms/header";
import Sidebar from "./../../../component/organisms/sidebar";
import SettingApi from "./_api/setting.api";
import { errorHandler } from "../../../util/errorHandler";
import { toastMsg } from "../../../util/toastMsg";
import { toast } from "react-toastify";

const Point = () => {
  const { t } = useTranslation();

  const [normalReview, setNormalReview] = useState(0);
  const [photoReview, setPhotoReview] = useState(0);
  const [minimumHold, setMinimumHold] = useState(0);
  const [minimumPayment, setMinimumPayment] = useState(0);
  const [deadline, setDeadline] = useState(0);
  const [normalGrade, setNormalGrade] = useState(0);
  const [aGrade, setAGrade] = useState(0);
  const [bGrade, setBGrade] = useState(0);
  const [cGrade, setCGrade] = useState(0);

  const [info, setInfo] = useState({});

  const getPointSetting = async () => {
    try {
      let data = (await SettingApi.getPointSetting()).data.data.content;
      setNormalReview(data.normalReview);
      setPhotoReview(data.photoReview);
      setMinimumHold(data.minimumHold);
      setMinimumPayment(data.minimumPayment);
      setDeadline(data.deadline);
      setNormalGrade(data.normalGrade);
      setAGrade(data.aGrade);
      setBGrade(data.bGrade);
      setCGrade(data.cGrade);

      setInfo(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getPointSetting();
  }, []);

  const [type, setType] = useState(0);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      let data = {};
      if (type === 1) {
        data = {
          normalReview,
          photoReview,
          minimumHold,
          minimumPayment,
          deadline,

          normalGrade: info.normalGrade,
          aGrade: info.aGrade,
          bGrade: info.bGrade,
          cGrade: info.cGrade,
        };
      } else if (type === 2) {
        data = {
          normalReview: info.normalReview,
          photoReview: info.photoReview,
          minimumHold: info.minimumHold,
          minimumPayment: info.minimumPayment,
          deadline: info.deadline,

          normalGrade,
          aGrade,
          bGrade,
          cGrade,
        };
      }

      await SettingApi.updatePointSetting(data);
      setSaveModalOpen(false);
      toast(t("toastMsg.update"));
      getPointSetting();
      setType(0);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <Tab
            list={[
              { title: `${t("setting.tab1")}`, url: route.setting },
              { title: `${t("setting.tab2")}`, url: route.transport },
              { title: `${t("setting.tab3")}`, url: route.point },
              { title: `${t("setting.tab4")}`, url: route.buy_terms },
            ]}
            style={{ marginBottom: "20px" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TableTitle style={{ marginBottom: "20px" }}>
              {t("setting.tab3")}
            </TableTitle>
            <DetailPage
              style={{
                minHeight: "582px",
              }}
            >
              <DetailPageTitle style={{ marginBottom: "30px" }}>
                {t("point.title1")}
              </DetailPageTitle>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <DetailPageForm>
                  <DetailFormTitle>{t("point.inputTitle1")}</DetailFormTitle>
                  <MainInput
                    type={"number"}
                    style={{
                      width: "338px",
                    }}
                    value={normalReview}
                    onChange={(e) =>
                      setNormalReview(e.target.value.toLocaleString())
                    }
                  />
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("point.inputTitle2")}</DetailFormTitle>
                  <MainInput
                    type={"number"}
                    style={{
                      width: "338px",
                    }}
                    value={photoReview}
                    onChange={(e) =>
                      setPhotoReview(e.target.value.toLocaleString())
                    }
                  />
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>{t("point.inputTitle3")}</DetailFormTitle>
                  <div>
                    <div style={{ marginBottom: "10px" }}>
                      <DetailFormTitle
                        style={{ color: "#808991", marginBottom: "3px" }}
                      >
                        {t("point.subTitle1")}
                      </DetailFormTitle>
                      <MainInput
                        type={"number"}
                        style={{
                          width: "338px",
                        }}
                        value={minimumHold}
                        onChange={(e) =>
                          setMinimumHold(e.target.value.toLocaleString())
                        }
                      />
                    </div>
                    <div>
                      <DetailFormTitle
                        style={{ color: "#808991", marginBottom: "3px" }}
                      >
                        {t("point.subTitle2")}
                      </DetailFormTitle>
                      <MainInput
                        type={"number"}
                        style={{
                          width: "338px",
                        }}
                        value={minimumPayment}
                        onChange={(e) =>
                          setMinimumPayment(e.target.value.toLocaleString())
                        }
                      />
                    </div>
                  </div>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("point.inputTitle4")}</DetailFormTitle>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <PretendardText
                      fontWeight="400"
                      style={{
                        color: "#262C31",
                        fontSize: 15,
                      }}
                    >
                      {t("point.max")}
                    </PretendardText>
                    <MainInput
                      style={{ maxWidth: "289px" }}
                      type={"number"}
                      value={deadline}
                      onChange={(e) =>
                        setDeadline(e.target.value.toLocaleString())
                      }
                    />
                    <PretendardText
                      fontWeight="400"
                      style={{
                        color: "#262C31",
                        fontSize: 15,
                      }}
                    >
                      {t("point.month")}
                    </PretendardText>
                  </div>
                </DetailPageForm>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <SaveBtn
                  onClick={() => {
                    setType(1);
                    saveBtnHandler();
                  }}
                />
              </div>
            </DetailPage>
            <DetailPage
              style={{
                minHeight: "466px",
              }}
            >
              <DetailPageTitle style={{ marginBottom: "30px" }}>
                {t("point.title2")}
              </DetailPageTitle>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <DetailPageForm>
                  <DetailFormTitle>{t("point.inputTitle5")}</DetailFormTitle>
                  <MainInput
                    style={{
                      width: "338px",
                    }}
                    type={"number"}
                    value={normalGrade}
                    onChange={(e) =>
                      setNormalGrade(e.target.value.toLocaleString())
                    }
                  />
                  <PretendardText style={{ display: "flex" }}>%</PretendardText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("point.inputTitle6")}</DetailFormTitle>
                  <MainInput
                    style={{
                      width: "338px",
                    }}
                    type={"number"}
                    value={aGrade}
                    onChange={(e) => setAGrade(e.target.value.toLocaleString())}
                  />
                  <PretendardText style={{ display: "flex" }}>%</PretendardText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("point.inputTitle7")}</DetailFormTitle>
                  <MainInput
                    style={{
                      width: "338px",
                    }}
                    type={"number"}
                    value={bGrade}
                    onChange={(e) => setBGrade(e.target.value.toLocaleString())}
                  />
                  <PretendardText style={{ display: "flex" }}>%</PretendardText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("point.inputTitle8")}</DetailFormTitle>
                  <MainInput
                    style={{
                      width: "338px",
                    }}
                    type={"number"}
                    value={cGrade}
                    onChange={(e) => setCGrade(e.target.value.toLocaleString())}
                  />
                  <PretendardText style={{ display: "flex" }}>%</PretendardText>
                </DetailPageForm>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <SaveBtn
                  onClick={() => {
                    setType(2);
                    saveBtnHandler();
                  }}
                />
              </div>
            </DetailPage>
          </div>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default Point;
