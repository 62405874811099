import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import ManagerApi from '../../api/video_detail.api';
import EditBtn from "../../component/atoms/button/editBtn";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../component/atoms/layout/mainLayout";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../component/molecules/goBack";
import Header from "../../component/organisms/header";
import Sidebar from "../../component/organisms/sidebar";
import { route } from "../../router/route";
import { errorHandler } from "../../util/errorHandler";
import { useTranslation } from "react-i18next";
import FileImgPreview from "../../component/molecules/fileImgPreview";
import DelBtn from "../../component/atoms/button/delBtn";
import VideoApi from "../../api/video.api";
import Modal from "../../component/templates/Modal";
import { toastMsg } from "../../util/toastMsg";
import { modalMsg } from "../../util/modalMsg";
import { toast } from "react-toastify";

function VideoDetail(props) {
  const { t } = useTranslation();
  // import
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getVideoInfo();
  }, []);

  // ----- 정보 가져오기
  const [videoInfo, setVideoInfo] = useState({});

  const getVideoInfo = async () => {
    try {
      const response = (await VideoApi.getById(id)).data.data.content;
      setVideoInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 승인
  const [approveType, setApproveType] = useState("");
  const [approveModalOpen, setApproveModalOpen] = useState(false);

  const approveFunc = async () => {
    try {
      const data = {
        approveStatus: approveType,
      };

      await VideoApi.approve(id, data);
      setApproveModalOpen(false);

      toast(t("toastMsg.update"));
      setApproveType("");
      getVideoInfo();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            {videoInfo.approveStatus === "REQUEST" && (
              <div style={{ display: "flex", gap: "6px" }}>
                <DelBtn
                  onClick={() => {
                    setApproveType("REJECT");
                    setApproveModalOpen(true);
                  }}
                  text={t("video_detail.rejectBtn")}
                />
                <DelBtn
                  onClick={() => {
                    setApproveType("APPROVE");
                    setApproveModalOpen(true);
                  }}
                  text={t("video_detail.approveBtn")}
                />
              </div>
            )}
          </DetailTop>
          <DetailPage style={{ minHeight: "250px" }}>
            <DetailPageTitle>{t("video_detail.title")}</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>
                  {t("video_detail.videoTitle")}
                </DetailFormTitle>
                <DetailFormText>{videoInfo.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("video_detail.link")}</DetailFormTitle>
                <DetailFormText>{videoInfo.link}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("video_detail.nickname")}</DetailFormTitle>
                <DetailFormText>{videoInfo.influencerName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>
                  {t("video_detail.productCode")}
                </DetailFormTitle>
                <DetailFormText>{videoInfo.merchCode}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>
                  {t("video_detail.productName")}
                </DetailFormTitle>
                <DetailFormText>{videoInfo.merchTitle}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("video_detail.amount")}</DetailFormTitle>
                <DetailFormText>
                  {videoInfo.salePrice?.toLocaleString()}
                  {t("component.monetary")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("video_detail.img")}</DetailFormTitle>
                <FileImgPreview
                  fileName={videoInfo.imageOriginFileName}
                  serverFileName={videoInfo.imageServerFileName}
                />
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {approveModalOpen ? (
        <Modal
          title={
            approveType === "APPROVE"
              ? modalMsg.approve.title
              : modalMsg.deny.title
          }
          setModalOpen={setApproveModalOpen}
          onClick={() => {
            approveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default VideoDetail;
