import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import DelBtn from "../../../component/atoms/button/delBtn";
import EditBtn from "../../../component/atoms/button/editBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import SearchBar from "../../../component/atoms/input/searchBar";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import GoBack from "../../../component/molecules/goBack";
import Pagination from "../../../component/molecules/pagination";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import ExhibitionModal from "./exhibitionModal";
import PromotionApi from "../../../api/promotion.api";
import moment from "moment";
import MerchApi from "../../../api/merch.api";
import ExcelApi from "../../../api/excel.api";
import { saveAs } from "file-saver";

const ExhibitionDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [merchList, setMerchList] = useState([]);
  const [promotionInfo, setPromotionInfo] = useState({});

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    if (promotionInfo.id) getMerchList(promotionInfo.id);
  }, [location]);

  const [keyword, setKeyword] = useState("");

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  useEffect(() => {
    getPromotionInfo();
  }, []);

  const getPromotionInfo = async () => {
    try {
      const response = (await PromotionApi.getById(id)).data.data.content;
      setPromotionInfo(response);

      getMerchList(response.id);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getMerchList = async (promotionId) => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
        promotionId,
      };

      const responseData = (await MerchApi.getList(data)).data.data;

      setMerchList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );

      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: [id],
      };
      await PromotionApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activationId, setActivationId] = useState(null);
  const [activation, setActivation] = useState(null);
  const [activationModalTitle, setActivationModalTitle] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      const data = {
        activation: activation,
      };
      await MerchApi.activation(activationId, data);
      toast(t("toastMsg.create"));
      setFilterOpen(-1);
      setActivationModalOpen(false);
      getMerchList(promotionInfo.id);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 상품 모달 오픈
  const [selectedMerchInfo, setSelectedMerchInfo] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: `${t(`product.name`)}`, key: "title", width: 40 },
      { header: `${t(`product.category`)}`, key: "categoryTitle", width: 40 },
      { header: `${t(`product.amount`)}`, key: "salePrice", width: 40 },
      { header: `${t(`product.state`)}`, key: "activeTitle", width: 40 },
      { header: `${t(`product.productState`)}`, key: "inventoryTitle", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${t("product.title")}.xlsx`);
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetMerchExcel({
          ids: [],
          allFlag: true,
        })
      ).data.data.content;

      response.map((item) => {
        const categoryTitle = item.category?.title;
        const activeTitle = item.merchStatus === "PROGRESS" ? `${t("product.ing")}` : `${t("product.stop")}`;
        const inventoryTitle =
          item.inventoryType === "PARTIALLY_OUT_OF_STOCK" ? `${t("product.soldoutSome")}` : item.inventoryType === "OUT_OF_STOCK" ? `${t("product.soldoutAll")}` : `${t("product.normal")}`;
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          categoryTitle: categoryTitle,
          activeTitle: activeTitle,
          inventoryTitle: inventoryTitle,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = merchList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast(t("placeholder.selectItem"));
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetMerchExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
        })
      ).data.data.content;

      response.map((item) => {
        const categoryTitle = item.category?.title;
        const activeTitle = item.merchStatus === "PROGRESS" ? `${t("product.ing")}` : `${t("product.stop")}`;
        const inventoryTitle =
          item.inventoryType === "PARTIALLY_OUT_OF_STOCK" ? `${t("product.soldoutSome")}` : item.inventoryType === "OUT_OF_STOCK" ? `${t("product.soldoutAll")}` : `${t("product.normal")}`;
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          categoryTitle: categoryTitle,
          activeTitle: activeTitle,
          inventoryTitle: inventoryTitle,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDeleteModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.exhibition_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "495px", marginBottom: "40px" }}>
            <DetailPageTitle>{t("exhibition_detail.more")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 기획전명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("exhibition_detail.title")}</DetailFormTitle>
                <DetailFormText>{promotionInfo.title}</DetailFormText>
              </DetailPageForm>

              {/* 기획전 썸네일 이미지 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("exhibition_detail.thumbnail")}</DetailFormTitle>
                <FileImgPreview fileName={promotionInfo.imageOriginFileName} serverFileName={promotionInfo.imageServerFileName} />
              </DetailPageForm>

              {/* 기획전 PC 이미지 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("exhibition_detail.pcImg")}</DetailFormTitle>
                <FileImgPreview fileName={promotionInfo.bannerImageOriginFileName} serverFileName={promotionInfo.bannerImageServerFileName} />
              </DetailPageForm>

              {/* 기획전 모바일 이미지 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("exhibition_detail.mobileImg")}</DetailFormTitle>
                <FileImgPreview fileName={promotionInfo.mobileImageOriginFileName} serverFileName={promotionInfo.mobileImageServerFileName} />
              </DetailPageForm>

              {/* 기간 */}
              <DetailPageForm>
                <DetailFormTitle>{t("exhibition_detail.period")}</DetailFormTitle>
                <DetailFormText>
                  {moment(promotionInfo.startDate).format("YYYY/MM/DD")} - {moment(promotionInfo.endDate).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
          <div>
            <TableTop>
              <TableTitle style={{ fontSize: "24px", marginBottom: 0 }}>{t("exhibition_detail.tableTitle")}</TableTitle>
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`${route.exhibition_detail + `/${id}`}?page=1&keyword=${keyword}`, {
                        replace: true,
                      });
                    }
                  }}
                  onClick={() => {
                    navigate(`${route.exhibition_detail + `/${id}`}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }}
                />
                <ExcelBtn onSelectClick={getExcelChecked} onAllClick={getExcelAll} />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 체크박스 */}
                <col width="90px" />
                {/* 상품명 */}
                <col width="200px" />
                {/* 카테고리 */}
                <col width="188px" />
                {/* 상품 가격 */}
                <col width="216px" />
                {/* 판매 상태 */}
                <col width="142px" />
                {/* 재고 */}
                <col width="130px" />
                {/* 상세보기 */}
                <col width="70px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <Checkbox
                      checked={false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setMerchList(
                            merchList.map((item) => {
                              return {
                                ...item,
                                checked: true,
                              };
                            })
                          );
                        } else {
                          setMerchList(
                            merchList.map((item) => {
                              return {
                                ...item,
                                checked: false,
                              };
                            })
                          );
                        }
                      }}
                    />
                  </Th>
                  <Th>
                    <TableText>{t("exhibition_detail.productName")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("exhibition_detail.category")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("exhibition_detail.amount")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("exhibition_detail.saleState")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("exhibition_detail.productState")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("exhibition_detail.more")}</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {merchList.map((item, index) => {
                  return (
                    <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                      <Td style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => {
                            item.checked = !item.checked;
                            setMerchList([...merchList]);
                          }}
                        />
                      </Td>
                      <Td>
                        <TableText>{item.title}</TableText>
                      </Td>
                      <Td>
                        <TableText>
                          <TableText style={{ width: "188px" }}>{item.category?.title}</TableText>
                        </TableText>
                      </Td>
                      <Td>
                        <TableText>{item.salePrice}</TableText>
                      </Td>
                      <Td>
                        <TableFilter
                          style={
                            {
                              // width: "75px",
                            }
                          }
                          onClick={() => {
                            filterOpen === index ? setFilterOpen(-1) : setFilterOpen(index);
                          }}
                          isOpen={filterOpen === index}
                          selectedValue={item.merchStatus}
                          list={[
                            {
                              value: "PROGRESS",
                              title: `${t("product.ing")}`,
                              onClick: () => {
                                setActivationId(item.id);
                                setActivation(true);
                                setActivationModalTitle(`${t("modalMsg.activate")}`);
                                setActivationModalOpen(true);
                              },
                            },
                            {
                              value: "END",
                              title: `${t("product.stop")}`,
                              onClick: () => {
                                setActivationId(item.id);
                                setActivation(false);
                                setActivationModalTitle(`${t("modalMsg.deactivate")}`);
                                setActivationModalOpen(true);
                              },
                            },
                          ]}
                          setFilterOpen={setFilterOpen}
                        />
                      </Td>
                      <Td>
                        <TableText>{item.inventoryTypeTitle ? item.inventoryTypeTitle : "정상"}</TableText>
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            setSelectedMerchInfo(item);
                            setDetailModalOpen(true);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.exhibition_detail + `/${id}`}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {activationModalOpen ? (
        <Modal
          title={`${activationModalTitle}`}
          setModalOpen={setActivationModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}

      {detailModalOpen ? <ExhibitionModal data={selectedMerchInfo} setModalOpen={setDetailModalOpen} /> : null}
    </>
  );
};

export default ExhibitionDetail;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
