import api from "../../../../api/api";

export default class MainInfoApi {
  static async getMainInfo() {
    return await api.get(`admin/main-info`);
  }
  static async updateMainInfo(id, data) {
    return await api.patch(`admin/main-info/${id}`, data);
  }
}
