import api from "../../../../api/api";


export default class AskApi {  
  static async getOrderAskList(params) {
    return await api.get(`admin/orderAsk`, params);
  }
  static async getOrderAskById(orderAskId, params) {
    return await api.get(`admin/orderAsk/${orderAskId}`, params);
  }
  static async updateOrderAsk(orderAskId, data) {
    return await api.patch(`admin/orderAsk/${orderAskId}`, data);
  }  

  static async getMerchAskList(params) {
    return await api.get(`admin/merchAsk`, params);
  }
  static async getMerchAskById(merchAskId, params) {
    return await api.get(`admin/merchAsk/${merchAskId}`, params);
  }
  static async updateMerchAsk(merchAskId, data) {
    return await api.patch(`admin/merchAsk/${merchAskId}`, data);
  }  
}
