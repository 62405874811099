import { Routes, Route } from "react-router-dom";
import React from "react";
import { route } from "./route";
import Login from "../pages/login/login";
import Join from "../pages/join/join";
import JoinComplete from "../pages/join/joinComplete";
import Home from "../pages/home/home";
import CommonMember from "../pages/user/commonMember/commonMember";
import CommonMemberDetail from "../pages/user/commonMember/commonMemberDetail";
import CommonMemberEdit from "../pages/user/commonMember/commonMemberEdit";
import Manager from "./../pages/manager/manager";
import ManagerDetail from "./../pages/manager/managerDetail";
import RootEdit from "./../pages/manager/rootEdit";
import AdminEdit from "./../pages/manager/adminEdit";
import Popup from "../pages/service/popup/popup";
import PopupCreate from "../pages/service/popup/popupCreate";
import PopupDetail from "../pages/service/popup/popupDetail";
import PopupEdit from "../pages/service/popup/popupEdit";
import Basic from "../pages/service/basic/basic";
import BasicEdit from "../pages/service/basic/basicEdit";
import TermsAdd from "../pages/service/terms/termsAdd";
import TermsDetail from "../pages/service/terms/termsDetail";
import TermsEdit from "../pages/service/terms/termsEdit";
import Terms from "../pages/service/terms/terms";
import Faq from "../pages/customer/faq/faq";
import FaqAdd from "../pages/customer/faq/faqAdd";
import FaqDetail from "../pages/customer/faq/faqDetail";
import FaqEdit from "../pages/customer/faq/faqEdit";
import Notice from "../pages/customer/notice/notice";
import NoticeAdd from "../pages/customer/notice/noticeAdd";
import NoticeDetail from "../pages/customer/notice/noticeDetail";
import Alarm from "../pages/customer/alarm/alarm";
import AlarmAdd from "../pages/customer/alarm/alarmAdd";
import AlarmDetail from "../pages/customer/alarm/alarmDetail";
import LeaveMember from "../pages/user/leaveMember/leaveMember";
import Setting from "../pages/service/setting/setting";
import Transport from "../pages/service/setting/transport";
import Point from "../pages/service/setting/point";
import BuyTerms from "../pages/service/setting/buyTerms";
import Ask from "../pages/customer/ask/ask";
import Qna from "../pages/customer/ask/qna";
import Influencer from "../pages/user/influencer/influencer";
import InfluencerDetail from "../pages/user/influencer/influencerDetail";
import InfluencerEdit from "../pages/user/influencer/influencerEdit";
import Calculate from "../pages/user/calculate/calculate";
import JoinApprove from "../pages/user/approve/joinApprove";
import LeaveApprove from "../pages/user/approve/leaveApprove";
import JoinApproveDetail from "../pages/user/approve/joinApproveDetail";
import LeaveApproveDetail from "../pages/user/approve/leaveApproveDetail";
import Video from "../pages/video/video";
import VideoDetail from "../pages/video/videoDetail";
import Category from "../pages/product/category/category";
import CategoryDetail from "../pages/product/category/categoryDetail";
import CategoryEdit from "../pages/product/category/categoryEdit";
import CategoryAdd from "../pages/product/category/categoryAdd";
import Return from "../pages/order/return/return";
import Review from "../pages/product/review/review";
import CouponIssuance from "../pages/product/coupon/couponIssuance";
import CouponHistory from "../pages/product/coupon/couponHistory";
import Exhibition from "../pages/product/exhibition/exhibition";
import ExhibitionDetail from "../pages/product/exhibition/exhibitionDetail";
import ExhibitionAdd from "../pages/product/exhibition/exhibitionAdd";
import ExhibitionEdit from "../pages/product/exhibition/exhibitionEdit";
import Exchange from "../pages/order/exchange/exchange";
import Cancel from "../pages/order/cancel/cancel";
import Shipping from "../pages/order/shipping/shipping";
import Product from "../pages/product/product/product";
import ProductDetail from "../pages/product/product/productDetail";
import ProductAdd from "../pages/product/product/productAdd";
import ProductEdit from "../pages/product/product/productEdit";
import MainManage from "../pages/service/mainManage/mainManage";
import Banner from "../pages/service/banner/banner";
import BannerServe from "../pages/service/banner/bannerServe";

const Router = () => {
  return (
    <Routes>
      {/* ---------- 기본 ---------- */}
      <Route path={route.default} element={<Login />} />

      {/* ---------- 로그인 ---------- */}
      <Route path={route.login} element={<Login />} />

      {/* ---------- 회원가입 ---------- */}
      <Route path={route.join} element={<Join />} />
      <Route path={route.join_complete} element={<JoinComplete />} />

      {/* ---------- 홈 ---------- */}
      <Route path={route.home} element={<Home />} />

      {/* ---------- 일반 회원 관리 ---------- */}
      <Route path={route.common_member} element={<CommonMember />} />
      <Route path={route.common_member_detail + "/:id"} element={<CommonMemberDetail />} />
      <Route path={route.common_member_edit + "/:id"} element={<CommonMemberEdit />} />

      {/* ---------- 인플루언서 회원  관리 ---------- */}
      <Route path={route.influencer_member} element={<Influencer />} />
      <Route path={route.influencer_member_detail + "/:id"} element={<InfluencerDetail />} />
      <Route path={route.influencer_member_edit + "/:id"} element={<InfluencerEdit />} />

      {/* ---------- 인플루언서 승인 요청 ---------- */}
      <Route path={route.approve_signup} element={<JoinApprove />} />
      <Route path={route.approve_signup_detail + "/:id"} element={<JoinApproveDetail />} />
      <Route path={route.approve_leave} element={<LeaveApprove />} />
      <Route path={route.approve_leave_detail + "/:id"} element={<LeaveApproveDetail />} />

      {/* ---------- 정산 관리 ---------- */}
      <Route path={route.calculate} element={<Calculate />} />

      {/* ---------- 탈퇴 회원 관리 ---------- */}
      <Route path={route.leave_member} element={<LeaveMember />} />

      {/* ---------- 관리자 관리 ---------- */}
      <Route path={route.manager} element={<Manager />} />
      <Route path={route.manager_detail + "/:id"} element={<ManagerDetail />} />
      <Route path={route.root_edit + "/:id"} element={<RootEdit />} />
      <Route path={route.admin_edit + "/:id"} element={<AdminEdit />} />

      {/* ---------- 영상 관리 ---------- */}
      <Route path={route.approve_video} element={<Video />} />
      <Route path={route.approve_video_detail + "/:id"} element={<VideoDetail />} />

      {/* ---------- 메인 관리 ---------- */}
      <Route path={route.main} element={<MainManage />} />

      {/* ---------- 배너 관리 ---------- */}
      <Route path={route.banner_main} element={<Banner />} />
      <Route path={route.banner_serve} element={<BannerServe />} />

      {/* ---------- 팝업 관리 ---------- */}
      <Route path={route.popup} element={<Popup />} />
      <Route path={route.popup_detail + "/:id"} element={<PopupDetail />} />
      <Route path={route.popup_edit + "/:id"} element={<PopupEdit />} />
      <Route path={route.popup_add} element={<PopupCreate />} />

      {/* ---------- 기본 관리 ---------- */}
      <Route path={route.basic} element={<Basic />} />
      <Route path={route.basic_edit} element={<BasicEdit />} />

      {/* ---------- 약관 관리 ---------- */}
      <Route path={route.terms} element={<Terms />} />
      <Route path={route.terms_add} element={<TermsAdd />} />
      <Route path={route.terms_detail + "/:id"} element={<TermsDetail />} />
      <Route path={route.terms_edit + "/:id"} element={<TermsEdit />} />

      {/* ---------- faq 관리 ---------- */}
      <Route path={route.faq} element={<Faq />} />
      <Route path={route.faq_add} element={<FaqAdd />} />
      <Route path={route.faq_detail + "/:id"} element={<FaqDetail />} />
      <Route path={route.faq_edit + "/:id"} element={<FaqEdit />} />

      {/* ----------- 공지사항 관리 ---------- */}
      <Route path={route.notice} element={<Notice />} />
      <Route path={route.notice_add} element={<NoticeAdd />} />
      <Route path={route.notice_detail + "/:id"} element={<NoticeDetail />} />

      {/* ----------- 1:1문의 관리 ---------- */}
      <Route path={route.ask} element={<Ask />} />
      <Route path={route.qna} element={<Qna />} />

      {/* ----------- 알람 관리 ---------- */}
      <Route path={route.alarm} element={<Alarm />} />
      <Route path={route.alarm_add} element={<AlarmAdd />} />
      <Route path={route.alarm_detail + "/:id"} element={<AlarmDetail />} />

      {/* ----------- 상품 환경설정 ---------- */}
      <Route path={route.setting} element={<Setting />} />
      <Route path={route.transport} element={<Transport />} />
      <Route path={route.point} element={<Point />} />
      <Route path={route.buy_terms} element={<BuyTerms />} />

      {/* ----------- 카테고리 ---------- */}
      <Route path={route.category} element={<Category />} />
      <Route path={route.category_detail + "/:id"} element={<CategoryDetail />} />
      <Route path={route.category_edit + "/:id"} element={<CategoryEdit />} />
      <Route path={route.category_add} element={<CategoryAdd />} />

      {/* ----------- 주문관리 ---------- */}
      <Route path={route.return} element={<Return />} />
      <Route path={route.exchange} element={<Exchange />} />
      <Route path={route.cancel} element={<Cancel />} />
      <Route path={route.shipping} element={<Shipping />} />

      {/* ----------- 리뷰 ---------- */}
      <Route path={route.review} element={<Review />} />

      {/* ----------- 쿠폰 ---------- */}
      <Route path={route.coupon_add} element={<CouponIssuance />} />
      <Route path={route.coupon_issued} element={<CouponHistory />} />

      {/* ----------- 기획전 ---------- */}
      <Route path={route.exhibition} element={<Exhibition />} />
      <Route path={route.exhibition_detail + "/:id"} element={<ExhibitionDetail />} />
      <Route path={route.exhibition_edit + "/:id"} element={<ExhibitionEdit />} />
      <Route path={route.exhibition_add} element={<ExhibitionAdd />} />

      {/* ----------- 상품 ---------- */}
      <Route path={route.product_list} element={<Product />} />
      <Route path={route.product_detail + "/:id"} element={<ProductDetail />} />
      <Route path={route.product_add} element={<ProductAdd />} />
      <Route path={route.product_edit + "/:id"} element={<ProductEdit />} />
    </Routes>
  );
};

export default Router;
