import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { isNull } from "../../../util/check";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import { useTranslation } from "react-i18next";
import DateInput from "../../../component/atoms/input/dateInput";
import SelectBox from "../../../component/molecules/selectBox";
import ImgPreview from "../../../component/molecules/imgPreview";
import MemberApi from "../../../api/member.api";
import PretendardText from "./../../../component/atoms/text/pretendardText";
import { fileUrl } from "../../../api/api";
import CommonApi from "../../../api/common.api";
import ImgInput from "../../../component/atoms/input/imgInput";
import Radio from "../../../component/atoms/input/radio";

const InfluencerEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getMemberInfo();
  }, []);

  const [memberInfo, setMemberInfo] = useState({
    email: "", // 이메일 ex) test@test.com
    phoneNumber: "", // 전화번호 ex) 010-1234-5678
    birth: "", // 생년월일 ex) 1990/01/01
    gender: "", // 성별 ex) ["NONE", "WOMAN", "MAN"]
    address: "", // 주소 ex) 서울특별시 우리구 우리로 101
    detailAddress: "", // 상세주소 ex) 우리아파트 101동 101호
    recommenderID: "", // 추천인 ID ex) "test"
    marketingAgreement: false, // [선택] 마케팅 목적의 개인정보 수집 및 이용 동의
    adAgreement: false, // [선택] 광고성 정보 수신 동의
    bankName: "", // 은행명 ex) 은행이름1
    accountHolderName: "", // 예금주 ex) 계좌주명1
    accountNumber: "", // 계좌번호 ex) 123-456-7890
    imageOriginFileName: "", // 프로필 이미지 원본 파일명 ex) test.png
    imageServerFileName: "", // 프로필 이미지 서버 파일명 ex) profile/4e1faa90-413c-4fc2-8323-ed3c5231e7f7.png
    screenshots: [
      // 스크린샷 목록
      {
        screenshotOriginFileName: "",
        screenshotServerFileName: "",
      },
    ],
    deleteIds: [0], // 삭제할 스크린샷 ID 목록
    passwordChangeFlag: false, // 비밀번호 변경 여부
    password: "", // 비밀번호 ex) test1234
    snsLink: "[]", // SNS 링크 목록
    isPick: false,
  });

  const [screenShotImgList, setScreenShotImgList] = useState({
    img1: {
      id: 0,
      file: "",
      imageLocalFileUrl: "",
      screenshotOriginFileName: "",
      screenshotServerFileName: "",
    },
    img2: {
      id: 0,
      file: "",
      imageLocalFileUrl: "",
      screenshotOriginFileName: "",
      screenshotServerFileName: "",
    },
    img3: {
      id: 0,
      file: "",
      imageLocalFileUrl: "",
      screenshotOriginFileName: "",
      screenshotServerFileName: "",
    },
  });

  const [delScreenShotIdList, setDelScreenShotIdList] = useState([]);

  const [snsLink, setSnsLink] = useState([]);

  const getMemberInfo = async () => {
    try {
      const response = (await MemberApi.getById(id)).data.data.content;

      setMemberInfo(response);

      setSnsLink(JSON.parse(response.snsLink));

      setScreenShotImgList((prevList) => ({
        ...prevList,
        img1: {
          ...prevList.img1,
          id: response.screenshots[0]?.id || 0,
          screenshotOriginFileName: response.screenshots[0]?.screenshotOriginFileName || "",
          screenshotServerFileName: response.screenshots[0]?.screenshotServerFileName || "",
        },
        img2: {
          ...prevList.img2,
          id: response.screenshots[1]?.id || 0,
          screenshotOriginFileName: response.screenshots[1]?.screenshotOriginFileName || "",
          screenshotServerFileName: response.screenshots[1]?.screenshotServerFileName || "",
        },
        img3: {
          ...prevList.img3,
          id: response.screenshots[2]?.id || 0,
          screenshotOriginFileName: response.screenshots[2]?.screenshotOriginFileName || "",
          screenshotServerFileName: response.screenshots[2]?.screenshotServerFileName || "",
        },
      }));
    } catch (error) {
      errorHandler(error);
    }
  };

  const [genderSelectBoxOpen, setGenderSelectBoxOpen] = useState(false);

  // ----------------------------------------------- //
  // ----------------- 이미지 관련 ----------------- //
  // --------------------------------------------- //

  // 이미지 추가, 삭제
  const inputFile = async (e, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = (value) => {
      setScreenShotImgList({
        ...screenShotImgList,
        [type]: {
          ...screenShotImgList[type],
          file: file,
          imageLocalFileUrl: value.target.result,
        },
      });

      e.target.value = "";
    };
  };

  // 파일 업로드
  const fileUpload = async (file) => {
    try {
      const data = {
        path: "influencer/screenshot/",
        extension: file.name.split(".").pop(),
      };

      const response = (await CommonApi.fileUpload(data)).data.data;

      await cloudFileUpload(response.presignedUrl, file);

      return {
        screenshotOriginFileName: file.name,
        screenshotServerFileName: response.serverFileName,
      };
    } catch (error) {
      errorHandler(error);
    }
  };

  const cloudFileUpload = async (url, file) => {
    try {
      await CommonApi.cloudFileUpload(url, file);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 첨부 파일 삭제
  const deleteFile = (type) => {
    setScreenShotImgList({
      ...screenShotImgList,
      [type]: {
        ...screenShotImgList[type],
        file: "",
        imageLocalFileUrl: "",
      },
    });
  };

  // --------------------------------------------- //
  // ----------------- 저장하기 ----------------- //
  // ------------------------------------------- //
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const firstFilteredImgList = Object.values(screenShotImgList).filter((img) => img.file !== "");

      const uploadedImages = await Promise.all(firstFilteredImgList.map((img) => fileUpload(img.file)));

      let data = {
        email: memberInfo.email,
        name: memberInfo.name,
        phoneNumber: memberInfo.phoneNumber,
        birth: memberInfo.birth,
        gender: memberInfo.gender,
        address: memberInfo.address,
        detailAddress: memberInfo.detailAddress,
        recommenderID: memberInfo.recommenderID,
        marketingAgreement: memberInfo.marketingAgreement,
        adAgreement: memberInfo.adAgreement,
        bankName: memberInfo.bankName,
        accountHolderName: memberInfo.accountHolderName,
        accountNumber: memberInfo.accountNumber,
        imageOriginFileName: memberInfo.imageOriginFileName,
        imageServerFileName: memberInfo.imageServerFileName,
        screenshots: [],
        deleteIds: delScreenShotIdList,
        // deleteIds: [],
        passwordChangeFlag: false,
        password: memberInfo.password,
        snsLink: JSON.stringify(snsLink),
        isPick: memberInfo.isPick,
      };

      uploadedImages.forEach((img) => {
        if (img) {
          data.screenshots.push({
            screenshotOriginFileName: img.screenshotOriginFileName,
            screenshotServerFileName: img.screenshotServerFileName,
          });
        }
      });

      await MemberApi.update(id, data);
      setSaveModalOpen(false);
      navigate(-1);
      toast(t("toastMsg.update"));
    } catch (error) {
      errorHandler(error);
    }
  };

  const dropdownBtnRef = useRef(null);
  const dropdownSelectBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownSelectBoxRef.current && !dropdownSelectBoxRef.current.contains(event.target) && dropdownBtnRef.current && !dropdownBtnRef.current.contains(event.target)) {
        setGenderSelectBoxOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "1487px" }}>
            <DetailPageTitle>{t("component.edit")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 이메일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.email")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={memberInfo.email}
                  disabled={true}
                />
              </DetailPageForm>
              {/* 이름 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.name")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.namePlaceholder")}
                  value={memberInfo.name}
                  onChange={(e) =>
                    setMemberInfo({
                      ...memberInfo,
                      name: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              {/* 닉네임 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.nickname")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={memberInfo.nickname}
                  disabled={true}
                />
              </DetailPageForm>
              {/* 휴대폰 번호 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.phone")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={memberInfo.phoneNumber}
                  onChange={(e) =>
                    setMemberInfo({
                      ...memberInfo,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              {/* 생년월일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.birth")}</DetailFormTitle>
                <DateInput
                  style={{
                    width: "338px",
                  }}
                  value={memberInfo.birth ? memberInfo.birth.split("T")[0] : ""}
                  onChange={(e) =>
                    setMemberInfo({
                      ...memberInfo,
                      birth: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              {/* 성별 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.gender")}</DetailFormTitle>
                {/* <SelectBox
                  style={{ width: "338px" }}
                  placeholder={"성별을 선택해주세요."}
                  list={["남", "여"]}
                /> */}
                <div>
                  <div style={{ position: "relative", width: "338px" }} ref={dropdownBtnRef}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setGenderSelectBoxOpen(!genderSelectBoxOpen);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: memberInfo.gender === "" || memberInfo.gender === "NONE" ? "#808991" : "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {memberInfo.gender === "" || memberInfo.gender === "NONE"
                          ? t("placeholder.defaultPlaceholder")
                          : memberInfo.gender === "WOMAN"
                          ? t("common_member.woman")
                          : t("common_member.man")}
                      </PretendardText>
                      <img className={genderSelectBoxOpen ? "rotate" : "rotate-back"} src="/assets/admin/icons/dropdown_arrow.png" alt="" style={{ width: "16px", height: "16px" }} />
                    </SelectBoxWrapper>
                    {genderSelectBoxOpen ? (
                      <SubMenuWrapper ref={dropdownSelectBoxRef}>
                        {["MAN", "WOMAN"].map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setMemberInfo({
                                  ...memberInfo,
                                  gender: item,
                                });
                                setGenderSelectBoxOpen(!genderSelectBoxOpen);
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item === "" || item === "NONE" ? "" : item === "WOMAN" ? t("common_member.woman") : t("common_member.man")}
                              </PretendardText>
                              {memberInfo.gender === item ? <img src="/assets/admin/icons/ico_select_checked.svg" alt="" /> : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>
              {/* 주소 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.address")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <MainInput
                    style={{
                      width: "338px",
                    }}
                    type={"text"}
                    placeholder={t("placeholder.defaultPlaceholder")}
                    value={memberInfo.address}
                    onChange={(e) => {
                      setMemberInfo({
                        ...memberInfo,
                        address: e.target.value,
                      });
                    }}
                  />
                  <MainInput
                    style={{
                      width: "338px",
                    }}
                    type={"text"}
                    placeholder={t("placeholder.defaultPlaceholder")}
                    value={memberInfo.detailAddress}
                    onChange={(e) => {
                      setMemberInfo({
                        ...memberInfo,
                        detailAddress: e.target.value,
                      });
                    }}
                  />
                </div>
              </DetailPageForm>
              {/* 은행명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.bankName")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={memberInfo.bankName}
                  onChange={(e) => {
                    setMemberInfo({
                      ...memberInfo,
                      bankName: e.target.value,
                    });
                  }}
                />
              </DetailPageForm>
              {/* 계좌번호 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.account")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={memberInfo.accountNumber}
                  onChange={(e) => {
                    setMemberInfo({
                      ...memberInfo,
                      accountNumber: e.target.value,
                    });
                  }}
                />
              </DetailPageForm>
              {/* 예금주명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.accountHolder")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={memberInfo.accountHolderName}
                  onChange={(e) => {
                    setMemberInfo({
                      ...memberInfo,
                      accountHolderName: e.target.value,
                    });
                  }}
                />
              </DetailPageForm>
              {/* 귀속 회원 수 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.attribution")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={"0"}
                  value={memberInfo.recommendCount}
                  disabled={true}
                />
              </DetailPageForm>
              {/* SNS 링크 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.snsLink")}</DetailFormTitle>
                <div style={{ display: "flex", gap: "12px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    {snsLink.map((item, index) => {
                      return (
                        <MainInput
                          key={index}
                          style={{
                            width: "338px",
                          }}
                          type={"text"}
                          placeholder={t("placeholder.defaultPlaceholder")}
                          value={item}
                          onChange={(e) => {
                            const copyArr = [...snsLink];
                            copyArr[index] = e.target.value;
                            setSnsLink(copyArr);
                            setMemberInfo({
                              ...memberInfo,
                              snsLink: JSON.stringify(copyArr),
                            });
                          }}
                        />
                      );
                    })}
                  </div>
                  <div
                    style={{
                      border: "1px solid #B8BFC4",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "48px",
                      height: "48px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (snsLink.length < 5) {
                        const copyArr = [...snsLink];
                        copyArr.push("");
                        setSnsLink(copyArr);
                      } else {
                        toast(t("influencer_detail.maxLengthToast"));
                      }
                    }}
                  >
                    <img src="/assets/admin/icons/plus.png" style={{ width: "18px", height: "18px" }} />
                  </div>
                </div>
              </DetailPageForm>
              {/* SNS 스크린샷 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.snsScreenShot")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  {/* SNS 스크린샷 - 1 */}
                  {screenShotImgList.img1.screenshotServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${screenShotImgList.img1.screenshotServerFileName}`}
                      onClick={() => {
                        setDelScreenShotIdList([...delScreenShotIdList, screenShotImgList.img1.id]);
                        setScreenShotImgList({
                          ...screenShotImgList,
                          img1: {
                            file: "",
                            imageLocalFileUrl: "",
                            screenshotOriginFileName: "",
                            screenshotServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : screenShotImgList.img1.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={screenShotImgList.img1.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img1");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img1"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img1");
                      }}
                    />
                  )}

                  {/* SNS 스크린샷 - 2 */}
                  {screenShotImgList.img2.screenshotServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${screenShotImgList.img2.screenshotServerFileName}`}
                      onClick={() => {
                        setDelScreenShotIdList([...delScreenShotIdList, screenShotImgList.img2.id]);
                        setScreenShotImgList({
                          ...screenShotImgList,
                          img2: {
                            file: "",
                            imageLocalFileUrl: "",
                            screenshotOriginFileName: "",
                            screenshotServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : screenShotImgList.img2.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={screenShotImgList.img2.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img2");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img2"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img2");
                      }}
                    />
                  )}

                  {/* SNS 스크린샷 - 3 */}
                  {screenShotImgList.img3.screenshotServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${screenShotImgList.img3.screenshotServerFileName}`}
                      onClick={() => {
                        setDelScreenShotIdList([...delScreenShotIdList, screenShotImgList.img3.id]);
                        setScreenShotImgList({
                          ...screenShotImgList,
                          img3: {
                            file: "",
                            imageLocalFileUrl: "",
                            screenshotOriginFileName: "",
                            screenshotServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : screenShotImgList.img3.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={screenShotImgList.img3.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img3");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img3"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img3");
                      }}
                    />
                  )}
                </div>
              </DetailPageForm>

              {/* 코비엣 PICK 인플루언서 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.pick")}</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"YES"}
                    valueText={t("component.yes")}
                    name={"isPick"}
                    checked={memberInfo.isPick}
                    onChange={() => {
                      setMemberInfo({
                        ...memberInfo,
                        isPick: true,
                      });
                    }}
                  />
                  <Radio
                    id={"NO"}
                    valueText={t("component.no")}
                    name={"isPick"}
                    checked={!memberInfo.isPick}
                    onChange={() => {
                      setMemberInfo({
                        ...memberInfo,
                        isPick: false,
                      });
                    }}
                  />
                </div>
              </DetailPageForm>

              {/* 약관 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("influencer_detail.terms")}*</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.useTerms")}</DetailFormText>
                    <Checkbox id="agreement" checked={true} disabled={true} />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.collect")}</DetailFormText>
                    <Checkbox id="privacy" checked={true} disabled={true} />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.providing")}</DetailFormText>
                    <Checkbox id="privacy" checked={true} disabled={true} />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>

              {/* 알림 수신 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("influencer_detail.alarm")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.sms")}</DetailFormText>
                    <Checkbox
                      id="marketingAgreement"
                      checked={memberInfo.marketingAgreement}
                      onChange={() =>
                        setMemberInfo({
                          ...memberInfo,
                          marketingAgreement: !memberInfo.marketingAgreement,
                        })
                      }
                    />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.ad")}</DetailFormText>
                    <Checkbox
                      id="adAgreement"
                      checked={memberInfo.adAgreement}
                      onChange={() =>
                        setMemberInfo({
                          ...memberInfo,
                          adAgreement: !memberInfo.adAgreement,
                        })
                      }
                    />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>
            </div>
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <SaveBtn
                style={{ position: "relative" }}
                onClick={() => {
                  saveBtnHandler();
                }}
              />
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default InfluencerEdit;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #b8bfc4;
  background: #fff;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  min-height: 48px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
  position: absolute;
  top: 50px;
  right: 0;
  padding: 10px;
  overflow-y: auto;
  z-index: 5;
`;

const SubMenuTextWrapper = styled.div.attrs((props) => {})`
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  width: "318px";
  height: "42px";
  padding: "10px";
  cursor: "pointer";
  border-radius: 3px;

  &:hover {
    background-color: #f2f4f5;
  }
`;
