export const ko = {
  auth: {
    login: "로그인",
    join: "회원가입",
    email: "이메일",
    emailError: "이메일 형식이 올바르지 않습니다",
    pw: "비밀번호",
    pwCaption: "영문/숫자/특수문자 중 2가지 이상 포함, 8자 ~ 16자",
    pwCheck: "비밀번호 확인",
    pwError: "비밀번호가 일치하지 않습니다",
    name: "이름",
    joinComplete: "가입완료",
    completeCaption: "관리자 승인 후 로그인이 가능합니다.",
    goLogin: "로그인 페이지로 이동",
  },

  header: {
    logout: "로그아웃",
  },

  placeholder: {
    defaultPlaceholder: "내용을 입력해 주세요.",
    emailPlaceholder: "이메일을 입력해 주세요.",
    pwPlaceholder: "비밀번호를 입력해 주세요.",
    pwCheckPlaceholder: "비밀번호를 한번 더 입력해 주세요.",
    namePlaceholder: "이름을 입력해 주세요.",
    searchPlaceholder: "검색어를 입력하세요",
    titlePlaceholder: "제목을 입력해 주세요.",
    contentPlaceholder: "내용을 입력해 주세요.",
    couponPlaceholder: "쿠폰명을 입력해 주세요.",
    amountPlaceholder: "금액을 입력해 주세요.",
    popupPlaceholder: "팝업명을 입력해 주세요.",
    categoryPlaceholder: "카테고리명을 입력해 주세요.",
    onlyNumberPlaceholder: "숫자만 입력해 주세요.",
    inventoryPlaceholder: "재고 수량을 입력해 주세요.",
    linkPlaceholder: "링크를 입력해 주세요.",
    exhibitionPlaceholder: "기획전명을 입력해 주세요.",
    datePlaceholder: "날짜를 입력하세요.",
    datePlaceholder02: "날짜를 선택해 주세요.",
    addressPlaceholder: "주소를 입력하세요.",
    detailAddressPlaceholder: "상세주소를 입력하세요.",
    selectItem: "항목을 선택해주세요.",
  },

  modalMsg: {
    update: {
      title: "저장하시겠습니까?",
      content: "이전 데이터는 복구가 불가합니다.\n저장을 진행하시겠습니까?",
    },
    delete: {
      title: "삭제하시겠습니까?",
      content: "삭제된 데이터는 복구가 불가합니다.\n그래도 삭제하시겠습니까?",
    },
    couponCancel: {
      title: "발급 취소 하시겠습니까?",
      content: "취소된 쿠폰은 복구가 불가합니다.",
    },
    couponComplete: {
      title: "사용 완료 처리 하시겠습니까?",
      content: "완료처리된 쿠폰은 복구가 불가합니다. \n그래도 진행하시겠습니까?",
    },
    create: "등록하시겠습니까?",
    approve: "승인하시겠습니까?",
    influencer_approve: "승인하시겠습니까?",
    deny: "반려하시겠습니까?",
    activate: "활성화하시겠습니까?",
    deactivate: "비활성화하시겠습니까?",
    rank: "등급을 변경 하시겠습니까?",
    influencer_deny: "취소",
    influencer_ok: "확인",
  },

  toastMsg: {
    create: "추가되었습니다.",
    update: "저장되었습니다.",
    delete: "삭제되었습니다.",
    input_empty: "필수 요소를 전부 입력해주세요.",
    errorText_500: "서버에 문제가 생겼습니다. 잠시 후에 다시 시도해주세요",
    productAddToast: "상품이 등록되었습니다. 옵션을 설정해주세요.",
    input_pw: "비밀번호를 입력해주세요.",
    check_pwFormat: "비밀번호를 올바르게 입력해주세요.",
    check_pw: "비밀번호가 일치하지 않습니다.",
    message401: "로그인 시간이 만료되었습니다. 다시 로그인해주세요.",
    errorEmail: "올바른 이메일 형식이 아닙니다.",
    errorPw: "올바른 비밀번호 형식이 아닙니다.",
    accountApprove: "계정이 승인되었습니다.",
    activeAdd: "카테고리 활성 이미지를 등록해주세요.",
    inActiveAdd: "카테고리 비활성 이미지를 등록해주세요.",
    titleAdd: "카테고리명을 입력해주세요",
    pcImageAdd: "PC이미지를 업로드해주세요.",
    moImageAdd: "모바일이미지를 업로드해주세요.",
    partAdd: "섹션명을 입력해주세요.",
    termTitleAdd: "약관명을 입력해주세요.",
    termContentAdd: "약관내용을 입력해주세요.",
  },

  errorMsg: {
    recommender: "입력한 추천인아이디가 존재하지 않습니다.",
  },

  sidebar: {
    home: "홈",
    user: "사용자 관리",
    common_member: "일반 회원 관리",
    influencer_member: "인플루언서 회원 관리",
    approve_signup: "인플루언서 승인 요청",
    calculate: "정산 관리",
    leave_member: "탈퇴 회원 관리",
    video: "영상 관리",
    approve_video: "영상 승인",
    product: "상품 관리",
    product_list: "상품 목록",
    category: "카테고리 관리",
    exhibition: "기획전 관리",
    coupon: "쿠폰 관리",
    review: "리뷰 관리",
    order: "주문 관리",
    shipping: "배송 관리",
    cancel: "취소/환불 내역",
    exchange: "교환 내역",
    return: "반품 내역",
    customer: "고객센터",
    alarm: "알림 관리",
    ask: "1:1문의 관리",
    notice: "공지사항 관리",
    faq: "FAQ 관리",
    service: "서비스 관리",
    setting: "상품 환경설정",
    terms: "약관 관리",
    basic: "기본 관리",
    popup: "팝업 관리",
    banner: "배너 관리",
    main: "메인 관리",
    manager: "관리자 권한 관리",
  },

  component: {
    excel: "엑셀로 내보내기",
    selectExcel: "선택 항목 내려받기",
    allExcel: "전체 항목 내려받기",
    active: "활성",
    inactive: "비활성",
    delete: "삭제",
    see: "보기",
    goBack: "뒤로가기",
    edit: "수정하기",
    save: "저장하기",
    memo: "메모하기",
    answer: "답변하기",
    addImg: "이미지 등록",
    add: "추가하기",
    required: "필수",
    choice: "선택",
    monetary: "동",
    count: "개",
    count02: "건",
    cancel: "취소",
    check: "확인",
    search: "검색",
    yes: "예",
    no: "아니오",
  },

  home: {
    welcome: "Welcome!",
    hello: "안녕하세요. 관리자님 :)",
    allUser: "전체 사용자 수",
    user: "회원(가입/탈퇴)",
    sales: "판매 현황",
    day: "일간",
    month: "월간",
    statistics: "항목별 통계",
    influencer: "판매 TOP 인플루언서",
    best: "베스트셀러",
    wishList: "위시리스트에 가장 많이 담긴 상품",
  },

  common_member: {
    title: "일반 회원 관리",
    userCount: "전체 이용자 수",
    email: "이메일",
    name: "이름",
    createdAt: "가입일",
    state: "계정 상태",
    delete: "계정 삭제",
    more: "상세보기",
    phone: "휴대폰 번호",
    man: "남",
    woman: "여",
  },

  common_member_detail: {
    userInfo: "회원정보",
    email: "이메일",
    name: "이름",
    phone: "휴대폰 번호",
    birth: "생년월일",
    gender: "성별",
    address: "주소",
    detailAddress: "상세주소",
    recommender: "추천인 아이디",
    point: "보유 포인트",
    terms: "약관 동의",
    alarm: "알림 수신 동의",
    useTerms: "회원이용약관 (필수)",
    collect: "개인정보 수집 및 이용동의 (필수)",
    providing: "개인정보 제 3자 제공 동의 (필수)",
    sms: "이메일 및 SMS 마케팅정보수신 동의 (선택)",
    service: "서비스 알림 수신 동의 (선택)",
    ad: "광고성 알림 수신 동의 (선택)",
  },

  influencer: {
    title: "인플루언서 회원 관리",
    userCount: "전체 이용자 수",
    email: "이메일",
    name: "이름",
    createdAt: "가입일",
    rank: "등급",
    state: "계정 상태",
    delete: "계정 삭제",
    more: "상세보기",
    aRank: "A등급",
    bRank: "B등급",
    cRank: "C등급",
    commonRank: "일반",
  },

  influencer_detail: {
    userInfo: "회원정보",
    email: "이메일",
    nickname: "닉네임",
    name: "이름",
    phone: "휴대폰 번호",
    birth: "생년월일",
    gender: "성별",
    address: "주소",
    bankName: "계좌 정보 - 은행명",
    account: "계좌 정보 - 계좌번호",
    accountHolder: "계좌 정보 - 예금주명",
    attribution: "귀속 회원 수",
    follower: "팔로워 수",
    snsLink: "SNS 링크",
    snsScreenShot: "SNS 스크린샷",
    point: "보유 포인트",
    terms: "약관 동의",
    alarm: "알림 수신 동의",
    useTerms: "회원이용약관 (필수)",
    collect: "개인정보 수집 및 이용동의 (필수)",
    providing: "개인정보 제 3자 제공 동의 (필수)",
    sms: "이메일 및 SMS 마케팅정보수신 동의 (선택)",
    service: "서비스 알림 수신 동의 (선택)",
    ad: "광고성 알림 수신 동의 (선택)",
    tableTitle: "귀속 회원 구매 내역",
    product: "상품명",
    amount: "결제금액",
    payDay: "결제일",
    point: "적립 포인트",
    maxLengthToast: "최대 5개까지 등록 가능합니다.",
    pick: "코비엣 PICK 인플루언서",
  },

  influencer_approve: {
    title: "인플루언서 승인 요청",
    tab1: "가입 승인 요청",
    tab2: "탈퇴 승인 요청",
    approveCount: "전체 요청 건수",
    email: "이메일",
    nickname: "닉네임",
    createdAt: "요청일",
    state: "계정 상태",
    approve: "승인 여부",
    more: "상세보기",
    checked: "승인 완료",
    reject: "미승인",
    approveBtn: "승인하기",
    delBtn: "선택항목 삭제",
    retiredApprove: "탈퇴 승인 하시겠습니까?",
  },

  approve_detail: {
    title: "기본 정보",
    email: "이메일",
    nickname: "닉네임",
    snsLink: "SNS 링크",
    snsScreenShot: "SNS 스크린샷",
    btn: "가입 승인",
    leaveBtn: "탈퇴 승인",
  },

  calculate: {
    title: "정산 관리",
    calculateCount: "전체 요청 건수",
    email: "이메일",
    bankname: "은행명",
    account: "계좌번호",
    accountHolderName: "예금주명",
    point: "정산 포인트",
    createdAt: "요청일",
    complete: "완료",
    notComplete: "미완료",
    completeBtn: "완료처리",
    checkedCompleteBtn: "선택항목 완료처리",
  },

  main_manage: {
    title: "메인 관리",
    sectionName: "섹션명",
    state: "게시 상태",
    exhibition: "기획전 상품 리스트",
    newProduct: "신규 상품 리스트",
    influencer: "인플루언서 리스트",
    event: "이벤트/베스트 바로가기 배너",
    MDPick: "MD PICK 상품 리스트",
    banner: "공지사항/자주 묻는 질문 바로가기 배너",
    placeholder: "섹션명을 입력해주세요",
  },

  banner: {
    title: "배너 관리",
    tab1: "메인페이지",
    tab2: "서브페이지",
    slide: "슬라이드 배너",
    pc: "PC 이미지",
    mobile: "모바일 이미지",
    recommendSize: "권장사이즈",
    link: "연결링크",
    newTab: "새창이동",
    goNewTab: "새창으로 이동",
    new: "신제품 이미지 배너",
    best: "베스트 이미지 배너",
    event: "이벤트 이미지 배너",
    hide: "숨김",
  },

  popup: {
    title: "팝업 관리",
    userCount: "전체 항목 수",
    delBtn: "선택항목 삭제",
    tableTitle: "제목",
    createdAt: "게시일",
    state: "게시 상태",
    more: "상세보기",
    del: "삭제하기",
    edit: "수정하기",
    popupName: "팝업명",
    period: "게시기간",
    image: "이미지",
    fullCaption: "최대 5장",
  },

  basic: {
    title: "기본 관리",
    edit: "수정하기",
    name: "상호명",
    ceo: "대표자",
    businessNumber: "사업자등록번호",
    mailOrderNumber: "통신판매업신고번호",
    address: "주소",
    tel: "TEL",
    email: "E-MAIL",
    cs: "CS CENTER",
    senderAddress: "보내는 곳",
  },

  manager: {
    title: "관리자 권한 관리",
    userCount: "전체 항목 수",
    delBtn: "선택항목 삭제",
    email: "이메일",
    name: "이름",
    type: "관리자 유형",
    createdAt: "신청일/승인일",
    activation: "활성화",
    state: "승인상태",
    approve: "승인",
    reject: "미승인",
    approveBtn: "승인하기",
    more: "상세보기",
    rootErrorMsg: "ROOT계정은 삭제할 수 없습니다.",
    edit: "수정하기",
    pw: "비밀번호",
    pwCheck: "비밀번호 재입력",
    pwEditBtn: "비밀번호 수정하기",
    rootCaption: "*ROOT일 경우 아래 접근권한 설정에 관계없이 모든 메뉴 접근이 가능합니다.",
    authority: "접근권한",
  },

  terms: {
    title: "약관 관리",
    userCount: "전체 항목 수",
    delBtn: "삭제",
    tableTitle: "약관 종류",
    lastEdit: "마지막 수정일",
    more: "상세보기",
    edit: "수정하기",
    add: "추가하기",
    addBtn: "등록하기",
    termsName: "약관명",
    content: "약관내용",
    required: "필수 선택 여부",
    state: "게시 상태",
  },

  faq: {
    title: "FAQ 관리",
    userCount: "전체 항목 수",
    delBtn: "선택항목 삭제",
    tableTitle: "제목",
    email: "관리자 이메일",
    createdAt: "작성일",
    more: "상세보기",
    edit: "수정하기",
    add: "추가하기",
    addBtn: "등록하기",
    content: "내용",
  },

  notice: {
    title: "공지사항 관리",
    userCount: "전체 항목 수",
    delBtn: "선택항목 삭제",
    tableTitle: "제목",
    email: "관리자 이메일",
    createdAt: "작성일",
    more: "상세보기",
    add: "추가하기",
    addBtn: "등록하기",
    content: "내용",
  },

  alarm: {
    title: "알림 관리",
    userCount: "전체 항목 수",
    delBtn: "선택항목 삭제",
    tableTitle: "제목",
    createdAt: "게시일",
    type: "유형",
    more: "상세보기",
    del: "삭제하기",
    add: "추가하기",
    addBtn: "등록하기",
    content: "내용",
    serviceAlarm: "서비스알림",
    adAlarm: "광고성알림",
  },

  leave: {
    title: "탈퇴 회원 관리",
    userCount: "전체 이용자 수",
    email: "이메일",
    name: "이름",
    reason: "탈퇴사유",
    leaveDate: "탈퇴일",
    del: "영구 삭제",
  },

  setting: {
    tab1: "환경설정",
    tab2: "배송/교환/반품 안내",
    tab3: "포인트설정",
    tab4: "구매 약관 관리",
    title1: "기본 설정",
    title2: "결제수단 설정",
    caption1: "주문 시 안내사항은 상품 주문 화면에서 노출되는 안내 문구 입니다.\n비워두면 노출되지 않습니다.",
    inputTitle1: "주문 설정",
    inputTitle2: "주문 시 안내사항",
    inputTitle3: "교환/반품 소요일",
    inputTitle4: "결제수단",
    checkbox1: "결제 완료 시 재고 수량 자동 차감",
    checkbox2: "결제 완료 시 자동으로 배송 준비중으로 변경",
    dueDate: "접수일로부터",
    date: "일",
    card: "카드 결제",
  },

  transport: {
    caption1: "상품 상세페이지의 ‘배송/교환/반품 안내’ 탭에 나타나는 내용입니다.",
    title1: "배송정보",
    title2: "교환/반품 안내",
    title3: "교환/반품 제한 사항",
    title4: "판매자 정보",
    inputTitle1: "내용",
    inputTitle2: "게시 상태",
  },

  point: {
    title1: "포인트 설정",
    title2: "인플루언서 등급별 포인트 지급 설정 ",
    inputTitle1: "리뷰 작성 시",
    inputTitle2: "포토리뷰 작성 시",
    inputTitle3: "포인트 사용 가능 조건",
    inputTitle4: "최대 사용 기간",
    inputTitle5: "일반등급",
    inputTitle6: "C등급",
    inputTitle7: "B등급",
    inputTitle8: "A등급",
    subTitle1: "최소 보유 포인트",
    subTitle2: "최소 구매 금액",
    max: "최대",
    month: "월",
  },

  buyTerms: {
    title1: "구매 약관 관리",
    caption1: "상품 주문 시 나타나는 구매 관련 약관 설정 화면입니다.",
    inputTitle1: "약관명",
    inputTitle2: "약관내용",
    inputTitle3: "필수 선택 여부",
    inputTitle4: "게시 상태",
  },

  ask: {
    title: "1:1 문의 관리",
    title2: "상품 Q&A 관리",
    askCount: "전체 항목 수",
    email: "이메일",
    name: "주문자명",
    product: "상품명",
    type: "문의 유형",
    createAt: "작성일",
    state: "답변상태",
    more: "상세보기",
    answerWait: "답변대기",
    answerComplete: "답변완료",
    detailTitle: "문의 상세",
    info: "상품 정보",
    content: "내용",
    comment: "답변",
  },

  qna: {
    title: "1:1 문의 관리",
    title2: "상품 Q&A 관리",
    qnaCount: "전체 항목 수",
    email: "이메일",
    name: "주문자명",
    product: "상품명",
    type: "문의 유형",
    createAt: "작성일",
    state: "답변상태",
    more: "상세보기",
  },

  video: {
    title: "영상 승인",
    videoCount: "전체 영상 수",
    nickname: "인플루언서 닉네임",
    videoTitle: "영상 제목",
    productCode: "상품코드",
    createAt: "등록일",
    state: "승인상태",
    more: "상세보기",
    delBtn: "선택항목 삭제",
    approve: "승인",
    reject: "미승인",
    deny: "반려",
    approveBtn: "승인하기",
    rejectBtn: "반려하기",
  },

  video_detail: {
    title: "영상정보",
    videoTitle: "영상 제목",
    link: "영상 링크 첨부",
    nickname: "인플루언서명",
    productCode: "상품 코드",
    productName: "상품명",
    amount: "상품 가격",
    img: "상품 이미지",
    approveBtn: "승인하기",
    rejectBtn: "반려하기",
  },

  category: {
    title: "카테고리 관리",
    userCount: "전체 항목 수",
    delBtn: "선택항목 삭제",
    activeImage: "카테고리 활성 이미지",
    inactiveImage: "카테고리 비활성 이미지",
    categoryName: "카테고리명",
    type: "단위",
    topCategory: "상위 카테고리",
    state: "활성 상태",
    more: "상세보기",
    del: "삭제하기",
    edit: "수정하기",
    category1: "1차 카테고리",
    category2: "2차 카테고리",
  },

  return: {
    title: "반품 내역 관리",
    returnCount: "전체 항목  수",
    cancelBtn: "취소처리",
    refundBtn: "환불처리",
    exchangeBtn: "교환처리",
    returnBtn: "반품처리",
    orderNum: "주문번호/일시",
    name: "주문자명",
    productName: "상품명",
    amount: "주문금액",
    state: "반품 상태",
    returnMore: "반품 내역 상세",
    orderForm: "주문서 보기",
    pickupIng: "수거중",
    pickupComplete: "수거완료",
    returnComplete: "반품완료",
    returnReject: "반품거부",
  },

  return_detail: {
    title: "반품 상세",
    createdAt: "요청일",
    reason: "교환/반품 사유",
    direction: "진행방향",
    directionDetail: "반품 후 환불",
    deliveryAddress: "배송지",
    deliveryDate: "배송 예정일",
    recoveryAddress: "상품 회수지",
    recoveryDate: "회수 예정일",
    memo: "관리자메모",
  },

  exchange: {
    title: "교환 내역 관리",
    exchangeCount: "전체 항목  수",
    cancelBtn: "취소처리",
    refundBtn: "환불처리",
    exchangeBtn: "교환처리",
    returnBtn: "반품처리",
    orderNum: "주문번호/일시",
    name: "주문자명",
    productName: "상품명",
    amount: "주문금액",
    state: "교환 상태",
    exchangeMore: "교환 내역 상세",
    orderForm: "주문서 보기",
    pickupIng: "수거중",
    pickupComplete: "수거완료",
    pending: "교환보류",
    resend: "교환 재발송",
    exchangeComplete: "교환 완료",
    exchangeReject: "교환 거부",
  },

  exchange_detail: {
    title: "교환 상세",
    createdAt: "요청일",
    reason: "교환/반품 사유",
    direction: "진행방향",
    directionDetail: "교환",
    deliveryAddress: "배송지",
    deliveryDate: "배송 예정일",
    recoveryAddress: "상품 회수지",
    recoveryDate: "회수 예정일",
  },

  shipping: {
    title: "배송 관리",
    shippingCount: "전체 항목 수",
    cancelBtn: "취소처리",
    refundBtn: "환불처리",
    exchangeBtn: "교환처리",
    returnBtn: "반품처리",
    orderNum: "주문번호/일시",
    name: "주문자명",
    productName: "상품명",
    amount: "주문금액",
    state: "배송 상태",
    orderForm: "주문서 보기",
    orderComplete: "주문완료",
    ready: "배송준비중",
    ing: "배송중",
    complete: "배송완료",
    waybillNumber: "운송장번호",
    count: "수량",
  },

  cancel: {
    title: "취소/환불 내역 관리",
    cancelCount: "전체 항목  수",
    cancelBtn: "취소처리",
    refundBtn: "환불처리",
    exchangeBtn: "교환처리",
    returnBtn: "반품처리",
    orderNum: "주문번호/일시",
    name: "주문자명",
    productName: "상품명",
    amount: "주문금액",
    state: "취소/환불상태",
    cancelMore: "취소/환불 상세",
    orderForm: "주문서 보기",
    canceling: "취소요청",
    canceled: "취소완료",
    refunding: "환불진행",
    refunded: "환불완료",
  },

  cancel_detail: {
    title: "취소/환불 상세",
    createdAt: "취소일",
    reason: "취소 사유",
    amount: "상품 금액",
    deleveryFee: "배송비",
    coupon: "쿠폰",
    point: "포인트",
    refundFee: "환불금액",
    couponReturn: "쿠폰 반환",
    pointReturn: "포인트 반환",
  },

  order_modal: {
    title: "주문서",
    orderNumber: "주문번호",
    orderDay: "주문일자",
    payMethod: "결제수단",
    deleveryInfo: "배송정보",
    customer: "주문자 정보",
    orderHistory: "주문 내역",
    option: "옵션",
    state: "주문상태",
    count: "수량",
    amount: "상품금액",
    deleveryFee: "배송비",
    sum: "합계",
    coupon: "쿠폰",
    point: "포인트",
    total: "총 결제금액",
    recipient: "수령인",
    koviet: "주식회사 코비엣",
  },

  review: {
    title: "리뷰 관리",
    reviewCount: "전체 리뷰 수",
    email: "이메일",
    content: "내용",
    rating: "별점",
    createdAt: "작성일",
    del: "삭제하기",
    more: "상세보기",
  },

  review_detail: {
    title: "리뷰 상세",
    product: "상품정보",
    image: "이미지",
    state: "상태",
    name: "이름",
  },

  coupon_add: {
    tab1: "쿠폰 발급",
    tab2: "발급 내역",
    title: "쿠폰 관리",
    addTitle: "발행하기",
    inputTitle1: "형식",
    inputTitle2: "선택된 회원",
    inputTitle3: "쿠폰명",
    inputTitle4: "유형",
    inputTitle5: "할인금액",
    inputTitle6: "사용기간",
    radio1: "지정 발행",
    radio2: "전체 발행",
    radio3: "금액 할인",
    radio4: "%할인",
    radio5: "배송비 무료",
    addBtn: "발행하기",
    userCount: "선택된 회원 수",
    email: "이메일",
    birth: "생년월일",
    caption1: "Khi nhập tìm kiếm ngày, hãy viết 4 số .",
    caption2: "ví dụ: 2024/07/10 sẽ hiển thị kết quả tìm kiếm nếu bạn nhập 0710",
  },

  coupon_history: {
    tab1: "쿠폰 발급",
    tab2: "발급 내역",
    title: "발급 내역",
    userCount: "쿠폰 발행 내역",
    email: "이메일",
    couponName: "쿠폰명",
    type: "형식",
    createdAt: "발급일",
    del: "발급취소",
    more: "상세보기",
    type1: "지정 발행",
    type2: "전체 발행",
  },

  coupon_detail: {
    title: "쿠폰 발행 내역 상세",
    type: "형식",
    email: "선택된 회원",
    couponName: "쿠폰명",
    discountType: "유형",
    amount: "할인 금액",
    period: "사용 기간",
    del: "발급취소",
    complete: "사용완료",
    cancelComplete: "취소완료",
  },

  exhibition: {
    title: "기획전 관리",
    userCount: "전체 항목 수",
    delBtn: "선택항목 삭제",
    tableTitle: "기획전명",
    period: "기간",
    state: "게시 상태",
    del: "삭제하기",
    more: "상세보기",
  },

  exhibition_detail: {
    more: "상세보기",
    title: "기획전명",
    thumbnail: "기획전 썸네일 이미지",
    pcImg: "기획전 배너 PC 이미지",
    mobileImg: "기획전 배너 모바일 이미지",
    period: "기간",
    state: "게시 상태",
    tableTitle: "관련 상품",
    productName: "상품명",
    category: "카테고리",
    amount: "상품 가격",
    saleState: "판매 상태",
    productState: "재고",
    onSale: "판매중",
    stopSell: "판매중지",
    recommendSize: "권장사이즈",
  },

  exhibition_modal: {
    title: "상품명",
    category: "카테고리",
    brand: "브랜드",
    amount: "상품 가격",
    productType: "상품 타입",
    image: "상품 이미지",
    count: "상품 재고 수량",
    productCode: "상품 코드",
  },

  product: {
    title: "상품 목록",
    productCount: "전체 상품 수",
    name: "상품명",
    category: "카테고리",
    amount: "상품 가격",
    state: "판매 상태",
    productState: "재고",
    more: "상세보기",
    add: "상품 등록",
    ing: "판매중",
    stop: "판매중지",
    soldoutAll: "품절",
    soldoutSome: "일부품절",
    normal: "정상",
  },

  product_detail: {
    delBtn: "삭제",

    detail1: "기본 정보",
    detail1_productName: "상품명",
    detail1_code: "상품 코드",
    detail1_state: "판매 상태",
    detail1_img: "상품 이미지",
    detail1_img_upload: "최대 6개로 제한됩니다.",
    detail1_content: "상품 설명",
    detail1_show: "미리보기",

    detail2: "상품 정보",
    detail2_manufacturer: "제조사",
    detail2_brand: "브랜드",
    detail2_origin: "원산지",
    detail2_type: "제품 타입",
    detail2_weight: "무게",

    detail3: "가격 설정",
    detail3_salePrice: "판매가",
    detail3_originPrice: "정가",

    detail4: "배송 설정",
    detail4_deliveryFee: "배송비",
    detail4_bundle: "묶음배송",
    detail4_company: "배송/출고사",

    detail5: "분류 설정",
    detail5_category: "카테고리",
    detail5_exhibition: "기획전",
    detail5_badge: "상품 뱃지",

    option: "옵션",
    optionCheck: "옵션 여부",
    required: "필수 여부",
    isSale: "판매 여부",
    optionYes: "옵션 있음(아래에서 설정)",
    optionNo: "옵션 없음",
    optionValue: "옵션값",
    optionPrice: "옵션 가격",
    count: "재고",

    optionName: "옵션명",
    setting: "설정",
  },

  product_add: {
    title: "상품 등록",
    money: "원",
    directlyEnter: "수기 입력",
    able: "가능",
    disable: "불가능",
    best: "베스트",
    new: "신상품",
    md: "MD 추천",
    event: "이벤트",
    required: "필수",
    choice: "선택",
    noOption: "옵션 없음",
    add: "상품 등록",
    edit: "상품 수정",
    option: "옵션",
    caption: "상품 등록 후 하단에서 옵션 설정 가능합니다.",
    caption02: "상품 수정 후 하단에서 옵션 설정 가능합니다.",
  },
};
