import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

function SearchBar({ value, onChange, disabled, style, onClick, onKeyDown, placeholder, maxLength }) {
  const { t } = useTranslation();

  return (
    <>
      <SearchBarWrapper
        style={{
          ...style,
        }}
      >
        <SearchBarInput
          maxLength={maxLength ? maxLength : 20}
          type="text"
          placeholder={placeholder ? placeholder : t(`placeholder.searchPlaceholder`)}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <img style={{ cursor: "pointer" }} onClick={onClick} src="/assets/admin/icons/ico_search.svg" />
      </SearchBarWrapper>
    </>
  );
}

export default SearchBar;

const SearchBarWrapper = styled.div.attrs((props) => {})`
  width: 340px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: #f2f4f5;
`;

const SearchBarInput = styled.input.attrs((props) => {})`
  width: 282px;
  padding: 0px;
  border: none;
  background: transparent;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.048px;
  color: #262c31;
  caret: #0077e5;

  &::placeholder {
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.048px;
    color: #b8bfc4;
  }

  &:focus {
    outline: none;
    border: none;
  }
`;
