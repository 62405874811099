import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { isNull } from "../../../util/check";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import { useTranslation } from "react-i18next";
import Radio from "../../../component/atoms/input/radio";
import CategoryApi from "../../../api/category.api";
import ImgInput from "./../../../component/atoms/input/imgInput";
import ImgPreview from "../../../component/molecules/imgPreview";
import PretendardText from "./../../../component/atoms/text/pretendardText";
import CommonApi from "../../../api/common.api";

const CategoryAdd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // mounted
  useEffect(() => {
    getCategoryList();
  }, []);

  // 카테고리 목록 가져오기
  const [categoryList, setCategoryList] = useState([]);

  const getCategoryList = async () => {
    try {
      const response = (await CategoryApi.getList()).data.data.content;
      setCategoryList(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  const [categoryInfo, setCategoryInfo] = useState({
    title: "",
    categoryType: "FIRST",
    activation: true,
    parentCategoryId: null,
    parentCategoryTitle: "",
    imageServerFileNameActive: "", // ex) "category/ic-skin-72-a.png"
    imageOriginFileNameActive: "", // ex) "ic-skin-72-a.png"
    imageServerFileNameInActive: "", // ex) "category/ic-skin-72.png"
    imageOriginFileNameInActive: "", // ex) "ic-skin-72.png"
    activeFileUrl: "",
    inactiveFileUrl: "",
  });

  // 상위 카테고리 박스 열기
  const [parentCategoryBoxOpen, setParentCategoryBoxOpen] = useState(false);

  // 활성, 비활성 이미지 파일 상태
  const [activeFile, setActiveFile] = useState(null);
  const [inactiveFile, setInactiveFile] = useState(null);

  //이미지 추가, 삭제
  const inputFile = async (e, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    if (type === "active") {
      fileReader.onload = (value) => {
        setCategoryInfo({
          ...categoryInfo,
          activeFileUrl: value.target.result,
        });
      };

      setActiveFile(file);
    } else if (type === "inactive") {
      fileReader.onload = (value) => {
        setCategoryInfo({
          ...categoryInfo,
          inactiveFileUrl: value.target.result,
        });
      };
      setInactiveFile(file);
    }

    e.target.value = "";
  };

  // 파일 업로드
  const fileUpload = async (file, type) => {
    try {
      const data = {
        path: "category/",
        extension: file.name.split(".").pop(),
      };

      const response = (await CommonApi.fileUpload(data)).data.data;

      setCategoryInfo((prev) => {
        if (type === "active") {
          return {
            ...prev,
            imageOriginFileNameActive: file.name,
            imageServerFileNameActive: response.serverFileName,
          };
        } else if (type === "inactive") {
          return {
            ...prev,
            imageOriginFileNameInActive: file.name,
            imageServerFileNameInActive: response.serverFileName,
          };
        }
      });

      await cloudFileUpload(response.presignedUrl, file);
    } catch (error) {
      errorHandler(error);
    }
  };

  const cloudFileUpload = async (url, file) => {
    try {
      await CommonApi.cloudFileUpload(url, file);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 첨부 파일 삭제
  const deleteFile = (type) => {
    setCategoryInfo({
      ...categoryInfo,
      activeFileUrl: type === "active" ? "" : categoryInfo.activeFileUrl,
      inactiveFileUrl: type === "inactive" ? "" : categoryInfo.inactiveFileUrl,
    });
  };

  // --------------------------------------------- //
  // ----------------- 저장하기 ----------------- //
  // ------------------------------------------- //
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (categoryInfo.categoryType === "FIRST") {
      if (isNull(categoryInfo.activeFileUrl)) {
        return toast(t("toastMsg.activeAdd"));
      } else if (isNull(categoryInfo.inactiveFileUrl)) {
        return toast(t("toastMsg.inActiveAdd"));
      }
    }

    if (isNull(categoryInfo.title)) {
      return toast(t("toastMsg.titleAdd"));
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      let data = {};

      if (categoryInfo.categoryType === "FIRST") {
        await fileUpload(activeFile, "active");
        await fileUpload(inactiveFile, "inactive");

        data = {
          title: categoryInfo.title,
          categoryType: "FIRST",
          activation: categoryInfo.activation,
          parentCategoryId: categoryInfo.parentCategoryId,
          imageOriginFileNameActive: categoryInfo.imageOriginFileNameActive,
          imageServerFileNameActive: categoryInfo.imageServerFileNameActive,
          imageOriginFileNameInActive: categoryInfo.imageOriginFileNameInActive,
          imageServerFileNameInActive: categoryInfo.imageServerFileNameInActive,
        };
      } else if (categoryInfo.categoryType === "SECOND") {
        data = {
          title: categoryInfo.title,
          categoryType: "SECOND",
          activation: categoryInfo.activation,
          parentCategoryId: categoryInfo.parentCategoryId,
          imageOriginFileNameActive: "",
          imageServerFileNameActive: "",
          imageOriginFileNameInActive: "",
          imageServerFileNameInActive: "",
        };
      }

      if (categoryInfo.categoryType === "FIRST" && data.imageOriginFileNameActive !== "" && categoryInfo.categoryType === "FIRST" && data.imageOriginFileNameInActive !== "") {
        await CategoryApi.create(data);
        setSaveModalOpen(false);
        navigate(-1);
        toast(t("toastMsg.update"));
      } else if (categoryInfo.categoryType === "SECOND") {
        await CategoryApi.create(data);
        setSaveModalOpen(false);
        navigate(-1);
        toast(t("toastMsg.update"));
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const dropdownBtnRef = useRef(null);
  const dropdownSelectBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownSelectBoxRef.current && !dropdownSelectBoxRef.current.contains(event.target) && dropdownBtnRef.current && !dropdownBtnRef.current.contains(event.target)) {
        setParentCategoryBoxOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Header />

      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "420px" }}>
            <DetailPageTitle>{t("component.add")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {categoryInfo.categoryType === "FIRST" && (
                <>
                  <DetailPageForm>
                    <DetailFormTitle>{t("category.activeImage")}*</DetailFormTitle>
                    {categoryInfo.activeFileUrl ? (
                      <ImgPreview
                        fileUrl={categoryInfo.activeFileUrl}
                        onClick={() => {
                          deleteFile("active");
                        }}
                      />
                    ) : (
                      <ImgInput
                        id={"activeFile"}
                        accept={"image/*"}
                        onChange={(e) => {
                          inputFile(e, "active");
                        }}
                      />
                    )}
                  </DetailPageForm>
                  <DetailPageForm>
                    <DetailFormTitle>{t("category.inactiveImage")}*</DetailFormTitle>
                    {categoryInfo.inactiveFileUrl ? (
                      <ImgPreview
                        fileUrl={categoryInfo.inactiveFileUrl}
                        onClick={() => {
                          deleteFile("inactive");
                        }}
                      />
                    ) : (
                      <ImgInput
                        id={"inactiveFile"}
                        accept={"image/*"}
                        onChange={(e) => {
                          inputFile(e, "inactive");
                        }}
                      />
                    )}
                  </DetailPageForm>
                </>
              )}
              <DetailPageForm>
                <DetailFormTitle>{t("category.categoryName")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.categoryPlaceholder")}
                  value={categoryInfo.title}
                  onChange={(e) => {
                    setCategoryInfo({ ...categoryInfo, title: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("category.type")}*</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Radio
                    id={"categoryType1"}
                    valueText={t("category.category1")}
                    checked={categoryInfo.categoryType === "FIRST"}
                    onChange={() => {
                      setCategoryInfo({
                        ...categoryInfo,
                        categoryType: "FIRST",
                      });
                    }}
                  />
                  <Radio
                    id={"categoryType2"}
                    valueText={t("category.category2")}
                    checked={categoryInfo.categoryType === "SECOND"}
                    onChange={() => {
                      setCategoryInfo({
                        ...categoryInfo,
                        categoryType: "SECOND",
                      });
                    }}
                  />
                </div>
              </DetailPageForm>
              {categoryInfo.categoryType === "SECOND" && (
                <DetailPageForm>
                  <DetailFormTitle>{t("category.topCategory")}</DetailFormTitle>
                  {/* <SelectBox
                    style={{ width: "338px" }}
                    list={categoryList}
                    selectedItem={""}
                    placeholder={t("placeholder.categoryPlaceholder")}
                  /> */}
                  <div>
                    <div style={{ position: "relative", width: "338px" }} ref={dropdownBtnRef}>
                      <SelectBoxWrapper
                        onClick={() => {
                          setParentCategoryBoxOpen(!parentCategoryBoxOpen);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <PretendardText
                          style={{
                            color: categoryInfo.parentCategoryTitle === "" ? "#808991" : "#262C31",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "21.25px",
                          }}
                        >
                          {categoryInfo.parentCategoryTitle === "" ? t("placeholder.categoryPlaceholder") : categoryInfo.parentCategoryTitle}
                        </PretendardText>
                        <img className={parentCategoryBoxOpen ? "rotate" : "rotate-back"} src="/assets/admin/icons/dropdown_arrow.png" alt="" style={{ width: "16px", height: "16px" }} />
                      </SelectBoxWrapper>
                      {parentCategoryBoxOpen ? (
                        <SubMenuWrapper ref={dropdownSelectBoxRef}>
                          {categoryList.map((item, index) => {
                            return (
                              <SubMenuTextWrapper
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "318px",
                                  height: "42px",
                                  padding: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setCategoryInfo({
                                    ...categoryInfo,
                                    parentCategoryId: item.id,
                                    parentCategoryTitle: item.title,
                                  });
                                  setParentCategoryBoxOpen(false);
                                }}
                              >
                                <PretendardText
                                  style={{
                                    color: "#262C31",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    lineHeight: "21.5px",
                                  }}
                                >
                                  {item.title}
                                </PretendardText>
                                {categoryInfo.parentCategoryTitle === item.title ? <img src="/assets/admin/icons/ico_select_checked.svg" alt="" /> : null}
                              </SubMenuTextWrapper>
                            );
                          })}
                        </SubMenuWrapper>
                      ) : null}
                    </div>
                  </div>
                </DetailPageForm>
              )}
              <DetailPageForm>
                <DetailFormTitle>{t("category.state")}*</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Radio
                    id={"active"}
                    valueText={t("component.active")}
                    checked={categoryInfo.activation === true}
                    onChange={() => {
                      setCategoryInfo({ ...categoryInfo, activation: true });
                    }}
                  />
                  <Radio
                    id={"inactive"}
                    valueText={t("component.inactive")}
                    checked={categoryInfo.activation === false}
                    onChange={() => {
                      setCategoryInfo({ ...categoryInfo, activation: false });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={modalMsg.create?.title}
          text={"작성한 내용으로 등록됩니다."}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CategoryAdd;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #b8bfc4;
  background: #fff;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  min-height: 190px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
  position: absolute;
  top: 50px;
  right: 0;
  padding: 10px;
  overflow-y: auto;
  z-index: 5;
`;

const SubMenuTextWrapper = styled.div.attrs((props) => {})`
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  width: "318px";
  height: "42px";
  padding: "10px";
  cursor: "pointer";
  border-radius: 3px;

  &:hover {
    background-color: #f2f4f5;
  }
`;
