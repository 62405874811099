import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
// import NoticeApi from "../../../api/notice.api";
import { errorHandler } from "../../../util/errorHandler";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { useTranslation } from "react-i18next";
import NoticeApi from "./_api/notice.api";

const NoticeDetail = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getNoticeInfo();
  }, []);

  // ----- 정보 가져오기
  const [noticeInfo, setNoticeInfo] = useState({
    title: "",
    content: "",
    email: "",
    createdAt: "",
  });

  const getNoticeInfo = async () => {
    try {
      const response = (await NoticeApi.getById(id)).data.data.content;
      setNoticeInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: [id],
      };
      await NoticeApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDeleteModalOpen(true)} />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "295px" }}>
            <DetailPageTitle>{t("notice.more")}</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>{t("notice.tableTitle")}</DetailFormTitle>
                <DetailFormText>{noticeInfo.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("notice.email")}</DetailFormTitle>
                <DetailFormText>{noticeInfo.adminEmail}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("notice.createdAt")}</DetailFormTitle>
                <DetailFormText>
                  {moment(noticeInfo.createdAt).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("notice.content")}</DetailFormTitle>
                <DetailFormText>{noticeInfo.content}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default NoticeDetail;
