import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/errorHandler";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import { useTranslation } from "react-i18next";
import FaqApi from "./_api/faq.api";
// import FaqApi from "../../../api/faq.api";

function FaqAdd() {
  const { t } = useTranslation();

  // import
  const navigate = useNavigate();

  const [faqData, setFaqData] = useState({
    title: "",
    content: "",
    adminEmail: sessionStorage.getItem("email"),
  });

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveFunc = async () => {
    try {
      await FaqApi.create(faqData);
      setSaveModalOpen(false);
      navigate(-1);
      toast(t("toastMsg.create"));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "330px" }}>
            <DetailPageTitle>{t("faq.add")}</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>{t("faq.tableTitle")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.titlePlaceholder")}
                  value={faqData.title}
                  onChange={(e) =>
                    setFaqData({ ...faqData, title: e.target.value })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("faq.content")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "744px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.contentPlaceholder")}
                  value={faqData.content}
                  onChange={(e) =>
                    setFaqData({ ...faqData, content: e.target.value })
                  }
                />
              </DetailPageForm>
            </div>
            <SaveBtn
              text={t("faq.addBtn")}
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (!faqData.title)
                  return toast(`${t("toastMsg.input_empty")}`);
                if (!faqData.content)
                  return toast(`${t("toastMsg.input_empty")}`);

                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : null}
    </>
  );
}

export default FaqAdd;
