import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import { fileUrl } from "../../../api/api";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Modal from "../../../component/templates/Modal";
import { isNull } from "../../../util/check";
import { errorHandler } from "../../../util/errorHandler";
import AskApi from "./_api/ask.api";

function AskModal({ data, setModalOpen, listHandler }) {
  const { t } = useTranslation();

  const [askData, setAskData] = useState(data);
  const [editAskData, setEditAskData] = useState(data);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (isNull(editAskData.answer)) {
      return toast(`${t("toastMsg.input_empty")}`);
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        answer: editAskData.answer,
      };

      await AskApi.updateOrderAsk(askData.id, data);
      toast(t("toastMsg.update"));
      setSaveModalOpen(false);
      setAskData({ ...askData, answer: editAskData.answer });
      listHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <ModalWrapper>
        <ModalBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <TableTitle style={{ marginBottom: "0px" }}>
              {t("ask.detailTitle")}
            </TableTitle>
            <img
              style={{
                cursor: "pointer",
              }}
              src="/assets/admin/icons/ico_popup_close.svg"
              alt=""
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              minHeight: "223px",
              overflowY: "auto",
            }}
          >
            <DetailPageForm>
              <DetailFormTitle>{t("ask.email")}</DetailFormTitle>
              <DetailFormText>{askData.order?.email}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("ask.name")}</DetailFormTitle>
              <DetailFormText>{askData.order?.name}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("ask.type")}</DetailFormTitle>
              <DetailFormText>{askData.askTypeTitle}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("ask.createAt")}</DetailFormTitle>
              <DetailFormText>
                {moment(askData.createdAt).format("YYYY/MM/DD")}
              </DetailFormText>
            </DetailPageForm>
            <DetailPageForm style={{ alignItems: "flex-start" }}>
              <DetailFormTitle>{t("ask.info")}</DetailFormTitle>
              <div>
                {askData.order?.merchInfo?.map((item, index) => {
                  return (
                    <div key={index}>
                      <img
                        src={`${fileUrl}/${item.imageServerFileName}`}
                        alt=""
                        style={{ width: "60px", height: "60px" }}
                      />
                      <DetailFormText>{item.title}</DetailFormText>
                      <DetailFormText>{item.optionString}</DetailFormText>
                    </div>
                  );
                })}
              </div>
            </DetailPageForm>
            <DetailPageForm style={{ alignItems: "flex-start" }}>
              <DetailFormTitle>{t("ask.content")}</DetailFormTitle>
              <DetailFormText>{askData.question}</DetailFormText>
            </DetailPageForm>
            <div
              style={{
                width: "100%",
                height: 5,
                backgroundColor: "#F8F8F8",
                margin: "10px 0",
              }}
            />
            <DetailPageForm style={{ alignItems: "flex-start" }}>
              <DetailFormTitle>{t("ask.comment")}</DetailFormTitle>
              {!askData.answer && (
                <MainInput
                  style={{
                    width: "730px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.contentPlaceholder")}
                  value={editAskData.answer}
                  onChange={(e) =>
                    setEditAskData({ ...editAskData, answer: e.target.value })
                  }
                />
              )}
              {askData.answer && (
                <DetailFormText>{askData.answer}</DetailFormText>
              )}
            </DetailPageForm>
            <SaveBtn
              text={
                askData.answer
                  ? `${t("component.edit")}`
                  : `${t("component.answer")}`
              }
              style={{
                position: "static",
                marginTop: "20px",
                width: "fit-content",
                alignSelf: "flex-end",
              }}
              onClick={
                askData.answer === ""
                  ? saveBtnHandler
                  : () => {
                      setAskData({ ...askData, answer: "" });
                    }
              }
            />
          </div>
        </ModalBox>
      </ModalWrapper>
      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default AskModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 570px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
