import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DelBtn from "../../../component/atoms/button/delBtn";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import { route } from "../../../router/route";
import { useTranslation } from "react-i18next";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import MerchApi from "../../../api/merch.api";
import { modalMsg } from "./../../../util/modalMsg";
import DetailPageFormCol from "../../../component/atoms/layout/detail/detailPageFormCol";
import { errorHandler } from "../../../util/errorHandler";
import { toast } from "react-toastify";
import { toastMsg } from "./../../../util/toastMsg";
import Modal from "./../../../component/templates/Modal";

// TODO:
// - 상품 설명 미리보기 확인 필요
// - API 수정되면 카테고리, 판매 상태, 재고, 상품 코드, 상품 설명, 제조사, 브랜드
//   원산지, 제품 타입, 정상가, 배송비, 묶음배송 여부, 배송/출고사, 기획전, 옵션 여부 수정 필요
// - 옵션 영역은 추후 작업 필요

const ProductDetail = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  let { id } = useParams();

  // ----- 정보 가져오기
  const [merchInfo, setMerchInfo] = useState({
    title: "", // 상품명
    merchStatus: "", // PROGRESS, END
    images: [
      {
        // 상품 이미지
        imageOriginFileName: "", // test.png
        imageServerFileName: "", // popup/4e1faa90-413c-4fc2-8323-ed3c5231e7f7.png
      },
    ],
    content: "", // 상품설명
    manufacturer: "", // 제조사
    brand: "", // 브랜드
    madeIn: "", // 원산지
    productType: "", // 제품타입
    salePrice: 0, // 판매가
    originalPrice: 0, // 정가
    weight: 0, // 무게
    bundled: false, // 묶음배송여부
    category: {
      id: 0, // 카테고리 ID
      title: "", // 카테고리명
    },
    parentCategoryTitle: "", // 상위 카테고리
    childCategoryTitle: "", // 하위 카테고리
    promotion: {
      id: 0, // 기획전 ID
      title: "", // 기획전명
    },
    bestFlag: false, // 상품 뱃지 - 베스트
    newFlag: false, // 상품 뱃지 - 신상품
    mdFlag: false, // 상품 뱃지 - MD추천
    eventFlag: false, // 상품 뱃지 - 이벤트
    optionFlag: false, // 옵션 여부
    inventory: 0, // 재고
  });

  const [optionList, setOptionList] = useState([]);

  const getMerchInfo = async () => {
    try {
      const response = (await MerchApi.getById(id)).data.data.content;

      setMerchInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getOption = async () => {
    try {
      const response = (await MerchApi.getOption(id)).data.data.content;

      if (response) {
        const copyArr = [...response];

        const arr = await Promise.all(
          copyArr.map(async (item, index) => {
            const optionRes = (await MerchApi.getOptionValue(item.id)).data.data.content;

            return {
              ...item,
              optionValueList: optionRes,
            };
          })
        );

        setOptionList(arr.reverse());
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  // mounted
  useEffect(() => {
    getMerchInfo();
    getOption();
  }, []);

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: [id],
      };
      await MerchApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDeleteModalOpen(true)} text={t("product_detail.delBtn")} />
              <EditBtn
                onClick={() => {
                  navigate(route.product_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>

          {/* 기본정보 */}
          <DetailPage style={{ minHeight: "405px" }}>
            <DetailPageTitle>{t("product_detail.detail1")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 기본정보 - 상품명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail1_productName")}</DetailFormTitle>
                <DetailFormText>{merchInfo.title}</DetailFormText>
              </DetailPageForm>
              {/* 기본정보 - 상품 코드 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("product_detail.detail1_code")}</DetailFormTitle>
                <DetailFormText>{merchInfo.merchCode}</DetailFormText>
              </DetailPageForm>
              {/* 기본정보 - 판매 상태 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail1_state")}</DetailFormTitle>
                <DetailFormText>{merchInfo.merchStatus === "PROGRESS" ? t("product.ing") : t("product.end")}</DetailFormText>
              </DetailPageForm>
              {/* 기본정보 - 상품 이미지 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("product_detail.detail1_img")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    width: 744,
                  }}
                >
                  {merchInfo.images?.map((image, index) => {
                    return (
                      <React.Fragment key={index}>
                        <FileImgPreview fileName={image.imageOriginFileName} serverFileName={image.imageServerFileName} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </DetailPageForm>
              {/* 기본정보 - 상품 설명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail1_content")}</DetailFormTitle>
                <DetailFormText
                  style={{ textDecorationLine: "underline", cursor: "pointer" }}
                  onClick={() => {
                    window.open(`https://koviet.vn/koviet/product_detail/${id}`, "_blank");
                  }}
                >
                  {t("product_detail.detail1_show")}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>

          {/* 상품 정보 */}
          <DetailPage style={{ minHeight: "295px" }}>
            <DetailPageTitle>{t("product_detail.detail2")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 상품 정보 - 제조사 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail2_manufacturer")}</DetailFormTitle>
                <DetailFormText>{merchInfo.manufacturer}</DetailFormText>
              </DetailPageForm>
              {/* 상품 정보 - 브랜드 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("product_detail.detail2_brand")}</DetailFormTitle>
                <DetailFormText>{merchInfo.brand}</DetailFormText>
              </DetailPageForm>
              {/* 상품 정보 - 원산지 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail2_origin")}</DetailFormTitle>
                <DetailFormText>{merchInfo.brand}</DetailFormText>
              </DetailPageForm>
              {/* 상품 정보 - 제품 타입 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail2_type")}</DetailFormTitle>
                <DetailFormText>{merchInfo.productType}</DetailFormText>
              </DetailPageForm>
              {/* 상품 정보 - 무게 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail2_weight")}</DetailFormTitle>
                <DetailFormText>{merchInfo.weight}g</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>

          {/* 가격 */}
          <DetailPage style={{ minHeight: "205px" }}>
            <DetailPageTitle>{t("product_detail.detail3")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 가격 - 판매가 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail3_salePrice")}</DetailFormTitle>
                <DetailFormText>
                  {merchInfo.salePrice.toLocaleString()}
                  {t("component.monetary")}
                </DetailFormText>
              </DetailPageForm>

              {/* 가격 - 정상가 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail3_originPrice")}</DetailFormTitle>
                <DetailFormText>
                  {merchInfo.originalPrice.toLocaleString()}
                  {t("component.monetary")}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>

          {/* 배송 */}
          <DetailPage style={{ minHeight: "250px" }}>
            <DetailPageTitle>{t("product_detail.detail4")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 배송 - 배송비 */}
              {/* <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail4_deliveryFee")}</DetailFormTitle>
                <DetailFormText>{merchInfo.deliveryCostTypeTitle || ""}</DetailFormText>
              </DetailPageForm> */}
              {/* 배송 - 묶음배송 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail4_bundle")}</DetailFormTitle>
                <DetailFormText>{merchInfo.bundled ? "Y" : "N"}</DetailFormText>
              </DetailPageForm>
              {/* 배송 - 배송/출고사 */}
              {/* <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail4_company")}</DetailFormTitle>
                <DetailFormText>{merchInfo.deliveryCompany}</DetailFormText>
              </DetailPageForm> */}
            </div>
          </DetailPage>

          {/* 분류 설정 */}
          <DetailPage style={{ minHeight: "250px" }}>
            <DetailPageTitle>{t("product_detail.detail5")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 분류 설정 - 카테고리 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail5_category")}</DetailFormTitle>
                <DetailFormText>
                  {merchInfo.category?.parentCategory?.title || ""}
                  {merchInfo.category?.title || ""}
                </DetailFormText>
              </DetailPageForm>
              {/* 분류 설정 - 기획전 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail5_exhibition")}</DetailFormTitle>
                <DetailFormText>{merchInfo.promotion?.title || ""}</DetailFormText>
              </DetailPageForm>
              {/* 분류 설정 - 상품 뱃지 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail5_badge")}</DetailFormTitle>
                <DetailFormText>
                  {merchInfo.bestFlag && `${t("product_add.best")}`}
                  {merchInfo.bestFlag && (merchInfo.newFlag || merchInfo.mdFlag || merchInfo.eventFlag) && `, `}
                  {merchInfo.newFlag && `${t("product_add.new")}`}
                  {merchInfo.newFlag && (merchInfo.mdFlag || merchInfo.eventFlag) && `, `}
                  {merchInfo.mdFlag && `${t("product_add.md")}`}
                  {merchInfo.mdFlag && merchInfo.eventFlag && `, `}
                  {merchInfo.eventFlag && `${t("product_add.event")}`}
                </DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>

          {/* 옵션 여부*/}
          <DetailPage style={{ minHeight: "100px" }}>
            <DetailPageTitle>{t("product_detail.optionCheck")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.optionCheck")}</DetailFormTitle>

                <DetailFormText>{merchInfo.optionFlag ? "Y" : "N"}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>

          {merchInfo.optionFlag && (
            <>
              {optionList.map((item, index) => {
                return (
                  <DetailPage style={{ minHeight: "250px" }}>
                    <DetailPageTitle>
                      {t("product_detail.option")} {index + 1}
                    </DetailPageTitle>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                      }}
                    >
                      <DetailPageForm>
                        <DetailFormTitle style={{ color: "#262C31" }}>{t("product_detail.optionName")}</DetailFormTitle>
                        <DetailFormText>{item.title}</DetailFormText>
                      </DetailPageForm>
                      {item.optionValueList &&
                        item.optionValueList.map((item02, index02) => {
                          return (
                            <DetailPageFormCol style={{ alignItems: "flex-start" }}>
                              <DetailFormTitle style={{ color: "#262C31" }}>
                                {t("product_detail.optionValue")} {index02 + 1}
                              </DetailFormTitle>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                                <DetailPageForm>
                                  <DetailFormTitle>{t("product_detail.optionValue")}</DetailFormTitle>
                                  <DetailFormText>{item02.title}</DetailFormText>
                                </DetailPageForm>
                                <DetailPageForm>
                                  <DetailFormTitle>{t("product_detail.optionPrice")}</DetailFormTitle>
                                  <DetailFormText>
                                    {item02.price.toLocaleString()}
                                    {t("component.monetary")}
                                  </DetailFormText>
                                </DetailPageForm>
                                <DetailPageForm>
                                  <DetailFormTitle>{t("product_detail.count")}</DetailFormTitle>
                                  <DetailFormText>{item02.inventory}</DetailFormText>
                                </DetailPageForm>
                              </div>
                            </DetailPageFormCol>
                          );
                        })}
                    </div>
                  </DetailPage>
                );
              })}
            </>
          )}
        </DetailPageLayout>
      </MainLayout>
      {deleteModalOpen && (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      )}
    </>
  );
};

export default ProductDetail;
