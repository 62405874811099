import React from "react";
import styled from "styled-components";

function DetailTop(props) {
  return (
    <>
      <DetailTopWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </DetailTopWrapper>
    </>
  );
}

export default DetailTop;

const DetailTopWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
`;
