import axios from "axios";
import api from "./api";

export default class CommonApi {
  // 파일 업로드 URL
  static async fileUpload(data) {
    return await api.post(`common/file/upload`, data);
  }

  static async cloudFileUpload(url, file) {
    return await axios
      .put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })

      .then((response) => {})
      .catch((error) => console.error(error));
  }

  static async fileDownload(data) {
    return await api.post(`common/file/download`, data, {
      responseType: "blob",
    });
  }
}
