// 자주사용문구 - 한국어
export const modalMsg = {
  update: {
    title: "저장하시겠습니까?",
    content: (
      <>
        이전 데이터는 복구가 불가합니다.
        <br />
        저장을 진행하시겠습니까?
      </>
    ),
  },
  delete: {
    title: "삭제하시겠습니까?",
    content: (
      <>
        삭제된 데이터는 복구가 불가합니다.
        <br />
        그래도 삭제하시겠습니까?
      </>
    ),
  },
  create: { title: "등록하시겠습니까?" },
  approve: { title: "승인하시겠습니까?" },
  deny: { title: "반려하시겠습니까?" },
  activate: { title: "활성화하시겠습니까?" },
  deactivate: { title: "비활성화하시겠습니까?" },
};
