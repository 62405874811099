import api from "../../../../api/api";

export default class BannerApi {
  static async createMainBanner(data) {
    return await api.post(`admin/main-banner`, data);
  }
  static async getMainBanner() {
    return await api.get(`admin/main-banner`);
  }
  static async updateMainBanner(id, data) {
    return await api.patch(`admin/main-banner/${id}`, data);
  }
  static async deleteMainBanner(data) {
    return await api.del(`admin/main-banner`, data);
  }

  static async getSubBanner() {
    return await api.get(`admin/sub-banner`);
  }
  static async updateSubBanner(id, data) {
    return await api.patch(`admin/sub-banner/${id}`, data);
  }
  static async updateSubBannerActivation(id, data) {
    return await api.post(`admin/sub-banner/activation/${id}`, data);
  }
}
