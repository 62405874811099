import React, { useEffect, useState } from "react";
// import BannerApi from "../../api/banner.api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SaveBtn from "../../../../component/atoms/button/saveBtn";
import Radio from "../../../../component/atoms/input/radio";
import TextArea from "../../../../component/atoms/input/textarea";
import DetailPage from "../../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../../component/atoms/text/detail/detailPageTitle";
import Modal from "../../../../component/templates/Modal";
import { errorHandler } from "../../../../util/errorHandler";
import SettingApi from "../_api/setting.api";

const TransPortItem = ({ title, data }) => {
  const { t } = useTranslation();

  const [shippingSetting, setShippingSetting] = useState({});

  useEffect(() => {
    setShippingSetting(data);
  }, [data]);

  // --------------------------------------------- //
  // ----------------- 저장하기 ----------------- //
  // ------------------------------------------- //
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (!shippingSetting.content) {
      toast(`${t("toastMsg.input_empty")}`);
      return;
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        content: shippingSetting.content,
        activation: shippingSetting.activation,
      };

      await SettingApi.updateShippingSetting(shippingSetting.id, data);
      setSaveModalOpen(false);
      toast(t("toastMsg.update"));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <DetailPage
        style={{
          minHeight: "392px",
        }}
      >
        <DetailPageTitle style={{ marginBottom: "30px" }}>{title}</DetailPageTitle>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <DetailPageForm style={{ alignItems: "flex-start" }}>
            <DetailFormTitle>{t("transport.inputTitle1")}*</DetailFormTitle>
            <TextArea
              placeholder={t("placeholder.defaultPlaceholder")}
              style={{ height: "133px" }}
              value={shippingSetting.content}
              onChange={(e) =>
                setShippingSetting({
                  ...shippingSetting,
                  content: e.target.value,
                })
              }
            />
          </DetailPageForm>
          <DetailPageForm style={{ alignItems: "flex-start" }}>
            <DetailFormTitle>{t("transport.inputTitle2")}</DetailFormTitle>
            <div style={{ display: "flex", gap: "19px" }}>
              <Radio
                id={t(`compnent.active_${shippingSetting.id}`)}
                valueText={t("component.active")}
                name={`activation_${shippingSetting.id}`}
                checked={shippingSetting.activation}
                onChange={() => {
                  setShippingSetting({ ...shippingSetting, activation: true });
                }}
              />
              <Radio
                id={t(`component.inactive_${shippingSetting.id}`)}
                valueText={t("component.inactive")}
                name={`inActivation_${shippingSetting.id}`}
                checked={!shippingSetting.activation}
                onChange={() => {
                  setShippingSetting({ ...shippingSetting, activation: false });
                }}
              />
            </div>
          </DetailPageForm>
        </div>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <SaveBtn
            onClick={() => {
              saveBtnHandler();
            }}
          />
        </div>
      </DetailPage>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default TransPortItem;
