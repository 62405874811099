import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
// import ExcelApi from "../../../api/excel.api";
import { useTranslation } from "react-i18next";
import MemberApi from "../../../api/member.api";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import PretendardText from "../../../component/atoms/text/pretendardText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Modal from "../../../component/templates/Modal";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { isNull } from "../../../util/check";
import { errorHandler } from "../../../util/errorHandler";
import SaveBtn from "./../../../component/atoms/button/saveBtn";
import Checkbox from "./../../../component/atoms/input/checkbox";
import DateInput from "./../../../component/atoms/input/dateInput";
import MainInput from "./../../../component/atoms/input/mainInput";
import Radio from "./../../../component/atoms/input/radio";
import SearchBar from "./../../../component/atoms/input/searchBar";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import Table from "./../../../component/atoms/layout/table/table";
import TableSearchBtn from "./../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "./../../../component/atoms/layout/table/tableTop";
import Td from "./../../../component/atoms/layout/table/td";
import Th from "./../../../component/atoms/layout/table/th";
import Tr from "./../../../component/atoms/layout/table/tr";
import DetailFormText from "./../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "./../../../component/atoms/text/detail/detailPageTitle";
import TableText from "./../../../component/atoms/text/table/tableText";
import Pagination from "./../../../component/molecules/pagination";
import TableCount from "./../../../component/molecules/tableCount";
import Header from "./../../../component/organisms/header";
import Sidebar from "./../../../component/organisms/sidebar";
import CouponApi from "./_api/coupon.api";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // 기본 스타일 파일
import "react-date-range/dist/theme/default.css"; // 테마 css 파일

const CouponIssuance = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");
  const [birth, setBirth] = useState("");

  const initialPublicationData = {
    couponForm: "INDIVIDUAL",
    title: "",
    couponType: "PRICE",
    amount: 0,
    startDate: new Date(),
    endDate: "",
  };

  const [couponData, setCouponData] = useState(initialPublicationData);

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (
      isNull(couponData.title) ||
      isNull(couponData.startDate) ||
      isNull(couponData.endDate) ||
      (couponData.couponForm === "INDIVIDUAL" && isNull(memberEmailAndIds)) ||
      (couponData.couponType !== "DELIVERY" && isNull(couponData.amount))
    ) {
      return toast(`${t("toastMsg.input_empty")}`);
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        couponForm: couponData.couponForm,
        couponType: couponData.couponType,
        title: couponData.title,
        amount: couponData.couponType === "PRICE" || couponData.couponType === "RATIO" ? couponData.amount : 0,
        startDate: moment(couponData.startDate).format("YYYY-MM-DD"),
        endDate: moment(couponData.endDate).format("YYYY-MM-DD"),
        memberEmailAndIds: JSON.stringify(memberEmailAndIds),
      };

      await CouponApi.createCoupon(data);
      setSaveModalOpen(false);
      setCouponData(initialPublicationData);
      setMemberEmailAndIds([]);
      getMemberList(true);
      toast(t("toastMsg.create"));
    } catch (error) {
      if (error.response.status === 400) {
        return toast(`${error.response.data.message}`);
      }
      errorHandler(error);
    }
  };

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    setBirth(searchParams.get("birth") ? searchParams.get("birth") : "");
    getMemberList();
  }, [location]);

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const [memberList, setMemberList] = useState([]);

  const getMemberList = async (reset) => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        birth: searchParams.get("birth"),
        page: searchParams.get("page"),
        size: pagination.size,
      };

      let responseData = (await MemberApi.getList(data)).data.data;

      setMemberList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: reset ? false : memberEmailAndIds.find((member) => member.id === item.id) ? true : false,
          };
        })
      );

      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllCheck = useMemo(() => {
    const checkedList = memberList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === memberList.length;
  }, [memberList]);

  const [memberEmailAndIds, setMemberEmailAndIds] = useState([]);

  function formatDateToMMDD(dateString) {
    const date = new Date(dateString); // 입력된 문자열을 Date 객체로 변환
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하므로 +1
    const day = date.getDate().toString().padStart(2, "0"); // 일도 두 자리로 맞춤
    return `${month}${day}`;
  }
  const [isCalendarOpen, setIsCalendarOpen] = useState();

  const btnAreaRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnAreaRef.current && !btnAreaRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <Tab
            list={[
              { title: `${t("coupon_add.tab1")}`, url: route.coupon_add },
              { title: `${t("coupon_add.tab2")}`, url: route.coupon_issued },
            ]}
            style={{ marginBottom: "20px" }}
          />
          <TableTitle style={{ marginBottom: "20px" }}>{t("coupon_add.title")}</TableTitle>
          <DetailPage style={{ minHeight: "400px" }}>
            <DetailPageTitle>{t("coupon_add.addTitle")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 형식 */}
              <DetailPageForm>
                <DetailFormTitle>{t("coupon_add.inputTitle1")}*</DetailFormTitle>
                <DetailFormText>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Radio
                      id={""}
                      valueText={t("coupon_add.radio1")}
                      checked={couponData.couponForm === "INDIVIDUAL"}
                      onChange={() => {
                        setCouponData({
                          ...couponData,
                          couponForm: "INDIVIDUAL",
                        });
                        getMemberList();
                      }}
                    />
                    <Radio
                      id={""}
                      valueText={t("coupon_add.radio2")}
                      checked={couponData.couponForm === "ENTIRE"}
                      onChange={() => {
                        setCouponData({
                          ...couponData,
                          couponForm: "ENTIRE",
                        });
                        setMemberList([]);
                      }}
                    />
                  </div>
                </DetailFormText>
              </DetailPageForm>

              {/* 선택된 회원 */}
              {couponData.couponForm === "INDIVIDUAL" ? (
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>{t("coupon_add.inputTitle2")}*</DetailFormTitle>
                  <DetailFormText
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "17px",
                      flexWrap: "wrap",
                    }}
                  >
                    {memberEmailAndIds.map((item, index) => (
                      <div key={index}>
                        <PretendardText
                          style={{
                            color: "#808991",
                            fontWeight: "400",
                            fontSize: "15px",
                            lineHeight: "21.25px",
                            backgroundColor: "#EEEEEE",
                            padding: "2px 10px",

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          {item.email}
                          <img
                            style={{
                              width: "18px",
                              height: "18px",
                              cursor: "pointer",
                            }}
                            src="/assets/admin/icons/ico_delete_18.svg"
                            alt=""
                            onClick={() => {
                              const member = memberList.find((member) => member.id === item.id);
                              if (member) {
                                member.checked = false;
                                setMemberList([...memberList]);
                              }

                              memberEmailAndIds.splice(index, 1);
                              setMemberEmailAndIds([...memberEmailAndIds]);
                            }}
                          />
                        </PretendardText>
                      </div>
                    ))}
                  </DetailFormText>
                </DetailPageForm>
              ) : null}

              {/* 쿠폰명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("coupon_add.inputTitle3")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={t("placeholder.couponPlaceholder")}
                  value={couponData.title}
                  onChange={(e) =>
                    setCouponData({
                      ...couponData,
                      title: e.target.value,
                    })
                  }
                />
              </DetailPageForm>

              {/* 유형 */}
              <DetailPageForm>
                <DetailFormTitle>{t("coupon_add.inputTitle4")}*</DetailFormTitle>
                <DetailFormText>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Radio
                      id={"PRICE"}
                      valueText={t("coupon_add.radio3")}
                      checked={couponData.couponType == "PRICE"}
                      onChange={() => {
                        setCouponData({
                          ...couponData,
                          couponType: "PRICE",
                        });
                      }}
                    />
                    <Radio
                      id={"RATIO"}
                      valueText={t("coupon_add.radio4")}
                      checked={couponData.couponType == "RATIO"}
                      onChange={() => {
                        setCouponData({
                          ...couponData,
                          couponType: "RATIO",
                        });
                      }}
                    />
                    <Radio
                      id={"DELIVERY"}
                      valueText={t("coupon_add.radio5")}
                      checked={couponData.couponType == "DELIVERY"}
                      onChange={() => {
                        setCouponData({
                          ...couponData,
                          couponType: "DELIVERY",
                        });
                      }}
                    />
                  </div>
                </DetailFormText>
              </DetailPageForm>

              {/* 할인금액 */}
              {couponData.couponType !== "DELIVERY" ? (
                <DetailPageForm>
                  <DetailFormTitle>{t("coupon_add.inputTitle5")}*</DetailFormTitle>
                  <MainInput
                    type={"number"}
                    style={{
                      width: 338,
                    }}
                    placeholder={t("placeholder.amountPlaceholder")}
                    value={couponData.amount}
                    onChange={(e) => {
                      if (couponData.couponType === "RATIO" && e.target.value > 100) {
                        return;
                      }
                      setCouponData({
                        ...couponData,
                        amount: e.target.value,
                      });
                    }}
                  />
                </DetailPageForm>
              ) : null}

              {/* 사용기간 */}
              <DetailPageForm>
                <DetailFormTitle>{t("coupon_add.inputTitle6")}*</DetailFormTitle>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <DateInput
                    value={couponData.endDate}
                    onChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      const today = new Date();

                      selectedDate.setHours(0, 0, 0, 0);
                      today.setHours(0, 0, 0, 0);

                      if (selectedDate < today) {
                        alert("The selected date cannot be earlier than today.");
                      } else {
                        setCouponData({
                          ...couponData,
                          endDate: e.target.value,
                        });
                      }
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <div
              style={{
                marginTop: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <SaveBtn
                style={{
                  position: "static",
                }}
                text={t("coupon_add.addBtn")}
                onClick={() => {
                  saveBtnHandler();
                }}
              />
            </div>
          </DetailPage>

          {couponData.couponForm === "INDIVIDUAL" ? (
            <div style={{ marginTop: "20px" }}>
              <TableTop>
                <TableCount text={t("coupon_add.userCount")} count={memberEmailAndIds.length} />
                <TableSearchBtn>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsCalendarOpen(!isCalendarOpen);
                      }}
                      alt=""
                      src="/assets/admin/icons/ico_calendar.svg"
                    />

                    {isCalendarOpen ? (
                      <div
                        style={{
                          zIndex: "20",
                          position: "absolute",
                          top: "20px",
                          right: "-100px",
                        }}
                        ref={btnAreaRef}
                      >
                        <Calendar
                          onChange={(item) => {
                            setBirth(item);
                            setIsCalendarOpen(!isCalendarOpen);
                            navigate(`${route.coupon_add}?page=1&keyword=${searchParams.get("keyword") || ""}&birth=${formatDateToMMDD(item)}`, {
                              replace: true,
                            });
                          }}
                          date={birth}
                        />
                      </div>
                    ) : undefined}
                  </div>

                  {birth ? (
                    <PretendardText
                      style={{
                        fontSize: "15px",
                        color: "#808991",
                        fontWeight: 500,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {`${searchParams.get("birth")?.substring(0, 2)}.${searchParams.get("birth")?.substring(2, 4)}`}
                      <img
                        src="/assets/admin/icons/ico_delete_18.svg"
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setBirth("");
                          navigate(`${route.coupon_add}?page=1&keyword=${searchParams.get("keyword") || ""}&birth=${""}`, {
                            replace: true,
                          });
                        }}
                      />
                    </PretendardText>
                  ) : undefined}
                  <SearchBar
                    placeholder={t("placeholder.emailPlaceholder")}
                    value={keyword}
                    maxLength={20}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(`${route.coupon_add}?page=1&keyword=${keyword}&birth=${searchParams.get("birth") || ""}`, {
                          replace: true,
                        });
                      }
                    }}
                    onClick={() => {
                      navigate(`${route.coupon_add}?page=1&keyword=${keyword}&birth=${searchParams.get("birth") || ""}`, {
                        replace: true,
                      });
                    }}
                  />
                </TableSearchBtn>
              </TableTop>
              <Table>
                <colgroup>
                  <col width="90px" />
                  <col width="438px" />
                  <col width="516px" />
                </colgroup>
                <thead>
                  <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                    <Th style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={isAllCheck}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setMemberList(
                              memberList.map((item) => {
                                return {
                                  ...item,
                                  checked: true,
                                };
                              })
                            );
                            for (let index = 0; index < memberList.length; index++) {
                              const element = memberList[index];
                              if (!memberEmailAndIds.some((item) => item.id === element.id)) {
                                memberEmailAndIds.push({
                                  email: element.email,
                                  id: element.id,
                                });
                              }
                            }
                          } else {
                            setMemberList(
                              memberList.map((item) => {
                                return {
                                  ...item,
                                  checked: false,
                                };
                              })
                            );
                            const filterList = memberEmailAndIds.filter((item) => !memberList.some((item2) => item2.id === item.id));
                            setMemberEmailAndIds([...filterList]);
                          }
                        }}
                      />
                    </Th>
                    <Th>
                      <TableText>{t("coupon_add.email")}</TableText>
                    </Th>
                    <Th>
                      <TableText>{t("coupon_add.birth")}</TableText>
                    </Th>
                  </Tr>
                </thead>
                <tbody>
                  {memberList.map((item, index) => {
                    return (
                      <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                        <Td style={{ paddingLeft: "20px" }}>
                          <Checkbox
                            checked={item.checked}
                            onChange={() => {
                              item.checked = !item.checked;
                              setMemberList([...memberList]);
                              if (item.checked) {
                                memberEmailAndIds.push({
                                  email: item.email,
                                  id: item.id,
                                });
                                setMemberEmailAndIds([...memberEmailAndIds]);
                              } else {
                                const filterList = memberEmailAndIds.filter((member) => member.id !== item.id);
                                setMemberEmailAndIds([...filterList]);
                              }
                            }}
                          />
                        </Td>
                        <Td>
                          <TableText>{item.email}</TableText>
                        </Td>
                        <Td>
                          <TableText> {isNull(item.birth) ? "" : moment(item.birth).format("YYYY/MM/DD")}</TableText>
                        </Td>
                      </Tr>
                    );
                  })}
                </tbody>
              </Table>
              <Pagination
                route={route.coupon_add}
                queryString={`keyword=${searchParams.get("keyword") || ""}&birth=${searchParams.get("birth") || ""}`}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          ) : undefined}
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CouponIssuance;

const QuestionWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover .question-box {
    visibility: visible;
    opacity: 1;
  }
`;

const QuestionBox = styled.div`
  padding: 10px 20px;
  border: 1px solid #dfe1e4;
  border-radius: 12px;
  box-shadow: 0px 4px 25px 0px #00000026;
  background-color: #fff;
  position: absolute;
  right: 0;
  bottom: 30px;
  width: max-content;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;
`;
