import React, { useEffect, useState } from "react";
// import BannerApi from "../../../api/banner.api";
import { useTranslation } from "react-i18next";
import PretendardText from "../../../component/atoms/text/pretendardText";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import TableTitle from "./../../../component/atoms/text/table/tableTitle";
import Header from "./../../../component/organisms/header";
import Sidebar from "./../../../component/organisms/sidebar";
import SettingApi from "./_api/setting.api";
import TransPortItem from "./_component/transportItem";

const Transport = () => {
  const { t } = useTranslation();

  const [SELLER, setSELLER] = useState({});
  const [RULES, setRULES] = useState({});
  const [GUIDE, setGUIDE] = useState({});
  const [SHIPPING, setSHIPPING] = useState({});

  const getShippingSetting = async () => {
    try {
      let responseData = (await SettingApi.getShippingSetting()).data.data
        .content;
      setSELLER(
        responseData.filter((item) => item.shippingSettingType === "SELLER")[0]
      );
      setRULES(
        responseData.filter((item) => item.shippingSettingType === "RULES")[0]
      );
      setGUIDE(
        responseData.filter((item) => item.shippingSettingType === "GUIDE")[0]
      );
      setSHIPPING(
        responseData.filter(
          (item) => item.shippingSettingType === "SHIPPING"
        )[0]
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getShippingSetting();
  }, []);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <Tab
            list={[
              { title: `${t("setting.tab1")}`, url: route.setting },
              { title: `${t("setting.tab2")}`, url: route.transport },
              { title: `${t("setting.tab3")}`, url: route.point },
              { title: `${t("setting.tab4")}`, url: route.buy_terms },
            ]}
            style={{ marginBottom: "20px" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              <TableTitle style={{ marginBottom: "10px" }}>
                {t("setting.tab2")}
              </TableTitle>
              <PretendardText
                fontWeight="400"
                style={{
                  color: "#5A636A",
                  fontSize: "15px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {t("transport.caption1")}
              </PretendardText>
            </div>

            <TransPortItem title={t("transport.title1")} data={SELLER} />
            <TransPortItem title={t("transport.title2")} data={RULES} />
            <TransPortItem title={t("transport.title3")} data={GUIDE} />
            <TransPortItem title={t("transport.title4")} data={SHIPPING} />
          </div>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
};

export default Transport;
