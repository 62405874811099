import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { useTranslation } from "react-i18next";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import MemberApi from "../../../api/member.api";
import Modal from "../../../component/templates/Modal";
import { toastMsg } from "../../../util/toastMsg";
import { toast } from "react-toastify";

function JoinApproveDetail(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getMemberInfo();
  }, []);

  const [memberInfo, setMemberInfo] = useState({});

  const getMemberInfo = async () => {
    try {
      const response = (await MemberApi.getById(id)).data.data.content;

      setMemberInfo({
        ...response,
        birth: response.birth ? response.birth.split("T")[0] : "",
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 승인
  const [approvalModalTitle, setApprovalModalTitle] = useState("");
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);

  const handleApproval = async () => {
    try {
      await MemberApi.approval(id);

      toast(t("toastMsg.create"));
      setApprovalModalOpen(false);
      getMemberInfo();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <EditBtn
              text={t("approve_detail.btn")}
              onClick={() => {
                setApprovalModalTitle(t("modalMsg.approve"));
                setApprovalModalOpen(true);
              }}
            />
          </DetailTop>
          <DetailPage style={{ minHeight: "250px" }}>
            <DetailPageTitle>{t("approve_detail.title")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 이메일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("approve_detail.email")}</DetailFormTitle>
                <DetailFormText>{memberInfo.email}</DetailFormText>
              </DetailPageForm>
              {/* 닉네임 */}
              <DetailPageForm>
                <DetailFormTitle>{t("approve_detail.nickname")}</DetailFormTitle>
                <DetailFormText>{memberInfo.nickname}</DetailFormText>
              </DetailPageForm>
              {/* SNS 링크 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("approve_detail.snsLink")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {memberInfo.snsLink ? JSON.parse(memberInfo.snsLink).map((item, index) => <DetailFormText key={index}>{item}</DetailFormText>) : <DetailFormText></DetailFormText>}
                </div>
              </DetailPageForm>
              {/* SNS 스크린샷 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("approve_detail.snsScreenShot")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    width: 744,
                  }}
                >
                  {memberInfo.screenshots ? (
                    memberInfo.screenshots.map((item, index) => <FileImgPreview key={index} fileName={item.screenshotOriginFileName} serverFileName={item.screenshotServerFileName} />)
                  ) : (
                    <DetailFormText></DetailFormText>
                  )}
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {approvalModalOpen ? (
        <Modal
          title={`${t("modalMsg.influencer_approve")}`}
          setModalOpen={setApprovalModalOpen}
          cancelBtnText={t("modalMsg.influencer_cancel")}
          okBtnText={t("modalMsg.influencer_ok")}
          onClick={() => {
            handleApproval();
          }}
        />
      ) : null}
    </>
  );
}

export default JoinApproveDetail;
