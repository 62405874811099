import api from "./api.js";

export default class MemberApi {
  // 회원 목록 조회
  static async getList(params) {
    return await api.get(`admin/member`, params);
  }

  // 회원 삭제
  static async delete(data) {
    return await api.del(`admin/member`, data);
  }

  // 회원 단일 조회
  static async getById(memberId, params) {
    return await api.get(`admin/member/${memberId}`, params);
  }

  // 회원 수정
  static async update(memberId, data) {
    return await api.patch(`admin/member/${memberId}`, data);
  }

  // 회원 활성/비활성
  static async activation(memberId, data) {
    return await api.post(`admin/member/activation/${memberId}`, data);
  }

  // 회원 등급변경
  static async grade(memberId, data) {
    return await api.patch(`admin/member/grade/${memberId}`, data);
  }

  // 회원 승인
  static async approval(memberId, data) {
    return await api.post(`admin/member/approval/${memberId}`, data);
  }

  // 회원 탈퇴 승인 목록
  static async getRetireApproval(params) {
    return await api.get(`admin/retired-member/request`, params);
  }

  // 회원 탈퇴 승인
  static async retireApproval(memberId) {
    return await api.post(`admin/retired-member/approval/${memberId}`);
  }

  // 회원 탈퇴 목록 조회
  static async getRetiredMemberList(params) {
    return await api.get(`admin/retired-member`, params);
  }

  // 회원 탈퇴 삭제
  static async retiredDelete(data) {
    return await api.del(`admin/retired-member`, data);
  }

  // 귀속 회원 구매 내역 조회
  static async getAttributionList(id, params) {
    return await api.get(`admin/point-history/${id}`, params);
  }
}
