import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import TableTitle from "../../component/atoms/text/table/tableTitle";
import Modal from "../../component/templates/Modal";
import { modalMsg } from "../../util/modalMsg";
import DetailPageFormCol from "./../../component/atoms/layout/detail/detailPageFormCol";
import PretendardText from "../../component/atoms/text/pretendardText";
import OrderApi from "../../api/order.api";
import { errorHandler } from "../../util/errorHandler";

function OrderModal({ data, setModalOpen, listHandler }) {
  const { t } = useTranslation();

  const [detailData, setDetailData] = useState({});
  const [editDetailData, setEditDetailData] = useState(data);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  // mounted
  useEffect(() => {
    getDetailData();
  }, []);

  const getDetailData = async () => {
    try {
      const response = await OrderApi.getOrderDetail(data.id);
      setDetailData({
        ...response.data.data.content,
        memberMerches: response.data.data.content.memberMerches.map((item) => {
          return {
            ...item,
            optionInfo: JSON.parse(item.optionInfo),
            optionInfoString: getOptionInfoString(JSON.parse(item.optionInfo)),
          };
        }),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const getOptionInfoString = (optionInfo) => {
    let optionInfoString = "";

    for (let index = 0; index < optionInfo.length; index++) {
      const element = optionInfo[index];
      const option = element.option;
      const optionValue = element.optionValue;

      if (index != 0) {
        optionInfoString += " / ";
      }
      optionInfoString += `${option.title}: ${optionValue.title}, ${t("shipping.count")}: ${optionValue.quantity}`;
    }

    return optionInfoString;
  };

  return (
    <>
      <ModalWrapper>
        <ModalBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "36px",
            }}
          >
            <TableTitle style={{ marginBottom: "0px", fontSize: "24px" }}>{t("order_modal.title")}</TableTitle>
            <img
              style={{
                cursor: "pointer",
                width: "30px",
                height: "30px",
              }}
              src="/assets/admin/icons/x_30size.png"
              alt=""
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              minHeight: "223px",
              overflowY: "auto",
            }}
          >
            <DetailPageForm>
              <OrderFormTitle>{t("order_modal.orderNumber")}</OrderFormTitle>
              <OrderFormText>{detailData.orderNumber}</OrderFormText>
            </DetailPageForm>
            <DetailPageForm>
              <OrderFormTitle>{t("order_modal.orderDay")}</OrderFormTitle>
              <DetailFormText>{moment(detailData.createdAt).format("YYYY/MM/DD")}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <OrderFormTitle>{t("order_modal.payMethod")}</OrderFormTitle>
              <OrderFormText>{detailData.payMethodTitle}</OrderFormText>
            </DetailPageForm>
            <div
              style={{
                marginTop: "26px",
                display: "flex",
                flexDirection: "row",
                // alignItems: "center",
                gap: "40px",
              }}
            >
              <DetailPageFormCol style={{ gap: "10px", flex: 1 }}>
                <OrderFormTitle>{t("order_modal.deleveryInfo")}</OrderFormTitle>
                <OrderFormText>
                  {detailData.address} {detailData.detailAddress}
                  <br />
                  {t("order_modal.recipient")}: {detailData.recipient}
                </OrderFormText>
              </DetailPageFormCol>
              <div
                style={{
                  width: "1px",
                  minHeight: "113px",
                  height: "max-content",
                  background: "#CECECE",
                }}
              />
              <DetailPageFormCol style={{ gap: "10px", flex: 1 }}>
                <OrderFormTitle>{t("order_modal.customer")}</OrderFormTitle>
                <OrderFormText>
                  {detailData.name}
                  <br />
                  {detailData.email}
                  <br />
                  {detailData.phoneNumber}
                </OrderFormText>
              </DetailPageFormCol>
            </div>
            <div
              style={{
                borderTop: "2px solid #bcbcbc",
                borderBottom: "2px solid #bcbcbc",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "10px 0",
                  borderBottom: "1px solid #E0E0E0",
                }}
              >
                <OrderTableText
                  style={{
                    width: "143px",
                    fontWeight: "700",
                    color: "#7D7D7D",
                  }}
                >
                  {t("order_modal.orderHistory")}
                </OrderTableText>
                <OrderTableText
                  style={{
                    width: "143px",
                    fontWeight: "700",
                    color: "#7D7D7D",
                  }}
                >
                  {t("order_modal.option")}
                </OrderTableText>
                <OrderTableText
                  style={{
                    width: "128px",
                    fontWeight: "700",
                    color: "#7D7D7D",
                  }}
                >
                  {/* {t("order_modal.state")} */}
                </OrderTableText>
                <OrderTableText
                  style={{
                    width: "78px",
                    fontWeight: "700",
                    color: "#7D7D7D",
                  }}
                >
                  {t("order_modal.count")}
                </OrderTableText>
                <OrderTableText
                  style={{
                    width: "98px",
                    fontWeight: "700",
                    color: "#7D7D7D",
                    textAlign: "right",
                  }}
                >
                  {t("order_modal.amount")}
                </OrderTableText>
                <OrderTableText
                  style={{
                    width: "110px",
                    fontWeight: "700",
                    color: "#7D7D7D",
                    textAlign: "right",
                  }}
                >
                  {t("order_modal.deleveryFee")}
                </OrderTableText>
              </div>
              {detailData.memberMerches?.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      padding: "10px 0 15px 0",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <OrderTableText
                      style={{
                        width: "143px",
                        paddingRight: "10px",
                      }}
                    >
                      {item.title}
                    </OrderTableText>
                    <OrderTableText
                      style={{
                        width: "143px",
                        paddingRight: "10px",
                      }}
                    >
                      {item.optionInfo?.map((item2, index2) => {
                        return <div key={index2}>{item2.option?.title}</div>;
                      })}
                    </OrderTableText>
                    <OrderTableText
                      style={{
                        width: "128px",
                      }}
                    ></OrderTableText>
                    <OrderTableText
                      style={{
                        width: "78px",
                      }}
                    >
                      {item.optionFlag
                        ? item.optionInfo?.map((item2, index2) => {
                            return <div key={index2}>{item2.optionValue?.quantity}</div>;
                          })
                        : item.noOptionQuantity}
                    </OrderTableText>
                    <OrderTableText
                      style={{
                        width: "98px",
                        textAlign: "right",
                      }}
                    >
                      {item.salePrice?.toLocaleString()}
                    </OrderTableText>
                    <OrderTableText
                      style={{
                        width: "110px",
                        textAlign: "right",
                      }}
                    >
                      {item.deliveryCost?.toLocaleString()}
                    </OrderTableText>
                  </div>
                );
              })}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ width: "50%", marginBottom: "30px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      padding: "10px 0",
                    }}
                  >
                    <OrderTableText>{t("order_modal.sum")}</OrderTableText>
                    <OrderTableText>{detailData.orderAmount?.toLocaleString()}</OrderTableText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      padding: "10px 0",
                    }}
                  >
                    <OrderTableText>{t("order_modal.deleveryFee")}</OrderTableText>
                    <OrderTableText>{detailData.deliveryAmount?.toLocaleString()}</OrderTableText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      padding: "10px 0",
                    }}
                  >
                    <OrderTableText>{t("order_modal.coupon")}</OrderTableText>
                    <OrderTableText>{detailData.couponAmount ? "-" + detailData.couponAmount?.toLocaleString() : 0}</OrderTableText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #E0E0E0",
                      padding: "10px 0",
                    }}
                  >
                    <OrderTableText>{t("order_modal.point")}</OrderTableText>
                    <OrderTableText>{detailData.pointAmount ? "-" + detailData.pointAmount?.toLocaleString() : 0}</OrderTableText>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0 0 0 ",
                    }}
                  >
                    <OrderTableText style={{ fontWeight: 700 }}>{t("order_modal.total")}</OrderTableText>
                    <OrderTableText style={{ fontWeight: 700 }}>{detailData.payAmount?.toLocaleString()}</OrderTableText>
                  </div>
                </div>
              </div>
            </div>
            <PretendardText
              style={{
                fontSize: "12px",
                fontWeight: 400,
                color: "#7d7d7d",
                textAlign: "center",
              }}
            >
              {t("order_modal.koviet")}
            </PretendardText>
          </div>
        </ModalBox>
      </ModalWrapper>
    </>
  );
}

export default OrderModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 785px;
  height: 785px;
  padding: 50px;
  padding-right: 20px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow-y: scroll;
`;

const OrderFormTitle = styled(PretendardText).attrs((props) => {})`
  min-width: 200px;
  max-width: 200px;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: #bcbcbc;
`;

const OrderFormText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #4b4d4c;
  max-width: 744px;
  white-space: pre-wrap;
`;

const OrderTableText = styled(PretendardText).attrs((props) => {})`
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #4b4d4c;
  max-width: 744px;
  white-space: pre-wrap;
`;
