import React, { useEffect, useState } from "react";
// import BannerApi from "../../api/banner.api";
import { useTranslation } from "react-i18next";
import DetailPage from "../../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../../component/atoms/input/mainInput";
import Radio from "../../../../component/atoms/input/radio";
import SaveBtn from "../../../../component/atoms/button/saveBtn";
import { errorHandler } from "../../../../util/errorHandler";
import MainInfoApi from "../_api/main-info.api";
import Modal from "../../../../component/templates/Modal";
import { modalMsg } from "../../../../util/modalMsg";
import { toast } from "react-toastify";
import { toastMsg } from "../../../../util/toastMsg";

const MainManageItem = ({ title, data }) => {
  const { t } = useTranslation();

  const [mainInfo, setMainInfo] = useState({});

  useEffect(() => {
    setMainInfo(data);
  }, [data]);

  // --------------------------------------------- //
  // ----------------- 저장하기 ----------------- //
  // ------------------------------------------- //
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (!mainInfo.title) {
      toast(t("toastMsg.partAdd"));
      return;
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        title: mainInfo.title,
        activation: mainInfo.activation,
      };

      await MainInfoApi.updateMainInfo(mainInfo.id, data);
      setSaveModalOpen(false);
      toast(t("toastMsg.update"));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <DetailPage style={{ minHeight: "317px" }}>
        <DetailPageTitle>{title}</DetailPageTitle>
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <DetailPageForm>
            <DetailFormTitle>{t("main_manage.sectionName")}*</DetailFormTitle>
            <MainInput
              style={{
                width: "338px",
              }}
              type={"text"}
              placeholder={t("main_manage.placeholder")}
              value={mainInfo.title}
              onChange={(e) => setMainInfo({ ...mainInfo, title: e.target.value })}
            />
          </DetailPageForm>
          <DetailPageForm>
            <DetailFormTitle>{t("main_manage.state")}*</DetailFormTitle>
            <div style={{ display: "flex", gap: "19px" }}>
              <Radio
                id={`activation_${mainInfo.id}`}
                valueText={t("component.active")}
                name={`activation_${mainInfo.id}`}
                checked={mainInfo.activation}
                onChange={() => {
                  setMainInfo({ ...mainInfo, activation: true });
                }}
              />
              <Radio
                id={`inActivation_${mainInfo.id}`}
                valueText={t("component.inactive")}
                name={`inActivation_${mainInfo.id}`}
                checked={!mainInfo.activation}
                onChange={() => {
                  setMainInfo({ ...mainInfo, activation: false });
                }}
              />
            </div>
          </DetailPageForm>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SaveBtn
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </div>
        </div>
      </DetailPage>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default MainManageItem;
