import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Tab = ({ list, style, onClick }) => {
  const { i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <TabWrapper style={{ ...style }}>
      {list.length > 0 &&
        list.map((item, index) => {
          return (
            <TabBox
              onClick={() => {
                if (onClick) {
                  onClick();
                } else {
                  navigate(item.url);
                }
              }}
              key={index}
              style={{
                padding: i18n.language === "VN" ? "19px 10px" : "19px 29px",
                borderBottomColor:
                  path === item.url ? "#27BEFF" : "transparent",
              }}
            >
              <PretendardText
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  color: path === item.url ? "#27BEFF" : "#ADB4BA",
                  lineHeight: "25px",
                }}
              >
                {item.title}
              </PretendardText>
            </TabBox>
          );
        })}
    </TabWrapper>
  );
};

export default Tab;

const TabWrapper = styled.div.attrs((props) => {})`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;

const TabBox = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px 10px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
`;
