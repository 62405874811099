import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import PopupApi from "../../../api/popup.api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DateInput from "../../../component/atoms/input/dateInput";
import ImgInput from "../../../component/atoms/input/imgInput";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import ImgPreview from "../../../component/molecules/imgPreview";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { isNull } from "../../../util/check";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import PretendardText from "../../../component/atoms/text/pretendardText";
import CommonApi from "../../../api/common.api";
import PopupApi from "./_api/popup.api";

const PopupCreate = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [popupInfo, setPopupInfo] = useState({
    title: "",
    images: [
      {
        // 이미지
        imageOriginFileName: "", // test.png
        imageServerFileName: "", // popup/4e1faa90-413c-4fc2-8323-ed3c5231e7f7.png
      },
    ],
  });

  // ----------------------------------------------- //
  // ----------------- 이미지 관련 ----------------- //
  // --------------------------------------------- //

  const [filteredImgList, setFilteredImgList] = useState([]);

  const [imgList, setImgList] = useState({
    img1: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img2: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img3: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img4: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img5: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
  });

  // 이미지 추가 삭제
  const inputFile = async (e, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = (value) => {
      setImgList({
        ...imgList,
        [type]: {
          ...imgList[type],
          status: true,
          file: file,
          imageLocalFileUrl: value.target.result,
        },
      });
    };

    e.target.value = "";
  };

  // 파일 업로드
  const fileUpload = async (file) => {
    try {
      const data = {
        path: "popup/",
        extension: file.name.split(".").pop(),
      };

      const response = (await CommonApi.fileUpload(data)).data.data;

      setFilteredImgList((prev) => [
        ...prev,
        {
          imageOriginFileName: file.name,
          imageServerFileName: response.serverFileName,
        },
      ]);

      await cloudFileUpload(response.presignedUrl, file);
    } catch (error) {
      errorHandler(error);
    }
  };

  const cloudFileUpload = async (url, file) => {
    try {
      await CommonApi.cloudFileUpload(url, file);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 이미지 삭제
  const deleteFile = (type) => {
    setImgList({
      ...imgList,
      [type]: {
        ...imgList[type],
        file: "",
        imageLocalFileUrl: "",
      },
    });
  };
  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = async () => {
    const firstFilteredImgList = Object.values(imgList).filter(
      (img) => img.file !== ""
    );

    if (isNull(popupInfo.title) || isNull(firstFilteredImgList)) {
      return toast(`${t("toastMsg.input_empty")}`);
    }

    firstFilteredImgList.forEach(async (img) => {
      await fileUpload(img.file);
    });

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        title: popupInfo.title,
        images: filteredImgList,
      };

      await PopupApi.create(data);
      setSaveModalOpen(false);
      navigate(-1);
      toast(t("toastMsg.create"));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "440px" }}>
            <DetailPageTitle>{t("component.add")}</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>{t("popup.popupName")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={popupInfo.title}
                  onChange={(e) =>
                    setPopupInfo({ ...popupInfo, title: e.target.value })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <div>
                  <DetailFormTitle>{t("popup.image")}</DetailFormTitle>
                  <DetailFormTitle style={{ fontWeight: "400" }}>
                    *{t("popup.fullCaption")}
                  </DetailFormTitle>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "end",
                    height: "fit-content",
                  }}
                >
                  {imgList.img1.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img1.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img1");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img1"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img1");
                      }}
                    />
                  )}
                  {imgList.img1.status &&
                    (imgList.img2.imageLocalFileUrl ? (
                      <ImgPreview
                        fileUrl={imgList.img2.imageLocalFileUrl}
                        onClick={() => {
                          deleteFile("img2");
                        }}
                      />
                    ) : (
                      <ImgInput
                        id={"img2"}
                        accept={"image/*"}
                        onChange={(e) => {
                          inputFile(e, "img2");
                        }}
                      />
                    ))}
                  {imgList.img2.status &&
                    (imgList.img3.imageLocalFileUrl ? (
                      <ImgPreview
                        fileUrl={imgList.img3.imageLocalFileUrl}
                        onClick={() => {
                          deleteFile("img3");
                        }}
                      />
                    ) : (
                      <ImgInput
                        id={"img3"}
                        accept={"image/*"}
                        onChange={(e) => {
                          inputFile(e, "img3");
                        }}
                      />
                    ))}
                  {imgList.img3.status &&
                    (imgList.img4.imageLocalFileUrl ? (
                      <ImgPreview
                        fileUrl={imgList.img4.imageLocalFileUrl}
                        onClick={() => {
                          deleteFile("img4");
                        }}
                      />
                    ) : (
                      <ImgInput
                        id={"img4"}
                        accept={"image/*"}
                        onChange={(e) => {
                          inputFile(e, "img4");
                        }}
                      />
                    ))}
                  {imgList.img4.status &&
                    (imgList.img5.imageLocalFileUrl ? (
                      <ImgPreview
                        fileUrl={imgList.img5.imageLocalFileUrl}
                        onClick={() => {
                          deleteFile("img5");
                        }}
                      />
                    ) : (
                      <ImgInput
                        id={"img5"}
                        accept={"image/*"}
                        onChange={(e) => {
                          inputFile(e, "img5");
                        }}
                      />
                    ))}
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : null}
    </>
  );
};

export default PopupCreate;
