import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
// import ExcelApi from "../../../api/excel.api";
// import MemberApi from "../../../api/memebr.api";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import AskModal from "./askModal";
import AskApi from "./_api/ask.api";
import { isNull } from "../../../util/check";
import { fileUrl } from "../../../api/api";
import PretendardText from "../../../component/atoms/text/pretendardText";
import styled from "styled-components";

const Ask = () => {
  const { t, i18n } = useTranslation();
  const isVn = i18n.language === "VN";

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getOrderAskList();
  }, [location]);

  const [orderAskList, setOrderAskList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const getOrderAskList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await AskApi.getOrderAskList(data)).data.data;
      setOrderAskList(
        responseData.content.map((item) => {
          return {
            ...item,
            order: {
              ...item.order,
              merchInfo: getMerchInfo(JSON.parse(item.order?.merchInfo)),
            },
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const getMerchInfo = (merchInfo) => {
    let merchInfoStringList = [];

    for (let index = 0; index < merchInfo.length; index++) {
      const element = merchInfo[index];
      const merchInfoString = {
        title: element.merch.title,
        imageOriginFileName: element.merch.imageOriginFileName,
        imageServerFileName: element.merch.imageServerFileName,
        optionString: element.merch.optionFlag
          ? getOptionInfo(element.optionInfo)
          : `수량: ${element.merch.noOptionQuantity}`,
      };

      merchInfoStringList.push(merchInfoString);
    }

    return merchInfoStringList;
  };

  const getOptionInfo = (optionInfo) => {
    let optionString = "";

    for (let index = 0; index < optionInfo.length; index++) {
      const element = optionInfo[index];
      const option = element.option;
      const optionValue = element.optionValue;

      if (index != 0) {
        optionString += " / ";
      }
      optionString += `${option.title}: ${optionValue.title}, 수량: ${optionValue.quantity}`;
    }

    return optionString;
  };

  const [selectedData, setSelectedData] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <Tab
            list={[
              { title: `${t("ask.title")}`, url: route.ask },
              { title: `${t("ask.title2")}`, url: route.qna },
            ]}
            style={{ marginBottom: "20px" }}
          />
          <TableTitle>{t("ask.title")}</TableTitle>
          <TableTop>
            <TableCount
              text={t("ask.askCount")}
              count={pagination.totalCount}
            />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.ask}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.ask}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <ExcelBtn
              // onSelectClick={getExcelChecked}
              // onAllClick={getExcelAll}
              />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 이메일 */}
              <col width={isVn ? "183px" : "183px"} />
              {/* 주문자명 */}
              <col width={isVn ? "131px" : "131px"} />
              {/* 상품명 */}
              <col width={isVn ? "243px" : "283px"} />
              {/* 문의 유형 */}
              <col width={isVn ? "125px" : "125px"} />
              {/* 작성일 */}
              <col width={isVn ? "105px" : "128px"} />
              {/* 답변상태 */}
              <col width={isVn ? "119px" : "96px"} />
              {/* 상세보기 */}
              <col width={isVn ? "78px" : "78px"} />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <TableText>{t("ask.email")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("ask.name")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("ask.product")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("ask.type")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("ask.createAt")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("ask.state")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("ask.more")}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {orderAskList.map((item, index) => {
                return (
                  <Tr
                    key={index}
                    style={{ height: "59px", background: "#fff" }}
                  >
                    <Td style={{ paddingLeft: "20px" }}>
                      <TableText>{item.order?.email}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.order?.name}</TableText>
                    </Td>
                    <Td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "3px",
                            }}
                            src={`${fileUrl}/${item.order?.merchInfo[0]?.imageServerFileName}`}
                            alt=""
                          />
                        </div>
                        <div>
                          <TableText
                            style={{ marginBottom: "4px", width: "181px" }}
                          >
                            {item.order?.merchInfo[0]?.title}
                          </TableText>
                          <TableText
                            style={{
                              fontSize: "12px",
                              lineHeight: "16px",
                              width: "181px",
                            }}
                          >
                            {item.order?.merchInfo[0]?.optionString}
                          </TableText>
                        </div>
                      </div>
                    </Td>
                    <Td>
                      <TableText>{item.askTypeTitle}</TableText>
                    </Td>
                    <Td>
                      <TableText>
                        {moment(item.createdAt).format("YYYY/MM/DD")}
                      </TableText>
                    </Td>
                    <Td>
                      <AnswerText isAnswer={item.answer}>
                        {isNull(item.answer)
                          ? t("ask.answerWait")
                          : t("ask.answerComplete")}
                      </AnswerText>
                    </Td>
                    <Td>
                      <DetailBtn
                        onClick={() => {
                          setSelectedData(item);
                          setDetailModalOpen(true);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.ask}
            queryString={`keyword=${searchParams.get("keyword") || ""}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>
      {detailModalOpen ? (
        <AskModal data={selectedData} setModalOpen={setDetailModalOpen} />
      ) : (
        ""
      )}
    </>
  );
};

export default Ask;

const AnswerText = styled(PretendardText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 22.75px;
  color: ${(props) => (props.isAnswer ? "#000" : "#004098")};
`;
