import api from "../../../../api/api";

export default class NoticeApi {
  static async create(data) {
    return await api.post(`admin/notice`, data);
  }
  static async getList(params) {
    return await api.get(`admin/notice`, params);
  }
  static async getById(noticeId, params) {
    return await api.get(`admin/notice/${noticeId}`, params);
  }
  static async update(noticeId, data) {
    return await api.patch(`admin/notice/${noticeId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/notice`, data);
  }
}
