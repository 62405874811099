import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddBtn from "../../../component/atoms/button/addBtn";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import SearchBar from "../../../component/atoms/input/searchBar";
import { useTranslation } from "react-i18next";
import PromotionApi from "../../../api/promotion.api";

const Exhibition = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // mounted
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getExhibitionList();
  }, [location]);

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const [exhibitionList, setExhibitionList] = useState([1]);
  const [keyword, setKeyword] = useState("");

  const getExhibitionList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };

      let responseData = (await PromotionApi.getList(data)).data.data;

      setExhibitionList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activationId, setActivationId] = useState(null);
  const [activation, setActivation] = useState(null);
  const [activationModalTitle, setActivationModalTitle] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      const data = {
        activation: activation,
      };
      await PromotionApi.updateActivation(activationId, data);
      toast(t("toastMsg.create"));
      setFilterOpen(-1);
      setActivationModalOpen(false);
      getExhibitionList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const isAllCheck = useMemo(() => {
    const checkedList = exhibitionList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === exhibitionList.length;
  }, [exhibitionList]);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: delIdList,
      };
      await PromotionApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      setDelIdList([]);
      getExhibitionList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{t("exhibition.title")}</TableTitle>
          <TableTop>
            <TableCount text={t("exhibition.userCount")} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.exhibition}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.exhibition}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <DelBtn
                text={t("exhibition.delBtn")}
                onClick={() => {
                  const delList = exhibitionList.filter((item) => item.checked);
                  if (delList.length <= 0) {
                    return;
                  }
                  setDelIdList(delList.map((item) => item.id));
                  setDeleteModalOpen(true);
                }}
              />
              <AddBtn onClick={() => navigate(route.exhibition_add)} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="90px" />
              <col width="438px" />
              <col width="347px" />
              <col width="89px" />
              <col width="79px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setExhibitionList(
                          exhibitionList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setExhibitionList(
                          exhibitionList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t("exhibition.tableTitle")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("exhibition.period")}</TableText>
                </Th>
                {/* <Th>
                  <TableText>{t("exhibition.state")}</TableText>
                </Th> */}
                <Th>
                  <TableText>{t("exhibition.del")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("exhibition.more")}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {exhibitionList.map((item, index) => {
                return (
                  <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setExhibitionList([...exhibitionList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText
                        style={{
                          width: "397px",
                        }}
                      >
                        {item.title}
                      </TableText>
                    </Td>
                    <Td>
                      <TableText>{moment.utc(item.createdAt).format("YYYY/MM/DD")}</TableText>
                    </Td>
                    {/* <Td>
                      <TableFilter
                        style={{
                          width: "75px",
                        }}
                        onClick={() => {
                          filterOpen === index
                            ? setFilterOpen(-1)
                            : setFilterOpen(index);
                        }}
                        isOpen={filterOpen === index}
                        // selectedValue={item.activation}
                        selectedValue={true}
                        list={[
                          {
                            value: true,
                            title: "활성",
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(true);
                              setActivationModalTitle(
                                `${t("modalMsg.activate")}`
                              );
                              setActivationModalOpen(true);
                            },
                          },
                          {
                            value: false,
                            title: "비활성",
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(false);
                              setActivationModalTitle(
                                `${t("modalMsg.deactivate")}`
                              );
                              setActivationModalOpen(true);
                            },
                          },
                        ]}
                      />
                    </Td> */}
                    <Td>
                      <TableDelBtn
                        onClick={() => {
                          setExhibitionList([...exhibitionList]);
                          const delList = [item];
                          setDelIdList(delList.map((item) => item.id));
                          setDeleteModalOpen(true);
                        }}
                      />
                    </Td>
                    <Td>
                      <DetailBtn
                        onClick={() => {
                          navigate(route.exhibition_detail + `/${item.id}`);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.exhibition}
            queryString={`keyword=${searchParams.get("keyword")}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>

      {activationModalOpen ? (
        <Modal
          title={`${activationModalTitle}`}
          setModalOpen={setActivationModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};
export default Exhibition;
