import React from "react";
import styled from "styled-components";

function DetailPageFormCol(props) {
  return (
    <>
      <DetailPageFormColWrapper
        style={{
          ...props.style,
        }}
      >
        {props.children}
      </DetailPageFormColWrapper>
    </>
  );
}

export default DetailPageFormCol;

const DetailPageFormColWrapper = styled.div.attrs((props) => {})`
  max-width: 964px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  gap: 10px;
`;
