import React from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { useTranslation } from "react-i18next";

function Modal({ title, text, setModalOpen, onClick, negative, cancelBtnText, okBtnText }) {
  const { t } = useTranslation();

  return (
    <ModalWrapper>
      <ModalBox>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ModalTitle>{title}</ModalTitle>
          {text ? <ModalText>{text}</ModalText> : null}
        </div>
        <ModalBtnWrapper>
          <CancelBtn onClick={() => setModalOpen(false)}>{cancelBtnText ? cancelBtnText : t(`component.cancel`)}</CancelBtn>
          <ModalBtn onClick={onClick} $negative={negative}>
            {okBtnText ? okBtnText : t(`component.check`)}
          </ModalBtn>
        </ModalBtnWrapper>
      </ModalBox>
    </ModalWrapper>
  );
}

export default Modal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 394px;
  min-height: 173px;
  height: auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
`;

const ModalTitle = styled(PretendardText).attrs((props) => {})`
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  color: #262c31;
  text-align: center;
  white-space: pre-wrap;
`;

const ModalText = styled(PretendardText).attrs((props) => {})`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #808991;
  text-align: center;
  margin-top: 6px;
  white-space: pre-wrap;
`;

const ModalBtnWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const CancelBtn = styled.button.attrs((props) => {})`
  padding: 5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #636c73;
  background: #f2f4f5;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  border-radius: 100px;
`;

const ModalBtn = styled.button.attrs((props) => {})`
  padding: 5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: ${(props) => (props.$negative ? "#FF003D" : "#279eff")};
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  border-radius: 100px;
`;
