import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { errorHandler } from "../../../util/errorHandler";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import TextArea from "../../../component/atoms/input/textarea";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import { useTranslation } from "react-i18next";
import Radio from "../../../component/atoms/input/radio";
import TermsApi from "./_api/terms.api";

function TermsEdit() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getTermsInfo();
  }, []);

  // ----- 정보 가져오기
  const [termsInfo, setTermsInfo] = useState({
    title: "",
    content: "",
    requiredFlag: true,
    activation: true,
  });

  const getTermsInfo = async () => {
    try {
      const response = (await TermsApi.getById(id)).data.data.content;
      setTermsInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 저장
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveFunc = async () => {
    const data = {
      title: termsInfo.title,
      content: termsInfo.content,
      requiredFlag: termsInfo.requiredFlag,
      activation: termsInfo.activation,
    };

    try {
      await TermsApi.update(id, data);
      toast(t("toastMsg.create"));
      setSaveModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "500px" }}>
            <DetailPageTitle>{t("terms.edit")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>{t("terms.termsName")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={termsInfo.title}
                  onChange={(e) => setTermsInfo({ ...termsInfo, title: e.target.value })}
                />
              </DetailPageForm>
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("terms.content")}*</DetailFormTitle>
                <TextArea
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={termsInfo.content}
                  onChange={(e) => setTermsInfo({ ...termsInfo, content: e.target.value })}
                  style={{
                    minHeight: "135px",
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("terms.state")}*</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"true"}
                    valueText={t("component.active")}
                    name={"activation1"}
                    checked={termsInfo.activation}
                    onChange={() => {
                      setTermsInfo({
                        ...termsInfo,
                        activation: true,
                      });
                    }}
                  />
                  <Radio
                    id={"false"}
                    valueText={t("component.inactive")}
                    name={"activation1"}
                    checked={!termsInfo.activation}
                    onChange={() => {
                      setTermsInfo({
                        ...termsInfo,
                        activation: false,
                      });
                    }}
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                if (!termsInfo.title) {
                  toast(t("toastMsg.termTitleAdd"));
                  return;
                }
                if (!termsInfo.title) {
                  toast(`${t("toastMsg.input_empty")}`);
                  return;
                }
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default TermsEdit;
