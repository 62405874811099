import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { isNull } from "../../../util/check";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import { useTranslation } from "react-i18next";
import Radio from "../../../component/atoms/input/radio";
import ImgPreview from "../../../component/molecules/imgPreview";
import DetailPageFormCol from "../../../component/atoms/layout/detail/detailPageFormCol";
import Checkbox from "../../../component/atoms/input/checkbox";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import styled from "styled-components";
import PretendardText from "./../../../component/atoms/text/pretendardText";
import TextFormEditor from "./../../../component/atoms/input/textFormEditor";
import ImgInput from "./../../../component/atoms/input/imgInput";
import CategoryApi from "../../../api/category.api";
import PromotionApi from "./../../../api/promotion.api";
import CommonApi from "../../../api/common.api";
import MerchApi from "../../../api/merch.api";
import { fileUrl } from "./../../../api/api";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import DelBtn from "../../../component/atoms/button/delBtn";

// TODO:
// - 텍스트 에디터에 사진 업로드까지는 정상적으로 되고 있으나, CORS 문제인지 이미지가 엑박으로 나오는 문제가 있음
// - 옵션 영역 추후 작업 필요

const ProductEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getOption();
    getMerchList();
    getCategoryList();
    getPromotionList();
  }, []);

  // --------------------------------------------- //
  // ----------------- 상품 관련 ----------------- //
  // ------------------------------------------- //

  const [originOptionFlag, setOriginOptionFlag] = useState(false);
  const [merchInfo, setMerchInfo] = useState({
    title: "", // 상품명
    merchStatus: "", // PROGRESS, END
    merchCode: "",
    images: [
      {
        // 상품 이미지
        imageOriginFileName: "", // test.png
        imageServerFileName: "", // popup/4e1faa90-413c-4fc2-8323-ed3c5231e7f7.png
      },
    ],
    content: "", // 상품설명
    manufacturer: "", // 제조사
    brand: "", // 브랜드
    madeIn: "", // 원산지
    productType: "", // 제품타입
    salePrice: 0, // 판매가
    originalPrice: 0, // 정가
    weight: 0, // 무게
    bundled: true, // 묶음배송여부
    category: {
      id: 0,
      title: "", // 하위 카테고리
      parentCategory: {
        id: 0,
        title: "", // 부모 카테고리
      },
    },
    promotion: {
      id: 0, // 기획전 ID
      title: "", // 기획전명
    },
    bestFlag: false, // 상품 뱃지 - 베스트
    newFlag: false, // 상품 뱃지 - 신상품
    mdFlag: false, // 상품 뱃지 - MD추천
    eventFlag: false, // 상품 뱃지 - 이벤트
    optionFlag: false, // 옵션 여부
    inventory: 0, // 재고
    deleteIds: [0],
  });

  const [imgList, setImgList] = useState({
    img1: {
      id: 0,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img2: {
      id: 0,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img3: {
      id: 0,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img4: {
      id: 0,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img5: {
      id: 0,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img6: {
      id: 0,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
  });

  const [delImgIdList, setDelImgIdList] = useState([]);

  const getMerchList = async () => {
    try {
      const response = (await MerchApi.getById(id)).data.data.content;

      if (response) {
        setMerchInfo(response);
        setOriginOptionFlag(response.optionFlag);

        if (response.images) {
          setImgList((prevList) => ({
            ...prevList,
            img1: {
              ...prevList.img1,
              id: response.images[0]?.id || 0,
              imageOriginFileName: response.images[0]?.imageOriginFileName || "",
              imageServerFileName: response.images[0]?.imageServerFileName || "",
            },
            img2: {
              ...prevList.img2,
              id: response.images[1]?.id || 0,
              imageOriginFileName: response.images[1]?.imageOriginFileName || "",
              imageServerFileName: response.images[1]?.imageServerFileName || "",
            },
            img3: {
              ...prevList.img3,
              id: response.images[2]?.id || 0,
              imageOriginFileName: response.images[2]?.imageOriginFileName || "",
              imageServerFileName: response.images[2]?.imageServerFileName || "",
            },
            img4: {
              ...prevList.img4,
              id: response.images[3]?.id || 0,
              imageOriginFileName: response.images[3]?.imageOriginFileName || "",
              imageServerFileName: response.images[3]?.imageServerFileName || "",
            },
            img5: {
              ...prevList.img5,
              id: response.images[4]?.id || 0,
              imageOriginFileName: response.images[4]?.imageOriginFileName || "",
              imageServerFileName: response.images[4]?.imageServerFileName || "",
            },
            img6: {
              ...prevList.img6,
              id: response.images[5]?.id || 0,
              imageOriginFileName: response.images[5]?.imageOriginFileName || "",
              imageServerFileName: response.images[5]?.imageServerFileName || "",
            },
          }));
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  // ------------------------------------------------- //
  // ----------------- 기획전 관련 ----------------- //
  // ----------------------------------------------- //
  const [promotionList, setPromotionList] = useState([]);

  const getPromotionList = async () => {
    try {
      const response = (await PromotionApi.getList()).data.data.content;

      setPromotionList(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ------------------------------------------------- //
  // ----------------- 카테고리 관련 ----------------- //
  // ----------------------------------------------- //

  // 카테고리 목록 가져오기
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [childCategoryList, setChildCategoryList] = useState([]);

  const getCategoryList = async () => {
    try {
      const response = (await CategoryApi.getList()).data.data.content;

      setParentCategoryList(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----------------------------------------------- //
  // ----------------- 이미지 관련 ----------------- //
  // --------------------------------------------- //

  // 이미지 추가 삭제
  const inputFile = async (e, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = (value) => {
      setImgList({
        ...imgList,
        [type]: {
          ...imgList[type],
          status: true,
          file: file,
          imageLocalFileUrl: value.target.result,
        },
      });
    };

    e.target.value = "";
  };

  // 파일 업로드
  const fileUpload = async (file) => {
    try {
      const data = {
        path: "merch/",
        extension: file.name.split(".").pop(),
      };

      const response = (await CommonApi.fileUpload(data)).data.data;

      await cloudFileUpload(response.presignedUrl, file);

      return {
        imageOriginFileName: file.name,
        imageServerFileName: response.serverFileName,
      };
    } catch (error) {
      errorHandler(error);
    }
  };

  const cloudFileUpload = async (url, file) => {
    try {
      await CommonApi.cloudFileUpload(url, file);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 이미지 삭제
  const deleteFile = (type) => {
    setImgList({
      ...imgList,
      [type]: {
        ...imgList[type],
        file: "",
        imageLocalFileUrl: "",
      },
    });
  };

  // ----------------------------------------- //
  // ----------------- 기타 ----------------- //
  // --------------------------------------- //

  // 에디터
  const [position, setPosition] = useState(0);
  const [isBlurEditor, setIsBlurEditor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // 선택 박스 열기
  const [selectBoxOpen, setSelectBoxOpen] = useState({
    saleStatus: false,
    parentCategory: false,
    childCategory: false,
    promotion: false,
  });

  // --------------------------------------------- //
  // ----------------- 저장하기 ----------------- //
  // ------------------------------------------- //
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (isNull(merchInfo.title)) {
      // 상품명이 비어있는 경우
      return toast(`${t("product_detail.detail1_productName")} ${t("placeholder.defaultPlaceholder")}`);
    } else if (isNull(merchInfo.merchStatus)) {
      // 판매 상태가 비어있는 경우
      return toast(`${t("product_detail.detail1_state")} ${t("placeholder.defaultPlaceholder")}`);
    } else if (isNull(merchInfo.salePrice) || merchInfo.salePrice === 0) {
      // 판매가가 비어있는 경우
      return toast(`${t("product_detail.detail3_salePrice")} ${t("placeholder.defaultPlaceholder")}`);
    } else if (isNull(merchInfo.originalPrice) || merchInfo.originalPrice === 0) {
      // 정가가 비어있는 경우
      return toast(`${t("product_detail.detail3_originPrice")} ${t("placeholder.defaultPlaceholder")}`);
    } else if (isNull(merchInfo.weight) || merchInfo.weight === 0) {
      // 무게가 비어있는 경우
      return toast(`${t("product_detail.detail2_weight")} ${t("placeholder.defaultPlaceholder")}`);
    } else if (merchInfo.category?.id === 0 || isNull(merchInfo.category?.id)) {
      // 카테고리가 비어있는 경우
      return toast(`${t("product_detail.detail5_category")} ${t("placeholder.defaultPlaceholder")}`);
    } else if (Object.values(imgList).every((img) => img.file === "" && img.imageServerFileName === "")) {
      // 이미지가 비어있는 경우
      return toast(`${t("toastMsg.input_empty")}`);
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    const firstFilteredImgList = Object.values(imgList).filter((img) => img.file !== "");

    const uploadedImages = await Promise.all(firstFilteredImgList.map((img) => fileUpload(img.file)));

    try {
      const data = {
        title: merchInfo.title, // 상품명
        merchStatus: merchInfo.merchStatus, // PROGRESS, END
        content: merchInfo.content, // 상품설명
        manufacturer: merchInfo.manufacturer, // 제조사
        brand: merchInfo.brand, // 브랜드
        madeIn: merchInfo.madeIn, // 원산지
        productType: merchInfo.productType, // 제품타입
        salePrice: Number(merchInfo.salePrice), // 판매가
        originalPrice: Number(merchInfo.originalPrice), // 정가
        weight: merchInfo.weight, // 무게
        bundled: merchInfo.bundled, // 묶음배송여부
        categoryId: merchInfo.category?.id, // 카테고리 ID
        bestFlag: merchInfo.bestFlag, // 상품 뱃지 - 베스트
        newFlag: merchInfo.newFlag, // 상품 뱃지 - 신상품
        mdFlag: merchInfo.mdFlag, // 상품 뱃지 - MD추천
        eventFlag: merchInfo.eventFlag, // 상품 뱃지 - 이벤트
        optionFlag: merchInfo.optionFlag, // 옵션 여부
        inventory: Number(merchInfo.inventory), // 재고
        images: [], // 이미지
        deleteIds: delImgIdList, // 삭제할 이미지 ID
      };

      if (merchInfo.promotion.id !== 0) {
        data.promotionId = merchInfo.promotion.id; // 기획전 ID
      } else {
        data.promotionId = 0; // 기획전 ID
      }

      uploadedImages.forEach((img) => {
        if (img) {
          data.images.push({
            imageOriginFileName: img.imageOriginFileName,
            imageServerFileName: img.imageServerFileName,
          });
        }
      });

      await MerchApi.update(id, data);
      setSaveModalOpen(false);

      toast(t("toastMsg.update"));

      if (!originOptionFlag && merchInfo.optionFlag) {
        setOriginOptionFlag(true);
        return;
      }
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ---------------------------------------- //
  // ----------------- 옵션 ----------------- //
  // --------------------------------------- //

  const [delId, setDelId] = useState(-1); //옵션 삭제 id
  const [valueDelId, setValueDelId] = useState(-1); //옵션값 삭제 id
  const [valueDelIndex, setValueDelIndex] = useState(-1); //옵션값 삭제할 때 해당 옵션의 index
  const [optionDelModalOpen, setOptionDelModalOpen] = useState(false);
  const [optionList, setOptionList] = useState([]);

  const addOptionName = async () => {
    try {
      const data = {
        title: "",
        requiredFlag: true,
      };
      const response = await MerchApi.optionAdd(id, data);
      toast(t("toastMsg.create"));
      getOption();
    } catch (error) {
      errorHandler(error);
    }
  };

  const addOptionValue = async (optionId) => {
    try {
      const data = {
        title: "",
        price: 0,
        inventory: 0,
        merchStatus: "PROGRESS",
      };

      const response = await MerchApi.optionValueAdd(optionId, data);
      toast(t("toastMsg.create"));
      getOption();
    } catch (error) {
      errorHandler(error);
    }
  };

  const getOption = async () => {
    try {
      const response = (await MerchApi.getOption(id)).data.data.content;

      if (response) {
        const copyArr = [...response];

        const arr = await Promise.all(
          copyArr.map(async (item, index) => {
            const optionRes = (await MerchApi.getOptionValue(item.id)).data.data.content;

            return {
              ...item,
              optionValueList: optionRes,
            };
          })
        );

        setOptionList(arr.reverse());
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const changeOptionName = async (changedTitle, index) => {
    const copyOption = [...optionList];
    optionList[index].title = changedTitle;
    setOptionList(copyOption);
  };

  const changeOptionRequired = async (value, index) => {
    const copyOption = [...optionList];
    optionList[index].requiredFlag = value;
    setOptionList(copyOption);
  };

  const editOptionName = async (id, index) => {
    try {
      const optionInfo = {
        title: optionList[index].title,
        requiredFlag: optionList[index].requiredFlag,
      };

      await MerchApi.patchOption(id, optionInfo);
      toast(t("toastMsg.update"));
    } catch (error) {
      errorHandler(error);
    }
  };

  const delOption = async () => {
    try {
      const data = {
        ids: [delId],
      };

      await MerchApi.delOption(data);
      toast(t("toastMsg.delete"));
      setOptionDelModalOpen(false);
      setDelId(-1);

      const filterArr = optionList.filter((item) => item.id !== delId);
      setOptionList(filterArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const delOptionValue = async () => {
    try {
      const data = {
        ids: [valueDelId],
      };

      await MerchApi.delOptionValue(data);
      toast(t("toastMsg.delete"));
      setOptionDelModalOpen(false);
      setValueDelId(-1);

      //옵션값 삭제 후 옵션에 저장하는 과정
      const filterArr = optionList[valueDelIndex].optionValueList.filter((item) => item.id !== valueDelId);

      const copyArr = [...optionList];
      copyArr[valueDelIndex].optionValueList = filterArr;

      setOptionList(copyArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  //옵션값 수정
  const updateOptionValue = (optionIndex, valueIndex, key, newValue) => {
    const updatedOptionList = [...optionList];
    updatedOptionList[optionIndex].optionValueList[valueIndex][key] = newValue;
    setOptionList(updatedOptionList);
  };

  const patchOptionValue = async (optionIndex, valueIndex) => {
    try {
      const data = optionList[optionIndex].optionValueList[valueIndex];

      const info = {
        title: data.title,
        price: Number(data.price),
        inventory: Number(data.inventory),
        merchStatus: data.merchStatus,
      };

      await MerchApi.patchOptionValue(data.id, info);
      toast(t("toastMsg.update"));
    } catch (error) {
      errorHandler(error);
    }
  };

  const dropdownBtnRef = useRef(null);
  const dropdownSelectBoxRef = useRef(null);
  const dropdownBtnRef2 = useRef(null);
  const dropdownSelectBoxRef2 = useRef(null);
  const dropdownBtnRef3 = useRef(null);
  const dropdownSelectBoxRef3 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownSelectBoxRef.current && !dropdownSelectBoxRef.current.contains(event.target) && dropdownBtnRef.current && !dropdownBtnRef.current.contains(event.target)) {
        setSelectBoxOpen({
          ...selectBoxOpen,
          saleStatus: false,
        });
      }
      if (dropdownSelectBoxRef2.current && !dropdownSelectBoxRef2.current.contains(event.target) && dropdownBtnRef2.current && !dropdownBtnRef2.current.contains(event.target)) {
        setSelectBoxOpen({
          ...selectBoxOpen,
          parentCategory: false,
          childCategory: false,
        });
      }
      if (dropdownSelectBoxRef3.current && !dropdownSelectBoxRef3.current.contains(event.target) && dropdownBtnRef3.current && !dropdownBtnRef3.current.contains(event.target)) {
        setSelectBoxOpen({
          ...selectBoxOpen,
          promotion: false,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Header />

      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>

          {/* 기본 설정 */}
          <DetailPage style={{ minHeight: "752px" }}>
            <DetailPageTitle>{t("product_detail.detail1")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 기본 설정 - 상품명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail1_productName")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={merchInfo.title || ""}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      title: e.target.value,
                    });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail1_code")}</DetailFormTitle>
                <MainInput
                  disabled={true}
                  value={merchInfo.merchCode}
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                />
              </DetailPageForm>
              {/* 기본 설정 - 판매 상태 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail1_state")}*</DetailFormTitle>
                {/* <SelectBox style={{ width: "338px" }} list={["남", "여"]} /> */}
                <div>
                  <div style={{ position: "relative", width: "338px" }} ref={dropdownBtnRef}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setSelectBoxOpen({
                          ...selectBoxOpen,
                          saleStatus: !selectBoxOpen.saleStatus,
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: merchInfo.merchStatus === "" ? "#808991" : "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {merchInfo.merchStatus === "" ? t("placeholder.defaultPlaceholder") : merchInfo.merchStatus === "PROGRESS" ? t("product.ing") : t("product.stop")}
                      </PretendardText>
                      <img className={selectBoxOpen.saleStatus ? "rotate" : "rotate-back"} src="/assets/admin/icons/dropdown_arrow.png" alt="" style={{ width: "16px", height: "16px" }} />
                    </SelectBoxWrapper>
                    {selectBoxOpen.saleStatus ? (
                      <SubMenuWrapper ref={dropdownSelectBoxRef}>
                        {["PROGRESS", "END"].map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setMerchInfo({
                                  ...merchInfo,
                                  merchStatus: item,
                                });
                                setSelectBoxOpen({
                                  ...selectBoxOpen,
                                  saleStatus: false,
                                });
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item === "PROGRESS" ? t("product.ing") : t("product.stop")}
                              </PretendardText>
                              {merchInfo.merchStatus === item ? <img src="/assets/admin/icons/ico_select_checked.svg" alt="" /> : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>
              {/* 기본 설정 - 상품 이미지 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail1_img")}*</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "end",
                    height: "fit-content",
                  }}
                >
                  {/* 상품 이미지 - 1 */}
                  {imgList.img1.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img1.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img1.id]);
                        setImgList({
                          ...imgList,
                          img1: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img1.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img1.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img1");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img1"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img1");
                      }}
                    />
                  )}

                  {/* 상품 이미지 - 2 */}
                  {imgList.img2.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img2.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img2.id]);
                        setImgList({
                          ...imgList,
                          img2: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img2.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img2.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img2");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img2"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img2");
                      }}
                    />
                  )}

                  {/* 상품 이미지 - 3 */}
                  {imgList.img3.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img3.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img3.id]);
                        setImgList({
                          ...imgList,
                          img3: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img3.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img3.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img3");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img3"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img3");
                      }}
                    />
                  )}

                  {/* 상품 이미지 - 4 */}
                  {imgList.img4.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img4.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img4.id]);
                        setImgList({
                          ...imgList,
                          img4: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img4.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img4.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img4");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img4"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img4");
                      }}
                    />
                  )}

                  {/* 상품 이미지 - 5 */}
                  {imgList.img5.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img5.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img5.id]);
                        setImgList({
                          ...imgList,
                          img5: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img5.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img5.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img5");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img5"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img5");
                      }}
                    />
                  )}

                  {/* 상품 이미지 - 6 */}
                  {imgList.img6.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img6.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img6.id]);
                        setImgList({
                          ...imgList,
                          img6: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img6.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img6.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img6");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img6"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img6");
                      }}
                    />
                  )}

                  <PretendardText
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "19.6px",
                      color: "#adb4ba",
                    }}
                  >
                    {t("product_detail.detail1_img_upload")}
                  </PretendardText>
                </div>
              </DetailPageForm>

              {/* 기본 설정 - 무게 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail2_weight")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"number"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={merchInfo.weight}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      weight: Number(e.target.value),
                    });
                  }}
                />
                <PretendardText>g</PretendardText>
              </DetailPageForm>

              {/* 기본 설정 - 상품 설명 */}
              <DetailPageFormCol>
                <DetailFormTitle>{t("product_detail.detail1_content")}*</DetailFormTitle>
                <TextFormEditor
                  placeholder={t()}
                  value={merchInfo.content}
                  onChange={(contents) => {
                    setMerchInfo((prevState) => ({
                      ...prevState,
                      content: contents,
                    }));
                  }}
                  editorHeight={262}
                  style={{
                    width: "964px",
                    marginTop: 6,
                  }}
                  position={position}
                  isBlur={isBlurEditor}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              </DetailPageFormCol>
            </div>
          </DetailPage>

          {/* 상품 정보 */}
          <DetailPage style={{ minHeight: "387px" }}>
            <DetailPageTitle>{t("product_detail.detail2")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 상품 정보 - 제조사 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail2_manufacturer")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={merchInfo.manufacturer || ""}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      manufacturer: e.target.value,
                    });
                  }}
                />
              </DetailPageForm>
              {/* 상품 정보 - 브랜드 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail2_brand")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={merchInfo.brand || ""}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      brand: e.target.value,
                    });
                  }}
                />
              </DetailPageForm>
              {/* 상품 정보 - 원산지 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail2_origin")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={merchInfo.madeIn || ""}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      madeIn: e.target.value,
                    });
                  }}
                />
              </DetailPageForm>
              {/* 상품 정보 - 제품 타입 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail2_type")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={merchInfo.productType || ""}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      productType: e.target.value,
                    });
                  }}
                />
              </DetailPageForm>
            </div>
          </DetailPage>

          {/* 가격 설정 */}
          <DetailPage style={{ minHeight: "251px" }}>
            <DetailPageTitle>{t("product_detail.detail3")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 가격 설정 - 판매가 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail3_salePrice")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"number"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={merchInfo.salePrice || ""}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      salePrice: Number(e.target.value),
                    });
                  }}
                />
              </DetailPageForm>
              {/* 가격 설정 - 원가 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail3_originPrice")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"number"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={merchInfo.originalPrice || ""}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      originalPrice: Number(e.target.value),
                    });
                  }}
                />
                <PretendardText>g</PretendardText>
              </DetailPageForm>
            </div>
          </DetailPage>

          {/* 배송 설정 */}
          <DetailPage style={{ minHeight: "250px" }}>
            <DetailPageTitle>{t("product_detail.detail4")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 배송 설정 - 배송비 */}
              {/* <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("product_detail.detail4_deliveryFee")}*</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "17px",
                  }}
                >
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Radio
                      id={"deliveryFee1"}
                      valueText={3000 + t("product_add.money")}
                      checked={merchInfo.deliveryCostType === "PRICE_3000"}
                      onChange={() => {
                        setMerchInfo({
                          ...merchInfo,
                          deliveryCostType: "PRICE_3000",
                          deliveryCost: 3000,
                        });
                      }}
                    />
                    <Radio
                      id={"deliveryFee2"}
                      valueText={5000 + t("product_add.money")}
                      checked={merchInfo.deliveryCostType === "PRICE_5000"}
                      onChange={() => {
                        setMerchInfo({
                          ...merchInfo,
                          deliveryCostType: "PRICE_5000",
                          deliveryCost: 5000,
                        });
                      }}
                    />
                    <Radio
                      id={"etc"}
                      valueText={t("product_add.directlyEnter")}
                      checked={merchInfo.deliveryCostType === "ETC"}
                      onChange={() => {
                        setMerchInfo({
                          ...merchInfo,
                          deliveryCostType: "ETC",
                        });
                      }}
                    />
                  </div>
                  {merchInfo.deliveryCostType === "ETC" && (
                    <MainInput
                      style={{
                        width: "338px",
                      }}
                      type={"number"}
                      placeholder={t("placeholder.defaultPlaceholder")}
                      value={merchInfo.deliveryCost || ""}
                      onChange={(e) => {
                        setMerchInfo({
                          ...merchInfo,
                          deliveryCost: Number(e.target.value),
                        });
                      }}
                    />
                  )}
                </div>
              </DetailPageForm> */}

              {/* 배송 설정 - 묶음배송 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail4_bundle")}*</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Radio
                    id={"bundle1"}
                    valueText={t("product_add.able")}
                    checked={merchInfo.bundled}
                    onChange={() => {
                      setMerchInfo({
                        ...merchInfo,
                        bundled: true,
                      });
                    }}
                  />
                  <Radio
                    id={"bundle2"}
                    valueText={t("product_add.disable")}
                    checked={!merchInfo.bundled}
                    onChange={() => {
                      setMerchInfo({
                        ...merchInfo,
                        bundled: false,
                      });
                    }}
                  />
                </div>
              </DetailPageForm>

              {/* 배송 설정 - 배송/출고사 */}
              {/* <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail4_company")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={merchInfo.deliveryCompany || ""}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      deliveryCompany: e.target.value,
                    });
                  }}
                />
              </DetailPageForm> */}
            </div>
          </DetailPage>

          {/* 분류 설정 */}
          <DetailPage style={{ minHeight: "296px" }}>
            <DetailPageTitle>{t("product_detail.detail5")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 분류 설정 - 카테고리 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail5_category")}*</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  {/* <SelectBox style={{ width: "338px" }} list={["남", "여"]} />
                <SelectBox style={{ width: "338px" }} list={["남", "여"]} /> */}

                  {/* 분류 설정 - 카테고리 - 상위 카테고리*/}
                  <div>
                    <div style={{ position: "relative", width: "338px" }} ref={dropdownBtnRef2}>
                      <SelectBoxWrapper
                        onClick={() => {
                          setSelectBoxOpen({
                            ...selectBoxOpen,
                            parentCategory: !selectBoxOpen.parentCategory,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <PretendardText
                          style={{
                            color: merchInfo.category?.parentCategory?.title !== "" ? "#262C31" : "#808991",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "21.25px",
                          }}
                        >
                          {merchInfo.category?.parentCategory ? merchInfo.category?.parentCategory?.title : `${t("category.category1")} ${t("placeholder.defaultPlaceholder")}`}
                        </PretendardText>
                        <img className={selectBoxOpen.parentCategory ? "rotate" : "rotate-back"} src="/assets/admin/icons/dropdown_arrow.png" alt="" style={{ width: "16px", height: "16px" }} />
                      </SelectBoxWrapper>
                      {selectBoxOpen.parentCategory && (
                        <SubMenuWrapper ref={dropdownSelectBoxRef2}>
                          {parentCategoryList.map((item, index) => {
                            return (
                              <SubMenuTextWrapper
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "318px",
                                  height: "42px",
                                  padding: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setMerchInfo({
                                    ...merchInfo,
                                    category: {
                                      ...merchInfo.category,
                                      id: 0,
                                      title: "",
                                      parentCategory: {
                                        id: item.id,
                                        title: item.title,
                                      },
                                    },
                                  });
                                  setChildCategoryList(item.childCategories);
                                  setSelectBoxOpen({
                                    ...selectBoxOpen,
                                    parentCategory: false,
                                    childCategory: false,
                                  });
                                }}
                              >
                                <PretendardText
                                  style={{
                                    color: "#262C31",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    lineHeight: "21.5px",
                                  }}
                                >
                                  {item.title}
                                </PretendardText>
                                {merchInfo.category?.title === item.title ? <img src="/assets/admin/icons/ico_select_checked.svg" alt="" /> : null}
                              </SubMenuTextWrapper>
                            );
                          })}
                        </SubMenuWrapper>
                      )}
                    </div>
                  </div>
                  {/* 분류 설정 - 카테고리 - 하위 카테고리*/}
                  <div>
                    <div style={{ position: "relative", width: "338px" }} ref={dropdownBtnRef2}>
                      <SelectBoxWrapper
                        onClick={() => {
                          setSelectBoxOpen({
                            ...selectBoxOpen,
                            parentCategory: false,
                            childCategory: !selectBoxOpen.childCategory,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <PretendardText
                          style={{
                            color: merchInfo.category?.title !== "" ? "#262C31" : "#808991",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "21.25px",
                          }}
                        >
                          {merchInfo.category?.title ? merchInfo.category?.title : `${t("category.category2")} ${t("placeholder.defaultPlaceholder")}`}
                        </PretendardText>
                        <img className={selectBoxOpen.childCategory ? "rotate" : "rotate-back"} src="/assets/admin/icons/dropdown_arrow.png" alt="" style={{ width: "16px", height: "16px" }} />
                      </SelectBoxWrapper>
                      {selectBoxOpen.childCategory && (
                        <SubMenuWrapper ref={dropdownSelectBoxRef2}>
                          {childCategoryList.map((item, index) => {
                            return (
                              <SubMenuTextWrapper
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "318px",
                                  height: "42px",
                                  padding: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setMerchInfo({
                                    ...merchInfo,
                                    category: {
                                      ...merchInfo.category,
                                      categoryType: "SECOND",
                                      id: item.id,
                                      title: item.title,
                                    },
                                  });
                                  setSelectBoxOpen({
                                    ...selectBoxOpen,
                                    parentCategory: false,
                                    childCategory: false,
                                  });
                                }}
                              >
                                <PretendardText
                                  style={{
                                    color: "#262C31",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    lineHeight: "21.5px",
                                  }}
                                >
                                  {item.title}
                                </PretendardText>
                                {merchInfo.category?.title === item.title ? <img src="/assets/admin/icons/ico_select_checked.svg" alt="" /> : null}
                              </SubMenuTextWrapper>
                            );
                          })}
                        </SubMenuWrapper>
                      )}
                    </div>
                  </div>
                </div>
              </DetailPageForm>
              {/* 분류 설정 - 기획전 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail5_exhibition")}</DetailFormTitle>
                {/* <SelectBox style={{ width: "338px" }} list={["남", "여"]} /> */}
                <div>
                  <div style={{ position: "relative", width: "338px" }} ref={dropdownBtnRef3}>
                    <SelectBoxWrapper
                      onClick={() => {
                        setSelectBoxOpen({
                          ...selectBoxOpen,
                          promotion: !selectBoxOpen.promotion,
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <PretendardText
                        style={{
                          color: merchInfo.promotion.title === "" ? "#808991" : "#262C31",
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "21.25px",
                        }}
                      >
                        {merchInfo.promotion.title === "" ? t("placeholder.defaultPlaceholder") : merchInfo.promotion.title}
                      </PretendardText>
                      <img className={selectBoxOpen.promotion ? "rotate" : "rotate-back"} src="/assets/admin/icons/dropdown_arrow.png" alt="" style={{ width: "16px", height: "16px" }} />
                    </SelectBoxWrapper>
                    {selectBoxOpen.promotion ? (
                      <SubMenuWrapper ref={dropdownSelectBoxRef3}>
                        <SubMenuTextWrapper
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "318px",
                            height: "42px",
                            padding: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setMerchInfo({
                              ...merchInfo,
                              promotion: {
                                id: 0,
                                title: "",
                              },
                            });
                            setSelectBoxOpen({
                              ...selectBoxOpen,
                              promotion: false,
                            });
                          }}
                        >
                          <PretendardText
                            style={{
                              color: "#262C31",
                              fontSize: "15px",
                              fontWeight: "500",
                              lineHeight: "21.5px",
                            }}
                          >
                            미선택
                          </PretendardText>
                          {merchInfo.promotion?.id === 0 ? <img src="/assets/admin/icons/ico_select_checked.svg" alt="" /> : null}
                        </SubMenuTextWrapper>
                        {promotionList.map((item, index) => {
                          return (
                            <SubMenuTextWrapper
                              key={index}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "318px",
                                height: "42px",
                                padding: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setMerchInfo({
                                  ...merchInfo,
                                  promotion: {
                                    id: item.id,
                                    title: item.title,
                                  },
                                });
                                setSelectBoxOpen({
                                  ...selectBoxOpen,
                                  promotion: false,
                                });
                              }}
                            >
                              <PretendardText
                                style={{
                                  color: "#262C31",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  lineHeight: "21.5px",
                                }}
                              >
                                {item.title}
                              </PretendardText>
                              {merchInfo.promotion?.id === item.id ? <img src="/assets/admin/icons/ico_select_checked.svg" alt="" /> : null}
                            </SubMenuTextWrapper>
                          );
                        })}
                      </SubMenuWrapper>
                    ) : null}
                  </div>
                </div>
              </DetailPageForm>
              {/* 분류 설정 - 상품 뱃지 */}
              <DetailPageForm>
                <DetailFormTitle>{t("product_detail.detail5_badge")}</DetailFormTitle>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Checkbox
                      id="bestFlag"
                      checked={merchInfo.bestFlag}
                      onChange={() => {
                        setMerchInfo({
                          ...merchInfo,
                          bestFlag: !merchInfo.bestFlag,
                        });
                      }}
                    />
                    <DetailFormText>{t("product_add.best")}</DetailFormText>
                  </div>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Checkbox
                      id="newFlag"
                      checked={merchInfo.newFlag}
                      onChange={() => {
                        setMerchInfo({
                          ...merchInfo,
                          newFlag: !merchInfo.newFlag,
                        });
                      }}
                    />
                    <DetailFormText>{t("product_add.new")}</DetailFormText>
                  </div>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Checkbox
                      id="mdFlag"
                      checked={merchInfo.mdFlag}
                      onChange={() => {
                        setMerchInfo({
                          ...merchInfo,
                          mdFlag: !merchInfo.mdFlag,
                        });
                      }}
                    />
                    <DetailFormText>{t("product_add.md")}</DetailFormText>
                  </div>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Checkbox
                      id="eventFlag"
                      checked={merchInfo.eventFlag}
                      onChange={() => {
                        setMerchInfo({
                          ...merchInfo,
                          eventFlag: !merchInfo.eventFlag,
                        });
                      }}
                    />
                    <DetailFormText>{t("product_add.event")}</DetailFormText>
                  </div>
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>

          {/* 옵션 여부 */}
          <DetailPage style={{ minHeight: "160px" }}>
            <DetailPageTitle>{t("product_detail.optionCheck")}</DetailPageTitle>
            {/* 옵션 여부 - 옵션 여부 */}
            <DetailPageForm>
              <DetailFormTitle>{t("product_detail.optionCheck")}</DetailFormTitle>
              <div style={{ display: "flex", gap: "20px" }}>
                <Radio
                  id={"yes"}
                  valueText={t("product_detail.optionYes")}
                  checked={merchInfo.optionFlag}
                  onChange={() => {
                    setMerchInfo({
                      ...merchInfo,
                      optionFlag: true,
                    });
                  }}
                />
                <Radio
                  id={"no"}
                  valueText={t("product_detail.optionNo")}
                  checked={!merchInfo.optionFlag}
                  onChange={() => {
                    setMerchInfo({
                      ...merchInfo,
                      optionFlag: false,
                    });
                  }}
                />
              </div>
            </DetailPageForm>
            {!merchInfo.optionFlag ? (
              <DetailPageForm style={{ marginTop: "20px" }}>
                <DetailFormTitle>{t("product.productState")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"number"}
                  placeholder={t("placeholder.inventoryPlaceholder")}
                  value={merchInfo.inventory || ""}
                  onChange={(e) => {
                    setMerchInfo({
                      ...merchInfo,
                      inventory: e.target.value,
                    });
                  }}
                />
              </DetailPageForm>
            ) : !originOptionFlag ? (
              <PretendardText
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22.75px",
                  color: "#262c31",
                  marginTop: "20px",
                }}
              >
                *{t("product_add.caption02")}
              </PretendardText>
            ) : null}
          </DetailPage>

          <AddBtn
            onClick={() => {
              saveBtnHandler();
            }}
          >
            {t("product_add.edit")}
          </AddBtn>

          {originOptionFlag && merchInfo.optionFlag && (
            <>
              {/* 옵션 설정 */}
              <TableTitle style={{ marginTop: "80px", fontSize: "24px" }}>{t("product_add.option")}</TableTitle>
              {/* <DetailPage style={{ minHeight: "342px" }}>
                <DetailPageTitle>
                  {t("product_detail.option")} {t("product_detail.setting")}
                </DetailPageTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <DetailFormTitle style={{ color: "#262c31" }}>
                    {t("product_detail.option")}1
                  </DetailFormTitle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <DetailPageFormCol>
                      <DetailFormTitle>
                        {t("product_detail.optionName")}
                      </DetailFormTitle>
                      <MainInput
                        style={{
                          width: "338px",
                        }}
                        type={"text"}
                      />
                    </DetailPageFormCol>
                    <DetailPageFormCol style={{ width: "100%" }}>
                      <DetailFormTitle>
                        {t("product_detail.optionValue")}
                      </DetailFormTitle>
                      <div
                        style={{
                          height: "48px",
                          width: "100%",
                          padding: "13px 15px",
                          border: "1px solid #B8BFC4",
                          borderRadius: "4px",
                        }}
                      ></div>
                    </DetailPageFormCol>
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Radio
                      id={"option1"} // 나중에 map 돌릴 때 꼭 index별로 구분하기
                      valueText={t("product_add.required")}
                      checked={false}
                      onChange={() => {}}
                    />
                    <Radio
                      id={"option2"}
                      valueText={t("product_add.choice")}
                      checked={false}
                      onChange={() => {}}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", gap: "8px", marginTop: "30px" }}>
                  <Checkbox />
                  <DetailFormText>{t("product_add.noOption")}</DetailFormText>
                </div>
                <OptionAddBtn>옵션추가</OptionAddBtn>
              </DetailPage> */}
              {optionList.length > 0 ? (
                <>
                  {optionList.map((item, index) => {
                    return (
                      <DetailPage key={index} style={{ minHeight: "342px" }}>
                        {/* 옵션명 설정 */}
                        <DetailPageTitle>
                          {t("product_detail.optionName")} {t("product_detail.setting")}
                        </DetailPageTitle>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <DetailPageForm>
                            <DetailFormTitle>{t("product_detail.optionName")}*</DetailFormTitle>
                            <MainInput
                              style={{
                                width: "338px",
                              }}
                              placeholder={t("placeholder.defaultPlaceholder")}
                              type={"text"}
                              value={item.title}
                              onChange={(e) => changeOptionName(e.target.value, index)}
                            />
                          </DetailPageForm>
                          <DetailPageForm>
                            <DetailFormTitle>{t("product_detail.required")}</DetailFormTitle>
                            <div style={{ display: "flex", gap: "20px" }}>
                              <Radio
                                id={`option1_${index}`} // 나중에 map 돌릴 때 꼭 index별로 구분하기
                                valueText={t("product_add.required")}
                                checked={item.requiredFlag}
                                onChange={() => changeOptionRequired(true, index)}
                              />
                              <Radio id={`option2_${index}`} valueText={t("product_add.choice")} checked={!item.requiredFlag} onChange={() => changeOptionRequired(false, index)} />
                            </div>
                          </DetailPageForm>
                          <DetailPageForm>
                            <DetailFormTitle></DetailFormTitle>
                            <div
                              style={{
                                width: "338px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <OptionEditBtn
                                onClick={() => {
                                  editOptionName(item.id, index);
                                }}
                              >
                                {t("component.edit")}
                              </OptionEditBtn>
                            </div>
                          </DetailPageForm>
                        </div>
                        {/* 옵션값 설정 */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "60px",
                            marginBottom: "30px",
                            gap: "10px",
                          }}
                        >
                          <DetailPageTitle style={{ marginBottom: "0px" }}>
                            {t("product_detail.optionValue")} {t("product_detail.setting")}
                          </DetailPageTitle>
                          <OptionEditBtn
                            style={{ borderColor: "#778088", color: "#778088" }}
                            onClick={() => {
                              addOptionValue(item.id);
                            }}
                          >
                            {t("component.add")}
                          </OptionEditBtn>
                        </div>
                        {item.optionValueList ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "40px",
                            }}
                          >
                            {item.optionValueList.map((item02, index02) => {
                              return (
                                <div
                                  key={index02}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "12px",
                                  }}
                                >
                                  <DetailPageForm>
                                    <DetailFormTitle>{t("product_detail.optionValue")}*</DetailFormTitle>
                                    <MainInput
                                      style={{
                                        width: "338px",
                                      }}
                                      placeholder={t("placeholder.defaultPlaceholder")}
                                      type={"text"}
                                      value={item02.title}
                                      onChange={(e) => updateOptionValue(index, index02, "title", e.target.value)}
                                    />
                                  </DetailPageForm>

                                  <DetailPageForm>
                                    <DetailFormTitle>{t("product_detail.optionPrice")}*</DetailFormTitle>
                                    <MainInput
                                      style={{
                                        width: "338px",
                                      }}
                                      placeholder={t("placeholder.defaultPlaceholder")}
                                      type={"number"}
                                      value={Number(item02.price)}
                                      onChange={(e) => updateOptionValue(index, index02, "price", e.target.value)}
                                    />
                                  </DetailPageForm>
                                  <DetailPageForm>
                                    <DetailFormTitle>{t("product_detail.count")}*</DetailFormTitle>
                                    <MainInput
                                      style={{
                                        width: "338px",
                                      }}
                                      placeholder={t("placeholder.defaultPlaceholder")}
                                      type={"number"}
                                      value={item02.inventory}
                                      onChange={(e) => updateOptionValue(index, index02, "inventory", e.target.value)}
                                    />
                                  </DetailPageForm>
                                  <DetailPageForm>
                                    <DetailFormTitle>{t("product_detail.isSale")}</DetailFormTitle>
                                    <div style={{ display: "flex", gap: "20px" }}>
                                      <Radio
                                        id={`option3_${index}_${index02}`} // 나중에 map 돌릴 때 꼭 index별로 구분하기
                                        valueText={t("product.ing")}
                                        checked={item02.merchStatus === "PROGRESS"}
                                        onChange={(e) => updateOptionValue(index, index02, "merchStatus", "PROGRESS")}
                                      />
                                      <Radio
                                        id={`option4_${index}_${index02}`}
                                        valueText={t("product.stop")}
                                        checked={item02.merchStatus === "END"}
                                        onChange={(e) => updateOptionValue(index, index02, "merchStatus", "END")}
                                      />
                                    </div>
                                  </DetailPageForm>
                                  <DetailPageForm>
                                    <DetailFormTitle></DetailFormTitle>
                                    <div
                                      style={{
                                        width: "338px",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        gap: "8px",
                                      }}
                                    >
                                      <TableDelBtn
                                        onClick={() => {
                                          setValueDelId(item02.id);
                                          setValueDelIndex(index);
                                          setOptionDelModalOpen(true);
                                        }}
                                      />
                                      <OptionEditBtn
                                        onClick={() => {
                                          patchOptionValue(index, index02);
                                        }}
                                      >
                                        {t("component.edit")}
                                      </OptionEditBtn>
                                    </div>
                                  </DetailPageForm>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "40px",
                          }}
                        >
                          <DelBtn
                            onClick={() => {
                              setDelId(item.id);
                              setOptionDelModalOpen(true);
                            }}
                          />
                        </div>
                      </DetailPage>
                    );
                  })}
                </>
              ) : null}

              <div style={{ display: "flex", justifyContent: "center" }}>
                <OptionAddBtn onClick={addOptionName}>{t("component.add")}</OptionAddBtn>
              </div>
              {/* <DetailPage style={{ minHeight: "342px" }}>
                <DetailPageTitle>
                  {t("product_detail.optionValue")}{" "}
                  {t("product_detail.setting")}
                </DetailPageTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <DetailFormTitle style={{ color: "#262c31" }}>
                    {t("product_detail.optionName")} -{" "}
                    {t("product_detail.optionValue")}1
                  </DetailFormTitle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <DetailPageFormCol>
                      <DetailFormTitle>
                        {t("product_detail.optionPrice")}
                      </DetailFormTitle>
                      <MainInput
                        style={{
                          width: "338px",
                        }}
                        type={"text"}
                      />
                    </DetailPageFormCol>
                    <DetailPageFormCol>
                      <DetailFormTitle>
                        {t("product_detail.count")}
                      </DetailFormTitle>
                      <MainInput
                        style={{
                          width: "338px",
                        }}
                        type={"text"}
                      />
                    </DetailPageFormCol>
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Radio
                      id={"option1"} // 나중에 map 돌릴 때 꼭 index별로 구분하기
                      valueText={t("product_add.required")}
                      checked={false}
                      onChange={() => {}}
                    />
                    <Radio
                      id={"option2"}
                      valueText={t("product_add.choice")}
                      checked={false}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              </DetailPage> */}
            </>
          )}
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}

      {optionDelModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setOptionDelModalOpen}
          onClick={() => {
            if (delId === -1) {
              delOptionValue();
            } else {
              delOption();
            }
          }}
        />
      ) : null}
    </>
  );
};

export default ProductEdit;

const OptionAddBtn = styled.button.attrs((props) => {})`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid #262c31;
  background: #262c31;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  color: #fff;
  cursor: pointer;
`;

const AddBtn = styled.button.attrs((props) => {})`
  width: 100%;
  display: flex;
  padding: 13px 0px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: #636c73;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 20px;
  color: #ffffff;
  cursor: pointer;
`;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 15px;
  border-radius: 4px;
  border: 1px solid #b8bfc4;
  background: #fff;
`;

const SubMenuWrapper = styled.div.attrs((props) => {})`
  width: 338px;
  min-height: 48px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 13, 20, 0.15);
  position: absolute;
  top: 50px;
  right: 0;
  padding: 10px;
  overflow-y: auto;
  z-index: 5;
`;

const SubMenuTextWrapper = styled.div.attrs((props) => {})`
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  width: "318px";
  height: "42px";
  padding: "10px";
  cursor: "pointer";
  border-radius: 3px;

  &:hover {
    background-color: #f2f4f5;
  }
`;

const OptionEditBtn = styled.button.attrs((props) => {})`
  display: flex;
  padding: 3px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #27beff;
  background: #fff;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.75px;
  color: #27beff;
  cursor: pointer;
`;
