import api from "./api.js";

export default class MerchApi {
  // 상품 등록
  static async create(data) {
    return await api.post(`admin/merch`, data);
  }

  // 상품 목록 조회
  static async getList(params) {
    return await api.get(`admin/merch`, params);
  }

  // 상품 단일 조회
  static async getById(merchId, params) {
    return await api.get(`admin/merch/${merchId}`, params);
  }

  // 상품 수정
  static async update(merchId, data) {
    return await api.patch(`admin/merch/${merchId}`, data);
  }

  // 영상 삭제
  static async delete(data) {
    return await api.del(`admin/merch`, data);
  }

  // 옵션명 추가
  static async optionAdd(merchId, data) {
    return await api.post(`admin/option/${merchId}`, data);
  }

  // 옵션값 추가
  static async optionValueAdd(id, data) {
    return await api.post(`admin/optionValue-value/${id}`, data);
  }

  // 옵션명 조회
  static async getOption(merchId, data) {
    return await api.get(`admin/option/${merchId}`, data);
  }

  // 옵션값 조회
  static async getOptionValue(id, data) {
    return await api.get(`admin/optionValue-value/${id}`, data);
  }

  // 옵션명 수정
  static async patchOption(id, data) {
    return await api.patch(`admin/option/${id}`, data);
  }

  // 옵션값 수정
  static async patchOptionValue(id, data) {
    return await api.patch(`admin/optionValue-value/${id}`, data);
  }

  // 옵션 삭제
  static async delOption(data) {
    return await api.del(`admin/option`, data);
  }

  // 옵션값 삭제
  static async delOptionValue(data) {
    return await api.del(`admin/optionValue-value`, data);
  }

  // 판매 상태 변경
  static async activation(id, data) {
    return await api.post(`admin/merch/activation/${id}`, data);
  }
}
