const prefix = "/ad";

export const route = {
  //=====auth=====//
  default: "/",
  login: prefix + "/login",
  join: prefix + "/join",
  join_complete: prefix + "/join/complete",

  //====================home====================//
  home: prefix + "/home",

  //====================user====================//
  user: prefix + "/user",

  /*일반회원 관리*/
  common_member: prefix + "/user/common",
  common_member_detail: prefix + "/user/common/detail",
  common_member_edit: prefix + "/user/common/edit",

  /*인플루언서 관리*/
  influencer_member: prefix + "/user/influencer",
  influencer_member_detail: prefix + "/user/influencer/detail",
  influencer_member_edit: prefix + "/user/influencer/edit",

  /*가입 승인 요청*/
  approve_signup: prefix + "/user/approve",
  approve_signup_detail: prefix + "/user/approve/detail",

  /*탈퇴 승인 요청*/
  approve_leave: prefix + "/user/approve/leave",
  approve_leave_detail: prefix + "/user/approve/leave/detail",

  /*정산 관리*/
  calculate: prefix + "/user/calculate",

  /*탈퇴회원 관리*/
  leave_member: prefix + "/user/leave",

  //====================video====================//
  video: prefix + "/video",

  /*영상 승인*/
  approve_video: prefix + "/video/approve",
  approve_video_detail: prefix + "/video/approve/detail",

  //====================product====================//
  product: prefix + "/product",

  /*상품 목록*/
  product_list: prefix + "/product/list",
  product_detail: prefix + "/product/list/detail",
  product_add: prefix + "/product/list/add",
  product_edit: prefix + "/product/list/edit",

  /*카테고리 관리*/
  category: prefix + "/product/category",
  category_detail: prefix + "/product/category/detail",
  category_add: prefix + "/product/category/add",
  category_edit: prefix + "/product/category/edit",

  /*기획전 관리*/
  exhibition: prefix + "/product/exhibition",
  exhibition_detail: prefix + "/product/exhibition/detail",
  exhibition_add: prefix + "/product/exhibition/add",
  exhibition_edit: prefix + "/product/exhibition/edit",

  /*쿠폰 관리*/
  coupon_add: prefix + "/product/coupon",
  coupon_issued: prefix + "/product/coupon/issued",

  /*리뷰 관리*/
  review: prefix + "/product/review",

  //====================order====================//
  order: prefix + "/order",
  shipping: prefix + "/order/shipping",
  cancel: prefix + "/order/cancel",
  exchange: prefix + "/order/exchange",
  return: prefix + "/order/return",

  //====================customer====================//
  customer: prefix + "/customer",

  /*알림 목록*/
  alarm: prefix + "/customer/alarm",
  alarm_detail: prefix + "/customer/alarm/detail",
  alarm_add: prefix + "/customer/alarm/add",

  /*1:1 문의 목록*/
  ask: prefix + "/customer/ask",
  qna: prefix + "/customer/ask/qna",

  /*공지 목록*/
  notice: prefix + "/customer/notice",
  notice_detail: prefix + "/customer/notice/detail",
  notice_add: prefix + "/customer/notice/add",
  notice_edit: prefix + "/customer/notice/edit",

  /*faq 목록*/
  faq: prefix + "/customer/faq",
  faq_detail: prefix + "/customer/faq/detail",
  faq_add: prefix + "/customer/faq/add",
  faq_edit: prefix + "/customer/faq/edit",

  //====================service====================//
  service: prefix + "/service",

  /*환경설정*/
  setting: prefix + "/service/setting",
  transport: prefix + "/service/setting/transport",
  point: prefix + "/service/setting/point",
  buy_terms: prefix + "/service/setting/buy_terms",

  /*약관 관리*/
  terms: prefix + "/service/terms",
  terms_detail: prefix + "/service/terms/detail",
  terms_add: prefix + "/service/terms/add",
  terms_edit: prefix + "/service/terms/edit",

  /*기본관리*/
  basic: prefix + "/service/basic",
  basic_edit: prefix + "/service/basic/edit",

  /*팝업 관리*/
  popup: prefix + "/service/popup",
  popup_detail: prefix + "/service/popup/detail",
  popup_add: prefix + "/service/popup/add",
  popup_edit: prefix + "/service/popup/edit",

  /*배너 관리*/
  banner_main: prefix + "/service/banner",
  banner_serve: prefix + "/service/banner/serve",

  /*메인 관리*/
  main: prefix + "/service/main",

  //====================manager====================//
  manager: prefix + "/manager",
  manager_detail: prefix + "/manager/detail",
  root_edit: prefix + "/manager/root/edit",
  admin_edit: prefix + "/manager/admin/edit",
};
