import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";

function SelectBox({ style, placeholder, selectedItem, list, customIcon, iconStyle, itemIndex, onChange }) {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownBtnRef = useRef(null);
  const dropdownSelectBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownSelectBoxRef.current && !dropdownSelectBoxRef.current.contains(event.target) && dropdownBtnRef.current && !dropdownBtnRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SelectBoxWrapper style={style} ref={dropdownBtnRef}>
      <Container onClick={() => setIsOpen(!isOpen)}>
        <PretendardText
          style={{
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "21.25px",
            color: selectedItem ? "#262C31" : "#808991",
          }}
        >
          {selectedItem || placeholder}
        </PretendardText>
        <img className={isOpen ? "rotate" : "rotate-back"} alt="" src={customIcon || `/assets/admin/icons/dropdown_arrow.png`} style={{ width: "16px", height: "16px", ...iconStyle }} />
      </Container>
      {isOpen && (
        <DropdownContainer ref={dropdownSelectBoxRef}>
          {list.length > 0 &&
            list.map((item, index) => {
              return (
                <PretendardText
                  key={index}
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "21.25px",
                    color: selectedItem ? "#262C31" : "#808991",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onChange(item, itemIndex);
                    setIsOpen(false);
                  }}
                >
                  {item}
                </PretendardText>
              );
            })}
        </DropdownContainer>
      )}
    </SelectBoxWrapper>
  );
}

export default SelectBox;

const SelectBoxWrapper = styled.div.attrs((props) => {})`
  position: relative;
`;

const Container = styled.div.attrs((props) => {})`
  position: relative;
  height: 48px;
  padding: 13px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #b8bfc4;
  cursor: pointer;
`;

const DropdownContainer = styled.div.attrs((props) => {})`
  position: absolute;
  width: 100%;
  top: 50px;
  padding: 13px 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #b8bfc4;
  z-index: 5;
  background-color: #fff;
`;
