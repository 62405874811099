import React, { useEffect, useState } from "react";
// import BannerApi from "../../api/banner.api";
import { useTranslation } from "react-i18next";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import MainInput from "../../../component/atoms/input/mainInput";
import Radio from "../../../component/atoms/input/radio";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import MainManageItem from "./_component/mainManageItem";
import { errorHandler } from "../../../util/errorHandler";
import MainInfoApi from "./_api/main-info.api";

const MainManage = () => {
  const { t } = useTranslation();

  const [SPECIAL, setSPECIAL] = useState({});
  const [NEW, setNEW] = useState({});
  const [INFLUENCER, setINFLUENCER] = useState({});
  const [EVENT, setEVENT] = useState({});
  const [MD, setMD] = useState({});
  const [NOTICE, setNOTICE] = useState({});

  const getMainInfo = async () => {
    try {
      let responseData = (await MainInfoApi.getMainInfo()).data.data.content;
      setSPECIAL(responseData.filter((item) => item.mainInfoType === "SPECIAL")[0]);
      setNEW(responseData.filter((item) => item.mainInfoType === "NEW")[0]);
      setINFLUENCER(responseData.filter((item) => item.mainInfoType === "INFLUENCER")[0]);
      setEVENT(responseData.filter((item) => item.mainInfoType === "EVENT")[0]);
      setMD(responseData.filter((item) => item.mainInfoType === "MD")[0]);
      setNOTICE(responseData.filter((item) => item.mainInfoType === "NOTICE")[0]);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getMainInfo();
  }, []);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <TableTitle>{t("main_manage.title")}</TableTitle>
          </DetailTop>
          <MainManageItem title={t("main_manage.exhibition")} data={SPECIAL} />
          <MainManageItem title={t("main_manage.newProduct")} data={NEW} />
          <MainManageItem title={t("main_manage.influencer")} data={INFLUENCER} />
          <MainManageItem title={t("main_manage.event")} data={EVENT} />
          <MainManageItem title={t("main_manage.MDPick")} data={MD} />
          <MainManageItem title={t("main_manage.banner")} data={NOTICE} />
        </DetailPageLayout>
      </MainLayout>
    </>
  );
};

export default MainManage;
