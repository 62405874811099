import api from "../../../../api/api";

export default class BasicApi {
  static async getBasicInfo() {
    return await api.get(`admin/base-info`);
  }
  static async updateBasicInfo(data) {
    return await api.post(`admin/base-info`, data);
  }
}
