import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DelBtn from "../../component/atoms/button/delBtn";
import DetailBtn from "../../component/atoms/button/detailBtn";
import Checkbox from "../../component/atoms/input/checkbox";
import SearchBar from "../../component/atoms/input/searchBar";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import MainLayout from "../../component/atoms/layout/mainLayout";
import Table from "../../component/atoms/layout/table/table";
import TableSearchBtn from "../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../component/atoms/layout/table/tableTop";
import Td from "../../component/atoms/layout/table/td";
import Th from "../../component/atoms/layout/table/th";
import Tr from "../../component/atoms/layout/table/tr";
import TableText from "../../component/atoms/text/table/tableText";
import TableTitle from "../../component/atoms/text/table/tableTitle";
import Pagination from "../../component/molecules/pagination";
import TableCount from "../../component/molecules/tableCount";
import Header from "../../component/organisms/header";
import Sidebar from "../../component/organisms/sidebar";
import { route } from "../../router/route";
// import NoticeApi from "./../../api/video.api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import VideoApi from "../../api/video.api";
import PretendardText from "../../component/atoms/text/pretendardText";
import Modal from "../../component/templates/Modal";
import { errorHandler } from "../../util/errorHandler";
import TdText from "./../../component/atoms/text/table/tdText";

const Video = () => {
  const { t, i18n } = useTranslation();
  const isVn = i18n.language === "VN";

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getVideoList();
  }, [location]);

  const [videoList, setVideoList] = useState([1]);
  const [keyword, setKeyword] = useState("");

  const getVideoList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await VideoApi.getList(data)).data.data;

      setVideoList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const isAllCheck = useMemo(() => {
    const checkedList = videoList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === videoList.length;
  }, [videoList]);

  // ----- 승인
  const [approveId, setApproveId] = useState(-1);
  const [approveType, setApproveType] = useState("");
  const [approveModalOpen, setApproveModalOpen] = useState(false);

  const approveFunc = async () => {
    try {
      const data = {
        approveStatus: approveType,
      };

      await VideoApi.approve(approveId, data);
      setApproveModalOpen(false);

      toast(t("toastMsg.update"));

      setApproveId(-1);
      setApproveType("");
      getVideoList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: delIdList,
      };
      await VideoApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      setDelIdList([]);
      getVideoList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <div style={{ marginTop: "40px" }}>
            <TableTitle>{t("video.title")}</TableTitle>
            <TableTop>
              <TableCount text={t("video.videoCount")} count={pagination.totalCount} />
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`${route.approve_video}?page=1&keyword=${keyword}`, {
                        replace: true,
                      });
                    }
                  }}
                  onClick={() => {
                    navigate(`${route.approve_video}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }}
                />

                <DelBtn
                  text={t("video.delBtn")}
                  onClick={() => {
                    const delList = videoList.filter((item) => item.checked);
                    if (delList.length <= 0) {
                      return;
                    }
                    setDelIdList(delList.map((item) => item.id));
                    setDeleteModalOpen(true);
                  }}
                />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 체크박스 */}
                <col width={isVn ? "70px" : "70px"} />
                {/* 인플루언서 닉네임 */}
                <col width={isVn ? "169px" : "169px"} />
                {/* 영상 제목 */}
                <col width={isVn ? "219px" : "229px"} />
                {/* 상품코드 */}
                <col width={isVn ? "151px" : "152px"} />
                {/* 등록일 */}
                <col width={isVn ? "113px" : "116px"} />
                {/* 승인상태 */}
                <col width={isVn ? "224px" : "210px"} />
                {/* 상세보기 */}
                <col width={isVn ? "87px" : "78px"} />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <Checkbox
                      checked={isAllCheck}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setVideoList(
                            videoList.map((item) => {
                              return {
                                ...item,
                                checked: true,
                              };
                            })
                          );
                        } else {
                          setVideoList(
                            videoList.map((item) => {
                              return {
                                ...item,
                                checked: false,
                              };
                            })
                          );
                        }
                      }}
                    />
                  </Th>
                  <Th>
                    <TableText>{t("video.nickname")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("video.videoTitle")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("video.productCode")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("video.createAt")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("video.state")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("video.more")}</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {videoList.map((item, index) => {
                  return (
                    <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                      <Td style={{ paddingLeft: "20px" }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => {
                            item.checked = !item.checked;
                            setVideoList([...videoList]);
                          }}
                        />
                      </Td>
                      <Td>
                        <TableText>{item.influencerNickname}</TableText>
                      </Td>
                      <Td>
                        <TableText>{item.title}</TableText>
                      </Td>
                      <Td>
                        <TableText>{item.merchCode}</TableText>
                      </Td>
                      <Td>
                        <TableText>{moment(item.createdAt).format("YYYY/MM/DD")}</TableText>
                      </Td>
                      <Td>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: isVn ? "column" : "row",
                            alignItems: isVn ? "flex-start" : "center",
                            gap: isVn ? "4px" : "10px",
                          }}
                        >
                          <TdText
                            style={{
                              color: `${item.approveStatus === "REQUEST" ? "#EF594B" : "#262c31"}`,
                            }}
                          >
                            {item.approveStatus === "REQUEST" ? `${t("video.reject")}` : item.approveStatus === "APPROVE" ? `${t("video.approve")}` : `${t("video.deny")}`}
                          </TdText>
                          <div style={{ display: "flex", gap: "10px" }}>
                            {item.approveStatus !== "REQUEST" ? null : (
                              <PretendardText
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "22.75px",
                                  color: "#262C31",
                                  border: "1px solid #DFE1E4",
                                  borderRadius: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "3px 8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setApproveId(item.id);
                                  setApproveType("APPROVE");
                                  setApproveModalOpen(true);
                                }}
                              >
                                {t("video.approveBtn")}
                              </PretendardText>
                            )}
                            {item.approveStatus !== "REQUEST" ? null : (
                              <PretendardText
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "22.75px",
                                  color: "#262C31",
                                  border: "1px solid #DFE1E4",
                                  borderRadius: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "3px 8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setApproveId(item.id);
                                  setApproveType("REJECT");
                                  setApproveModalOpen(true);
                                }}
                              >
                                {t("video.rejectBtn")}
                              </PretendardText>
                            )}
                          </div>
                        </div>
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            navigate(route.approve_video_detail + `/${item.id}`);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.approve_video}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {approveModalOpen ? (
        <Modal
          title={approveType === "APPROVE" ? `${t("modalMsg.approve")}` : `${t("modalMsg.deny")}`}
          setModalOpen={setApproveModalOpen}
          onClick={() => {
            approveFunc();
          }}
        />
      ) : null}

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default Video;
