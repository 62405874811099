import React from "react";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Router from "./router/router";
import "./App.css";

function App() {
  return (
    <RecoilRoot>
      <div className="App">
        <Router />
        <ToastContainer
          className="toast"
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
        />
      </div>
    </RecoilRoot>
  );
}

export default App;
