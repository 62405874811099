import api from "./api.js";

export default class ExcelApi {
  static async GetMemberExcel(data) {
    return await api.post(`admin/member/excel`, data);
  }

  static async GetRetiredMemberExcel(data) {
    return await api.post(`admin/retired-member/excel`, data);
  }

  static async GetMerchAskExcel(data) {
    return await api.post(`admin/merchAsk/excel`, data);
  }

  static async GetOrderAskExcel(data) {
    return await api.post(`admin/orderAsk/excel`, data);
  }

  static async GetSettlementExcel(data) {
    return await api.post(`admin/settlement/excel`, data);
  }

  static async GetReviewExcel(data) {
    return await api.post(`admin/review/excel`, data);
  }

  static async GetOrderExcel(data) {
    return await api.post(`admin/order/excel`, data);
  }

  static async GetMemberMerchExcel(data) {
    return await api.post(`admin/member-merch/excel`, data);
  }

  static async GetMerchExcel(data) {
    return await api.post(`admin/merch/excel`, data);
  }

  static async GetCouponExcel(data) {
    return await api.post(`admin/member-coupon/excel`, data);
  }
}
