import api from "../../../../api/api";

export default class SettingApi {
  static async getEnvSetting() {
    return await api.get(`admin/env-setting`);
  }
  static async updateEnvSetting(data) {
    return await api.patch(`admin/env-setting/1`, data);
  }

  static async getShippingSetting() {
    return await api.get(`admin/shipping-setting`);
  }
  static async updateShippingSetting(id, data) {
    return await api.patch(`admin/shipping-setting/${id}`, data);
  }

  static async getPointSetting() {
    return await api.get(`admin/point-setting`);
  }
  static async updatePointSetting(data) {
    return await api.patch(`admin/point-setting`, data);
  }

  static async createTermSetting(data) {
    return await api.post(`admin/term-setting`, data);
  }
  static async getTermSetting() {
    return await api.get(`admin/term-setting`);
  }
  static async updateTermSetting(id, data) {
    return await api.patch(`admin/term-setting/${id}`, data);
  }
  static async deleteTermSetting(data) {
    return await api.del(`admin/term-setting`, data);
  }
}
