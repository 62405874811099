import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import DelBtn from "../../../component/atoms/button/delBtn";
import EditBtn from "../../../component/atoms/button/editBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import SearchBar from "../../../component/atoms/input/searchBar";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import GoBack from "../../../component/molecules/goBack";
import Pagination from "../../../component/molecules/pagination";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import MemberApi from "../../../api/member.api";
import { formatPhoneNumber } from "./../../../util/formatNumber";
import { fileUrl } from "../../../api/api";
import moment from "moment";
import { isNull } from "../../../util/check";

const InfluencerDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [keyword, setKeyword] = useState("");
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getMemberInfo();
    getAttributionList();
  }, []);

  const [memberInfo, setMemberInfo] = useState({});

  const getMemberInfo = async () => {
    try {
      const response = (await MemberApi.getById(id)).data.data.content;

      setMemberInfo({
        ...response,
        birth: response.birth ? response.birth.split("T")[0] : "",
        address: getSenderAddressString(JSON.parse(response.address)),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ---------------------------------------- //
  // ---------- 귀속 회원 구매 내역 ---------- //
  // -------------------------------------- //

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getAttributionList();
  }, [location]);

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const [attributionList, setAttributionList] = useState([]);

  const getAttributionList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };

      let responseData = (await MemberApi.getAttributionList(id, data)).data.data;

      setAttributionList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );

      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: [id],
      };
      await MemberApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  //------------엑셀 생성
  // const downloadExcel = async (excelData) => {
  //   const Excel = require("exceljs");
  //   const workbook = new Excel.Workbook();
  //   workbook.addWorksheet("Sheet One");
  //   const sheetOne = workbook.getWorksheet("Sheet One");

  //   sheetOne.columns = [
  //     { header: "이메일", key: "email", width: 40 },
  //     { header: "이름", key: "name", width: 40 },
  //     { header: "가입일", key: "createdAt", width: 40 },
  //     { header: "계정 상태", key: "activation", width: 40 },
  //   ];

  //   excelData.map((item) => {
  //     // 행 추가
  //     sheetOne.addRow(item);
  //   });
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   saveAs(new Blob([buffer]), "일반 회원 관리.xlsx");
  // };

  // const getExcelAll = async () => {
  //   try {
  //     const excelArr = [];
  //     const response = (
  //       await ExcelApi.GetMemberExcel({
  //         ids: [],
  //         allFlag: true,
  //       })
  //     ).data.data.content;

  //     response.map((item) => {
  //       const activeTitle = item.activation ? "활성" : "비활성";
  //       const createDate = moment(item.createdAt).format("YYYY/MM/DD");
  //       excelArr.unshift({
  //         ...item,
  //         activation: activeTitle,
  //         createdAt: createDate,
  //       });
  //     });

  //     downloadExcel(excelArr);
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };

  // const getExcelChecked = async () => {
  //   const filter = commonMemberList.filter((item) => item.checked);
  //   if (filter.length <= 0) {
  //     return toast(t("placeholder.selectItem"));
  //   }

  //   try {
  //     const excelArr = [];
  //     const response = (
  //       await ExcelApi.GetMemberExcel({
  //         ids: filter.map((item) => item.id),
  //         allFlag: false,
  //       })
  //     ).data.data.content;

  //     response.map((item) => {
  //       const activeTitle = item.activation ? "활성" : "비활성";
  //       const createDate = moment(item.createdAt).format("YYYY/MM/DD");
  //       excelArr.unshift({
  //         ...item,
  //         activation: activeTitle,
  //         createdAt: createDate,
  //       });
  //     });

  //     downloadExcel(excelArr);
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };
  //------------액셀 부분 끝

  const getOptionInfo = (optionInfo) => {
    let optionString = "";

    for (let index = 0; index < optionInfo.length; index++) {
      const element = optionInfo[index];
      const option = element.option;
      const optionValue = element.optionValue;

      if (index != 0) {
        optionString += " / ";
      }
      optionString += `${option.title}: ${optionValue.title}, 수량: ${optionValue.quantity}`;
    }

    return optionString;
  };

  const getSenderAddressString = (senderAddressArray) => {
    let senderAddressString = "";

    if (senderAddressArray[0]) {
      senderAddressString += senderAddressArray[0].provinceName;
    }

    if (senderAddressArray[1]) {
      senderAddressString += `, ${senderAddressArray[1].districtName}`;
    }

    if (senderAddressArray[2]) {
      senderAddressString += `, ${senderAddressArray[2].wardName}`;
    }

    return senderAddressString;
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDeleteModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.influencer_member_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "495px", marginBottom: "40px" }}>
            <DetailPageTitle>{t("influencer_detail.userInfo")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 이메일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.email")}</DetailFormTitle>
                <DetailFormText>{memberInfo.email}</DetailFormText>
              </DetailPageForm>

              {/* 이름 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.name")}</DetailFormTitle>
                <DetailFormText>{memberInfo.name || "-"}</DetailFormText>
              </DetailPageForm>

              {/* 휴대폰 번호 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.phone")}</DetailFormTitle>
                <DetailFormText>{formatPhoneNumber(memberInfo.phoneNumber)}</DetailFormText>
              </DetailPageForm>

              {/* 생년월일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.birth")}</DetailFormTitle>
                <DetailFormText>{memberInfo.birth || "-"}</DetailFormText>
              </DetailPageForm>

              {/* 성별 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.gender")}</DetailFormTitle>
                <DetailFormText>{memberInfo.gender === "" || memberInfo.gender === "NONE" ? "-" : memberInfo.gender === "WOMAN" ? t("common_member.woman") : t("common_member.man")}</DetailFormText>
              </DetailPageForm>

              {/* 주소 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.address")}</DetailFormTitle>
                <DetailFormText>{`${memberInfo.address} ${memberInfo.detailAddress}`}</DetailFormText>
              </DetailPageForm>

              {/* 계좌 정보 - 은행명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.bankName")}</DetailFormTitle>
                <DetailFormText>{memberInfo.bankName || "-"}</DetailFormText>
              </DetailPageForm>

              {/* 계좌 정보 - 계좌번호 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.account")}</DetailFormTitle>
                <DetailFormText>{memberInfo.accountNumber || "-"}</DetailFormText>
              </DetailPageForm>

              {/* 계좌 정보 - 예금주명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.accountHolder")}</DetailFormTitle>
                <DetailFormText>{memberInfo.accountHolderName || "-"}</DetailFormText>
              </DetailPageForm>

              {/* 귀속 회원 수 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.attribution")}</DetailFormTitle>
                <DetailFormText>{memberInfo.recommendCount}</DetailFormText>
              </DetailPageForm>

              {/* 팔로워 수 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.follower")}</DetailFormTitle>
                <DetailFormText>{memberInfo.followerCount}</DetailFormText>
              </DetailPageForm>

              {/* SNS 링크 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.snsLink")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {memberInfo.snsLink ? JSON.parse(memberInfo.snsLink).map((item, index) => <DetailFormText key={index}>{item}</DetailFormText>) : <DetailFormText></DetailFormText>}
                </div>
              </DetailPageForm>

              {/* SNS 스크린샷 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.snsScreenShot")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    width: 744,
                  }}
                >
                  {memberInfo.screenshots ? (
                    memberInfo.screenshots.map((item, index) => <FileImgPreview key={index} fileName={item.screenshotOriginFileName} serverFileName={item.screenshotServerFileName} />)
                  ) : (
                    <DetailFormText></DetailFormText>
                  )}
                </div>
              </DetailPageForm>

              {/* 보유 포인트 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.point")}</DetailFormTitle>
                <DetailFormText>{memberInfo.point}</DetailFormText>
              </DetailPageForm>

              {/* 보유 포인트 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.pick")}</DetailFormTitle>
                <DetailFormText>{memberInfo.isPick ? `${t("component.yes")}` : `${t("component.no")}`}</DetailFormText>
              </DetailPageForm>

              {/* 약관 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("influencer_detail.terms")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.useTerms")}</DetailFormText>
                    <Checkbox id="agreement" checked={true} disabled={true} />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.collect")}</DetailFormText>
                    <Checkbox id="privacy" checked={true} disabled={true} />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.providing")}</DetailFormText>
                    <Checkbox id="privacy" checked={true} disabled={true} />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>

              {/* 알림 수신 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("influencer_detail.alarm")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.sms")}</DetailFormText>
                    <Checkbox disabled id="marketingAgreement" checked={memberInfo.marketingAgreement} />
                  </CheckboxWrapper>

                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.ad")}</DetailFormText>
                    <Checkbox disabled id="adAgreement" checked={memberInfo.adAgreement} />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>
          <div>
            <TableTop>
              <TableTitle style={{ fontSize: "24px", marginBottom: 0 }}>{t("influencer_detail.tableTitle")}</TableTitle>
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`${route.influencer_member_detail + `/${id}`}?page=1&keyword=${keyword}`, {
                        replace: true,
                      });
                    }
                  }}
                  onClick={() => {
                    navigate(`${route.influencer_member_detail + `/${id}`}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }}
                />
                <ExcelBtn
                // onSelectClick={getExcelChecked}
                // onAllClick={getExcelAll}
                />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 이메일 */}
                <col width="222px" />
                {/* 상품명 */}
                <col width="272px" />
                {/* 결제금액 */}
                <col width="203px" />
                {/* 결제일 */}
                <col width="182px" />
                {/* 적립 포인트 */}
                <col width="145px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <TableText>{t("influencer_detail.email")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("influencer_detail.product")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("influencer_detail.amount")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("influencer_detail.payDay")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("influencer_detail.point")}</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {attributionList.map((item, index) => {
                  return (
                    <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                      <Td style={{ paddingLeft: "20px" }}>
                        <TableText>{item.email}</TableText>
                      </Td>
                      <Td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <img
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "3px",
                              }}
                              src={`${fileUrl}/${item.imageServerFileName}`}
                              alt=""
                            />
                          </div>
                          <div>
                            <TableText style={{ marginBottom: "4px" }}>{item.title}</TableText>
                            <TableText style={{ fontSize: "12px", lineHeight: "16px" }}>{item.optionFlag ? getOptionInfo(JSON.parse(item.optionInfo)) : `수량: ${item.noOptionQuantity}`}</TableText>
                          </div>
                        </div>
                      </Td>
                      <Td>
                        <TableText>{item.payAmount}</TableText>
                      </Td>
                      <Td>
                        <TableText>{moment(item.createdAt).format("YYYY/MM/DD")}</TableText>
                      </Td>
                      <Td>
                        <TableText>{item.rewardPoint}</TableText>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.influencer_member_detail + `/${id}`}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default InfluencerDetail;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
