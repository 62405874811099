import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { route } from "../../router/route";
import PretendardText from "../atoms/text/pretendardText";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Sidebar = (props) => {
  const { t } = useTranslation();
  const generateMenus = () => [
    // 홈 영역
    {
      title: `${t(`sidebar.home`)}`,
      link: route.home,
      subMenus: [],
      isView: true,
    },
    // 사용자 관리 영역
    {
      title: `${t(`sidebar.user`)}`,
      link: route.user,
      subMenus: [
        {
          title: `${t(`sidebar.common_member`)}`,
          link: route.common_member,
          isView: true,
        },
        {
          title: `${t(`sidebar.influencer_member`)}`,
          link: route.influencer_member,
          isView: true,
        },
        {
          title: `${t(`sidebar.approve_signup`)}`,
          link: route.approve_signup,
          isView: true,
        },
        {
          title: `${t(`sidebar.calculate`)}`,
          link: route.calculate,
          isView: true,
        },
        {
          title: `${t(`sidebar.leave_member`)}`,
          link: route.leave_member,
          isView: true,
        },
      ],
      isView: true,
    },
    // 영상 관리
    {
      title: `${t(`sidebar.video`)}`,
      link: route.video,
      subMenus: [
        {
          title: `${t(`sidebar.approve_video`)}`,
          link: route.approve_video,
          isView: true,
        },
      ],
      isView: true,
    },
    // 상품 관리
    {
      title: `${t(`sidebar.product`)}`,
      link: route.product,
      subMenus: [
        {
          title: `${t(`sidebar.product_list`)}`,
          link: route.product_list,
          isView: true,
        },
        {
          title: `${t(`sidebar.category`)}`,
          link: route.category,
          isView: true,
        },
        {
          title: `${t(`sidebar.exhibition`)}`,
          link: route.exhibition,
          isView: true,
        },
        {
          title: `${t(`sidebar.coupon`)}`,
          link: route.coupon_add,
          isView: true,
        },
        {
          title: `${t(`sidebar.review`)}`,
          link: route.review,
          isView: true,
        },
      ],
      isView: true,
    },
    // 주문 관리
    {
      title: `${t(`sidebar.order`)}`,
      link: route.order,
      subMenus: [
        {
          title: `${t(`sidebar.shipping`)}`,
          link: route.shipping,
          isView: true,
        },
        {
          title: `${t(`sidebar.cancel`)}`,
          link: route.cancel,
          isView: true,
        },
        {
          title: `${t(`sidebar.exchange`)}`,
          link: route.exchange,
          isView: true,
        },
        {
          title: `${t(`sidebar.return`)}`,
          link: route.return,
          isView: true,
        },
      ],
      isView: true,
    },
    // 고객센터
    {
      title: `${t(`sidebar.customer`)}`,
      link: route.customer,
      subMenus: [
        {
          title: `${t(`sidebar.alarm`)}`,
          link: route.alarm,
          isView: true,
        },
        {
          title: `${t(`sidebar.ask`)}`,
          link: route.ask,
          isView: true,
        },
        {
          title: `${t(`sidebar.notice`)}`,
          link: route.notice,
          isView: true,
        },
        {
          title: `${t(`sidebar.faq`)}`,
          link: route.faq,
          isView: true,
        },
      ],
      isView: true,
    },
    // 서비스 관리
    {
      title: `${t(`sidebar.service`)}`,
      link: route.service,
      subMenus: [
        {
          title: `${t(`sidebar.setting`)}`,
          link: route.setting,
          isView: true,
        },
        {
          title: `${t(`sidebar.terms`)}`,
          link: route.terms,
          isView: true,
        },
        {
          title: `${t(`sidebar.basic`)}`,
          link: route.basic,
          isView: true,
        },
        {
          title: `${t(`sidebar.popup`)}`,
          link: route.popup,
          isView: true,
        },
        {
          title: `${t(`sidebar.banner`)}`,
          link: route.banner_main,
          isView: true,
        },
        {
          title: `${t(`sidebar.main`)}`,
          link: route.main,
          isView: true,
        },
      ],
      isView: true,
    },
    // 관리자 권한 관리 영역
    {
      title: `${t(`sidebar.manager`)}`,
      link: route.manager,
      subMenus: [],
      isView: true,
    },
  ];

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(0);

  //경로 저장
  const location = useLocation();
  const [path, setPath] = useState(null);

  const [menus, setMenus] = useState(generateMenus);

  useEffect(() => {
    const handleLanguageChange = () => {
      setMenus(generateMenus());
    };

    i18next.on("languageChanged", handleLanguageChange);
    return () => {
      i18next.off("languageChanged", handleLanguageChange);
    };
  }, []);

  // useEffect(() => {
  //   setPath(location.pathname);
  // }, [location]);

  useEffect(() => {
    setPath(location.pathname);
    if (sessionStorage.getItem("role") !== "ROOT") {
      const accessMenu = sessionStorage.getItem("accessMenu");
      for (let index = 0; index < menus.length; index++) {
        const element = menus[index];
        if (element.subMenus.length > 0) {
          for (let index2 = 0; index2 < element.subMenus.length; index2++) {
            const element2 = element.subMenus[index2];
            if (!accessMenu?.includes(element2.link)) {
              element2.isView = false;
            }
          }
          if (
            element.subMenus.filter((item) => !item.isView).length ===
            element.subMenus.length
          ) {
            element.isView = false;
          }
        } else {
          if (!accessMenu?.includes(element.link)) {
            element.isView = false;
          }
        }
      }
      setMenus([...menus]);
    }
  }, [location]);

  return (
    <SidebarWrapper style={{ ...props.style }}>
      <ProfileWrapper>
        <img src="/assets/admin/icons/ico_profile02.svg" alt="" />
        <SidebarPretendardText
          style={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.042px",
            cursor: "unset",
          }}
        >
          {sessionStorage.getItem("name") || "관리자 이메일"}
        </SidebarPretendardText>
      </ProfileWrapper>
      <NavListWrapper>
        {menus.map((item, index) => {
          return (
            <div key={index}>
              {item.isView ? (
                <NavWrapper
                  onClick={() => {
                    if (item.subMenus.length > 0) {
                      isOpen === item.link
                        ? setIsOpen(0)
                        : setIsOpen(item.link);
                    } else {
                      navigate(item.link);
                      sessionStorage.removeItem("tab");
                    }
                  }}
                >
                  <SidebarPretendardText
                    style={{
                      color: `${path?.includes(item.link) ? "#27BEFF" : ""}`,
                    }}
                  >
                    {item.title}
                  </SidebarPretendardText>
                  {item.subMenus.length > 0 ? (
                    <PretendardText
                      style={{
                        width: "12px",
                        height: "12px",
                        color: `${
                          path?.includes(item.link) ? "#27BEFF" : "#F2F4F5"
                        }`,
                      }}
                    >
                      {path?.includes(item.link) || isOpen === item.link
                        ? "-"
                        : "+"}
                    </PretendardText>
                  ) : null}
                </NavWrapper>
              ) : null}
              <SubmenuListWrapper>
                {item.subMenus.length > 0
                  ? item.subMenus.map((item2, index2) => {
                      return path?.includes(item.link) ||
                        isOpen === item.link ? (
                        <div key={index2}>
                          {item2.isView ? (
                            <SubmenuWrapper
                              onClick={() => {
                                navigate(item2.link);
                                sessionStorage.removeItem("tab");
                              }}
                              style={{
                                backgroundColor: `${
                                  path?.includes(item2.link) ? "#ffffff10" : ""
                                }`,
                                cursor: "pointer",
                              }}
                            >
                              <SubPretendardText
                                style={{
                                  color: `${
                                    path?.includes(item2.link) ? "#FFF" : ""
                                  }`,
                                }}
                              >
                                {item2.title}
                              </SubPretendardText>
                            </SubmenuWrapper>
                          ) : null}
                        </div>
                      ) : null;
                    })
                  : null}
              </SubmenuListWrapper>
            </div>
          );
        })}
      </NavListWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;

const SidebarWrapper = styled.div.attrs((props) => {})`
  width: 265px;
  min-height: 100vh;
  height: auto;
  background-color: #2e313e;
  padding-top: 83px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  z-index: 5;
`;

const ProfileWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 58px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NavListWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const NavWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 25px;
  cursor: pointer;
`;

const SidebarPretendardText = styled(PretendardText).attrs((props) => {})`
  color: #f2f4f5;
  font-size: 18px;
  font-weight: 600;
  line-height: 32.5px;
  letter-spacing: -0.054px;
  cursor: pointer;
`;

const SubmenuListWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13.5px;
`;

const SubmenuWrapper = styled.div.attrs((props) => {})`
  width: 236px;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 14px 25px;
  border-radius: 8px;
`;

const SubPretendardText = styled(PretendardText).attrs((props) => {})`
  color: #778088;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.048px;
  cursor: pointer;
`;
