import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../atoms/text/pretendardText";
import { useTranslation } from "react-i18next";

function GoBack(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <>
      <GoBackWrapper
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          } else navigate(-1);
        }}
        style={{ cursor: "pointer" }}
      >
        <img src="/assets/admin/icons/ico_arrow_left.svg" />
        <PretendardText
          style={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "28px",
            letterSpacing: "-0.048px",
            color: "#5A636A",
          }}
        >
          {t("component.goBack")}
        </PretendardText>
      </GoBackWrapper>
    </>
  );
}

export default GoBack;

const GoBackWrapper = styled.div.attrs((props) => {})`
  width: 101.276;
  display: flex;
  align-items: center;
  gap: 10px;
`;
