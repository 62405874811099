import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import { route } from "../../../router/route";
// import TermsApi from "../../../api/terms.api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AddBtn from "../../../component/atoms/button/addBtn";
import DelBtn from "../../../component/atoms/button/delBtn";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/errorHandler";
import TermsApi from "./_api/terms.api";

const Terms = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [termsList, setTermsList] = useState([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getTermsList();
  }, [location]);

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const getTermsList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await TermsApi.getList(data)).data.data;
      setTermsList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const isAllCheck = useMemo(() => {
    const checkedList = termsList.filter((item) => item.checked);
    return (
      checkedList.length !== 0 &&
      checkedList.length === termsList.filter((item) => item.updateFlag).length
    );
  }, [termsList]);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: delIdList,
      };
      await TermsApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      setDelIdList([]);
      getTermsList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <div style={{ marginTop: "40px" }}>
            <TableTitle>{t("terms.title")}</TableTitle>
            <TableTop>
              <TableCount
                text={t("terms.userCount")}
                count={pagination.totalCount}
              />
              <TableSearchBtn>
                <DelBtn
                  text={t("terms.delBtn")}
                  onClick={() => {
                    const delList = termsList.filter((item) => item.checked);
                    if (delList.length <= 0) {
                      return;
                    }
                    setDelIdList(delList.map((item) => item.id));
                    setDeleteModalOpen(true);
                  }}
                />
                <AddBtn onClick={() => navigate(route.terms_add)} />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 체크박스 */}
                <col width="90px" />
                {/* 약관 종류 */}
                <col width="685px" />
                {/* 마지막 수정일 */}
                <col width="181px" />
                {/* 상세보기 */}
                <col width="90px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "41px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <Checkbox
                      checked={isAllCheck}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setTermsList(
                            termsList.map((item) => {
                              return {
                                ...item,
                                checked: item.updateFlag ? true : false,
                              };
                            })
                          );
                        } else {
                          setTermsList(
                            termsList.map((item) => {
                              return {
                                ...item,
                                checked: false,
                              };
                            })
                          );
                        }
                      }}
                    />
                  </Th>
                  <Th>
                    <TableText>{t("terms.tableTitle")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("terms.lastEdit")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("terms.more")}</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {termsList.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      style={{ height: "59px", background: "#fff" }}
                    >
                      <Td style={{ paddingLeft: "20px" }}>
                        {item.updateFlag ? (
                          <Checkbox
                            checked={item.checked}
                            onChange={() => {
                              item.checked = !item.checked;
                              setTermsList([...termsList]);
                            }}
                          />
                        ) : undefined}
                      </Td>
                      <Td>
                        <TableText>{item.title}</TableText>
                      </Td>
                      <Td>
                        <TableText>
                          {moment(item.updatedAt).format("YYYY/MM/DD")}
                        </TableText>
                      </Td>
                      <Td>
                        <DetailBtn
                          onClick={() => {
                            navigate(route.terms_detail + `/${item.id}`);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.general_terms}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default Terms;
