import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import BasicApi from "../../../api/basic.api";
import EditBtn from "../../../component/atoms/button/editBtn";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { useTranslation } from "react-i18next";
import BasicApi from "./_api/basic.api";

const Basic = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [basicInfo, setBasicInfo] = useState({
    companyName: "",
    ceo: "",
    businessNumber: "",
    reportNumber: "",
    address: "",
    tel: "",
    email: "",
    csInfo1: "",
    csInfo2: "",
    csInfo3: "",
    senderAddress: "",
  });

  const getBasicInfo = async () => {
    try {
      let responseData = (await BasicApi.getBasicInfo()).data.data.content;
      setBasicInfo({
        ...responseData,
        senderAddress: getSenderAddressString(JSON.parse(responseData.senderAddress)),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getBasicInfo();
  }, []);

  const getSenderAddressString = (senderAddressArray) => {
    let senderAddressString = "";

    if (senderAddressArray[0]) {
      senderAddressString += senderAddressArray[0].provinceName;
    }

    if (senderAddressArray[1]) {
      senderAddressString += `, ${senderAddressArray[1].districtName}`;
    }

    if (senderAddressArray[2]) {
      senderAddressString += `, ${senderAddressArray[2].wardName}`;
    }

    return senderAddressString;
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <TableTitle>{t("basic.title")}</TableTitle>
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <EditBtn
                onClick={() => {
                  navigate(route.basic_edit);
                }}
              />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "295px" }}>
            <DetailPageTitle>{t("basic.title")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>{t("basic.name")}</DetailFormTitle>
                <DetailFormText>{basicInfo.companyName}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("basic.ceo")}</DetailFormTitle>
                <DetailFormText>{basicInfo.ceo}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("basic.businessNumber")}</DetailFormTitle>
                <DetailFormText>{basicInfo.businessNumber}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("basic.mailOrderNumber")}</DetailFormTitle>
                <DetailFormText>{basicInfo.reportNumber}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("basic.address")}</DetailFormTitle>
                <DetailFormText>{basicInfo.address}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("basic.tel")}</DetailFormTitle>
                <DetailFormText>{basicInfo.tel}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("basic.email")}</DetailFormTitle>
                <DetailFormText>{basicInfo.email}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("basic.cs")}</DetailFormTitle>
                <div>
                  <DetailFormText>{basicInfo.csInfo1}</DetailFormText>
                  <DetailFormText>{basicInfo.csInfo2}</DetailFormText>
                  <DetailFormText>{basicInfo.csInfo3}</DetailFormText>
                </div>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("basic.senderAddress")}</DetailFormTitle>
                <DetailFormText>{basicInfo.senderAddress}</DetailFormText>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
    </>
  );
};

export default Basic;
