export const vi = {
  auth: {
    login: "Đăng nhập",
    join: "Đăng ký",
    email: "E-mail",
    emailError: "Hình thức email không chính xác.",
    pw: "Mật khẩu",
    pwCaption: "Mật khẩu có từ 8 ký tự trở lên đến 16 ký tự bao gồm chữ cái, số, ký tự đặc biệt.",
    pwCheck: "Xác nhận mật khẩu",
    pwError: "Mật khẩu không khớp.",
    name: "Tên",
    joinComplete: "Đăng ký hoàn tất",
    completeCaption: "Bạn có thể đăng nhập sau khi được quản trị viên phê duyệt.",
    goLogin: "Tới trang đăng nhập",
  },

  placeholder: {
    defaultPlaceholder: "Vui lòng nhập nội dung.",
    emailPlaceholder: "Vui lòng nhập Email.",
    pwPlaceholder: "Vui lòng nhập mật khẩu.",
    pwCheckPlaceholder: "Vui lòng kiểm tra lại mật khẩu.",
    namePlaceholder: "Vui lòng nhập tên.",
    searchPlaceholder: "Vui lòng nhập từ tìm kiếm.",
    titlePlaceholder: "Vui lòng nhập tiêu đề.",
    contentPlaceholder: "Vui lòng nhập nội dung.",
    couponPlaceholder: "Vui lòng nhập tên Mã giảm giá.",
    amountPlaceholder: "Vui lòng nhập số tiền.",
    popupPlaceholder: "Vui lòng nhập tên Pop-up.",
    categoryPlaceholder: "Vui lòng nhập tên danh mục.",
    onlyNumberPlaceholder: "Vui lòng chỉ nhập số.",
    inventoryPlaceholder: "Vui lòng nhập số lượng hàng còn.",
    linkPlaceholder: "Vui lòng nhập liên kết.",
    exhibitionPlaceholder: "Vui lòng nhập tên Khuyến mãi.",
    datePlaceholder: "Vui lòng nhập ngày.",
    datePlaceholder02: "Vui lòng chọn ngày.",
    addressPlaceholder: "Hãy nhập địa chỉ.",
    detailAddressPlaceholder: "Hãy nhập địa chỉ chi tiết..",
    selectItem: "Vui lòng chọn một mục.",
  },

  header: {
    logout: "Log out",
  },

  modalMsg: {
    update: {
      title: "Các bạn có muốn lưu lại không?",
      content: "Không thể khôi phục dữ liệu cũ.\nBạn có muốn tiến hành lưu không?",
    },
    delete: {
      title: "Bạn có muốn xóa nó đi không?",
      content: "Không thể khôi phục dữ liệu đã bị xóa.\nDù vậy thì bạn vẫn sẽ xóa chứ?",
    },
    couponCancel: {
      title: "Bạn có muốn hủy phát hành không?",
      content: "Phiếu giảm giá đã hủy không thể được khôi phục.",
    },
    couponComplete: {
      title: "Bạn có muốn xử lý nó như đã sử dụng không?",
      content: "Không thể khôi phục các phiếu giảm giá đã được xử lý. \nBạn vẫn muốn tiếp tục chứ?",
    },
    create: "Bạn có muốn đăng ký không?",
    approve: "Bạn có chấp thuận không?",
    influencer_approve: "Bạn có chấp nhận phê duyệt không?",
    deny: "Bạn có muốn trả lại không?",
    activate: "Bạn có muốn kích hoạt nó không?",
    deactivate: "Bạn có muốn vô hiệu hóa nó không?",
    rank: "Bạn có muốn thay đổi cấp bậc không?",
    influencer_cancel: "Từ chối",
    influencer_ok: "Phê duyệt",
  },

  toastMsg: {
    create: "Đã thêm.",
    update: "Đã lưu.",
    delete: "Đã xóa.",
    input_empty: "Hãy nhập toàn bộ yếu tố cần thiết.",
    errorText_500: "Lỗi máy chủ. Hãy thử lại sau vài phút nữa.",
    productAddToast: "Sản phẩm đã được đăng ký. Vui lòng cài đặt tùy chọn.",
    input_pw: "Hãy nhập mật khẩu.",
    check_pwFormat: "Hãy nhập đúng mật khẩu.",
    check_pw: "Mật khẩu không trùng khớp.",
    message401: "Đã quá thời gian. Vui lòng đăng nhập lại.",
    errorEmail: "E-mail không chính xác.",
    errorPw: "Mật khẩu không chính xác.",
    accountApprove: "Tài khoản đã được phê duyệt.",
    activeAdd: "Vui lòng đăng ký hình ảnh hoạt động của Danh mục.",
    inActiveAdd: "Vui lòng đăng ký hình ảnh không hoạt động của Danh mục.",
    titleAdd: " Vui lòng nhập tên Danh mục.",
    pcImageAdd: "Vui lòng đăng tải hình ảnh PC.",
    moImageAdd: "Vui lòng đăng tải hình ảnh Mobile.",
    partAdd: "Vui lòng nhập tên Phần.",
    termTitleAdd: "Vui lòng nhập tên Điều khoản.",
    termContentAdd: "Vui lòng nhập nội dung Điều khoản.",
  },

  errorMsg: {
    recommender: "ID giới thiệu đã nhập không tồn tại.",
  },

  sidebar: {
    home: "Trang chủ",
    user: "Quản lý thành viên",
    common_member: "Quản lý thành viên",
    influencer_member: "Quản lý Influencer",
    approve_signup: "Yêu cầu phê duyệt",
    calculate: "Quản lý thanh toán",
    leave_member: "Quản lý xóa tài khoản",
    video: "Quản lý video",
    approve_video: "Phê duyệt video",
    product: "Quản lý Sản phẩm",
    product_list: "Danh sách Sản phẩm",
    category: "Quản lý Danh mục",
    exhibition: "Quản lý Khuyến mãi",
    coupon: "Quản lý Mã giảm giá",
    review: "Quản lý Đánh giá",
    order: "Quản lý đơn hàng",
    shipping: "Quản lý vận chuyển",
    cancel: "Quản lý Trả hàng / Hoàn tiền",
    exchange: "Quản lý đổi hàng",
    return: "Quản lý Trả hàng",
    customer: "Trung tâm khách hàng",
    alarm: "Quản lý thông báo",
    ask: "Quản lý câu hỏi 1:1",
    notice: "Quản lý thông báo web",
    faq: "Quản lý FAQ",
    service: "Quản lý dịch vụ",
    setting: "Cài đặt sản phẩm",
    terms: "Quản lý điều khoản",
    basic: "Quản lý chung",
    popup: "Quản lý pop-up",
    banner: "Quản lý banner",
    main: "Quản lý chính",
    manager: "Quản lý quản trị viên",
  },

  component: {
    excel: "Excel",
    selectExcel: "Download hạng mục lựa chọn",
    allExcel: "Tải xuống toàn bộ hạng mục",
    active: "Hoạt động",
    inactive: "Ngừng hoạt động",
    delete: "Xóa",
    see: "Xem",
    goBack: "Quay lại",
    edit: "Chỉnh sửa",
    save: "Lưu",
    memo: "Ghi chú",
    answer: "Hồi đáp",
    addImg: "Thêm ảnh",
    add: "Đăng ký",
    required: "Bắt buộc",
    choice: "Lựa chọn",
    monetary: "₫",
    count: "cái",
    count02: "vụ",
    cancel: "huỷ bỏ",
    check: "xác nhận",
    search: "Tìm kiếm",
    yes: "Vâng",
    no: "Không phải",
  },

  home: {
    welcome: "Chào mừng!",
    hello: "Xin chào :)",
    allUser: "Tổng số người dùng",
    user: "Thành viên (Đăng ký/ Xóa tài khoản)",
    sales: "Phân tích bán hàng",
    day: "Theo ngày",
    month: "Theo tháng",
    statistics: "Thống kê theo mục",
    influencer: "TOP Infuencer",
    best: "Best Seller",
    wishList: "Danh sách Yêu thích",
  },

  common_member: {
    title: "Quản lý thành viên",
    userCount: "Tổng số người dùng",
    email: "E-mail",
    name: "Tên",
    createdAt: "Ngày gia nhập",
    state: "Trạng thái",
    delete: "Xóa",
    more: "Chi tiết",
    phone: "số điện thoại",
    man: "Nam",
    woman: "Nữ",
  },

  common_member_detail: {
    userInfo: "Thông tin cơ bản",
    email: "E-mail",
    name: "Tên",
    phone: "Số điện thoại",
    birth: "Ngày sinh",
    gender: "Giới tính",
    address: "Địa chỉ",
    detailAddress: "Địa chỉ chi tiết",
    recommender: "ID người giới thiệu",
    point: "Số điểm",
    terms: "Đồng ý điều khoản",
    alarm: "Chọn đồng ý nhận thông báo",
    useTerms: "Điều khoản sử dụng thành viên (bắt buộc)",
    collect: "Đồng ý thu thập và sử dụng thông tin cá nhân (bắt buộc)",
    providing: "Đồng ý cung cấp thông tin cá nhân cho bên thứ ba (bắt buộc)",
    sms: "Đồng ý nhận thông tin tiếp thị qua thư điện tử và SMS (tùy chọn)",
    service: "Đồng ý nhận thông báo dịch vụ (tùy chọn)",
    ad: "Đồng ý nhận thông báo quảng cáo (tùy chọn)",
  },

  influencer: {
    title: "Quản lý Infuencer",
    userCount: "Tổng số người dùng",
    email: "E-mail",
    name: "Tên",
    createdAt: "Ngày gia nhập",
    rank: "Xếp hạng",
    state: "Trạng thái",
    delete: "Xóa tài khoản",
    more: "Xem chi tiết",
    aRank: "Điểm A",
    bRank: "Điểm B",
    cRank: "Điểm C",
    commonRank: "Hạng phổ thông",
  },

  influencer_detail: {
    userInfo: "Thông tin cơ bản",
    email: "E-mail",
    nickname: "Nick name",
    name: "Tên",
    phone: "Số điện thoại",
    birth: "Ngày sinh",
    gender: "Giới tính",
    address: "Địa chỉ",
    bankName: "Thông tin tài khoản - Tên ngân hàng",
    account: "Thông tin tài khoản - số tài khoản",
    accountHolder: "Thông tin tài khoản - tên chủ tài khoản",
    attribution: "Số lượng thành viên",
    follower: "Lượt theo dõi",
    snsLink: "Liên kết SNS",
    snsScreenShot: "Ảnh chụp màn hình SNS",
    point: "Số tích điểm",
    terms: "Đồng ý với Điều khoản và Điều kiện",
    alarm: "Chọn đồng ý nhận thông báo",
    useTerms: "Điều khoản sử dụng thành viên (bắt buộc)",
    collect: "Đồng ý thu thập và sử dụng thông tin cá nhân (bắt buộc)",
    providing: "Đồng ý cung cấp thông tin cá nhân cho bên thứ ba (bắt buộc)",
    sms: "Đồng ý nhận thông tin tiếp thị qua thư điện tử và SMS (tùy chọn)",
    service: "Đồng ý nhận thông báo dịch vụ (tùy chọn)",
    ad: "Đồng ý nhận thông báo quảng cáo (tùy chọn)",
    tableTitle: "Lịch sử mua hàng của thành viên",
    product: "Tên sản phẩm",
    amount: "Số tiền thanh toán",
    payDay: "Ngày thanh toán",
    point: "Số điểm",
    maxLengthToast: "Có thể đăng ký tối đa 5 cái.",
    pick: "KOVIET PICK",
  },

  influencer_approve: {
    title: "Yêu cầu phê duyệt Influencer",
    tab1: "Yêu cầu đăng ký",
    tab2: "Yêu cầu xóa bỏ",
    approveCount: "Tổng số yêu cầu",
    email: "E-mail",
    nickname: "Nick name",
    createdAt: "Ngày yêu cầu",
    state: "Trạng thái",
    approve: "Phê duyệt",
    more: "Xem chi tiết",
    checked: "Tán thành",
    reject: "Không được phê duyệt",
    approveBtn: "Chấp nhận",
    delBtn: "Xóa",
    retiredApprove: "Bạn có muốn phê duyệt việc rút tiền không?",
  },

  approve_detail: {
    title: "Thông tin cơ bản",
    email: "E-mail",
    nickname: "Nick name",
    snsLink: "Liên kết SNS",
    snsScreenShot: "Ảnh chụp màn hình SNS",
    btn: "Phê duyệt",
    leaveBtn: "Phê duyệt xóa tài khoản",
  },

  calculate: {
    title: "Quản lý thanh toán",
    calculateCount: "Tổng số yêu cầu",
    email: "E-mail",
    bankname: "Ngân hàng",
    account: "Số tài khoản",
    accountHolderName: "Tên người nhận",
    point: "Điểm sử dụng",
    createdAt: "Ngày yêu cầu",
    complete: "Trạng thái",
    notComplete: "Chưa hoàn thành",
    completeBtn: "Đã hoàn thành",
    checkedCompleteBtn: "Hạng mục hoàn tất xử lý",
  },

  main_manage: {
    title: "Quản lý chính",
    sectionName: "Tên",
    state: "Trạng thái",
    exhibition: "Danh sách Khuyến mãi",
    newProduct: "Danh sách sản phẩm mới",
    influencer: "Danh sách Influencer",
    event: "Banner đi đến Event / Best",
    MDPick: "Danh sách MD PICK",
    banner: "Banner đi đến câu hỏi thường gặp",
    placeholder: "Hãy nhập tên section.",
  },

  banner: {
    title: "Quản lý banner",
    tab1: "Trang chính",
    tab2: "Trang phụ",
    slide: "Banner",
    pc: "Hình ảnh PC",
    mobile: "Hình ảnh Mobile",
    recommendSize: "kích thước được đề xuất",
    link: "Liên kết",
    newTab: "Đi đến trang mới",
    goNewTab: "Đi đến trang mới",
    new: "Banner hình ảnh Sản phẩm mới",
    best: "Banner hình ảnh Sản phẩm tốt nhất",
    event: "Banner hình ảnh Sự kiện / Khuyến mãi",
    hide: "banner hình ảnh event",
  },

  manager: {
    title: "Quản lý quyền hạn Quản trị viên",
    userCount: "tổng số hạng mục",
    delBtn: "Số hạng mục lựa chọn",
    email: "E-mail",
    name: "Tên",
    type: "Vai trò",
    createdAt: "Ngày đăng ký",
    activation: "Trạng thái",
    state: "Tình trạng phê duyệt",
    approve: "phê duyệt",
    reject: "chưa được phê duyệt",
    approveBtn: "Chấp nhận",
    more: "Xem chi tiết",
    rootErrorMsg: "Không thể xóa tài khoản.",
    edit: "Chỉnh sửa",
    pw: "Mật khẩu",
    pwCheck: "Xác nhận mật khẩu",
    pwEditBtn: "Đổi mật khẩu",
    rootCaption: "*Trường hợp là ROOT, sẽ được quyền truy cập tất cả mọi quyền lợi dưới đây.",
    authority: "Quyền truy cập",
    userManage: "Quản lý thành viên",
  },

  popup: {
    title: "Quản lý pop-up",
    userCount: "Tổng số mục",
    delBtn: "Số hạng mục lựa chọn",
    tableTitle: "Tiêu đề",
    createdAt: "Ngày đăng tải",
    state: "Trạng thái",
    more: "Xem chi tiết",
    del: "Xem chi tiết",
    edit: "Chỉnh sửa",
    popupName: "Tên Pop-up",
    period: "thời gian đăng",
    image: "Hình ảnh",
    fullCaption: "tối đa 5 tấm",
  },

  basic: {
    title: "Quản lý chung",
    edit: "Chỉnh sửa",
    name: "Tên thương hiệu",
    ceo: "Người đại diện",
    businessNumber: "Số đăng ký kinh doanh",
    mailOrderNumber: "Nơi cấp",
    address: "Địa chỉ",
    tel: "TEL",
    email: "E-MAIL",
    cs: "CS CENTER",
    senderAddress: "Nơi gửi đi",
  },

  terms: {
    title: "Quản lý điều khoản",
    userCount: "Tổng số mục",
    delBtn: "Xóa",
    tableTitle: "Loại điều khoản",
    lastEdit: "Ngày chỉnh sửa cuối cùng",
    more: "Xem chi tiết",
    edit: "Chỉnh sửa",
    add: "Thêm vào",
    addBtn: "Thêm",
    termsName: "Tên điều khoản",
    content: "Nội dung điều khoản",
    required: "Lựa chọn bắt buộc",
    state: "Trạng thái",
  },

  faq: {
    title: "Quản lý FAQ",
    userCount: "Tổng số mặt hàng",
    delBtn: "Xóa lựa chọn",
    tableTitle: "Tiêu đê",
    email: "E-mail",
    createdAt: "Ngày tạo",
    more: "Xem chi tiết",
    edit: "Chỉnh sửa",
    add: "Thêm vào",
    addBtn: "Đăng ký",
    content: "chi tiết",
  },

  notice: {
    title: "Quản lý thông báo",
    userCount: "Tổng số mặt hàng",
    delBtn: "Xóa lựa chọn",
    tableTitle: "Tiêu đê",
    email: "E-mail",
    createdAt: "Ngày tạo",
    more: "Xem chi tiết",
    add: "Thêm vào",
    addBtn: "Đăng ký",
    content: "Nội dung",
  },

  alarm: {
    title: "Quản lý thông báo",
    userCount: "Tổng số mặt hàng",
    delBtn: "Xóa lựa chọn",
    tableTitle: "Tiêu đê",
    createdAt: "Ngày đăng",
    type: "Phân loại",
    more: "Xem chi tiết",
    del: "Xóa bỏ",
    add: "Thêm vào",
    addBtn: "Đăng ký",
    content: "chi tiết",
    serviceAlarm: "thông báo dịch vụ",
    adAlarm: "Thông báo quảng cáo",
  },

  leave: {
    title: "Quản lý thành viên rút tiền",
    userCount: "Tổng số người dùng",
    email: "E-mail",
    name: "Tên",
    reason: "Lý do xóa tài khoản",
    leaveDate: "Ngày",
    del: "Xóa vĩnh viễn",
  },

  setting: {
    tab1: "Cài đặt sản phẩm",
    tab2: "Hướng dẫn vận chuyển / đổi hàng / trả hàng",
    tab3: "Thiết lập điểm",
    tab4: "Quản lý điều khoản mua hàng",
    title1: "Cài đặt chung",
    title2: "Thiết lập phương tiện thanh toán",
    caption1: "Khi đặt hàng nội dung sẽ được thể hiện ở trang đặt đơn. Nếu để trống thì sẽ không hiện thông tin.",
    inputTitle1: "Cài đặt đơn hàng",
    inputTitle2: "Hướng dẫn khi đặt hàng",
    inputTitle3: "Điều kiện thời gian Hoàn trả hàng",
    inputTitle4: "Phương thức thanh toán",
    checkbox1: "Tự động trừ số lượng hàng tồn kho khi thanh toán xong",
    checkbox2: "Tự động đổi sang chuẩn bị giao hàng khi thanh toán xong",
    dueDate: "Từ ngày tiếp nhận",
    date: "ngày",
    card: "Thanh toán bằng thẻ",
  },

  transport: {
    caption1: "Đây là nội dung xuất hiện trên tab ' Hướng dẫn chuyển đổi / trao đổi / trả lại' của trang chi tiết sản phẩm.",
    title1: "Thông tin vận chuyển",
    title2: "Hướng dẫn Đổi hàng / Trả hàng",
    title3: "Điều khoản hạn chế Đổi hàng / Trả hàng",
    title4: "Thông tin người bán",
    inputTitle1: "Nội dung",
    inputTitle2: "Trạng thái",
  },

  point: {
    title1: "Cài đặt điểm",
    title2: "Thiết lập điểm theo cấp bậc Influencer",
    inputTitle1: "Trường hợp Đánh giá văn bản",
    inputTitle2: "Trường hợp Đánh giá kèm hình",
    inputTitle3: "Điều kiện sử dụng",
    inputTitle4: "Thời gian tối đa sử dụng",
    inputTitle5: "Hạng phổ thông",
    inputTitle6: "Hạng C",
    inputTitle7: "Hạng B",
    inputTitle8: "Hạng A",
    subTitle1: "Số điểm lưu giữ tối thiểu",
    subTitle2: "Số tiền mua tối thiểu",
    max: "Tối đa",
    month: "Ngày",
  },

  buyTerms: {
    title1: "Quản lý các điều khoản và điều kiện mua hàng",
    caption1: "Màn hình cài đặt điều khoản liên quan đến\nmua hàng xuất hiện khi đặt hàng",
    inputTitle1: "Tên điều khoản",
    inputTitle2: "Nội dung điều khoản",
    inputTitle3: "Lựa chọn bắt buộc*",
    inputTitle4: "Trạng thái",
  },

  ask: {
    title: "Quản lý câu hỏi 1:1",
    title2: "Quản lý Q&A sản phẩm",
    askCount: "tổng số hạng mục",
    email: "E-mail",
    name: "Tên",
    product: "Tên sản phẩm",
    type: "Loại yêu cầu",
    createAt: "Ngày tạo",
    state: "Trạng thái trả lời",
    more: "Xem chi tiết",
    answerWait: "Chờ trả lời",
    answerComplete: "Đã trả lời",
    detailTitle: "Chi tiết yêu cầu",
    info: "Thông tin sản phẩm",
    content: "Nội dung",
    comment: "Trả lời",
  },

  qna: {
    title: "Quản lý câu hỏi 1:1",
    title2: "Quản lý Q&A sản phẩm",
    askCount: "tổng số hạng mục",
    email: "E-mail",
    name: "Tên người đặt hàng",
    product: "Tên sản phẩm",
    type: "Loại yêu cầu",
    createAt: "Ngày tạo",
    state: "Trạng thái trả lời",
    more: "Xem chi tiết",
  },

  video: {
    title: "Phê duyệt video",
    videoCount: "Tổng số video",
    nickname: "Influencer",
    videoTitle: "Tên video",
    productCode: "Tên mã sản phẩm",
    createAt: "Ngày đăng kí",
    state: "Trạng thái",
    more: "Xem chi tiết",
    delBtn: "Xóa lựa chọn",
    approve: "người phê duyệt",
    reject: "Không được phê duyệt",
    deny: "bạn đồng hành",
    approveBtn: "Chấp nhận",
    rejectBtn: "Từ chối",
  },
  video_detail: {
    title: "Thông tin video",
    videoTitle: "Tiêu đề",
    link: "Link sản phẩm",
    nickname: "Tên Influencer",
    productCode: "Mã sản phẩm",
    productName: "Tên sản phẩm",
    amount: "Mã sản phẩm",
    img: "Hình ảnh sản phẩm",
    approveBtn: "Chấp nhận",
    rejectBtn: "Từ chối",
  },

  category: {
    title: "Quản lý danh mục",
    userCount: "Tổng số mục",
    delBtn: "Xóa các mục đã chọn",
    activeImage: "Danh mục Hình ảnh Hoạt động",
    inactiveImage: "Danh mục Hình ảnh không hoạt động",
    categoryName: "Tên danh mục",
    type: "Đơn vị",
    topCategory: "Mục",
    state: "Trạng thái",
    more: "Xem chi tiết",
    del: "Xóa",
    edit: "Chỉnh sửa",
    category1: "Danh mục cấp 1",
    category2: "Danh mục cấp 2",
  },

  return: {
    title: "Quản lý lịch sử trả hàng",
    returnCount: "Tổng số mục",
    cancelBtn: "Xử lý hủy",
    refundBtn: "Xử lý hoàn tiền",
    exchangeBtn: "Xử lý đổi hàng",
    returnBtn: "Xử lý trả hàng",
    orderNum: "Số đơn hàng/ngày",
    name: "Tên người đặt hàng",
    productName: "Tên sản phẩm",
    amount: "Số tiền đơn hàng",
    state: "Trạng thái",
    returnMore: "Xem chi tiết",
    orderForm: "Xem đơn hàng",
    pickupIng: "Đang xử lý",
    pickupComplete: "Đã xử lý",
    returnComplete: "Đã trả hàng",
    returnReject: "Đã từ chối",
  },

  return_detail: {
    title: "Chi tiết yêu cầu trả hàng",
    createdAt: "Ngày yêu cầu",
    reason: "Lý do đổi/trả",
    direction: "Hướng xử lý",
    directionDetail: "Hoàn tiền sau khi trả lại",
    deliveryAddress: "Địa chỉ giao hàng",
    deliveryDate: "Ngày dự kiến giao hàng",
    recoveryAddress: "Địa chỉ thu hồi sản phẩm",
    recoveryDate: "Ngày dự kiến thu hồi",
    memo: "Ghi chú",
  },

  exchange: {
    title: "Quản lý lịch sử đổi hàng",
    exchangeCount: "Tổng số mục",
    cancelBtn: "Xử lý hủy",
    refundBtn: "Xử lý hoàn tiền",
    exchangeBtn: "Xử lý đổi hàng",
    returnBtn: "Xử lý trả hàng",
    orderNum: "Mã đơn hàng",
    name: "Tên người đặt hàng",
    productName: "Tên sản phẩm",
    amount: "Số tiền đơn hàng",
    state: "trạng thái trả lại",
    exchangeMore: "Xem chi tiết",
    orderForm: "Xem đơn hàng",
    pickupIng: "Đang thu hồi",
    pickupComplete: "Thu hồi hoàn tất",
    pending: "Đang tạm hoãn",
    resend: "Gửi lại sản phẩm",
    exchangeComplete: "Hoàn tất đổi hàng",
    exchangeReject: "Từ chối đổi hàng",
  },

  exchange_detail: {
    title: "Chi tiết đổi hàng",
    createdAt: "Ngày yêu cầu",
    reason: "Lý do đổi hàng / trả hàng",
    direction: "Hướng xử lý",
    directionDetail: "trao đổi",
    deliveryAddress: "Địa chỉ giao hàng",
    deliveryDate: "Ngày dự kiến giao hàng",
    recoveryAddress: "Địa chỉ thu hồi hàng",
    recoveryDate: "Ngày dự kiến thu hồi",
  },

  shipping: {
    title: "Quản lý vận chuyển",
    shippingCount: "Tổng số mục",
    cancelBtn: "Xử lý hủy",
    refundBtn: "Xử lý hoàn tiền",
    exchangeBtn: "Xử lý đổi hàng",
    returnBtn: "Xử lý trả hàng",
    orderNum: "Số đơn hàng/ngày",
    name: "Tên người đặt hàng",
    productName: "Tên sản phẩm",
    amount: "Số tiền đơn hàng",
    state: "Trạng thái",
    orderForm: "Xem đơn hàng",
    orderComplete: "Đơn hàng hoàn tất",
    ready: "Chuẩn bị vận chuyển",
    ing: "Đang vận chuyển",
    complete: "Hoàn tất vận chuyển",
    waybillNumber: "mã vận chuyển",
    count: "số lượng",
  },

  cancel: {
    title: "Quản lý trả hàng / hoàn tiền",
    cancelCount: "Tổng số mục",
    cancelBtn: "Xử lý hủy",
    refundBtn: "Xử lý hoàn tiền",
    cancelBtn: "Xử lý đổi hàng",
    returnBtn: "Xử lý trả hàng",
    orderNum: "Số đơn hàng/ngày",
    name: "Tên người đặt hàng",
    productName: "Tên sản phẩm",
    amount: "Số tiền đặt hàng",
    state: "Trạng thái hủy/hoàn tiền",
    cancelMore: "Xem chi tiết",
    orderForm: "Xem đơn hàng",
    canceling: "Yêu cầu hủy",
    canceled: "Đã hủy",
    refunding: "Đang hoàn tiền",
    refunded: "Đã hoàn tiền",
  },

  cancel_detail: {
    title: "Chi tiết hủy/hoàn tiền",
    createdAt: "Ngày hủy",
    reason: "Lý do hủy",
    amount: "Số tiền đơn hàng",
    deleveryFee: "Phí vận chuyển",
    coupon: "Coupon",
    point: "Điểm",
    refundFee: "Số tiền hoàn lại",
    couponReturn: "Hoàn lại coupon",
    pointReturn: "Hoàn lại điểm",
  },

  order_modal: {
    title: "Đơn đặt hàng",
    orderNumber: "Mã đơn hàng",
    orderDay: "Ngày đặt hàng",
    payMethod: "Phương thức thanh toán",
    deleveryInfo: "Thông tin giao hàng",
    customer: "Thông tin người đặt hàng",
    orderHistory: "Lịch sử đặt hàng",
    option: "Tùy chọn",
    state: "Trạng thái đơn hàng",
    count: "Số lượng",
    amount: "Số tiền đơn hàng",
    deleveryFee: "Phí vận chuyển",
    sum: "Tổng cộng",
    coupon: "Mã giảm giá",
    point: "Điểm",
    total: "Tổng số tiền thanh toán",
    recipient: "Người nhận",
    koviet: "Công ty Cổ phần Koviet",
  },

  review: {
    title: "Quản lý đánh giá",
    reviewCount: "Lịch sử đánh giá",
    email: "E-mail",
    content: "Nội dung",
    rating: "Đánh giá",
    createdAt: "Ngày viết",
    del: "Xóa",
    more: "Xem chi tiết",
  },

  review_detail: {
    title: "Chi tiết đánh giá",
    product: "Thông tin sản phẩm",
    image: "Hình ảnh",
    state: "Trạng thái",
    name: "Tên",
  },

  coupon_add: {
    tab1: "Phát hành mã",
    tab2: "Lịch sử phát hành",
    title: "Quản lý coupon",
    addTitle: "Phát hành",
    inputTitle1: "Hình thức",
    inputTitle2: "Thành viên đã chọn",
    inputTitle3: "Tên mã",
    inputTitle4: "Loại",
    inputTitle5: "Số tiền giảm giá",
    inputTitle6: "Thời gian sử dụng",
    radio1: "Chỉ định",
    radio2: "Tất cả",
    radio3: "Giảm giá theo số tiền",
    radio4: "Giảm giá theo %",
    radio5: "Miễn phí vận chuyển",
    addBtn: "Phát hành",
    userCount: "Số thành viên đã chọn",
    email: "E-mail",
    birth: "Ngày sinh",
    caption1: "날짜 검색 입력 시 숫자 4글자로 작성합니다.",
    caption2: "예: 07/10 은 0710으로 입력 시 검색 결과 출력",
  },

  coupon_history: {
    tab1: "Phát hành coupon",
    tab2: "Lịch sử phát hành",
    title: "Lịch sử phát hành",
    userCount: "Lịch sử phát hành coupon",
    email: "E-mail",
    couponName: "Tên coupon",
    type: "Loại",
    createdAt: "Ngày phát hành",
    del: "Hủy phát hành",
    more: "Xem chi tiết",
    type1: "phát hành được chỉ định",
    type2: "được xuất bản đầy đủ",
  },

  coupon_detail: {
    title: "Chi tiết lịch sử phát hành coupon",
    type: "Loại",
    email: "Thành viên đã chọn",
    couponName: "Tên coupon",
    discountType: "Loại hình",
    amount: "Số tiền giảm giá",
    period: "Thời gian sử dụng",
    del: "Hủy phát hành",
    complete: "Hoàn tất sử dụng",
    cancelComplete: "Việc hủy hoàn tất",
  },

  exhibition: {
    title: "Quản lý triển lãm",
    userCount: "Tổng số mục",
    delBtn: "Xóa các mục đã chọn",
    tableTitle: "Tên khuyến mãi",
    period: "Thời gian",
    state: "Trạng thái đăng",
    del: "Xóa",
    more: "Xem chi tiết",
  },

  exhibition_detail: {
    more: "Xem chi tiết",
    title: "Tên Khuyến mãi",
    thumbnail: "Hình ảnh thu nhỏ triển lãm",
    pcImg: "Hình ảnh banner PC",
    mobileImg: "Hình ảnh banner di động",
    period: "Thời gian",
    state: "Trạng thái đăng",
    tableTitle: "Sản phẩm liên quan",
    productName: "Tên sản phẩm",
    category: "Danh mục",
    amount: "Giá sản phẩm",
    saleState: "Trạng thái",
    productState: "Hàng còn",
    onSale: "Đang bán",
    stopSell: "Ngừng bán",
    recommendSize: "Kích thước khuyến nghị",
  },

  exhibition_modal: {
    title: "Chi tiết sản phẩm",
    category: "Phân loại",
    brand: "Thương hiệu",
    amount: "Giá sản phẩm",
    productType: "Phân loại",
    image: "Hình ảnh sản phẩm",
    count: "Số lượng hàng tồn kho",
    productCode: "Mã sản phẩm",
  },

  product: {
    title: "Danh sách sản phẩm",
    productCount: "Tổng số sản phẩm",
    name: "Tên sản phẩm",
    category: "Phân loại",
    amount: "Giá sản phẩm",
    state: "Trạng thái",
    productState: "Kho hàng",
    more: "Xem chi tiết",
    add: "Đăng ký sản phẩm",
    ing: "Hoạt động",
    stop: "Ngừng hoạt động",
    soldoutAll: "Hết hàng",
    soldoutSome: "Hết hàng một phần",
    normal: "Bình thường",
  },

  product_detail: {
    delBtn: "Xóa",

    detail1: "Thông tin cơ bản",
    detail1_productName: "Tên sản phẩm",
    detail1_code: "Mã sản phẩm",
    detail1_state: "Trạng thái",
    detail1_img: "Hình ảnh sản phẩm",
    detail1_img_upload: "Giới hạn tối đa là 6.",
    detail1_content: "Mô tả sản phẩm",
    detail1_show: "Xem trước",

    detail2: "Thông tin sản phẩm",
    detail2_manufacturer: "Nhà sản xuất",
    detail2_brand: "Thương hiệu",
    detail2_origin: "Xuất xứ",
    detail2_type: "Phân loại",
    detail2_weight: "Trọng lượng",

    detail3: "Cài đặt giá",
    detail3_salePrice: "Giá bán",
    detail3_originPrice: "Giá gốc",

    detail4: "Vận chuyển",
    detail4_deliveryFee: "Phí vận chuyển",
    detail4_bundle: "Giao hàng chung",
    detail4_company: "Công ty giao hàng/Xuất hàng",

    detail5: "Cài đặt phân loại",
    detail5_category: "Phân loại",
    detail5_exhibition: "Khuyến mãi đặc biệt",
    detail5_badge: "Xếp loại sản phẩm",

    option: "Tùy chọn",
    optionCheck: "Tùy chọn",
    required: "sự cần thiết hay không",
    isSale: "có bán hay không",
    optionYes: "Có",
    optionNo: "Không",
    optionValue: "Giá trị tùy chọn",
    optionPrice: "Giá tùy chọn",
    count: "Số lượng tồn kho",

    optionName: "Tên tùy chọn",
    setting: "Cài đặt",
  },

  product_add: {
    title: "Đăng ký sản phẩm",
    money: "won",
    directlyEnter: "Nhập tay",
    able: "Có",
    disable: "Không",
    best: "BEST",
    new: "NEW",
    md: "MD PICK",
    event: "SALE",
    required: "Thiết yếu",
    choice: "Chọn lựa",
    noOption: "Không có tùy chọn",
    add: "Đăng ký sản phẩm",
    edit: "Chỉnh sửa sản phẩm",
    caption: "Sau khi đăng ký sản phẩm, bạn có thể cài đặt tùy chọn ở dưới cùng.",
    caption02: "Bạn có thể cài đặt tùy chọn ở dưới cùng sau khi sửa đổi sản phẩm.",
    option: "Tùy chọn",
  },
};
