import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import SaveBtn from "../../component/atoms/button/saveBtn";
import MainInput from "../../component/atoms/input/mainInput";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../component/atoms/layout/mainLayout";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../component/molecules/goBack";
import Header from "../../component/organisms/header";
import Sidebar from "../../component/organisms/sidebar";
import Modal from "../../component/templates/Modal";
import { isNull } from "../../util/check";
import { errorHandler } from "../../util/errorHandler";
import { regExpression } from "../../util/regExpresstion";
// import ManagerApi from '../../api/manager.api';

function AdminEdit(props) {
  const { t } = useTranslation();

  // import
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getManagerInfo();
  }, []);

  // ----- 정보 가져오기
  const [managerInfo, setManagerInfo] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
    passwordCheck: "",
    passwordChangeFlag: false,
  });

  const getManagerInfo = async () => {
    try {
      // const response = await ManagerApi.getById(id);
      // const data = response.data.data.content;
      // setManagerInfo({
      //   ...data,
      //   password: '',
      //   passwordCheck: '',
      //   passwordChangeFlag: false,
      // });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 비밀번호 변경
  const [pwEdit, setPwEdit] = useState(false);

  // 비밀번호 체크
  const pwErr = useMemo(() => {
    if (
      !isNull(managerInfo.password) &&
      !regExpression.password.test(managerInfo.password)
    ) {
      return true;
    }
    return false;
  }, [managerInfo.password]);

  // 비밀번호 확인 체크
  const pwCheckErr = useMemo(() => {
    if (
      !isNull(managerInfo.password) &&
      !isNull(managerInfo.passwordCheck) &&
      managerInfo.password !== managerInfo.passwordCheck
    ) {
      return true;
    }
    return false;
  }, [managerInfo.password, managerInfo.passwordCheck]);

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (isNull(managerInfo.name)) {
        setSaveModalOpen(false);
        return toast(t("toastMsg.input_empty"));
      }
      if (pwEdit && isNull(managerInfo.password)) {
        setSaveModalOpen(false);
        return toast(t("toastMsg.input_pw"));
      }
      if (pwEdit && isNull(managerInfo.passwordCheck)) {
        setSaveModalOpen(false);
        return toast(t("toastMsg.input_pw"));
      }
      if (pwErr) {
        setSaveModalOpen(false);
        return toast(t("toastMsg.check_pwFormat"));
      }
      if (pwCheckErr) {
        setSaveModalOpen(false);
        return toast(t("toastMsg.check_pw"));
      }

      const requestDto = {
        email: managerInfo.email,
        name: managerInfo.name,
        role: managerInfo.role,
        accessMenu: managerInfo.accessMenu,
        passwordChangeFlag: pwEdit,
        password: managerInfo.password,
      };

      // await ManagerApi.update(id, requestDto);
      toast(t("toastMsg.update"));
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "375px" }}>
            <DetailPageTitle>{t("manager.edit")}</DetailPageTitle>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginBottom: "79px",
              }}
            >
              <DetailPageForm>
                <DetailFormTitle>{t("manager.name")}</DetailFormTitle>
                <MainInput
                  style={{ width: "338px" }}
                  type={"text"}
                  placeholder={t("placeholder.namePlaceholder")}
                  value={managerInfo.name}
                  onChange={(e) =>
                    setManagerInfo({ ...managerInfo, name: e.target.value })
                  }
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("manager.email")}</DetailFormTitle>
                <DetailFormText>{managerInfo.email}</DetailFormText>
              </DetailPageForm>
              {pwEdit ? (
                <>
                  <DetailPageForm>
                    <DetailFormTitle>{t("manager.pw")}</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"password"}
                      placeholder={t("placeholder.pwPlaceholder")}
                      helperText={t("auth.pwCaption")}
                      onError={pwErr}
                      value={managerInfo.password}
                      onChange={(e) => {
                        setManagerInfo({
                          ...managerInfo,
                          password: e.target.value,
                        });
                      }}
                    />
                  </DetailPageForm>
                  <DetailPageForm>
                    <DetailFormTitle>{t("manager.pwCheck")}</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"password"}
                      placeholder={t("placeholder.pwCheckPlaceholder")}
                      helperText={t("auth.pwError")}
                      onError={pwCheckErr}
                      value={managerInfo.passwordCheck}
                      onChange={(e) => {
                        setManagerInfo({
                          ...managerInfo,
                          passwordCheck: e.target.value,
                        });
                      }}
                    />
                  </DetailPageForm>
                </>
              ) : (
                <DetailPageForm>
                  <DetailFormTitle>{t("manager.pw")}</DetailFormTitle>
                  <PwEditBtn
                    onClick={() => {
                      setPwEdit(true);
                      managerInfo.passwordChangeFlag = true;
                      setManagerInfo({ ...managerInfo });
                    }}
                  >
                    {t("manager.pwEditBtn")}
                  </PwEditBtn>
                </DetailPageForm>
              )}
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>
      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default AdminEdit;

const PwEditBtn = styled.button.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #b8bfc4;
  background-color: transparent;
  color: #808991;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
`;
