import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { toast } from "react-toastify";
import { errorHandler } from "../../../util/errorHandler";
import { toastMsg } from "../../../util/toastMsg";
import CouponApi from "./_api/coupon.api";

function CouponModal({ data, setModalOpen, listHandler }) {
  const { t } = useTranslation();

  const [couponData, setCouponData] = useState(data);

  // 사용완료
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      const data = {
        ids: [couponData.id],
      };
      await CouponApi.usedCoupon(data);
      toast(t("toastMsg.update"));
      setSaveModalOpen(false);
      setCouponData({ ...couponData, couponStatus: "USED" });
      listHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 취소
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const cancelFunc = async () => {
    try {
      const data = {
        ids: [couponData.id],
      };
      await CouponApi.cancelCoupon(data);
      toast(t("toastMsg.update"));
      setCancelModalOpen(false);
      setCouponData({ ...couponData, couponStatus: "CANCEL" });
      listHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <ModalWrapper>
        <ModalBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <TableTitle style={{ marginBottom: "0px" }}>
              {t("coupon_detail.title")}
            </TableTitle>
            <img
              style={{
                cursor: "pointer",
              }}
              src="/assets/admin/icons/ico_popup_close.svg"
              alt=""
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              minHeight: "223px",
              overflowY: "auto",
            }}
          >
            <DetailPageForm>
              <DetailFormTitle>{t("coupon_detail.type")}</DetailFormTitle>
              <DetailFormText>
                {couponData.couponForm === "INDIVIDUAL"
                  ? t("coupon_add.radio1")
                  : t("coupon_add.radio2")}
              </DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("coupon_detail.email")}</DetailFormTitle>
              <DetailFormText>{couponData.email}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("coupon_detail.couponName")}</DetailFormTitle>
              <DetailFormText>{couponData.coupon?.title}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>
                {t("coupon_detail.discountType")}
              </DetailFormTitle>
              <DetailFormText>
                {couponData.coupon?.couponType === "RATIO"
                  ? t("coupon_add.radio3")
                  : couponData.coupon?.couponTypeTitle === "RATIO"
                  ? t("coupon_add.radio4")
                  : t("coupon_add.radio5")}
              </DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("coupon_detail.amount")}</DetailFormTitle>
              <DetailFormText>{`${couponData.coupon?.amount}${
                couponData.coupon?.couponType === "RATIO"
                  ? "%"
                  : t("component.monetary")
              }`}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("coupon_detail.period")}</DetailFormTitle>
              <DetailFormText>{`${moment(couponData.startDate).format(
                "YYYY/MM/DD"
              )} ~ ${moment(couponData.endDate).format(
                "YYYY/MM/DD"
              )}`}</DetailFormText>
            </DetailPageForm>
          </div>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            {couponData.couponStatus === "POSSIBLE" ? (
              <SaveBtn
                style={{
                  position: "relative",
                  background: "#fff",
                  color: "#636C73",
                }}
                text={t("coupon_detail.del")}
                onClick={() => setCancelModalOpen(true)}
              />
            ) : undefined}

            {couponData.couponStatus === "POSSIBLE" ? (
              <SaveBtn
                style={{ position: "relative" }}
                text={t("coupon_detail.complete")}
                onClick={() => setSaveModalOpen(true)}
              />
            ) : undefined}
          </div>
        </ModalBox>
      </ModalWrapper>
      {saveModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.couponComplete.title")}`}
          text={`${t("modalMsg.couponComplete.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}

      {cancelModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.couponCancel.title")}`}
          text={`${t("modalMsg.couponCancel.content")}`}
          setModalOpen={setCancelModalOpen}
          onClick={() => {
            cancelFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default CouponModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 505px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
