import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

function EditBtn({ style, text, onClick }) {
  const { t } = useTranslation();

  return (
    <>
      <EditBtnWrapper style={{ ...style }} onClick={onClick}>
        {text ?? `${t("component.edit")}`}
      </EditBtnWrapper>
    </>
  );
}

export default EditBtn;

const EditBtnWrapper = styled.button.attrs((props) => {})`
  display: flex;
  height: 42px;
  padding: 10px 14px;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  background: #262c31;
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.048px;
  color: #fff;
  cursor: pointer;
`;
