import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
// import ExcelApi from "../../../api/excel.api";
// import MemberApi from "../../../api/memebr.api";
import styled from "styled-components";
import { fileUrl } from "../../../api/api";
import OrderApi from "../../../api/order.api";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import DateInput02 from "../../../component/atoms/input/dateInput02";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import OrderModal from "../orderModal";
import CancelModal from "./cancelModal";
import ExcelApi from "../../../api/excel.api";
import { saveAs } from "file-saver";

const Cancel = () => {
  const { t, i18n } = useTranslation();
  const isVn = i18n.language === "VN";

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getCancelList();
  }, [location]);

  const [cancelList, setCancelList] = useState([1]);
  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getCancelList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
        orderStatus: "CANCEL",
        startDate,
        endDate,
      };
      let responseData = (await OrderApi.getList(data)).data.data;

      setCancelList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
            memberMerches: item.memberMerches.map((item2) => {
              return {
                ...item2,
                optionInfo: item2.optionInfo ? JSON.parse(item2.optionInfo) : [],
                optionInfoString: item2.optionInfo ? getOptionInfoString(JSON.parse(item2.optionInfo)) : "",
              };
            }),
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const getOptionInfoString = (optionInfo) => {
    let optionInfoString = "";

    for (let index = 0; index < optionInfo.length; index++) {
      const element = optionInfo[index];
      const option = element.option;
      const optionValue = element.optionValue;

      if (index != 0) {
        optionInfoString += " / ";
      }
      optionInfoString += `${option.title}: ${optionValue.title}, ${t("shipping.count")}: ${optionValue.quantity}`;
    }

    return optionInfoString;
  };

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const [title, setTitle] = useState(`${t("cancel.title")}`);
  const [subTitle, setSubTitle] = useState(`${t("cancel.cancelCount")}`);

  // ------------엑셀 생성
  const CANCEL_STATUS_INFOS = [
    { value: "CANCEL_REQUESTED", title: t("cancel.canceling") },
    { value: "CANCEL_COMPLETED", title: t("cancel.canceled") },
    { value: "REFUND_REQUESTED", title: t("cancel.refunding") },
    { value: "REFUND_COMPLETED", title: t("cancel.refunded") },
  ];

  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: t("cancel.orderNum"), key: "orderNumber", width: 40 },
      { header: t("cancel.name"), key: "name", width: 40 },
      { header: t("cancel.amount"), key: "payAmount", width: 40 },
      { header: t("cancel.state"), key: "state", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${t("cancel.title")}.xlsx`);
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetOrderExcel({
          ids: [],
          allFlag: true,
          orderStatus: "CANCEL",
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          orderNumber: `${item.orderNumber}/${createDate}`,
          payAmount: item.payAmount?.toLocaleString(),
          state: CANCEL_STATUS_INFOS.find((info) => info.value === item.cancelStatus)?.title,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = cancelList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast(t("placeholder.selectItem"));
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetOrderExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
          orderStatus: "CANCEL",
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          orderNumber: `${item.orderNumber}/${createDate}`,
          payAmount: item.payAmount?.toLocaleString(),
          state: CANCEL_STATUS_INFOS.find((info) => info.value === item.cancelStatus)?.title,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  const [selectedData, setSelectedData] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  const isAllCheck = useMemo(() => {
    const checkedList = cancelList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === cancelList.length;
  }, [cancelList]);

  // ----- 취소/환불상태
  const [orderfilterOpen, setOrderfilterOpen] = useState(-1);
  const [orderId, setOrderId] = useState(-1);
  const [orderType, setOrderType] = useState("");
  const [changeModalOpen, setChangeModalOpen] = useState(false);

  const changeOrder = async () => {
    try {
      const data = {
        cancelStatus: orderType,
      };

      await OrderApi.changeOrder(orderId, data);
      toast(t("toastMsg.update"));
      setOrderfilterOpen(-1);
      setChangeModalOpen(false);
      setOrderId(-1);
      setOrderType("");
      getCancelList();
    } catch (error) {
      errorHandler(error);
    }
  };

  const [selectedOrderData, setSelectedOrderData] = useState({});
  const [orderModalOpen, setOrderModalOpen] = useState(false);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{title}</TableTitle>
          <TableTop style={{ marginBottom: "10px" }}>
            <TableCount text={subTitle} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.cancel}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.cancel}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <ExcelBtn onSelectClick={getExcelChecked} onAllClick={getExcelAll} />
            </TableSearchBtn>
          </TableTop>
          <TableTop>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <TopBtn>{t("cancel.cancelBtn")}</TopBtn>
              <TopBtn>{t("cancel.refundBtn")}</TopBtn>
              <TopBtn>{t("cancel.cancelBtn")}</TopBtn>
              <TopBtn>{t("cancel.returnBtn")}</TopBtn> */}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <DateInput02
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
                <div
                  style={{
                    height: "1px",
                    width: "9px",
                    backgroundColor: "#7d7d7d",
                  }}
                />
                <DateInput02
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </div>
              <SearchBtn
                onClick={() => {
                  navigate(`${route.cancel}?page=1&keyword=${searchParams.get("keyword") || ""}&startDate=${startDate}&startDate=${endDate}`, {
                    replace: true,
                  });
                }}
              >
                검색
              </SearchBtn>
            </div>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 */}
              <col width={isVn ? "60px" : "73px"} />
              {/* 주문번호/일시 */}
              <col width={isVn ? "136px" : "152px"} />
              {/* 주문자명 */}
              <col width={isVn ? "116px" : "88px"} />
              {/* 상품명 */}
              <col width={isVn ? "194px" : "265px"} />
              {/* 주문 금액 */}
              <col width={isVn ? "141px" : "151px"} />
              {/* 반품 상태 */}
              <col width={isVn ? "180px" : "120px"} />
              {/* 반품 내역 상세 */}
              <col width={isVn ? "81px" : "120px"} />
              {/* 주문서 보기 */}
              <col width={isVn ? "103px" : "84px"} />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCancelList(
                          cancelList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setCancelList(
                          cancelList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t("cancel.orderNum")}</TableText>
                </Th>
                <Th>
                  <TableText style={{ textAlign: "center", paddingRight: "39px" }}>{t("cancel.name")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("cancel.productName")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("cancel.amount")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("cancel.state")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("cancel.cancelMore")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("cancel.orderForm")}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {cancelList.map((item, index) => {
                return (
                  <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setCancelList([...cancelList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <div>
                        <TableText style={{ textDecoration: "underline" }}>{item.orderNumber}</TableText>
                        <TableText style={{ fontWeight: "300" }}>{moment(item.createdAt).format("YYYY-MM-DD hh:mm")}</TableText>
                      </div>
                    </Td>
                    <Td>
                      <TableText style={{ textAlign: "center", paddingRight: "44px" }}>{item.name}</TableText>
                    </Td>
                    <Td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "3px",
                            }}
                            src={`${fileUrl}/${item.memberMerches ? item.memberMerches[0]?.imageServerFileName : ""}`}
                            alt=""
                          />
                        </div>
                        <div>
                          <TableText
                            style={{
                              marginBottom: "4px",
                              width: isVn ? "140px" : "181px",
                            }}
                          >
                            {item.memberMerches ? item.memberMerches[0]?.title : ""}
                          </TableText>
                          <TableText
                            style={{
                              fontSize: "12px",
                              lineHeight: "16px",
                              width: isVn ? "140px" : "181px",
                            }}
                          >
                            {item.memberMerches ? item.memberMerches[0]?.optionInfoString : ""}
                          </TableText>
                        </div>
                      </div>
                    </Td>
                    <Td>
                      <TableText>
                        {item.payAmount?.toLocaleString()}
                        {t("component.monetary")}
                      </TableText>
                    </Td>
                    <Td>
                      <TableFilter
                        subStyle={{
                          width: isVn ? "220px" : "140px",
                        }}
                        onClick={() => {
                          if (item.cancelStatus === "REFUND_COMPLETED") {
                            return;
                          }

                          orderfilterOpen === index ? setOrderfilterOpen(-1) : setOrderfilterOpen(index);
                        }}
                        isOpen={orderfilterOpen === index}
                        selectedValue={item.cancelStatus}
                        list={[
                          {
                            value: "CANCEL_REQUESTED",
                            title: `${t("cancel.canceling")}`,
                            onClick: () => {
                              setOrderId(item.id);
                              setOrderType("CANCEL_REQUESTED");
                              setChangeModalOpen(true);
                            },
                          },
                          {
                            value: "CANCEL_COMPLETED",
                            title: `${t("cancel.canceled")}`,
                            onClick: () => {
                              setOrderId(item.id);
                              setOrderType("CANCEL_COMPLETED");
                              setChangeModalOpen(true);
                            },
                          },
                          {
                            value: "REFUND_REQUESTED",
                            title: `${t("cancel.refunding")}`,
                            onClick: () => {
                              setOrderId(item.id);
                              setOrderType("REFUND_REQUESTED");
                              setChangeModalOpen(true);
                            },
                          },
                          {
                            value: "REFUND_COMPLETED",
                            title: `${t("cancel.refunded")}`,
                            onClick: () => {
                              setOrderId(item.id);
                              setOrderType("REFUND_COMPLETED");
                              setChangeModalOpen(true);
                            },
                          },
                        ]}
                        setFilterOpen={setOrderfilterOpen}
                      />
                    </Td>
                    <Td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingRight: "40px",
                        }}
                      >
                        <DetailBtn
                          imgHide
                          style={{
                            background: "#fff",
                            border: "1px solid #778088",
                            color: "#778088",
                          }}
                          onClick={() => {
                            setSelectedData(item);
                            setDetailModalOpen(true);
                          }}
                        />
                      </div>
                    </Td>
                    <Td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingRight: "15px",
                        }}
                      >
                        <DetailBtn
                          imgHide
                          onClick={() => {
                            setSelectedOrderData(item);
                            setOrderModalOpen(true);
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.cancel}
            queryString={`keyword=${searchParams.get("keyword") || ""}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>
      {detailModalOpen ? <CancelModal data={selectedData} setModalOpen={setDetailModalOpen} /> : ""}

      {orderModalOpen ? <OrderModal data={selectedOrderData} setModalOpen={setOrderModalOpen} /> : ""}

      {changeModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          setModalOpen={setChangeModalOpen}
          onClick={() => {
            changeOrder();
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Cancel;

const TopBtn = styled.button.attrs((props) => {})`
  display: flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid #778088;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.75px;
  color: #778088;
  cursor: pointer;
`;

const SearchBtn = styled.button.attrs((props) => {})`
  display: flex;
  height: 29px;
  align-items: center;
  justify-content: center;
  padding: 3px 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.75px;
  color: #4b4d4c;
  cursor: pointer;
`;
