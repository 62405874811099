import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import BannerApi from "../../api/banner.api";
import { useTranslation } from "react-i18next";
import { fileUrl } from "../../../api/api";
import AddBtn from "../../../component/atoms/button/addBtn";
import DelBtn from "../../../component/atoms/button/delBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import ImgInput from "../../../component/atoms/input/imgInput";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import PretendardText from "../../../component/atoms/text/pretendardText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { isNull } from "../../../util/check";
import { errorHandler } from "../../../util/errorHandler";
import { fileUpload } from "../../../util/fileManagement";
import BannerApi from "./_api/banner.api";

const Banner = () => {
  const { t } = useTranslation();

  const [list, setList] = useState([]);

  const getList = async () => {
    try {
      let responseData = (await BannerApi.getMainBanner()).data.data.content;
      setList(
        responseData.map((item) => {
          return {
            ...item,
            fileUrlPc: item.imageServerFileNamePc
              ? `${fileUrl}/${item.imageServerFileNamePc}`
              : "",
            fileUrlMo: item.imageServerFileNameMo
              ? `${fileUrl}/${item.imageServerFileNameMo}`
              : "",
          };
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  //이미지 삭제
  const delImg = (index, type) => {
    const copyArr = [...list];
    const obj = copyArr[index];
    if (obj) {
      if (type === "pc") {
        obj.filePc = "";
        obj.fileUrlPc = "";
        obj.imageOriginFileNamePc = "";
        obj.imageServerFileNamePc = "";
      } else {
        obj.fileMo = "";
        obj.fileUrlMo = "";
        obj.imageOriginFileNameMo = "";
        obj.imageServerFileNameMo = "";
      }
    }

    copyArr[index] = obj;
    setList(copyArr);
  };

  //이미지 추가
  const inputFile = (e, index, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      const copyArr = [...list];
      const obj = copyArr[index];

      if (type === "pc") {
        obj.filePc = file;
        obj.fileUrlPc = value.target.result;
      } else {
        obj.fileMo = file;
        obj.fileUrlMo = value.target.result;
      }

      setList(copyArr);
    };
    e.target.value = "";
  };

  // 추가하기
  const addBannerItem = async () => {
    const copyArr = [...list];
    const item = {
      file: "",
      fileUrlPc: "",
      fileUrlMo: "",
      imageOriginFileNamePc: "",
      imageServerFileNamePc: "",
      imageOriginFileNameMo: "",
      imageServerFileNameMo: "",
      link: "",
      newWindow: false,
    };
    const id = await addBanner();
    item.id = id;
    copyArr.push(item);
    setList(copyArr);
  };

  const addBanner = async (banner) => {
    try {
      const data = {
        imageOriginFileNamePc: "",
        imageServerFileNamePc: "",
        imageOriginFileNameMo: "",
        imageServerFileNameMo: "",
        link: "",
        newWindow: false,
      };
      let responseData = (await BannerApi.createMainBanner(data)).data.data
        .content;

      return responseData.id;
    } catch (error) {
      errorHandler(error);
    }
  };

  // 삭제하기
  const [delIndex, setDelIndex] = useState(-1);
  const [delModalOpen, setDelModalOpen] = useState(false);
  const delBanner = async () => {
    try {
      const obj = list[delIndex];
      const data = {
        ids: [obj.id],
      };

      await BannerApi.deleteMainBanner(data);

      toast(t("toastMsg.delete"));
      setDelModalOpen(false);
      setDelIndex(-1);
      list.splice(delIndex, 1);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 저장하기
  const [editIndex, setEditIndex] = useState(-1);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const editBtnHandler = (index) => {
    const obj = list[index];

    if (isNull(obj.link)) {
      toast("t(toastMsg.input_empty)");
      return;
    }
    if (isNull(obj.imageOriginFileNamePc) && isNull(obj.filePc)) {
      toast("t(toastMsg.input_empty)");
      return;
    }
    if (isNull(obj.imageOriginFileNameMo) && isNull(obj.fileMo)) {
      toast("t(toastMsg.input_empty)");
      return;
    }

    setEditIndex(index);
    setEditModalOpen(true);
  };

  const editFunc = async () => {
    try {
      const obj = list[editIndex];

      const data = {
        imageOriginFileNamePc: obj.imageOriginFileNamePc,
        imageServerFileNamePc: obj.imageServerFileNamePc,
        imageOriginFileNameMo: obj.imageOriginFileNameMo,
        imageServerFileNameMo: obj.imageServerFileNameMo,
        link: obj.link,
        newWindow: obj.newWindow,
      };

      // 이미지 처리
      if (obj.filePc) {
        const { imageOriginFileName, imageServerFileName } = await fileUpload(
          obj.filePc,
          "banner/"
        );
        data.imageOriginFileNamePc = imageOriginFileName;
        data.imageServerFileNamePc = imageServerFileName;
      }

      if (obj.fileMo) {
        const { imageOriginFileName, imageServerFileName } = await fileUpload(
          obj.fileMo,
          "banner/"
        );
        data.imageOriginFileNameMo = imageOriginFileName;
        data.imageServerFileNameMo = imageServerFileName;
      }

      await BannerApi.updateMainBanner(obj.id, data);

      toast(t("toastMsg.update"));
      setEditModalOpen(false);
      setEditIndex(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <TableTitle>{t("banner.title")}</TableTitle>
          </DetailTop>

          <Tab
            list={[
              { title: `${t("banner.tab1")}`, url: route.banner_main },
              { title: `${t("banner.tab2")}`, url: route.banner_serve },
            ]}
            style={{ marginBottom: "20px" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 40,
            }}
          >
            {list.map((data, index) => (
              <DetailPage
                style={{
                  minHeight: "520px",
                }}
                key={index}
              >
                <DetailPageTitle>
                  {t("banner.slide") + (index + 1)}
                </DetailPageTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <DetailPageForm style={{ alignItems: "flex-start" }}>
                    <DetailFormTitle>
                      {t("banner.pc")}*
                      <br />
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "24.5px",
                          color: "#ADB4BA",
                        }}
                      >
                        ({t("banner.recommendSize")} 1080*380)
                      </span>
                    </DetailFormTitle>
                    {data.fileUrlPc ? (
                      <div style={{ position: "relative" }}>
                        <img
                          style={{
                            width: "90px",
                            height: "90px",
                            borderRadius: "3px",
                          }}
                          src={data.fileUrlPc}
                          alt=""
                        />
                        <img
                          src="/assets/admin/icons/x.png"
                          alt=""
                          style={{
                            position: "absolute",
                            top: 6,
                            right: 6,
                            cursor: "pointer",
                          }}
                          onClick={() => delImg(index, "pc")}
                        />
                      </div>
                    ) : (
                      <ImgInput
                        id={`file${index}`}
                        accept={"image/*"}
                        onChange={(e) => {
                          inputFile(e, index, "pc");
                        }}
                      />
                    )}
                  </DetailPageForm>
                  <DetailPageForm style={{ alignItems: "flex-start" }}>
                    <DetailFormTitle>
                      {t("banner.mobile")}*
                      <br />
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          lineHeight: "24.5px",
                          color: "#ADB4BA",
                        }}
                      >
                        ({t("banner.recommendSize")} 335*220)
                      </span>
                    </DetailFormTitle>
                    {data.fileUrlMo ? (
                      <div style={{ position: "relative" }}>
                        <img
                          style={{
                            width: "90px",
                            height: "90px",
                            borderRadius: "3px",
                          }}
                          src={data.fileUrlMo}
                          alt=""
                        />
                        <img
                          src="/assets/admin/icons/x.png"
                          alt=""
                          style={{
                            position: "absolute",
                            top: 6,
                            right: 6,
                            cursor: "pointer",
                          }}
                          onClick={() => delImg(index, "mo")}
                        />
                      </div>
                    ) : (
                      <ImgInput
                        id={`file${index}`}
                        accept={"image/*"}
                        onChange={(e) => {
                          inputFile(e, index, "mo");
                        }}
                      />
                    )}
                  </DetailPageForm>
                  <DetailPageForm>
                    <DetailFormTitle>{t("banner.link")}*</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"text"}
                      placeholder={t("placeholder.linkPlaceholder")}
                      value={data.link}
                      onChange={(e) => {
                        setList((prev) => {
                          const copyArr = [...prev];
                          copyArr[index].link = e.target.value;
                          return copyArr;
                        });
                      }}
                    />
                  </DetailPageForm>
                  <DetailPageForm>
                    <DetailFormTitle>{t("banner.newTab")}*</DetailFormTitle>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <Checkbox
                        checked={data.newWindow}
                        onChange={() => {
                          setList((prev) => {
                            const copyArr = [...prev];
                            copyArr[index].newWindow =
                              !copyArr[index].newWindow;
                            return copyArr;
                          });
                        }}
                      />
                      <PretendardText
                        style={{
                          color: "#808991",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {t("banner.goNewTab")}
                      </PretendardText>
                    </div>
                  </DetailPageForm>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 6,
                    marginTop: 30,
                  }}
                >
                  <DelBtn
                    onClick={() => {
                      setDelIndex(index);
                      setDelModalOpen(true);
                    }}
                  />
                  <AddBtn
                    text={t("component.save")}
                    onClick={() => {
                      editBtnHandler(index);
                    }}
                  />
                </div>
              </DetailPage>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <AddBtn
              onClick={() => {
                addBannerItem();
              }}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {delModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            delBanner();
          }}
        />
      ) : null}

      {editModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          btnTitle={"수정하기"}
          setModalOpen={setEditModalOpen}
          onClick={() => {
            editFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default Banner;
