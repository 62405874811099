import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import OrderApi from "../../../api/order.api";
import { errorHandler } from "../../../util/errorHandler";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import MainInput from "../../../component/atoms/input/mainInput";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";

function ReturnModal({ data, setModalOpen, listHandler }) {
  const { t } = useTranslation();

  const [detailData, setDetailData] = useState({});
  const [memo, setMemo] = useState("");
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [newFlag, setNewFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);

  // mounted
  useEffect(() => {
    getDetailData();
  }, []);

  const getDetailData = async () => {
    try {
      const response = await OrderApi.getExchangeDetail(data.id);
      setDetailData({
        ...response.data.data.content,
      });

      setNewFlag(isNull(response.data.data.content.memo));
    } catch (error) {
      errorHandler(error);
    }
  };

  const saveBtnHandler = () => {
    if (isNull(memo)) {
      return toast(`${t("toastMsg.input_empty")}`);
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const data = {
        memo: memo,
      };

      await OrderApi.updateMemo(detailData.id, data);
      toast(t("toastMsg.update"));
      setSaveModalOpen(false);
      setDetailData({ ...detailData, memo: memo });
      setEditFlag(false);
      listHandler();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <ModalWrapper>
        <ModalBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <TableTitle style={{ marginBottom: "0px" }}>{t("return_detail.title")}</TableTitle>
            <img
              style={{
                cursor: "pointer",
              }}
              src="/assets/admin/icons/ico_popup_close.svg"
              alt=""
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              minHeight: "223px",
              overflowY: "auto",
            }}
          >
            <DetailPageForm>
              <DetailFormTitle>{t("return_detail.createdAt")}</DetailFormTitle>
              <DetailFormText>{moment(detailData.createdAt).format("YYYY/MM/DD")}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("return_detail.reason")}</DetailFormTitle>
              <DetailFormText>{detailData.reason}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("return_detail.direction")}</DetailFormTitle>
              <DetailFormText>{t("return_detail.directionDetail")}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("return_detail.deliveryAddress")}</DetailFormTitle>
              <div>
                <DetailFormText>
                  {detailData.addressDelivery} {detailData.detailAddressDelivery}
                </DetailFormText>
                <DetailFormText>
                  {detailData.recipientDelivery} {detailData.phoneNumberDelivery}
                </DetailFormText>
              </div>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("return_detail.deliveryDate")}</DetailFormTitle>
              <DetailFormText>{moment(detailData.expectedDeliveryDate).format("YYYY/MM/DD")}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("return_detail.recoveryAddress")}</DetailFormTitle>
              <div>
                <DetailFormText>
                  {detailData.addressReturn} {detailData.detailAddressReturn}
                </DetailFormText>
                <DetailFormText>
                  {detailData.recipientReturn} {detailData.phoneNumberReturn}
                </DetailFormText>
              </div>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("return_detail.recoveryDate")}</DetailFormTitle>
              <DetailFormText>{moment(detailData.expectedReturnDate).format("YYYY/MM/DD")}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm style={{ alignItems: "flex-start" }}>
              {newFlag ? undefined : (
                <>
                  <DetailFormTitle>{t("return_detail.memo")}</DetailFormTitle>
                  {editFlag ? (
                    <MainInput
                      style={{
                        width: "730px",
                      }}
                      type={"text"}
                      placeholder={t("placeholder.contentPlaceholder")}
                      value={memo}
                      onChange={(e) => setMemo(e.target.value)}
                    />
                  ) : (
                    <DetailFormText>{detailData.memo}</DetailFormText>
                  )}
                </>
              )}
            </DetailPageForm>
            <SaveBtn
              text={newFlag ? `${t("component.memo")}` : editFlag ? `${t("component.save")}` : `${t("component.edit")}`}
              style={{
                position: "static",
                marginTop: "20px",
                width: "fit-content",
                alignSelf: "flex-end",
              }}
              onClick={() => {
                if (newFlag || !editFlag) {
                  setNewFlag(false);
                  setEditFlag(true);
                  setMemo(detailData.memo);
                } else if (editFlag) {
                  saveBtnHandler();
                }
              }}
            />
          </div>
        </ModalBox>
      </ModalWrapper>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
}

export default ReturnModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 505px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
