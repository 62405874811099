import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import BannerApi from "../../../api/banner.api";
import { useTranslation } from "react-i18next";
import AddBtn from "../../../component/atoms/button/addBtn";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import MainInput from "../../../component/atoms/input/mainInput";
import Radio from "../../../component/atoms/input/radio";
import TextArea from "../../../component/atoms/input/textarea";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import PretendardText from "../../../component/atoms/text/pretendardText";
import Modal from "../../../component/templates/Modal";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import Header from "./../../../component/organisms/header";
import Sidebar from "./../../../component/organisms/sidebar";
import SettingApi from "./_api/setting.api";
import DelBtn from "../../../component/atoms/button/delBtn";
import { isNull } from "../../../util/check";

const BuyTerms = () => {
  const { t } = useTranslation();

  const [list, setList] = useState([]);

  const getList = async () => {
    try {
      let responseData = (await SettingApi.getTermSetting()).data.data.content;
      setList(
        responseData.map((item) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  // 추가하기
  const addTermSettingItem = async () => {
    const copyArr = [...list];
    const item = {
      title: "",
      content: "",
      requiredFlag: true,
      activation: true,
    };
    const id = await SettingApi.createTermSetting(item);
    item.id = id;

    copyArr.push(item);
    setList(copyArr);
  };

  // 삭제하기
  const [delIndex, setDelIndex] = useState(-1);
  const [delModalOpen, setDelModalOpen] = useState(false);
  const delTermSetting = async () => {
    try {
      const obj = list[delIndex];
      const data = {
        ids: [obj.id],
      };

      await SettingApi.deleteTermSetting(data);

      toast(t("toastMsg.delete"));
      setDelModalOpen(false);
      setDelIndex(-1);
      list.splice(delIndex, 1);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 저장하기
  const [editIndex, setEditIndex] = useState(-1);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const editBtnHandler = (index) => {
    const obj = list[index];

    if (isNull(obj.title)) {
      toast(t("toastMsg.termTitleAdd"));
      return;
    }
    if (isNull(obj.content)) {
      toast(t("toastMsg.termContentAdd"));
      return;
    }

    setEditIndex(index);
    setEditModalOpen(true);
  };

  const editFunc = async () => {
    try {
      const obj = list[editIndex];

      const data = {
        title: obj.title,
        content: obj.content,
        requiredFlag: obj.requiredFlag,
        activation: obj.activation,
      };

      await SettingApi.updateTermSetting(obj.id, data);

      toast(t("toastMsg.update"));
      setEditModalOpen(false);
      setEditIndex(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <Tab
            list={[
              { title: `${t("setting.tab1")}`, url: route.setting },
              { title: `${t("setting.tab2")}`, url: route.transport },
              { title: `${t("setting.tab3")}`, url: route.point },
              { title: `${t("setting.tab4")}`, url: route.buy_terms },
            ]}
            style={{ marginBottom: "20px" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 40,
            }}
          >
            {list.map((data, index) => (
              <DetailPage
                style={{
                  minHeight: "532px",
                }}
                key={index}
              >
                <DetailPageTitle style={{ marginBottom: 5 }}>{t("buyTerms.title1")}</DetailPageTitle>
                <PretendardText
                  fontWeight="400"
                  style={{
                    color: "#5A636A",
                    fontSize: 15,
                    marginBottom: 30,
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {t("buyTerms.caption1")}
                </PretendardText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <DetailPageForm>
                    <DetailFormTitle>{t("buyTerms.inputTitle1")}*</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"text"}
                      placeholder={t("placeholder.defaultPlaceholder")}
                      value={data.title}
                      onChange={(e) => {
                        setList((prev) => {
                          const copyArr = [...prev];
                          copyArr[index].title = e.target.value;
                          return copyArr;
                        });
                      }}
                    />
                  </DetailPageForm>
                  <DetailPageForm style={{ alignItems: "flex-start" }}>
                    <DetailFormTitle>{t("buyTerms.inputTitle2")}*</DetailFormTitle>
                    <TextArea
                      style={{ height: "133px" }}
                      value={data.content}
                      placeholder={t("placeholder.defaultPlaceholder")}
                      onChange={(e) => {
                        setList((prev) => {
                          const copyArr = [...prev];
                          copyArr[index].content = e.target.value;
                          return copyArr;
                        });
                      }}
                    />
                  </DetailPageForm>
                  <DetailPageForm style={{ alignItems: "flex-start" }}>
                    <DetailFormTitle>{t("buyTerms.inputTitle3")}*</DetailFormTitle>
                    <div style={{ display: "flex", gap: "19px" }}>
                      <Radio
                        id={`activation1_${data.id}`}
                        valueText={t("component.required")}
                        name={`activation1_${data.id}`}
                        checked={data.requiredFlag}
                        onChange={() => {
                          setList((prev) => {
                            const copyArr = [...prev];
                            copyArr[index].requiredFlag = true;
                            return copyArr;
                          });
                        }}
                      />
                      <Radio
                        id={`inActivation1_${data.id}`}
                        valueText={t("component.choice")}
                        name={`inActivation1_${data.id}`}
                        checked={!data.requiredFlag}
                        onChange={() => {
                          setList((prev) => {
                            const copyArr = [...prev];
                            copyArr[index].requiredFlag = false;
                            return copyArr;
                          });
                        }}
                      />
                    </div>
                  </DetailPageForm>
                  <DetailPageForm style={{ alignItems: "flex-start" }}>
                    <DetailFormTitle>{t("buyTerms.inputTitle4")}*</DetailFormTitle>
                    <div style={{ display: "flex", gap: "19px" }}>
                      <Radio
                        id={`activation2_${data.id}`}
                        valueText={t("component.active")}
                        name={`activation2_${data.id}`}
                        checked={data.activation}
                        onChange={() => {
                          setList((prev) => {
                            const copyArr = [...prev];
                            copyArr[index].activation = true;
                            return copyArr;
                          });
                        }}
                      />
                      <Radio
                        id={`inActivation2_${data.id}`}
                        valueText={t("component.inactive")}
                        name={`inActivation2_${data.id}`}
                        checked={!data.activation}
                        onChange={() => {
                          setList((prev) => {
                            const copyArr = [...prev];
                            copyArr[index].activation = false;
                            return copyArr;
                          });
                        }}
                      />
                    </div>
                  </DetailPageForm>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 6,
                    marginTop: 30,
                  }}
                >
                  <DelBtn
                    onClick={() => {
                      setDelIndex(index);
                      setDelModalOpen(true);
                    }}
                  />
                  <AddBtn
                    text={t("component.save")}
                    onClick={() => {
                      editBtnHandler(index);
                    }}
                  />
                </div>
              </DetailPage>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <AddBtn
              onClick={() => {
                addTermSettingItem();
              }}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {delModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDelModalOpen}
          onClick={() => {
            delTermSetting();
          }}
        />
      ) : null}

      {editModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          btnTitle={"수정하기"}
          setModalOpen={setEditModalOpen}
          onClick={() => {
            editFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default BuyTerms;
