import React from "react";
import styled from "styled-components";
import PretendardText from "../pretendardText";

const DetailFormTitle = (props) => {
  return <DetailFormTitleWrapper {...props} />;
};

export default DetailFormTitle;

const DetailFormTitleWrapper = styled(PretendardText).attrs((props) => {})`
  min-width: 200px;
  max-width: 200px;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  color: #adb4ba;
`;
