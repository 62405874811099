import { toast } from 'react-toastify';
import { route } from '../router/route';

function errorHandler(error) {
  const message = error.response?.data?.message;
  const status = error.response?.status;

  switch (error.response?.status) {
    case 500:
      toast(
        `${
          error.response.data.message
            ? error.response.data.message
            : '서버에 문제가 있습니다. 잠시후 다시 시도해주세요.'
        }`
      );
      break;
    default:
      break;
  }
}

export { errorHandler };
