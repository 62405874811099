import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { route } from "../../router/route";
import PretendardText from "../atoms/text/pretendardText";
import i18n from "../../locales/i18n";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  const [langOpen, setLangOpen] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
    setLangOpen(false); // 언어 변경 후 드롭다운을 닫습니다.
  };

  const langBtnRef = useRef(null);
  const langSelectBoxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        langSelectBoxRef.current &&
        !langSelectBoxRef.current.contains(event.target) &&
        langBtnRef.current &&
        !langBtnRef.current.contains(event.target)
      ) {
        setLangOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <HeaderWrapper style={{ ...props.style }}>
      {props.isSignPage ? null : (
        <HeaderInfo>
          <img src="/assets/admin/icons/ico_profile.svg" alt="" />
          <PretendardText
            style={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "32.5px",
              letterSpacing: "-0.054px",
              color: "#262C31",
            }}
          >
            {sessionStorage.getItem("email")}
          </PretendardText>
        </HeaderInfo>
      )}
      <div style={{ display: "flex", gap: "18px" }}>
        {props.isSignPage ? null : (
          <LogoutBtn
            onClick={() => {
              navigate(route.login);
              sessionStorage.clear();
            }}
          >
            {t(`header.logout`)}
          </LogoutBtn>
        )}
        <div
          style={{ position: "relative" }}
          onClick={() => setLangOpen(!langOpen)}
        >
          <LangBtn ref={langBtnRef}>
            <PretendardText
              style={{
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "20.7px",
                color: "#636c73",
              }}
            >
              {currentLanguage}
            </PretendardText>
            <img
              className={langOpen ? "rotate" : "rotate-back"}
              alt=""
              src="/assets/admin/icons/triangle.png"
              style={{ width: "14px", height: "14px" }}
            />
          </LangBtn>
          {langOpen && (
            <LangSelectBox ref={langSelectBoxRef}>
              <LangBox onClick={() => changeLanguage("KR")}>
                <PretendardText
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "21.5px",
                    color: currentLanguage === "KR" ? "#202026" : "#808991",
                  }}
                >
                  KR
                </PretendardText>
                {currentLanguage === "KR" && (
                  <img
                    alt=""
                    src="/assets/admin/icons/check.png"
                    style={{ width: "18px", height: "18px" }}
                  />
                )}
              </LangBox>
              <LangBox onClick={() => changeLanguage("VN")}>
                <PretendardText
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "21.5px",
                    color: currentLanguage === "VN" ? "#202026" : "#808991",
                  }}
                >
                  VN
                </PretendardText>
                {currentLanguage === "VN" && (
                  <img
                    alt=""
                    src="/assets/admin/icons/check.png"
                    style={{ width: "18px", height: "18px" }}
                  />
                )}
              </LangBox>
            </LangSelectBox>
          )}
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15.8%;
  gap: 40px;
  position: absolute;
  z-index: 2;
  top: 0;
  border-bottom: 1px solid #f2f4f5;
`;

const HeaderInfo = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LogoutBtn = styled.button.attrs((props) => {})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 41px;
  border-radius: 22px;
  background: #f2f4f5;
  color: #636c73;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.5px;

  &:hover {
    background: #d1d5d9;
  }
`;

const LangBtn = styled.button.attrs((props) => {})`
  max-width: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  gap: 10px;
  padding: 8px 18px;
  border-radius: 50px;
  border: 1px solid #636c73;
`;

const LangSelectBox = styled.div.attrs((props) => {})`
  min-width: 79px;
  background: #fff;
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0px 2px 5px 0px #00000040;
  position: absolute;
  top: 43px;
`;

const LangBox = styled.button.attrs((props) => {})`
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  display: flex;
  /* align-items: center; */
  gap: 10px;

  &:hover {
    background-color: #f2f4f5;
  }
`;
