import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import MerchApi from "../../../api/merch.api";
import { saveAs } from "file-saver";
import moment from "moment";
import ExcelApi from "../../../api/excel.api";

const Product = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");

  // ---------- 마운트가 되면 ---------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getMerchList();
  }, [location]);

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const [merchList, setMerchList] = useState([
    {
      title: "",
      categoryTitle: "",
      salePrice: 0,
      merchStatus: "PROGRESS",
      inventory: 0,
      inventoryType: "",
    },
  ]);

  const getMerchList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };

      const responseData = (await MerchApi.getList(data)).data.data;

      setMerchList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );

      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllCheck = useMemo(() => {
    const checkedList = merchList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === merchList.length;
  }, [merchList]);

  const [title, setTitle] = useState(t("product.title"));
  const [subTitle, setSubTitle] = useState(t("product.productCount"));

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activationId, setActivationId] = useState(null);
  const [activation, setActivation] = useState(null);
  const [activationModalTitle, setActivationModalTitle] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      const data = {
        activation: activation,
      };
      await MerchApi.activation(activationId, data);
      toast(t("toastMsg.create"));
      setFilterOpen(-1);
      setActivationModalOpen(false);
      getMerchList();
    } catch (error) {
      errorHandler(error);
    }
  };

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: `${t(`product.name`)}`, key: "title", width: 40 },
      { header: `${t(`product.category`)}`, key: "categoryTitle", width: 40 },
      { header: `${t(`product.amount`)}`, key: "salePrice", width: 40 },
      { header: `${t(`product.state`)}`, key: "activeTitle", width: 40 },
      { header: `${t(`product.productState`)}`, key: "inventoryTitle", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${t("product.title")}.xlsx`);
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetMerchExcel({
          ids: [],
          allFlag: true,
        })
      ).data.data.content;

      response.map((item) => {
        const categoryTitle = item.category?.title;
        const activeTitle = item.merchStatus === "PROGRESS" ? `${t("product.ing")}` : `${t("product.stop")}`;
        const inventoryTitle =
          item.inventoryType === "PARTIALLY_OUT_OF_STOCK" ? `${t("product.soldoutSome")}` : item.inventoryType === "OUT_OF_STOCK" ? `${t("product.soldoutAll")}` : `${t("product.normal")}`;
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          categoryTitle: categoryTitle,
          activeTitle: activeTitle,
          inventoryTitle: inventoryTitle,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = merchList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast(t("placeholder.selectItem"));
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetMerchExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
        })
      ).data.data.content;

      response.map((item) => {
        const categoryTitle = item.category?.title;
        const activeTitle = item.merchStatus === "PROGRESS" ? `${t("product.ing")}` : `${t("product.stop")}`;
        const inventoryTitle =
          item.inventoryType === "PARTIALLY_OUT_OF_STOCK" ? `${t("product.soldoutSome")}` : item.inventoryType === "OUT_OF_STOCK" ? `${t("product.soldoutAll")}` : `${t("product.normal")}`;
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
          categoryTitle: categoryTitle,
          activeTitle: activeTitle,
          inventoryTitle: inventoryTitle,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{title}</TableTitle>
          <TableTop>
            <TableCount text={subTitle} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.product_list}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.product_list}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <ExcelBtn onSelectClick={getExcelChecked} onAllClick={getExcelAll} />
              {/* 상품 등록 버튼 */}
              <DelBtn text={t("product.add")} onClick={() => navigate(route.product_add)} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 영역 */}
              <col width="90px" />
              {/* 상품명 */}
              <col width="241px" />
              {/* 카테고리 */}
              <col width="156px" />
              {/* 상품 가격 */}
              <col width="183px" />
              {/* 판매 상태 */}
              <col width="150px" />
              {/* 재고 */}
              <col width="150px" />
              {/* 상세보기 */}
              <col width="78px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setMerchList(
                          merchList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setMerchList(
                          merchList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t(`product.name`)}</TableText>
                </Th>
                <Th>
                  <TableText>{t(`product.category`)}</TableText>
                </Th>
                <Th>
                  <TableText>{t(`product.amount`)}</TableText>
                </Th>
                <Th>
                  <TableText>{t(`product.state`)}</TableText>
                </Th>
                <Th>
                  <TableText>{t(`product.productState`)}</TableText>
                </Th>
                <Th>
                  <TableText>{t(`product.more`)}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {merchList.map((item, index) => {
                return (
                  <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setMerchList([...merchList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText style={{ width: "200px" }}>{item.title}</TableText>
                    </Td>
                    <Td>
                      <TableText style={{ width: "188px" }}>{item.category?.title}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.salePrice}</TableText>
                    </Td>
                    <Td>
                      <TableFilter
                        style={
                          {
                            // width: "75px",
                          }
                        }
                        onClick={() => {
                          filterOpen === index ? setFilterOpen(-1) : setFilterOpen(index);
                        }}
                        isOpen={filterOpen === index}
                        selectedValue={item.merchStatus}
                        list={[
                          {
                            value: "PROGRESS",
                            title: `${t("product.ing")}`,
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(true);
                              setActivationModalTitle(`${t("modalMsg.activate")}`);
                              setActivationModalOpen(true);
                            },
                          },
                          {
                            value: "END",
                            title: `${t("product.stop")}`,
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(false);
                              setActivationModalTitle(`${t("modalMsg.deactivate")}`);
                              setActivationModalOpen(true);
                            },
                          },
                        ]}
                        setFilterOpen={setFilterOpen}
                      />
                    </Td>
                    <Td>
                      <TableText>
                        {item.inventoryType === "PARTIALLY_OUT_OF_STOCK"
                          ? `${t("product.soldoutSome")}`
                          : item.inventoryType === "OUT_OF_STOCK"
                          ? `${t("product.soldoutAll")}`
                          : `${t("product.normal")}`}
                      </TableText>
                    </Td>
                    <Td>
                      <DetailBtn
                        onClick={() => {
                          navigate(route.product_detail + `/${item.id}`);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination route={route.product_list} queryString={`keyword=${searchParams.get("keyword") || ""}`} currentPage={page} totalPages={pagination.totalPages} rangeSize={pagination.rangeSize} />
        </TableLayout>
      </MainLayout>

      {activationModalOpen ? (
        <Modal
          title={`${activationModalTitle}`}
          setModalOpen={setActivationModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default Product;
