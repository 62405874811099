import api from "./api.js";

export default class OrderApi {
  static async getList(params) {
    return await api.get(`admin/order`, params);
  }

  static async getMemberMerchList(params) {
    return await api.get(`admin/member-merch`, params);
  }

  static async changeOrder(id, params) {
    return await api.patch(`admin/order/status/${id}`, params);
  }

  static async changeMemberMerch(id, params) {
    return await api.patch(`admin/member-merch/status/${id}`, params);
  }

  static async changeDeliveryNumber(id, params) {
    return await api.patch(`admin/member-merch/deliveryNumber/${id}`, params);
  }

  static async getOrderDetail(orderId, params) {
    return await api.get(`admin/order/${orderId}`, params);
  }

  static async getCancelDetail(orderId, params) {
    return await api.get(`admin/cancel/${orderId}`, params);
  }

  static async getExchangeDetail(orderId, params) {
    return await api.get(`admin/exchange/${orderId}`, params);
  }

  static async setStatusExchange(data) {
    return await api.post(`admin/member-merch/status/exchange`, data);
  }

  static async setStatusReturn(data) {
    return await api.post(`admin/member-merch/status/return`, data);
  }

  static async updateMemo(exchangeId, data) {
    return await api.patch(`admin/exchange/memo/${exchangeId}`, data);
  }

  static async updateCancelMemo(cancelId, data) {
    return await api.patch(`admin/cancel/memo/${cancelId}`, data);
  }
}
