import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

function SaveBtn({ style, onClick, text, disabled }) {
  const { t } = useTranslation();

  return (
    <>
      <SaveBtnWrapper
        onClick={onClick}
        disabled={disabled}
        style={{ ...style }}
      >
        {text || `${t("component.save")}`}
      </SaveBtnWrapper>
    </>
  );
}

export default SaveBtn;

const SaveBtnWrapper = styled.button.attrs((props) => {})`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #636c73;
  background: #636c73;
  position: absolute;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  color: #fff;
  cursor: pointer;

  &:hover {
    border: 1px solid #262c31;
    background: #262c31;
  }

  &:disabled {
    outline: none;
    border: 1px solid #d7dbe0;
    background: #eef2f3;
    color: #b8bfc4;
  }
`;
