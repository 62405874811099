import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import Checkbox from "../../../component/atoms/input/checkbox";
import TdText from "../../../component/atoms/text/table/tdText";
import PretendardText from "../../../component/atoms/text/pretendardText";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import DelBtn from "../../../component/atoms/button/delBtn";
import MemberApi from "../../../api/member.api";
import { modalMsg } from "./../../../util/modalMsg";
import Modal from "./../../../component/templates/Modal";
import { toastMsg } from "../../../util/toastMsg";

const JoinApprove = () => {
  const { t, i18n } = useTranslation();
  const isVn = i18n.language === "VN";

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getMemberList();
  }, [location]);

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const [approveRequestList, setApproveRequestList] = useState([1]);

  const getMemberList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
        role: "INFLUENCER",
      };

      let responseData = (await MemberApi.getList(data)).data.data;

      setApproveRequestList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );

      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 전체 체크
  const isAllCheck = useMemo(() => {
    const checkedList = approveRequestList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === approveRequestList.length;
  }, [approveRequestList]);

  // ----- 승인
  const [approveId, setApproveId] = useState(-1);
  const [approveType, setApproveType] = useState("");
  const [approveModalOpen, setApproveModalOpen] = useState(false);

  const approveFunc = async () => {
    try {
      const data = {
        approveStatus: approveType,
      };

      await MemberApi.approval(approveId, data);
      setApproveModalOpen(false);

      toast(t("toastMsg.update"));

      setApproveId(-1);
      setApproveType("");
      getMemberList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activationId, setActivationId] = useState(null);
  const [activation, setActivation] = useState(null);
  const [activationModalTitle, setActivationModalTitle] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      const data = {
        activation: activation,
      };
      await MemberApi.activation(activationId, data);
      toast(t("toastMsg.create"));
      setFilterOpen(-1);
      setActivationModalOpen(false);
      getMemberList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: delIdList,
      };
      await MemberApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      setDelIdList([]);
      getMemberList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{t("influencer_approve.title")}</TableTitle>
          <Tab
            list={[
              {
                title: `${t("influencer_approve.tab1")}`,
                url: route.approve_signup,
              },
              {
                title: `${t("influencer_approve.tab2")}`,
                url: route.approve_leave,
              },
            ]}
            style={{ marginBottom: "20px" }}
          />
          <TableTop>
            <TableCount text={t("influencer_approve.approveCount")} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.approve_signup}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.approve_signup}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <DelBtn
                text={t("influencer_approve.delBtn")}
                onClick={() => {
                  const delList = [];

                  approveRequestList.forEach((item) => {
                    if (item.checked) {
                      delList.push(item.id);
                    }
                  });

                  if (delList.length <= 0) {
                    return;
                  }

                  setDelIdList(delList);
                  setDeleteModalOpen(true);
                }}
              />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 영역 */}
              <col width="90px" />
              {/* 이메일 */}
              <col width="187px" />
              {/* 닉네임 */}
              <col width={isVn ? "157px" : "134px"} />
              {/* 요청일 */}
              <col width={isVn ? "130px" : "151px"} />
              {/* 계정 상태 */}
              <col width={isVn ? "120px" : "124px"} />
              {/* 승인 여부 */}
              <col width={isVn ? "270px" : "248px"} />
              {/* 상세보기 */}
              <col width={isVn ? "88px" : "78px"} />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setApproveRequestList(
                          approveRequestList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setApproveRequestList(
                          approveRequestList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t("influencer_approve.email")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("influencer_approve.nickname")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("influencer_approve.createdAt")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("influencer_approve.state")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("influencer_approve.approve")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("influencer_approve.more")}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {approveRequestList.map((item, index) => {
                return (
                  <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setApproveRequestList([...approveRequestList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText>{item.email}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.nickname}</TableText>
                    </Td>
                    <Td>
                      <TableText>{moment(item.createdAt).format("YYYY/MM/DD")}</TableText>
                    </Td>
                    <Td>
                      <TableFilter
                        style={
                          {
                            // width: "75px",
                          }
                        }
                        onClick={() => {
                          filterOpen === index ? setFilterOpen(-1) : setFilterOpen(index);
                        }}
                        isOpen={filterOpen === index}
                        selectedValue={item.activation || false}
                        list={[
                          {
                            value: true,
                            title: `${t("component.active")}`,
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(true);
                              setActivationModalTitle(`${t("modalMsg.activate")}`);
                              setActivationModalOpen(true);
                            },
                          },
                          {
                            value: false,
                            title: `${t("component.inactive")}`,
                            onClick: () => {
                              setActivationId(item.id);
                              setActivation(false);
                              setActivationModalTitle(`${t("modalMsg.deactivate")}`);
                              setActivationModalOpen(true);
                            },
                          },
                        ]}
                        setFilterOpen={setFilterOpen}
                      />
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: isVn ? "column" : "row",
                          alignItems: isVn ? "flex-start" : "center",
                          gap: isVn ? "4px" : "10px",
                        }}
                      >
                        <TdText
                          style={{
                            color: `${item.approveStatus === "REQUEST" ? "#EF594B" : "#262c31"}`,
                          }}
                        >
                          {item.approveStatus === "REQUEST" ? `${t("video.reject")}` : item.approveStatus === "APPROVE" ? `${t("video.approve")}` : `${t("video.deny")}`}
                        </TdText>
                        <div style={{ display: "flex", gap: "10px" }}>
                          {item.approveStatus !== "REQUEST" ? null : (
                            <PretendardText
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "22.75px",
                                color: "#262C31",
                                border: "1px solid #DFE1E4",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "3px 8px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setApproveId(item.id);
                                setApproveType("APPROVE");
                                setApproveModalOpen(true);
                              }}
                            >
                              {t("video.approveBtn")}
                            </PretendardText>
                          )}
                          {item.approveStatus !== "REQUEST" ? null : (
                            <PretendardText
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "22.75px",
                                color: "#262C31",
                                border: "1px solid #DFE1E4",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "3px 8px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setApproveId(item.id);
                                setApproveType("REJECT");
                                setApproveModalOpen(true);
                              }}
                            >
                              {t("video.rejectBtn")}
                            </PretendardText>
                          )}
                        </div>
                      </div>
                    </Td>
                    <Td>
                      <DetailBtn
                        onClick={() => {
                          navigate(route.approve_signup_detail + `/${item.id}`);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.approve_signup}
            queryString={`keyword=${searchParams.get("keyword") || ""}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>

      {activationModalOpen ? (
        <Modal
          title={`${activationModalTitle}`}
          setModalOpen={setActivationModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}

      {approveModalOpen ? (
        <Modal
          title={approveType === "APPROVE" ? `${t("modalMsg.influencer_approve")}` : `${t("modalMsg.deny")}`}
          setModalOpen={setApproveModalOpen}
          cancelBtnText={t("modalMsg.influencer_cancel")}
          okBtnText={t("modalMsg.influencer_ok")}
          onClick={() => {
            approveFunc();
          }}
        />
      ) : null}

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default JoinApprove;
