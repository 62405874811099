import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PretendardText from '../atoms/text/pretendardText';

function Pagination({
  route,
  queryString,
  totalPages,
  currentPage,
  rangeSize,
  style,
}) {
  const navigate = useNavigate();

  const pageList = useMemo(() => {
    const pageGroup = Math.ceil(currentPage / rangeSize);

    let firstNumber = pageGroup * rangeSize - (rangeSize - 1);
    if (firstNumber <= 0) {
      firstNumber = 1;
    }

    let lastNumber = pageGroup * rangeSize;
    if (lastNumber > totalPages) {
      lastNumber = totalPages;
    }

    const pageList = [];
    for (let i = firstNumber; i <= lastNumber; i++) {
      pageList.push(i);
    }

    return pageList;
  }, [totalPages, currentPage, rangeSize]);

  return (
    <>
      {pageList.length > 0 ? (
        <>
          <PagenationWrapper style={style}>
            <img
              src="/assets/admin/icons/ico_pagination_left.svg"
              alt="arrow-left"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                if (currentPage <= 1) {
                  return;
                }

                navigate(
                  route +
                    `?page=${Number(currentPage) - 1}${
                      queryString ? '&' + queryString : ''
                    }`,
                  { replace: true }
                );
              }}
            />
            <PagenationNumWrapper>
              {pageList.map((item, index) => {
                return (
                  <PagenationNum
                    key={index}
                    style={{
                      color:
                        Number(currentPage) === item ? '#262C31' : '#B8BFC4',
                      background: Number(currentPage) === item ? '#F2F4F5' : '',
                      borderRadius: Number(currentPage) === item ? '6px' : '',
                    }}
                    onClick={() => {
                      if (item === currentPage) {
                        return;
                      }

                      navigate(
                        route +
                          `?page=${item}${
                            queryString ? '&' + queryString : ''
                          }`,
                        { replace: true }
                      );
                    }}
                  >
                    {item}
                  </PagenationNum>
                );
              })}
            </PagenationNumWrapper>
            <img
              src="/assets/admin/icons/ico_pagination_right.svg"
              alt="arrow-right"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                if (currentPage >= totalPages) {
                  return;
                }

                navigate(
                  route +
                    `?page=${Number(currentPage) + 1}${
                      queryString ? '&' + queryString : ''
                    }`,
                  { replace: true }
                );
              }}
            />
          </PagenationWrapper>
        </>
      ) : null}
    </>
  );
}

export default Pagination;

const PagenationWrapper = styled.div.attrs((props) => {})`
  margin-top: 40px;
  width: 1044px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const PagenationNum = styled(PretendardText).attrs((props) => {})`
  display: flex;
  font-weight: 700;
  line-height: 28px;

  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #b8bfc4;
`;

const PagenationNumWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;
`;
