import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import CategoryApi from "../../../api/category.api";
import { errorHandler } from "../../../util/errorHandler";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { useTranslation } from "react-i18next";
import EditBtn from "../../../component/atoms/button/editBtn";
import { route } from "../../../router/route";
import FileImgPreview from "./../../../component/molecules/fileImgPreview";

const CategoryDetail = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getCategoryInfo();
  }, []);

  // ----- 정보 가져오기
  const [categoryInfo, setCategoryInfo] = useState({
    title: "",
    categoryType: "",
    content: "",
    email: "",
    createdAt: "",
    activation: false,
    parentCategory: {
      title: "",
    },
  });

  const getCategoryInfo = async () => {
    try {
      const response = (await CategoryApi.getById(id)).data.data.content;

      setCategoryInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: [id],
      };
      await CategoryApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDeleteModalOpen(true)} />
              <EditBtn onClick={() => navigate(route.category_edit + `/${id}`)} />
            </div>
          </DetailTop>

          <DetailPage style={{ minHeight: "295px" }}>
            <DetailPageTitle>{t("category.more")}</DetailPageTitle>
            {categoryInfo.categoryType === "FIRST" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <DetailPageForm>
                  <DetailFormTitle>{t("category.activeImage")}</DetailFormTitle>
                  <FileImgPreview fileName={categoryInfo.imageOriginFileNameActive} serverFileName={categoryInfo.imageServerFileNameActive} />
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("category.inactiveImage")}</DetailFormTitle>
                  <FileImgPreview fileName={categoryInfo.imageOriginFileNameInActive} serverFileName={categoryInfo.imageServerFileNameInActive} />
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("category.categoryName")}</DetailFormTitle>
                  <DetailFormText>{categoryInfo.title}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("category.type")}</DetailFormTitle>
                  <DetailFormText>{t("category.category1")}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("category.state")}</DetailFormTitle>
                  <DetailFormText>{categoryInfo.activation ? `${t("component.active")}` : `${t("component.inactive")}`}</DetailFormText>
                </DetailPageForm>
              </div>
            )}
            {categoryInfo.categoryType === "SECOND" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <DetailPageForm>
                  <DetailFormTitle>{t("category.categoryName")}</DetailFormTitle>
                  <DetailFormText>{categoryInfo.title}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("category.type")}</DetailFormTitle>
                  <DetailFormText>{t("category.category2")}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("category.topCategory")}</DetailFormTitle>
                  <DetailFormText>{categoryInfo.parentCategory.title}</DetailFormText>
                </DetailPageForm>
                <DetailPageForm>
                  <DetailFormTitle>{t("category.state")}</DetailFormTitle>
                  <DetailFormText>{categoryInfo.activation ? `${t("component.active")}` : `${t("component.inactive")}`}</DetailFormText>
                </DetailPageForm>
              </div>
            )}
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CategoryDetail;
