import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import MainInput from "../../component/atoms/input/mainInput";
import { route } from "../../router/route";
import { isNull } from "../../util/check";
import { regExpression } from "../../util/regExpresstion";
import PretendardText from "../../component/atoms/text/pretendardText";
import ManagerApi from "../../api/manager.api";
import { errorHandler } from "../../util/errorHandler";
import Header from "../../component/organisms/header";
import { useTranslation } from "react-i18next";

function Join(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [joinData, setJoinData] = useState({
    accessMenu: [
      route.common_member,
      route.leave_member,
      route.used_trading_post,
      route.general_alarm,
      route.general_ask,
      route.general_coupon,
      route.general_notice,
      route.general_faq,
      route.general_popup,
      route.general_banner,
      route.general_terms,
      route.general_basic,
      route.manager,
    ],
    name: "",
    email: "",
    password: "",
    passwordCheck: "",
  });

  //에러
  const [emailErr, setEmailErr] = useState(false);
  const [PwErr, setPwErr] = useState(false);
  const [PwCheckErr, setPwCheckErr] = useState(false);

  const activeFlag = useMemo(() => {
    if (
      !isNull(joinData.name) &&
      !isNull(joinData.email) &&
      !isNull(joinData.password) &&
      !isNull(joinData.passwordCheck) &&
      !emailErr &&
      !PwErr &&
      !PwCheckErr
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    joinData.name,
    joinData.email,
    joinData.password,
    joinData.passwordCheck,
    emailErr,
    PwErr,
    PwCheckErr,
  ]);

  useEffect(() => {
    if (joinData.password !== joinData.passwordCheck) {
      setPwCheckErr(true);
    } else setPwCheckErr(false);
  }, [joinData.passwordCheck, joinData.password]);

  //회원가입 처리
  const handleJoin = async () => {
    const obj = {
      // accessMenu: JSON.stringify(joinData.accessMenu),
      name: joinData.name,
      email: joinData.email,
      password: joinData.password,
      role: "ADMIN",
    };
    try {
      await ManagerApi.sign(obj);
      navigate(route.join_complete);
    } catch (error) {
      errorHandler(error);
      // toast(error.response.data.message);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Header isSignPage={true} />
      <JoinWrapper>
        <JoinBox>
          <PretendardText
            style={{
              fontSize: "30px",
              fontWeight: "700",
              lineHeight: "41.5px",
              letterSpacing: "-0.21px",
              color: "#000",
              marginBottom: "40px",
            }}
          >
            {t(`auth.join`)}
          </PretendardText>
          <JoinInputWrapper>
            <JoinInputForm>
              <PretendardText
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "26px",
                  color: "#808991",
                }}
              >
                {t(`auth.name`)}
              </PretendardText>
              <MainInput
                type={"text"}
                placeholder={t(`placeholder.namePlaceholder`)}
                style={{ width: "343px" }}
                value={joinData.name}
                onChange={(e) =>
                  setJoinData({ ...joinData, name: e.target.value })
                }
              />
            </JoinInputForm>
            <div>
              <JoinInputForm>
                <PretendardText
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "26px",
                    color: "#808991",
                  }}
                >
                  {t(`auth.email`)}
                </PretendardText>
                <MainInput
                  type={"email"}
                  placeholder={t(`placeholder.emailPlaceholder`)}
                  helperText={t(`auth.emailError`)}
                  onError={emailErr}
                  style={{
                    width: "343px",
                  }}
                  value={joinData.email}
                  onChange={(e) => {
                    setJoinData({ ...joinData, email: e.target.value });
                    if (!regExpression.email.test(e.target.value)) {
                      return setEmailErr(true);
                    }
                    setEmailErr(false);
                  }}
                />
              </JoinInputForm>
            </div>
            <div>
              <JoinInputForm>
                <PretendardText
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "26px",
                    color: "#808991",
                  }}
                >
                  {t(`auth.pw`)}
                </PretendardText>
                <MainInput
                  type={"password"}
                  placeholder={t(`placeholder.pwPlaceholder`)}
                  style={{
                    width: "343px",
                  }}
                  value={joinData.password}
                  onChange={(e) => {
                    setJoinData({ ...joinData, password: e.target.value });
                    if (!regExpression.password.test(e.target.value)) {
                      setPwErr(true);
                      return;
                    }
                    setPwErr(false);
                  }}
                />
              </JoinInputForm>
              <div style={{ width: "100%", marginTop: "3px" }}>
                <JoinCaption style={{ color: `${PwErr ? "#FF003D" : ""}` }}>
                  {t(`auth.pwCaption`)}
                </JoinCaption>
              </div>
            </div>
            <div>
              <JoinInputForm>
                <PretendardText
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "26px",
                    color: "#808991",
                  }}
                >
                  {t(`auth.pwCheck`)}
                </PretendardText>
                <MainInput
                  type={"password"}
                  placeholder={t(`placeholder.pwCheckPlaceholder`)}
                  style={{ width: "343px" }}
                  value={joinData.passwordCheck}
                  onChange={(e) =>
                    setJoinData({ ...joinData, passwordCheck: e.target.value })
                  }
                  onError={joinData.passwordCheck !== "" && PwCheckErr}
                  helperText={t(`auth.pwError`)}
                />
              </JoinInputForm>
            </div>
          </JoinInputWrapper>
          <JoinBtn disabled={!activeFlag} onClick={handleJoin}>
            {t(`auth.join`)}
          </JoinBtn>
        </JoinBox>
      </JoinWrapper>
    </div>
  );
}

export default Join;

const JoinWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const JoinBox = styled.div.attrs((props) => {})`
  width: 518px;
  min-height: 730px;
  padding: 86px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 4px 8px 36px 0px rgba(0, 0, 0, 0.05);
`;

const JoinInputWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  margin-bottom: 40px;
`;

const JoinInputForm = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
`;

const JoinBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.disabled ? "#BABABA" : "#009BDE")};
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
  border-radius: 3px;

  &:hover {
    background-color: #009bde;
  }
  &:disabled:hover {
    background-color: #bababa;
  }
`;

const JoinCaption = styled(PretendardText).attrs((props) => {})`
  color: #808991;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.75px; /* 141.667% */
`;
