import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import DelBtn from "../../../component/atoms/button/delBtn";
import EditBtn from "../../../component/atoms/button/editBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import SearchBar from "../../../component/atoms/input/searchBar";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import GoBack from "../../../component/molecules/goBack";
import Pagination from "../../../component/molecules/pagination";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import MemberApi from "../../../api/member.api";
import { formatPhoneNumber } from "./../../../util/formatNumber";
import { isNull } from "../../../util/check";

const LeaveApproveDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [keyword, setKeyword] = useState("");
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getMemberInfo();
    getAttributionList();
  }, []);

  const [memberInfo, setMemberInfo] = useState({});

  const getMemberInfo = async () => {
    try {
      const response = (await MemberApi.getById(id)).data.data.content;

      setMemberInfo({
        ...response,
        birth: response.birth ? response.birth.split("T")[0] : "",
        address: getSenderAddressString(JSON.parse(response.address)),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ---------------------------------------- //
  // ---------- 귀속 회원 구매 내역 ---------- //
  // -------------------------------------- //

  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getAttributionList();
  }, [location]);

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const [attributionList, setAttributionList] = useState([]);

  const getAttributionList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };

      let responseData = (await MemberApi.getList(data)).data.data;

      setAttributionList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );

      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const [approveModalOpen, setApproveModalOpen] = useState(false);

  const handleRetireApproval = async (id) => {
    try {
      await MemberApi.retireApproval(id);
      toast(t("toastMsg.update"));
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getSenderAddressString = (senderAddressArray) => {
    let senderAddressString = "";

    if (senderAddressArray[0]) {
      senderAddressString += senderAddressArray[0].provinceName;
    }

    if (senderAddressArray[1]) {
      senderAddressString += `, ${senderAddressArray[1].districtName}`;
    }

    if (senderAddressArray[2]) {
      senderAddressString += `, ${senderAddressArray[2].wardName}`;
    }

    return senderAddressString;
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <EditBtn
                text={t("approve_detail.leaveBtn")}
                onClick={() => {
                  setApproveModalOpen(true);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "495px", marginBottom: "40px" }}>
            <DetailPageTitle>{t("influencer_detail.userInfo")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 이메일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.email")}</DetailFormTitle>
                <DetailFormText>{memberInfo.email}</DetailFormText>
              </DetailPageForm>

              {/* 이름 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.name")}</DetailFormTitle>
                <DetailFormText>{memberInfo.name}</DetailFormText>
              </DetailPageForm>

              {/* 휴대폰 번호 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.phone")}</DetailFormTitle>
                <DetailFormText>{formatPhoneNumber(memberInfo.phoneNumber)}</DetailFormText>
              </DetailPageForm>

              {/* 생년월일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.birth")}</DetailFormTitle>
                <DetailFormText>{memberInfo.birth}</DetailFormText>
              </DetailPageForm>

              {/* 성별 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.gender")}</DetailFormTitle>
                <DetailFormText>{memberInfo.gender === "" || memberInfo.gender === "NONE" ? "" : memberInfo.gender === "WOMAN" ? t("common_member.woman") : t("common_member.man")}</DetailFormText>
              </DetailPageForm>

              {/* 주소 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.address")}</DetailFormTitle>
                <DetailFormText>{`${memberInfo.address} ${memberInfo.detailAddress}`}</DetailFormText>
              </DetailPageForm>

              {/* 계좌 정보 - 은행명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.bankName")}</DetailFormTitle>
                <DetailFormText>{memberInfo.bankName}</DetailFormText>
              </DetailPageForm>

              {/* 계좌 정보 - 계좌번호 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.account")}</DetailFormTitle>
                <DetailFormText>{memberInfo.accountNumber}</DetailFormText>
              </DetailPageForm>

              {/* 계좌 정보 - 예금주명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.accountHolder")}</DetailFormTitle>
                <DetailFormText>{memberInfo.accountHolderName}</DetailFormText>
              </DetailPageForm>

              {/* 귀속 회원 수 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.attribution")}</DetailFormTitle>
                <DetailFormText>{memberInfo.recommendCount}</DetailFormText>
              </DetailPageForm>

              {/* 팔로워 수 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.follower")}</DetailFormTitle>
                <DetailFormText>{memberInfo.followerCount}</DetailFormText>
              </DetailPageForm>

              {/* SNS 링크 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.snsLink")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {memberInfo.snsLink ? JSON.parse(memberInfo.snsLink).map((item, index) => <DetailFormText key={index}>{item}</DetailFormText>) : <DetailFormText></DetailFormText>}
                </div>
              </DetailPageForm>

              {/* SNS 스크린샷 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("influencer_detail.snsScreenShot")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    width: 744,
                  }}
                >
                  {memberInfo.screenshots ? (
                    memberInfo.screenshots.map((item, index) => <FileImgPreview key={index} fileName={item.screenshotOriginFileName} serverFileName={item.screenshotServerFileName} />)
                  ) : (
                    <DetailFormText></DetailFormText>
                  )}
                </div>
              </DetailPageForm>

              {/* 보유 포인트 */}
              <DetailPageForm>
                <DetailFormTitle>{t("influencer_detail.point")}</DetailFormTitle>
                <DetailFormText>{memberInfo.point}</DetailFormText>
              </DetailPageForm>

              {/* 약관 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("influencer_detail.terms")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.useTerms")}</DetailFormText>
                    <Checkbox id="agreement" checked={true} disabled={true} />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.collect")}</DetailFormText>
                    <Checkbox id="privacy" checked={true} disabled={true} />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.providing")}</DetailFormText>
                    <Checkbox id="privacy" checked={true} disabled={true} />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>

              {/* 알림 수신 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("influencer_detail.alarm")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.sms")}</DetailFormText>
                    <Checkbox disabled id="marketingAgreement" checked={memberInfo.marketingAgreement} />
                  </CheckboxWrapper>

                  <CheckboxWrapper>
                    <DetailFormText>{t("influencer_detail.ad")}</DetailFormText>
                    <Checkbox disabled id="adAgreement" checked={memberInfo.adAgreement} />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>
          <div>
            <TableTop>
              <TableTitle style={{ fontSize: "24px", marginBottom: 0 }}>{t("influencer_detail.tableTitle")}</TableTitle>
              <TableSearchBtn>
                <SearchBar
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`${route.approve_leave_detail + `/${id}`}?page=1&keyword=${keyword}`, {
                        replace: true,
                      });
                    }
                  }}
                  onClick={() => {
                    navigate(`${route.approve_leave_detail + `/${id}`}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }}
                />
                <ExcelBtn
                // onSelectClick={getExcelChecked}
                // onAllClick={getExcelAll}
                />
              </TableSearchBtn>
            </TableTop>
            <Table>
              <colgroup>
                {/* 이메일 */}
                <col width="222px" />
                {/* 상품명 */}
                <col width="272px" />
                {/* 결제금액 */}
                <col width="203px" />
                {/* 결제일 */}
                <col width="182px" />
                {/* 적립 포인트 */}
                <col width="145px" />
              </colgroup>
              <thead>
                <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                  <Th style={{ paddingLeft: "20px" }}>
                    <TableText>{t("influencer_detail.email")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("influencer_detail.product")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("influencer_detail.amount")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("influencer_detail.payDay")}</TableText>
                  </Th>
                  <Th>
                    <TableText>{t("influencer_detail.point")}</TableText>
                  </Th>
                </Tr>
              </thead>
              <tbody>
                {attributionList.map((item, index) => {
                  return (
                    <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                      <Td>
                        <TableText>{item.email}</TableText>
                      </Td>
                      <Td>
                        <TableText>{item.name}</TableText>
                      </Td>
                      <Td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              backgroundColor: "#000",
                            }}
                          ></div>
                          <div>
                            <TableText style={{ marginBottom: "4px" }}>상품명상품명상품명상품명상품명</TableText>
                            <TableText style={{ fontSize: "12px", lineHeight: "16px" }}>옵션 1: white / 옵션2: small / 수량: 2</TableText>
                          </div>
                        </div>
                      </Td>
                      <Td>
                        <TableText>{item.reason}</TableText>
                      </Td>
                      <Td>
                        <TableText>{item.reason}</TableText>
                      </Td>
                    </Tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              route={route.approve_leave_detail + `/${id}`}
              queryString={`keyword=${searchParams.get("keyword") || ""}`}
              currentPage={page}
              totalPages={pagination.totalPages}
              rangeSize={pagination.rangeSize}
              first={pagination.first}
              last={pagination.last}
            />
          </div>
        </DetailPageLayout>
      </MainLayout>

      {approveModalOpen && (
        <Modal
          title={t("influencer_approve.retiredApprove")}
          setModalOpen={setApproveModalOpen}
          onClick={() => {
            handleRetireApproval(id);
          }}
        />
      )}
    </>
  );
};

export default LeaveApproveDetail;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
