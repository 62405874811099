import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
// import ExcelApi from "../../../api/excel.api";
import MemberApi from "../../../api/member.api";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { saveAs } from "file-saver";
import ExcelApi from "../../../api/excel.api";

const LeaveMember = () => {
  const { t, i18n } = useTranslation();
  const isVn = i18n.language === "VN";

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getLeaveMemberList();
  }, [location]);

  const [leaveMemberList, setLeaveMemberList] = useState([]);

  const getLeaveMemberList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let response = (await MemberApi.getRetiredMemberList(data)).data.data;

      setLeaveMemberList(
        response.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );

      setPagination({
        ...pagination,
        totalCount: response.totalCount ? response.totalCount : 0,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllCheck = useMemo(() => {
    const checkedList = leaveMemberList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === leaveMemberList.length;
  }, [leaveMemberList]);

  const [title, setTitle] = useState(`${t("leave.title")}`);
  const [subTitle, setSubTitle] = useState(`${t("leave.userCount")}`);

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: delIdList,
      };
      await MemberApi.retiredDelete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      setDelIdList([]);
      getLeaveMemberList();
    } catch (error) {
      errorHandler(error);
    }
  };

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: t("leave.email"), key: "email", width: 40 },
      { header: t("leave.name"), key: "name", width: 40 },
      { header: t("leave.reason"), key: "reason", width: 40 },
      { header: t("leave.leaveDate"), key: "createdAt", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${t("leave.title")}.xlsx`);
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetRetiredMemberExcel({
          ids: [],
          allFlag: true,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = leaveMemberList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast(t("placeholder.selectItem"));
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetRetiredMemberExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{title}</TableTitle>
          <TableTop>
            <TableCount text={subTitle} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.leave_member}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.leave_member}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <ExcelBtn onSelectClick={getExcelChecked} onAllClick={getExcelAll} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 영역 */}
              <col width={isVn ? "90px" : "90px"} />
              {/* 이메일 */}
              <col width={isVn ? "241px" : "241px"} />
              {/* 이름 */}
              <col width={isVn ? "156px" : "156px"} />
              {/* 탈퇴사유 */}
              <col width={isVn ? "320px" : "340px"} />
              {/* 탈퇴일 */}
              <col width={isVn ? "130px" : "144px"} />
              {/* 완전 삭제 */}
              <col width={isVn ? "106px" : "73px"} />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setLeaveMemberList(
                          leaveMemberList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setLeaveMemberList(
                          leaveMemberList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t("leave.email")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("leave.name")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("leave.reason")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("leave.leaveDate")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("leave.del")}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {leaveMemberList.map((item, index) => {
                return (
                  <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setLeaveMemberList([...leaveMemberList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText>{item.email}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.name}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.reason}</TableText>
                    </Td>
                    <Td>
                      <TableText>{moment(item.createdAt).format("YYYY/MM/DD")}</TableText>
                    </Td>
                    <Td>
                      <TableDelBtn
                        style={{ marginLeft: "6px" }}
                        onClick={() => {
                          setLeaveMemberList([...leaveMemberList]);
                          const delList = [item];
                          setDelIdList(delList.map((item) => item.id));
                          setDeleteModalOpen(true);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.leave_member}
            queryString={`keyword=${searchParams.get("keyword") || ""}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default LeaveMember;
