import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { fileUrl } from "../../../api/api";
import ReviewApi from "../../../api/review.api";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import { errorHandler } from "../../../util/errorHandler";

function ReviewModal({ data, setModalOpen }) {
  const { t } = useTranslation();

  return (
    <>
      <ModalWrapper>
        <ModalBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <TableTitle style={{ marginBottom: "0px" }}>
              {t("review_detail.title")}
            </TableTitle>
            <img
              style={{
                cursor: "pointer",
              }}
              src="/assets/admin/icons/ico_popup_close.svg"
              alt=""
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              minHeight: "223px",
              overflowY: "auto",
            }}
          >
            <DetailPageForm>
              <DetailFormTitle>{t("review.email")}</DetailFormTitle>
              <DetailFormText>{data?.email}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("review.createdAt")}</DetailFormTitle>
              <DetailFormText>
                {moment(data?.createdAt).format("YYYY/MM/DD")}
              </DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("review.rating")}</DetailFormTitle>
              <DetailFormText>{data?.score}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm style={{ alignItems: "flex-start" }}>
              <DetailFormTitle>{t("review_detail.product")}</DetailFormTitle>
              <div>
                <img
                  src={`${fileUrl}/${data.imageServerFileName}`}
                  style={{ width: "60px", height: "60px", borderRadius: "3px" }}
                />
                <DetailFormText>{data?.title}</DetailFormText>
              </div>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("review.content")}</DetailFormTitle>
              <DetailFormText>{data?.content}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm style={{ alignItems: "flex-start" }}>
              <DetailFormTitle>{t("review_detail.image")}</DetailFormTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {data.images &&
                  data.images?.map((item, index) => {
                    return (
                      <FileImgPreview
                        key={index}
                        fileName={item.imageOriginFileName}
                        serverFileName={item.imageServerFileName}
                      />
                    );
                  })}
              </div>
            </DetailPageForm>
          </div>
        </ModalBox>
      </ModalWrapper>
    </>
  );
}

export default ReviewModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 505px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
