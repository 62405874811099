import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "../../../util/errorHandler";
// import PopupApi from "../../../api/popup.api";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import EditBtn from "../../../component/atoms/button/editBtn";
import { route } from "../../../router/route";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import moment from "moment";
import FileImgPreview from "../../../component/molecules/fileImgPreview";
import DelBtn from "../../../component/atoms/button/delBtn";
import Modal from "../../../component/templates/Modal";
import { modalMsg } from "../../../util/modalMsg";
import { toast } from "react-toastify";
import { toastMsg } from "../../../util/toastMsg";
import { useTranslation } from "react-i18next";
import PopupApi from "./_api/popup.api";

const PopupDetail = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getPopupInfo();
  }, []);

  // ----- 정보 가져오기
  const [popupInfo, setPopupInfo] = useState({
    title: "",
    content: "",
  });

  const getPopupInfo = async () => {
    try {
      const response = (await PopupApi.getById(id)).data.data.content;
      setPopupInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: [id],
      };
      await PopupApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDeleteModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.popup_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "360px" }}>
            <DetailPageTitle>{t("popup.more")}</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>{t("popup.popupName")}</DetailFormTitle>
                <DetailFormText>{popupInfo.title}</DetailFormText>
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("popup.createdAt")}</DetailFormTitle>
                <DetailFormText>
                  {moment(popupInfo.createdAt).format("YYYY/MM/DD")}
                </DetailFormText>
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("popup.image")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    width: 744,
                  }}
                >
                  {popupInfo.images?.map((image, index) => {
                    return (
                      <React.Fragment key={index}>
                        <FileImgPreview
                          fileName={image.imageOriginFileName}
                          serverFileName={image.imageServerFileName}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default PopupDetail;
