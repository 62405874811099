import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import ExhibitionApi from "../../../api/exhibition.api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DateInput from "../../../component/atoms/input/dateInput";
import ImgInput from "../../../component/atoms/input/imgInput";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import ImgPreview from "../../../component/molecules/imgPreview";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { isNull } from "../../../util/check";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import PromotionApi from "../../../api/promotion.api";
import CommonApi from "../../../api/common.api";

const ExhibitionAdd = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [exhibitionInfo, setExhibitionInfo] = useState({
    title: "",
    startDate: "",
    endDate: "",
    file: "",
    fileUrl: "",
    banner: "",
    bannerUrl: "",
    mobileBanner: "",
    mobileBannerUrl: "",
  });

  //이미지 추가, 삭제
  const inputFile = (e, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      if (type === "file") {
        setExhibitionInfo({
          ...exhibitionInfo,
          file: file,
          fileUrl: value.target.result,
        });
      }
      if (type === "banner") {
        setExhibitionInfo({
          ...exhibitionInfo,
          banner: file,
          bannerUrl: value.target.result,
        });
      }
      if (type === "mobileBanner") {
        setExhibitionInfo({
          ...exhibitionInfo,
          mobileBanner: file,
          mobileBannerUrl: value.target.result,
        });
      }
    };
    e.target.value = "";
  };

  const deleteFile = (type) => {
    if (type === "file") {
      setExhibitionInfo({ ...exhibitionInfo, file: "", fileUrl: "" });
    }
    if (type === "banner") {
      setExhibitionInfo({ ...exhibitionInfo, banner: "", bannerUrl: "" });
    }
    if (type === "mobileBanner") {
      setExhibitionInfo({
        ...exhibitionInfo,
        mobileBanner: "",
        mobileBannerUrl: "",
      });
    }
  };

  // 파일 업로드
  const fileUpload = async (file, type) => {
    try {
      const data = {
        path: `promotion/${type}`,
        extension: file.name.split(".").pop(),
      };

      const response = (await CommonApi.fileUpload(data)).data.data;

      await cloudFileUpload(response.presignedUrl, file);

      return {
        serverFileName: response.serverFileName,
        originFileName: file.name,
      };
    } catch (error) {
      errorHandler(error);
    }
  };

  const cloudFileUpload = async (url, file) => {
    try {
      await CommonApi.cloudFileUpload(url, file);
    } catch (error) {
      errorHandler(error);
    }
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (
      isNull(exhibitionInfo.title) ||
      isNull(exhibitionInfo.startDate) ||
      isNull(exhibitionInfo.endDate) ||
      isNull(exhibitionInfo.file) ||
      isNull(exhibitionInfo.banner) ||
      isNull(exhibitionInfo.mobileBanner)
    ) {
      return toast(`${t("toastMsg.input_empty")}`);
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const [fileData, bannerData, mobileBannerData] = await Promise.all([
        fileUpload(exhibitionInfo.file, "file"),
        fileUpload(exhibitionInfo.banner, "banner"),
        fileUpload(exhibitionInfo.mobileBanner, "mobileBanner"),
      ]);

      const info = {
        title: exhibitionInfo.title,
        imageServerFileName: fileData.serverFileName,
        imageOriginFileName: fileData.originFileName,
        bannerImageServerFileName: bannerData.serverFileName,
        bannerImageOriginFileName: bannerData.originFileName,
        mobileImageServerFileName: mobileBannerData.serverFileName,
        mobileImageOriginFileName: mobileBannerData.originFileName,
        startDate: exhibitionInfo.startDate,
        endDate: exhibitionInfo.endDate,
      };

      await PromotionApi.create(info);
      setSaveModalOpen(false);
      navigate(-1);
      toast(t("toastMsg.create"));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "660px" }}>
            <DetailPageTitle>{t("component.add")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>{t("exhibition_detail.title")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.exhibitionPlaceholder")}
                  value={exhibitionInfo.title}
                  onChange={(e) =>
                    setExhibitionInfo({
                      ...exhibitionInfo,
                      title: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <div>
                  <DetailFormTitle>{t("exhibition_detail.thumbnail")} *</DetailFormTitle>
                  <DetailFormTitle style={{ fontWeight: "400" }}>({t("exhibition_detail.recommendSize")} 576*320)</DetailFormTitle>
                </div>
                {exhibitionInfo.fileUrl ? (
                  <ImgPreview
                    fileUrl={exhibitionInfo.fileUrl}
                    onClick={() => {
                      deleteFile("file");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file01"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e, "file");
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <div>
                  <DetailFormTitle>{t("exhibition_detail.pcImg")} *</DetailFormTitle>
                  <DetailFormTitle style={{ fontWeight: "400" }}>({t("exhibition_detail.recommendSize")} 1180*356)</DetailFormTitle>
                </div>
                {exhibitionInfo.bannerUrl ? (
                  <ImgPreview
                    fileUrl={exhibitionInfo.bannerUrl}
                    onClick={() => {
                      deleteFile("banner");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file02"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e, "banner");
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <div>
                  <DetailFormTitle>{t("exhibition_detail.mobileImg")} *</DetailFormTitle>
                  <DetailFormTitle style={{ fontWeight: "400" }}>({t("exhibition_detail.recommendSize")} 335*220)</DetailFormTitle>
                </div>
                {exhibitionInfo.mobileBannerUrl ? (
                  <ImgPreview
                    fileUrl={exhibitionInfo.mobileBannerUrl}
                    onClick={() => {
                      deleteFile("mobileBanner");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file03"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e, "mobileBanner");
                    }}
                  />
                )}
              </DetailPageForm>

              <DetailPageForm>
                <DetailFormTitle>{t("exhibition.period")} *</DetailFormTitle>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <DateInput
                    value={moment(exhibitionInfo.startDate).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      setExhibitionInfo({
                        ...exhibitionInfo,
                        startDate: e.target.value,
                      })
                    }
                  />
                  <img src="/assets/admin/icons/ico_date_devider.svg" alt="" />
                  <DateInput
                    value={moment(exhibitionInfo.endDate).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      setExhibitionInfo({
                        ...exhibitionInfo,
                        endDate: e.target.value,
                      })
                    }
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? <Modal title={`${t("modalMsg.update.title")}`} text={`${t("modalMsg.update.content")}`} setModalOpen={setSaveModalOpen} onClick={saveFunc} /> : null}
    </>
  );
};

export default ExhibitionAdd;
