import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import MainInput from "../../component/atoms/input/mainInput";
import PretendardText from "../../component/atoms/text/pretendardText";
import { route } from "../../router/route";
import { errorHandler } from "../../util/errorHandler";
import { regExpression } from "../../util/regExpresstion";
import Header from "../../component/organisms/header";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import ManagerApi from "../../api/manager.api";

function Login(props) {
  const { t } = useTranslation();

  // import
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["autoLogin"]);
  const [searchParams, setSearchParams] = useSearchParams();

  // mounted
  useEffect(() => {
    if (searchParams.get("reflesh")) {
      const message401 = t(`toastMsg.message401`);
      toast(`${message401}`);
    }
  }, []);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async () => {
    if (!(loginData.email && loginData.password)) {
      return;
    }
    if (!regExpression.email.test(loginData.email)) {
      toast(t("toastMsg.errorEmail"));
      return;
    }
    if (!regExpression.password.test(loginData.password)) {
      toast(t("toastMsg.errorPw"));
      return;
    }
    let obj = { email: loginData.email, password: loginData.password };
    try {
      let response = "";
      response = await ManagerApi.login(obj);
      sessionStorage.setItem("token", response.data.data.accessToken);

      response = await ManagerApi.getLoginInfo();

      sessionStorage.setItem("approval", response.data.data.content.approval);
      sessionStorage.setItem("id", response.data.data.content.id);
      sessionStorage.setItem("name", response.data.data.content.name);
      sessionStorage.setItem("accessMenu", response.data.data.content.accessMenu);
      sessionStorage.setItem("role", response.data.data.content.role);
      sessionStorage.setItem("email", response.data.data.content.email);

      if (autoLogin) {
        setCookie("autoLogin", true, { maxAge: 3600 * 24 * 14, path: "/" }); //2주
      } else {
        removeCookie("autoLogin", { path: "/" });
      }

      navigate(route.home);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return toast(`${error.response.data.message}`);
        }
        if (error.response.status === 404) {
          return toast(`존재하지 않는 회원입니다.`);
        }
      } else {
        toast("알 수 없는 오류가 발생했습니다.");
      }
      errorHandler(error);
    }
  };

  //자동로그인
  const [autoLogin, setAutoLogin] = useState(false);

  const autoLoginFunc = async () => {
    try {
      const response = await ManagerApi.getLoginInfo();
      sessionStorage.setItem("name", response.data.data.name);
      sessionStorage.setItem("accessMenu", response.data.data.accessMenu);
      sessionStorage.setItem("role", response.data.data.role);
      sessionStorage.setItem("email", response.data.data.email);
      sessionStorage.setItem("id", response.data.data.id);

      navigate(route.home);
    } catch (error) {
      if (error.response.status === 401) {
        return toast(`${error.response.data.message}`);
      }
      if (error.response.status === 404) {
        return toast(`존재하지 않는 회원입니다.`);
      }
      errorHandler(error);
    }
  };

  useEffect(() => {
    const allRefresh = searchParams.get("refresh");
    if (allRefresh) {
      removeCookie("autoLogin", { path: "/" });
    }
    if (!allRefresh && cookies.autoLogin) {
      autoLoginFunc();
    }
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <Header isSignPage={true} />
      <LoginWrapper>
        <LoginBox>
          <PretendardText
            style={{
              fontSize: "30px",
              fontWeight: "700",
              lineHeight: "41.5px",
              letterSpacing: "-0.21px",
              color: "#000",
              marginBottom: "40px",
            }}
          >
            {t(`auth.login`)}
          </PretendardText>
          <LoginInputWrapper>
            <LoginInputForm>
              <PretendardText
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  color: "#333",
                }}
              >
                {t(`auth.email`)}
              </PretendardText>
              <MainInput
                type={"email"}
                placeholder={t(`placeholder.emailPlaceholder`)}
                style={{ width: "343px" }}
                value={loginData.email}
                onChange={(e) => {
                  setLoginData({ ...loginData, email: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
            </LoginInputForm>
            <LoginInputForm>
              <PretendardText
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  color: "#333",
                }}
              >
                {t(`auth.pw`)}
              </PretendardText>
              <MainInput
                type={"password"}
                placeholder={t(`placeholder.pwPlaceholder`)}
                style={{ width: "343px" }}
                value={loginData.password}
                onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
            </LoginInputForm>
          </LoginInputWrapper>
          <LoginBtnWrapper>
            <LoginBtn
              disabled={!(loginData.email && loginData.password)}
              onClick={() => {
                handleLogin();
              }}
            >
              {t(`auth.login`)}
            </LoginBtn>
            <JoinBtn onClick={() => navigate(route.join)}>{t(`auth.join`)}</JoinBtn>
          </LoginBtnWrapper>
        </LoginBox>
      </LoginWrapper>
    </div>
  );
}

export default Login;

const LoginWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginBox = styled.div.attrs((props) => {})`
  width: 518px;
  height: 574px;
  padding: 86px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 4px 8px 36px 0px rgba(0, 0, 0, 0.05);
`;

const LoginInputWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
`;

const LoginInputForm = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
`;

const LoginBtnWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const LoginBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.disabled ? "#BABABA" : "#009BDE")};
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
  border-radius: 3px;

  &:hover {
    background-color: #009bde;
  }
  &:disabled:hover {
    background-color: #bababa;
  }
`;

const JoinBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bababa;
  background-color: #fff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #939393;
  border-radius: 3px;

  &:hover {
    background-color: #e6e8eb;
  }
`;
