import api from "./api.js";

export default class CategoryApi {
  // 카테고리 등록
  static async create(data) {
    return await api.post(`admin/category`, data);
  }

  // 카테고리 목록 조회
  static async getList(params) {
    return await api.get(`admin/category`, params);
  }

  // 카테고리 삭제
  static async delete(data) {
    return await api.del(`admin/category`, data);
  }

  // 카테고리 단일 조회
  static async getById(categoryId, params) {
    return await api.get(`admin/category/${categoryId}`, params);
  }

  // 카테고리 수정
  static async update(categoryId, data) {
    return await api.patch(`admin/category/${categoryId}`, data);
  }

  // 카테고리 활성/비활성
  static async activation(categoryId, data) {
    return await api.post(`admin/category/activation/${categoryId}`, data);
  }

  // 카테고리 순서 변경
  static async change(categoryId, data) {
    return await api.post(`admin/category/order/${categoryId}`, data);
  }
}
