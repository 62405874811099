import React, { useEffect, useState } from "react";
// import BannerApi from "../../../api/banner.api";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import PretendardText from "../../../component/atoms/text/pretendardText";
import Modal from "../../../component/templates/Modal";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import DetailPageForm from "./../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "./../../../component/atoms/layout/detail/detailPageLayout";
import MainLayout from "./../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "./../../../component/atoms/text/detail/detailFormTitle";
import TableTitle from "./../../../component/atoms/text/table/tableTitle";
import Header from "./../../../component/organisms/header";
import Sidebar from "./../../../component/organisms/sidebar";
import SettingApi from "./_api/setting.api";

const Setting = () => {
  const { t } = useTranslation();

  const [autoDecrementQuantity, setAutoDecrementQuantity] = useState(true);
  const [autoChangeStatus, setAutoChangeStatus] = useState(true);
  const [notification, setNotification] = useState("");
  const [requiredDays, setRequiredDays] = useState(3);
  const [cardPayment, setCardPayment] = useState(true);

  const [info, setInfo] = useState({});

  const getEnvSetting = async () => {
    try {
      let data = (await SettingApi.getEnvSetting()).data.data.content;
      setAutoDecrementQuantity(data.autoDecrementQuantity);
      setAutoChangeStatus(data.autoChangeStatus);
      setNotification(data.notification);
      setRequiredDays(data.requiredDays);
      setCardPayment(data.cardPayment);

      setInfo(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getEnvSetting();
  }, []);

  const [type, setType] = useState(0);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      let data = {};

      if (type === 1) {
        data = {
          autoDecrementQuantity,
          autoChangeStatus,
          notification,
          requiredDays,

          cardPayment: info.cardPayment,
        };
      } else if (type === 2) {
        data = {
          autoDecrementQuantity: info.autoDecrementQuantity,
          autoChangeStatus: info.autoChangeStatus,
          notification: info.notification,
          requiredDays: info.requiredDays,

          cardPayment,
        };
      }

      await SettingApi.updateEnvSetting(data);
      setSaveModalOpen(false);
      toast(t("toastMsg.update"));
      getEnvSetting();
      setType(0);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <Tab
            list={[
              { title: `${t("setting.tab1")}`, url: route.setting },
              { title: `${t("setting.tab2")}`, url: route.transport },
              { title: `${t("setting.tab3")}`, url: route.point },
              { title: `${t("setting.tab4")}`, url: route.buy_terms },
            ]}
            style={{ marginBottom: "20px" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TableTitle style={{ marginBottom: "20px" }}>
              {t("setting.tab1")}
            </TableTitle>
            <DetailPage
              style={{
                minHeight: "453px",
              }}
            >
              <DetailPageTitle style={{ marginBottom: 5 }}>
                {t("setting.title1")}
              </DetailPageTitle>
              <PretendardText
                fontWeight="400"
                style={{
                  color: "#5A636A",
                  fontSize: 15,
                  marginBottom: 30,
                  whiteSpace: "pre-wrap",
                }}
              >
                {t("setting.caption1")}
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>{t("setting.inputTitle1")}*</DetailFormTitle>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "11px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={autoDecrementQuantity}
                        onChange={() =>
                          setAutoDecrementQuantity(!autoDecrementQuantity)
                        }
                      />
                      <PretendardText
                        fontWeight="500"
                        style={{
                          color: "#262C31",
                          fontSize: 15,
                        }}
                      >
                        {t("setting.checkbox1")}
                      </PretendardText>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={autoChangeStatus}
                        onChange={() => setAutoChangeStatus(!autoChangeStatus)}
                      />
                      <PretendardText
                        fontWeight="500"
                        style={{
                          color: "#262C31",
                          fontSize: 15,
                        }}
                      >
                        {t("setting.checkbox2")}
                      </PretendardText>
                    </div>
                  </div>
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>{t("setting.inputTitle2")}</DetailFormTitle>
                  <MainInput
                    placeholder={t("placeholder.defaultPlaceholder")}
                    value={notification}
                    onChange={(e) => setNotification(e.target.value)}
                  />
                </DetailPageForm>
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>{t("setting.inputTitle3")}</DetailFormTitle>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <PretendardText
                      fontWeight="400"
                      style={{
                        color: "#262C31",
                        fontSize: 15,
                      }}
                    >
                      {t("setting.dueDate")}
                    </PretendardText>
                    <MainInput
                      placeholder={t("placeholder.defaultPlaceholder")}
                      type={"number"}
                      style={{ maxWidth: "289px" }}
                      value={requiredDays}
                      onChange={(e) =>
                        setRequiredDays(e.target.value.toLocaleString())
                      }
                    />
                    <PretendardText
                      fontWeight="400"
                      style={{
                        color: "#262C31",
                        fontSize: 15,
                      }}
                    >
                      {t("setting.date")}
                    </PretendardText>
                  </div>
                </DetailPageForm>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <SaveBtn
                  onClick={() => {
                    setType(1);
                    saveBtnHandler();
                  }}
                />
              </div>
            </DetailPage>
            <DetailPage
              style={{
                minHeight: "239px",
              }}
            >
              <DetailPageTitle>{t("setting.title2")}</DetailPageTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <DetailPageForm style={{ alignItems: "flex-start" }}>
                  <DetailFormTitle>{t("setting.inputTitle4")}*</DetailFormTitle>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={cardPayment}
                      onChange={() => setCardPayment(!cardPayment)}
                    />
                    <PretendardText
                      fontWeight="500"
                      style={{
                        color: "#262C31",
                        fontSize: 15,
                      }}
                    >
                      {t("setting.card")}
                    </PretendardText>
                  </div>
                </DetailPageForm>
              </div>
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <SaveBtn
                  onClick={() => {
                    setType(2);
                    saveBtnHandler();
                  }}
                />
              </div>
            </DetailPage>
          </div>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default Setting;
