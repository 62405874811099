import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import FileImgPreview from "../../../component/molecules/fileImgPreview";

function ExhibitionModal({ data, setModalOpen, listHandler }) {
  const { t } = useTranslation();

  return (
    <>
      <ModalWrapper>
        <ModalBox>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "30px",
            }}
          >
            <TableTitle style={{ marginBottom: "0px" }}>{t("exhibition_modal.title")}</TableTitle>
            <img
              style={{
                cursor: "pointer",
              }}
              src="/assets/admin/icons/ico_popup_close.svg"
              alt=""
              onClick={() => setModalOpen(false)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              minHeight: "223px",
              overflowY: "auto",
            }}
          >
            <DetailPageForm>
              <DetailFormTitle>{t("exhibition_modal.title")}</DetailFormTitle>
              <DetailFormText>{data?.title}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("exhibition_modal.category")}</DetailFormTitle>
              <DetailFormText>
                {data?.category?.parentCategory?.title || ""} {" > "}
                {data?.category.title || ""}
              </DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("exhibition_modal.brand")}</DetailFormTitle>
              <DetailFormText>{data?.brand}</DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("exhibition_modal.amount")}</DetailFormTitle>
              <DetailFormText>
                {data?.originalPrice?.toLocaleString()}
                {t("component.monetary")}
              </DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("exhibition_modal.productType")}</DetailFormTitle>
              <DetailFormText></DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("exhibition_modal.image")}</DetailFormTitle>
              <FileImgPreview fileName={data?.imageOriginFileName} serverFileName={data?.imageServerFileName} />
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("exhibition_modal.count")}</DetailFormTitle>
              <DetailFormText></DetailFormText>
            </DetailPageForm>
            <DetailPageForm>
              <DetailFormTitle>{t("exhibition_modal.productCode")}</DetailFormTitle>
              <DetailFormText>{data?.merchCode}</DetailFormText>
            </DetailPageForm>
          </div>
        </ModalBox>
      </ModalWrapper>
    </>
  );
}

export default ExhibitionModal;

const ModalWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000060;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
`;

const ModalBox = styled.div.attrs((props) => {})`
  width: 1064px;
  min-height: 505px;
  padding: 50px;
  border: 1px solid #d7dbe0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
`;
