import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PretendardText from "../../component/atoms/text/pretendardText";
import { route } from "../../router/route";
import { useTranslation } from "react-i18next";

function JoinComplete(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <JoinCompleteWrapper>
      <JoinCompleteBox>
        <PretendardText
          style={{
            fontSize: "30px",
            fontWeight: "700",
            lineHeight: "41.5px",
            letterSpacing: "-0.21px",
            color: "#000",
            marginBottom: "8px",
          }}
        >
          {t(`auth.joinComplete`)}
        </PretendardText>
        <PretendardText
          style={{
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "32.5px",
            color: "#808991",
            marginBottom: "40px",
          }}
        >
          {t(`auth.completeCaption`)}
        </PretendardText>
        <JoinCompleteBtn onClick={() => navigate(route.login)}>
          {t(`auth.goLogin`)}
        </JoinCompleteBtn>
      </JoinCompleteBox>
    </JoinCompleteWrapper>
  );
}

export default JoinComplete;

const JoinCompleteWrapper = styled.div.attrs((props) => {})`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const JoinCompleteBox = styled.div.attrs((props) => {})`
  width: 518px;
  height: 343px;
  padding: 86px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 4px 8px 36px 0px rgba(0, 0, 0, 0.05);
`;

const JoinCompleteBtn = styled.button.attrs((props) => {})`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #009bde;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 3px;

  &:hover {
    background-color: #009bde;
  }
`;
