import axios from "axios";
import { Buffer } from "buffer";

export default class EfulfillApi {
  static async login() {
    const authHeader = "Basic " + Buffer.from(`koviet.api:${process.env.REACT_APP_EFULFILLMENT_KEY}`).toString("base64");

    try {
      const response = await axios.get("https://api.mdl.com.vn/auth/api/api/login", {
        headers: {
          Authorization: authHeader,
        },
      });

      sessionStorage.setItem("efulfillToken", response.data.data.access_token);
      sessionStorage.setItem("efulfillSecret", response.data.data.secret);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  static async getAddressList() {
    try {
      const efulfillToken = sessionStorage.getItem("efulfillToken");
      const efulfillSecret = sessionStorage.getItem("efulfillSecret");

      const response = await axios.get("https://api.mdl.com.vn/tmm/api/v1/loc/provinces", {
        headers: {
          Authorization: `Bearer ${efulfillToken}`,
          secret: efulfillSecret,
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  static async getAddressDistrictList(provinceCode) {
    try {
      const efulfillToken = sessionStorage.getItem("efulfillToken");
      const efulfillSecret = sessionStorage.getItem("efulfillSecret");

      const response = await axios.get("https://api.mdl.com.vn/tmm/api/v1/loc/districts", {
        headers: {
          Authorization: `Bearer ${efulfillToken}`,
          secret: efulfillSecret,
          "Content-Type": "application/json",
        },
        params: {
          provinceCode: provinceCode,
        },
      });

      return response;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  static async getAddressWardsList(districtCode) {
    try {
      const efulfillToken = sessionStorage.getItem("efulfillToken");
      const efulfillSecret = sessionStorage.getItem("efulfillSecret");

      const response = await axios.get("https://api.mdl.com.vn/tmm/api/v1/loc/wards", {
        headers: {
          Authorization: `Bearer ${efulfillToken}`,
          secret: efulfillSecret,
          "Content-Type": "application/json",
        },
        params: {
          districtCode: districtCode,
        },
      });

      return response;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  static async getDeliveryAmount(addressInfo) {
    try {
      const efulfillToken = sessionStorage.getItem("efulfillToken");
      const efulfillSecret = sessionStorage.getItem("efulfillSecret");

      const data = {
        weight: 3,
        paymentMethod: "NGTTS",
        pcs: 1,
        senderProvinceName: addressInfo[1].provinceName,
        senderDistrictName: addressInfo[1].districtName,
        receiverProvinceName: addressInfo[1].provinceName,
        receiverDistrictName: addressInfo[1].districtName,
      };

      const response = await axios.post("https://api.mdl.com.vn/omm/fulfillment/order/estimate_shipping_fee", data, {
        headers: {
          Authorization: `Bearer ${efulfillToken}`,
          secret: efulfillSecret,
          "Content-Type": "application/json",
        },
      });

      return response;
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }
}
