import React from "react";
import styled from "styled-components";

function DateInput({ style, iconStyle, onChange, value }) {
  return (
    <>
      <div
        style={{
          width: "338px",
          position: "relative",
        }}
      >
        <InputWrapper
          // required={true}
          // aria-required={true}
          type={"date"}
          data-placeholder="YYYY/MM/DD"
          style={{
            ...style,
          }}
          onChange={onChange}
          value={value}
        />
        {/* <img
          src="/assets/admin/icons/ico_celendar.svg"
          alt=""
          style={{
            position: 'absolute',
            right: '15px',
            top: '15px',
            ...iconStyle,
          }}
        /> */}
      </div>
    </>
  );
}

export default DateInput;

const InputWrapper = styled.input.attrs((props) => {})`
  width: 100%;
  height: 48px;
  border: 1px solid #b8bfc4;
  border-radius: 4px;
  background: inherit;
  padding: 13px 15px;
  color: #808991;
  font-family: Pretendard;
  font-weight: 400;
  font-size: 15px;
  line-height: 21.25px;

  :focus {
    outline: none !important;
    border-color: #27beff;
  }

  ::placeholder {
    color: #808991;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  :disabled {
    border-color: #b8bfc4;
    color: #808991 !important;
    background: #80899133 !important;
  }
`;
