import api from "./api.js";

export default class SettlementApi {
  // 정산 목록 조회
  static async getList(params) {
    return await api.get(`admin/settlement`, params);
  }

  // 정산 완료 처리
  static async update(data) {
    return await api.patch(`admin/settlement`, data);
  }
}
