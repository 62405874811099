import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MainLayout from "../../component/atoms/layout/mainLayout";
import PretendardText from "../../component/atoms/text/pretendardText";
import MainGraph from "../../component/molecules/mainGraph";
import Header from "../../component/organisms/header";
import Sidebar from "../../component/organisms/sidebar";
import { useTranslation } from "react-i18next";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // 기본 스타일 파일
import "react-date-range/dist/theme/default.css"; // 테마 css 파일
import moment from "moment";
import { errorHandler } from "../../util/errorHandler";
import SaleApi from "../../api/sale.api";
import HomeApi from "../../api/home.api";

function Home(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // ------------------------------- 상태 ------------------------------- //
  //방문자수, 날짜 정보
  const [isDay, setIsDay] = useState(true);
  const [totalSale, setTotalSale] = useState([]);
  const [graphMax, setGraphMax] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [totalMemberCount, setTotalMemberCount] = useState({
    memberActivationCount: 0,
    memberTotalCount: 0,
    memberRetiredCount: 0,
  });
  const [visitLine, setVisitLine] = useState([]);

  // ------------------------------- 마운트 ------------------------------- //
  useEffect(() => {
    getMemberCount(); // 회원수 가져오기
  }, []);

  // 회원수 가져오기
  const getMemberCount = async () => {
    try {
      const response = (await HomeApi.getCount()).data.data;
      setTotalMemberCount(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  // ------------------------------- 그래프 ------------------------------- //

  const getSale = async () => {
    try {
      const data = {
        page: currentPage,
        size: 10,
      };
      const response = (await SaleApi.getSale(isDay, data)).data.data;
      if (response) {
        setTotalSale(response.content.reverse());
      }
      //totalPage 구하기
      setTotalPages(Math.ceil(response.totalCount / 10));
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getSale();
  }, [currentPage, isDay]);

  useEffect(() => {
    let line = [];
    let max = 0;
    for (let i = 0; i < totalSale.length; i++) {
      if (max <= totalSale[i].count) {
        max = totalSale[i].count;
      }
    }
    setGraphMax(max);
    let per = max / 3;
    for (let i = 0; i < 3; i++) {
      line.push(Math.round(max - per * i));
    }
    setVisitLine(line);
  }, [totalSale]);

  // ------------------------------- 항목별 통계 ------------------------------- //
  const [topMemberList, setTopMemberList] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [bestList, setBestList] = useState([]);

  const [dateOpen, setDateOpen] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ]);

  const getTopMember = async () => {
    try {
      const data = {
        size: 8,
        startDate: date[0].startDate ? moment(date[0].startDate).format("YYYY.MM.DD") : "",
        endDate: date[0].endDate ? moment(date[0].endDate).format("YYYY.MM.DD") : "",
      };

      const response = (await SaleApi.getTopMember(data)).data.data.content;

      if (response) {
        setTopMemberList(response);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const getWishList = async () => {
    try {
      const data = {
        size: 8,
        startDate: date[0].startDate ? moment(date[0].startDate).format("YYYY.MM.DD") : "",
        endDate: date[0].endDate ? moment(date[0].endDate).format("YYYY.MM.DD") : "",
      };

      const response = (await SaleApi.getWishList(data)).data.data.content;

      if (response) {
        setWishList(response);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const getBest = async () => {
    try {
      const data = {
        size: 8,
        startDate: date[0].startDate ? moment(date[0].startDate).format("YYYY.MM.DD") : "",
        endDate: date[0].endDate ? moment(date[0].endDate).format("YYYY.MM.DD") : "",
      };

      const response = (await SaleApi.getBest(data)).data.data.content;

      if (response) {
        setBestList(response);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getTopMember();
    getWishList();
    getBest();
  }, [date]);

  const btnAreaRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnAreaRef.current && !btnAreaRef.current.contains(event.target)) {
        setDateOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <HomeWrapper>
          <HomeHeader>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <PretendardText
                style={{
                  color: "#808991",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "34px",
                  letterSpacing: "-0.06px",
                }}
              >
                {t(`home.welcome`)}
              </PretendardText>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <PretendardText
                  style={{
                    color: "#262C31",
                    fontSize: "40px",
                    fontWeight: "700",
                    lineHeight: "56px",
                    letterSpacing: "-0.28px",
                  }}
                >
                  {t(`home.hello`)}
                </PretendardText>
                <TotalCountWrapper>
                  {/* 전체 사용자 수 */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 14,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: "28px",
                        letterSpacing: "-0.3%",
                        color: "#636c73",
                      }}
                    >
                      {t(`home.allUser`)}
                    </PretendardText>
                    <TextDivider />
                    <PretendardText
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: "28px",
                        letterSpacing: "-0.3%",
                        color: "#636c73",
                      }}
                    >
                      {totalMemberCount?.memberActivationCount}명
                    </PretendardText>
                  </div>

                  {/* 회원(가입/탈퇴) */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 14,
                    }}
                  >
                    <PretendardText
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: "28px",
                        letterSpacing: "-0.3%",
                        color: "#636c73",
                      }}
                    >
                      {t(`home.user`)}
                    </PretendardText>
                    <TextDivider />
                    <PretendardText
                      style={{
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: "28px",
                        letterSpacing: "-0.3%",
                        color: "#636c73",
                      }}
                    >
                      {totalMemberCount?.memberTotalCount}/{totalMemberCount?.memberRetiredCount}
                    </PretendardText>
                  </div>
                </TotalCountWrapper>
              </div>
            </div>
          </HomeHeader>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <HomePretendardText style={{ marginBottom: "10px" }}>{t(`home.sales`)}</HomePretendardText>
              <div style={{ display: "flex", gap: "16px" }}>
                <div style={{ display: "flex", gap: "4px" }}>
                  <PretendardText
                    onClick={() => setIsDay(true)}
                    style={{
                      fontSize: "15px",
                      fontWeight: isDay ? 700 : 500,
                      lineHeight: "21.5px",
                      color: isDay ? "#000" : "#808991",
                      cursor: "pointer",
                    }}
                  >
                    {t(`home.day`)}
                  </PretendardText>
                  {isDay && <img src="/assets/admin/icons/check.png" alt="" style={{ width: "18px", height: "18px" }} />}
                </div>
                <div style={{ display: "flex", gap: "4px" }}>
                  <PretendardText
                    onClick={() => setIsDay(false)}
                    style={{
                      fontSize: "15px",
                      fontWeight: !isDay ? 700 : 500,
                      lineHeight: "21.5px",
                      color: !isDay ? "#000" : "#808991",
                      cursor: "pointer",
                    }}
                  >
                    {t(`home.month`)}
                  </PretendardText>
                  {!isDay && <img src="/assets/admin/icons/check.png" alt="" style={{ width: "18px", height: "18px" }} />}
                </div>
              </div>
            </div>
            <MainGraph visit={totalSale} graphMax={graphMax} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} visitLine={visitLine} isDay={isDay} />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <HomePretendardText>{t(`home.statistics`)}</HomePretendardText>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  position: "relative",
                }}
              >
                {date[0].startDate && date[0].endDate ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <PretendardText
                      style={{
                        fontSize: "15px",
                        color: "#808991",
                        fontWeight: 500,
                      }}
                    >
                      {moment(date[0].startDate).format("YYYY.MM.DD")}
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontSize: "15px",
                        color: "#808991",
                        fontWeight: 500,
                      }}
                    >
                      ~
                    </PretendardText>
                    <PretendardText
                      style={{
                        fontSize: "15px",
                        color: "#808991",
                        fontWeight: 500,
                      }}
                    >
                      {moment(date[0].endDate).format("YYYY.MM.DD")}
                    </PretendardText>
                  </div>
                ) : (
                  <PretendardText
                    style={{
                      fontSize: "15px",
                      color: "#808991",
                      fontWeight: 500,
                    }}
                  >
                    {t(`placeholder.datePlaceholder02`)}
                  </PretendardText>
                )}
                <img
                  onClick={() => setDateOpen(!dateOpen)}
                  src="/assets/admin/icons/ico_arrow_down.svg"
                  alt="up"
                  style={{
                    cursor: "pointer",
                    width: "28px",
                    height: "28px",
                  }}
                />
                {dateOpen && (
                  <div style={{ position: "absolute", top: "38px" }} ref={btnAreaRef}>
                    <DateRange editableDateInputs={true} onChange={(item) => setDate([item.selection])} moveRangeOnFirstSelection={false} ranges={date} />
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginBottom: "100px",
                gap: "20px",
              }}
            >
              <RankWrapper>
                <RankTitle>{t(`home.influencer`)}</RankTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {topMemberList.map((item, index) => {
                    return (
                      <RankInfoWrapper key={index}>
                        <RankInfoTitle>{item.nickname}</RankInfoTitle>
                        <RankInfo>
                          {item.totalPayAmount?.toLocaleString()}
                          {t(`component.monetary`)}
                        </RankInfo>
                      </RankInfoWrapper>
                    );
                  })}
                </div>
              </RankWrapper>
              <RankWrapper>
                <RankTitle>{t(`home.best`)}</RankTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {bestList.map((item, index) => {
                    return (
                      <RankInfoWrapper key={index}>
                        <RankInfoTitle>{item.title}</RankInfoTitle>
                        <RankInfo>
                          {item.count.toLocaleString()}
                          {t(`component.count`)}
                        </RankInfo>
                      </RankInfoWrapper>
                    );
                  })}
                </div>
              </RankWrapper>
              <RankWrapper>
                <RankTitle>{t(`home.wishList`)}</RankTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {wishList.map((item, index) => {
                    return (
                      <RankInfoWrapper key={index}>
                        <RankInfoTitle>{item.title}</RankInfoTitle>
                        <RankInfo>
                          {item.count.toLocaleString()}
                          {t(`component.count02`)}
                        </RankInfo>
                      </RankInfoWrapper>
                    );
                  })}
                </div>
              </RankWrapper>
            </div>
          </div>
        </HomeWrapper>
      </MainLayout>
    </>
  );
}

export default Home;

const HomeWrapper = styled.div.attrs((props) => {})`
  width: 1044px;
  height: auto;
  margin-top: 190px;
`;

const HomeHeader = styled.div.attrs((props) => {})`
  width: 1044px;
  height: 90px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const TotalCountWrapper = styled.div.attrs((props) => {})`
  padding: 14px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  background: #f2f4f5;
  border-radius: 6px;
`;

const HomePretendardText = styled(PretendardText).attrs((props) => {})`
  color: #262c31;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.048px;
`;

const TextDivider = styled.span`
  height: 10px;
  width: 1px;
  background-color: #b8bfc4;
`;

const RankWrapper = styled.div.attrs((props) => {})`
  padding: 27px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #d7dbe0;
  width: 334px;
  min-height: 350px;
`;

const RankTitle = styled(PretendardText).attrs((props) => {})`
  color: #27beff;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
`;

const RankInfoWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-radius: 8px;
`;

const RankInfoTitle = styled(PretendardText).attrs((props) => {})`
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  line-height: 24.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RankInfo = styled(PretendardText).attrs((props) => {})`
  color: #898989;
  font-size: 14px;
  font-weight: 400;
  line-height: 24.5px;
  min-width: 25px;
  text-align: right;
`;
