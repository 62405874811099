import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

function TableDelBtn({ style, text, onClick }) {
  const { t } = useTranslation();

  return (
    <>
      <TableDelBtnWrapper style={{ ...style }} onClick={onClick}>
        {text ?? `${t("component.delete")}`}
      </TableDelBtnWrapper>
    </>
  );
}

export default TableDelBtn;

const TableDelBtnWrapper = styled.button.attrs((props) => {})`
  display: flex;
  padding: 3px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #ef594b;
  background: #fff;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.75px;
  color: #ef594b;
  cursor: pointer;
`;
