import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import AddBtn from "../../../component/atoms/button/addBtn";
import DelBtn from "../../../component/atoms/button/delBtn";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableFilter from "../../../component/atoms/layout/table/tableFilter";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import CategoryApi from "../../../api/category.api";
import { useRecoilState, useSetRecoilState } from "recoil";
import { categoryOpenListState } from "./categoryState";

const Category = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // mounted
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getCategoryList();
  }, [location]);

  // ----- 목록 가져오기
  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  const [categoryList, setCategoryList] = useState([1]);
  let [categoryOpenList, setCategoryOpenList] = useRecoilState(categoryOpenListState);
  const [keyword, setKeyword] = useState("");

  const getCategoryList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await CategoryApi.getList(data)).data.data;
      setCategoryList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
            rotated: categoryOpenList.includes(item.id),
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 활성/비활성
  const [filterOpen, setFilterOpen] = useState(-1);
  const [activationId, setActivationId] = useState(null);
  const [activation, setActivation] = useState(null);
  const [activationModalTitle, setActivationModalTitle] = useState("");
  const [activationModalOpen, setActivationModalOpen] = useState(false);

  const activationFunc = async () => {
    try {
      const data = {
        activation: activation,
      };
      await CategoryApi.activation(activationId, data);
      toast(t("toastMsg.create"));
      setFilterOpen(-1);
      setActivationModalOpen(false);
      getCategoryList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const isAllCheck = useMemo(() => {
    const checkedList = categoryList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === categoryList.length;
  }, [categoryList]);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: delIdList,
      };
      await CategoryApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      setDelIdList([]);
      getCategoryList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // 카테고리 순서 변경
  const handleChangeOrder = async (requestCategoryId, requestedCategoryId) => {
    try {
      const data = {
        requestedCategoryId: requestedCategoryId,
      };
      await CategoryApi.change(requestCategoryId, data);
      getCategoryList();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{t("category.title")}</TableTitle>
          <TableTop>
            <TableCount text={t("category.userCount")} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.category}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.category}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <DelBtn
                text={t("category.delBtn")}
                onClick={() => {
                  const delList = [];

                  categoryList.forEach((item) => {
                    if (item.checked) {
                      delList.push(item.id);
                    }
                    if (item.childCategories) {
                      item.childCategories.forEach((childItem) => {
                        if (childItem.checked) {
                          delList.push(childItem.id);
                        }
                      });
                    }
                  });

                  if (delList.length <= 0) {
                    return;
                  }

                  setDelIdList(delList);
                  setDeleteModalOpen(true);
                }}
              />
              <AddBtn onClick={() => navigate(route.category_add)} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              <col width="120px" />
              <col width="230px" />
              <col width="158px" />
              <col width="279px" />
              <col width="133px" />
              <col width="124px" />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCategoryList(
                          categoryList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setCategoryList(
                          categoryList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t("category.categoryName")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("category.type")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("category.topCategory")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("category.state")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("category.more")}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {categoryList.map((item, index) => {
                return (
                  <>
                    <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                      <Td style={{ paddingLeft: "20px" }}>
                        <div style={{ display: "flex", gap: "22px" }}>
                          <Checkbox
                            checked={item.checked}
                            onChange={() => {
                              item.checked = !item.checked;
                              setCategoryList((prev) => [...prev]);
                            }}
                          />
                          <div style={{ display: "flex", gap: "5px" }}>
                            <img
                              src="/assets/admin/icons/ico_arrow_up.svg"
                              alt="up"
                              style={{
                                cursor: "pointer",
                                width: "18px",
                                height: "18px",
                              }}
                              onClick={() => handleChangeOrder(item.id, categoryList[index - 1].id)}
                            />
                            <img
                              src="/assets/admin/icons/ico_arrow_down.svg"
                              alt="down"
                              style={{
                                cursor: "pointer",
                                width: "18px",
                                height: "18px",
                              }}
                              onClick={() => handleChangeOrder(item.id, categoryList[index + 1].id)}
                            />
                          </div>
                        </div>
                      </Td>
                      <Td>
                        <TableText>{item.title}</TableText>
                      </Td>
                      <Td>
                        <TableText>{t("category.category1")}</TableText>
                      </Td>
                      <Td>
                        <TableText>-</TableText>
                      </Td>
                      <Td>
                        <TableFilter
                          onClick={() => {
                            filterOpen === item.id ? setFilterOpen(-1) : setFilterOpen(item.id);
                          }}
                          isOpen={filterOpen === item.id}
                          selectedValue={item.activation ? item.activation : false}
                          list={[
                            {
                              value: true,
                              title: `${t("component.active")}`,
                              onClick: () => {
                                setActivationId(item.id);
                                setActivation(true);
                                setActivationModalTitle(`${t("modalMsg.deactivate")}`);
                                setActivationModalOpen(true);
                              },
                            },
                            {
                              value: false,
                              title: `${t("component.inactive")}`,
                              onClick: () => {
                                setActivationId(item.id);
                                setActivation(false);
                                setActivationModalTitle(`${t("modalMsg.deactivate")}`);
                                setActivationModalOpen(true);
                              },
                            },
                          ]}
                          setFilterOpen={setFilterOpen}
                        />
                      </Td>
                      <Td>
                        <div style={{ display: "flex", gap: "22px" }}>
                          <DetailBtn
                            onClick={() => {
                              navigate(route.category_detail + `/${item.id}`);
                            }}
                          />
                          <img
                            className={item.rotated ? "rotate" : "rotate-back"}
                            style={{
                              cursor: "pointer",
                            }}
                            src="/assets/admin/icons/ico_arrow_down.svg"
                            alt="more"
                            onClick={() => {
                              item.rotated = !item.rotated;
                              setCategoryList([...categoryList]);
                              if (item.rotated) {
                                const tempList = [...categoryOpenList];
                                tempList.push(item.id);
                                setCategoryOpenList([...tempList]);
                              } else {
                                const tempList = categoryOpenList.filter((value) => value !== item.id);
                                setCategoryOpenList([...tempList]);
                              }
                            }}
                          />
                        </div>
                      </Td>
                    </Tr>
                    {item.rotated &&
                      item.childCategories.map((childItem, childIndex) => {
                        return (
                          <Tr key={childIndex} style={{ height: "59px", background: "#f2f4f5" }}>
                            <Td style={{ paddingLeft: "20px" }}>
                              <div style={{ display: "flex", gap: "22px" }}>
                                <Checkbox
                                  checked={childItem.checked}
                                  onChange={() => {
                                    childItem.checked = !childItem.checked;
                                    setCategoryList((prev) => [...prev]);
                                  }}
                                />
                                <div style={{ display: "flex", gap: "5px" }}>
                                  <img
                                    src="/assets/admin/icons/ico_arrow_up.svg"
                                    alt="up"
                                    style={{
                                      cursor: "pointer",
                                      width: "18px",
                                      height: "18px",
                                    }}
                                    onClick={() => handleChangeOrder(childItem.id, item.childCategories[childIndex - 1].id)}
                                  />
                                  <img
                                    src="/assets/admin/icons/ico_arrow_down.svg"
                                    alt="down"
                                    style={{
                                      cursor: "pointer",
                                      width: "18px",
                                      height: "18px",
                                    }}
                                    onClick={() => handleChangeOrder(childItem.id, item.childCategories[childIndex + 1].id)}
                                  />
                                </div>
                              </div>
                            </Td>
                            <Td>
                              <TableText>{childItem.title}</TableText>
                            </Td>
                            <Td>
                              <TableText>{t("category.category2")}</TableText>
                            </Td>
                            <Td>
                              <TableText>{item.title}</TableText>
                            </Td>
                            <Td>
                              <TableFilter
                                onClick={() => {
                                  filterOpen === childItem.id ? setFilterOpen(-1) : setFilterOpen(childItem.id);
                                }}
                                isOpen={filterOpen === childItem.id}
                                selectedValue={childItem.activation || false}
                                list={[
                                  {
                                    value: true,
                                    title: `${t("component.active")}`,
                                    onClick: () => {
                                      setActivationId(childItem.id);
                                      setActivation(true);
                                      setActivationModalTitle(`${t("modalMsg.activate")}`);
                                      setActivationModalOpen(true);
                                    },
                                  },
                                  {
                                    value: false,
                                    title: `${t("component.inactive")}`,
                                    onClick: () => {
                                      setActivationId(childItem.id);
                                      setActivation(false);
                                      setActivationModalTitle(`${t("modalMsg.deactivate")}`);
                                      setActivationModalOpen(true);
                                    },
                                  },
                                ]}
                                setFilterOpen={setFilterOpen}
                              />
                            </Td>
                            <Td>
                              <div style={{ display: "flex", gap: "22px" }}>
                                <DetailBtn
                                  onClick={() => {
                                    sessionStorage.setItem("categoryId", item.id);
                                    navigate(route.category_detail + `/${childItem.id}`);
                                  }}
                                />
                              </div>
                            </Td>
                          </Tr>
                        );
                      })}
                  </>
                );
              })}
            </tbody>
          </Table>
          {/* <Pagination
            route={route.category}
            queryString={`keyword=${searchParams.get("keyword")}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          /> */}
        </TableLayout>
      </MainLayout>

      {activationModalOpen ? (
        <Modal
          title={`${activationModalTitle}?`}
          setModalOpen={setActivationModalOpen}
          onClick={() => {
            activationFunc();
          }}
        />
      ) : null}

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};
export default Category;
