import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import PopupApi from "../../../api/popup.api";
import { errorHandler } from "../../../util/errorHandler";
import Header from "../../../component/organisms/header";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Sidebar from "../../../component/organisms/sidebar";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import GoBack from "../../../component/molecules/goBack";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import ImgPreview from "../../../component/molecules/imgPreview";
import ImgInput from "../../../component/atoms/input/imgInput";
import MainInput from "../../../component/atoms/input/mainInput";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import { isNull } from "../../../util/check";
import { toast } from "react-toastify";
import moment from "moment";
import Modal from "../../../component/templates/Modal";
import { useTranslation } from "react-i18next";
import PopupApi from "./_api/popup.api";
import { fileUrl } from "../../../api/api";
import CommonApi from "../../../api/common.api";

const PopupEdit = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getPopupInfo();
  }, []);

  // ----- 정보 가져오기
  const [popupInfo, setPopupInfo] = useState({
    title: "",
    images: [
      {
        // 이미지
        imageOriginFileName: "", // test.png
        imageServerFileName: "", // popup/4e1faa90-413c-4fc2-8323-ed3c5231e7f7.png
      },
    ],
  });

  const getPopupInfo = async () => {
    try {
      const response = (await PopupApi.getById(id)).data.data.content;
      setPopupInfo({ ...response });

      setImgList((prevList) => ({
        ...prevList,
        img1: {
          ...prevList.img1,
          id: response.images[0]?.id || 0,
          imageOriginFileName: response.images[0]?.imageOriginFileName || "",
          imageServerFileName: response.images[0]?.imageServerFileName || "",
        },
        img2: {
          ...prevList.img2,
          id: response.images[1]?.id || 0,
          imageOriginFileName: response.images[1]?.imageOriginFileName || "",
          imageServerFileName: response.images[1]?.imageServerFileName || "",
        },
        img3: {
          ...prevList.img3,
          id: response.images[2]?.id || 0,
          imageOriginFileName: response.images[2]?.imageOriginFileName || "",
          imageServerFileName: response.images[2]?.imageServerFileName || "",
        },
        img4: {
          ...prevList.img4,
          id: response.images[3]?.id || 0,
          imageOriginFileName: response.images[3]?.imageOriginFileName || "",
          imageServerFileName: response.images[3]?.imageServerFileName || "",
        },
        img5: {
          ...prevList.img5,
          id: response.images[4]?.id || 0,
          imageOriginFileName: response.images[4]?.imageOriginFileName || "",
          imageServerFileName: response.images[4]?.imageServerFileName || "",
        },
        img6: {
          ...prevList.img6,
          id: response.images[5]?.id || 0,
          imageOriginFileName: response.images[5]?.imageOriginFileName || "",
          imageServerFileName: response.images[5]?.imageServerFileName || "",
        },
      }));
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----------------------------------------------- //
  // ----------------- 이미지 관련 ----------------- //
  // --------------------------------------------- //

  const [delImgIdList, setDelImgIdList] = useState([]);

  const [imgList, setImgList] = useState({
    img1: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img2: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img3: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img4: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
    img5: {
      status: false,
      file: "",
      imageLocalFileUrl: "",
      imageOriginFileName: "",
      imageServerFileName: "",
    },
  });

  // 이미지 추가 삭제
  const inputFile = async (e, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = (value) => {
      setImgList({
        ...imgList,
        [type]: {
          ...imgList[type],
          status: true,
          file: file,
          imageLocalFileUrl: value.target.result,
        },
      });
    };

    e.target.value = "";
  };

  // 파일 업로드
  const fileUpload = async (file) => {
    try {
      const data = {
        path: "popup/",
        extension: file.name.split(".").pop(),
      };

      const response = (await CommonApi.fileUpload(data)).data.data;

      await cloudFileUpload(response.presignedUrl, file);

      return {
        imageOriginFileName: file.name,
        imageServerFileName: response.serverFileName,
      };
    } catch (error) {
      errorHandler(error);
    }
  };

  const cloudFileUpload = async (url, file) => {
    try {
      await CommonApi.cloudFileUpload(url, file);
    } catch (error) {
      errorHandler(error);
    }
  };

  // 이미지 삭제
  const deleteFile = (type) => {
    setImgList({
      ...imgList,
      [type]: {
        ...imgList[type],
        file: "",
        imageLocalFileUrl: "",
      },
    });
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (isNull(popupInfo.title)) {
      return toast(`${t("toastMsg.input_empty")}`);
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    const firstFilteredImgList = Object.values(imgList).filter((img) => img.file !== "");

    const uploadedImages = await Promise.all(firstFilteredImgList.map((img) => fileUpload(img.file)));

    try {
      const data = {
        title: popupInfo.title,
        images: [],
        deleteIds: delImgIdList,
      };

      uploadedImages.forEach((img) => {
        if (img) {
          data.images.push({
            imageOriginFileName: img.imageOriginFileName,
            imageServerFileName: img.imageServerFileName,
          });
        }
      });

      await PopupApi.update(id, data);
      toast(t("toastMsg.update"));
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "440px" }}>
            <DetailPageTitle>{t("popup.edit")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>{t("popup.popupName")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.defaultPlaceholder")}
                  value={popupInfo.title}
                  onChange={(e) => setPopupInfo({ ...popupInfo, title: e.target.value })}
                />
              </DetailPageForm>
              <DetailPageForm>
                <div>
                  <DetailFormTitle>{t("popup.image")}</DetailFormTitle>
                  <DetailFormTitle style={{ fontWeight: "400" }}>*{t("popup.fullCaption")}</DetailFormTitle>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "end",
                    height: "fit-content",
                  }}
                >
                  {/* 상품 이미지 - 1 */}
                  {imgList.img1.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img1.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img1.id]);
                        setImgList({
                          ...imgList,
                          img1: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img1.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img1.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img1");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img1"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img1");
                      }}
                    />
                  )}

                  {/* 상품 이미지 - 2 */}
                  {imgList.img2.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img2.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img2.id]);
                        setImgList({
                          ...imgList,
                          img2: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img2.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img2.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img2");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img2"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img2");
                      }}
                    />
                  )}

                  {/* 상품 이미지 - 3 */}
                  {imgList.img3.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img3.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img3.id]);
                        setImgList({
                          ...imgList,
                          img3: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img3.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img3.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img3");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img3"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img3");
                      }}
                    />
                  )}

                  {/* 상품 이미지 - 4 */}
                  {imgList.img4.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img4.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img4.id]);
                        setImgList({
                          ...imgList,
                          img4: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img4.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img4.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img4");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img4"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img4");
                      }}
                    />
                  )}

                  {/* 상품 이미지 - 5 */}
                  {imgList.img5.imageServerFileName ? (
                    <ImgPreview
                      fileUrl={`${fileUrl}/${imgList.img5.imageServerFileName}`}
                      onClick={() => {
                        setDelImgIdList([...delImgIdList, imgList.img5.id]);
                        setImgList({
                          ...imgList,
                          img5: {
                            file: "",
                            imageLocalFileUrl: "",
                            imageOriginFileName: "",
                            imageServerFileName: "",
                          },
                        });
                      }}
                    />
                  ) : imgList.img5.imageLocalFileUrl ? (
                    <ImgPreview
                      fileUrl={imgList.img5.imageLocalFileUrl}
                      onClick={() => {
                        deleteFile("img5");
                      }}
                    />
                  ) : (
                    <ImgInput
                      id={"img5"}
                      accept={"image/*"}
                      onChange={(e) => {
                        inputFile(e, "img5");
                      }}
                    />
                  )}
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? <Modal title={`${t("modalMsg.update.title")}`} text={`${t("modalMsg.update.content")}`} setModalOpen={setSaveModalOpen} onClick={saveFunc} /> : null}
    </>
  );
};

export default PopupEdit;
