import api from "../../../../api/api";

export default class CouponApi {
  static async createCoupon(data) {
    return await api.post(`admin/coupon`, data);
  }
  static async getCoupon(param) {
    return await api.get(`admin/member-coupon`, param);
  }
  static async deleteCoupon(data) {
    return await api.del(`admin/member-coupon`, data);
  }
  static async usedCoupon(data) {
    return await api.patch(`admin/member-coupon/used`, data);
  }
  static async cancelCoupon(data) {
    return await api.patch(`admin/member-coupon/cancel`, data);
  }
}
