import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ko } from "./lang/ko";
import { vi } from "./lang/vi";

const resources = {
  KR: { translation: ko },
  VN: { translation: vi },
};

const savedLanguage = localStorage.getItem("language") || "KR";

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage,
  fallbackLng: "KR",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
