import api from "./api.js";

export default class SaleApi {
  // 판매 현황
  static async getSale(type, params) {
    return await api.get(`admin/order/sale/statistics/${type}`, params);
  }

  // 탑 인플루언서 조회
  static async getTopMember(params) {
    return await api.get(`admin/point-history/top-member/statistics`, params);
  }

  // 위시리스트에 가장 많이 담긴 상품
  static async getWishList(params) {
    return await api.get(`admin/wish/sale/statistics`, params);
  }

  // 베스트셀러
  static async getBest(params) {
    return await api.get(`admin/member-merch/best/statistics`, params);
  }
}
