import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PretendardText from "../text/pretendardText";
import { useTranslation } from "react-i18next";

function ExcelBtn({ onSelectClick, onAllClick, style }) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState();

  const btnAreaRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnAreaRef.current && !btnAreaRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <ExcelBtnWrapper
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        style={{ ...style, cursor: "pointer" }}
      >
        <img src="/assets/admin/icons/ico_excel_download.svg" alt="" />
        <PretendardText
          style={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "22.75px",
            color: "#FFF",
          }}
        >
          {t(`component.excel`)}
        </PretendardText>
        <img src="/assets/admin/icons/ico_excel_arrow.svg" alt="" />
      </ExcelBtnWrapper>
      {isOpen ? (
        <SubmenuWrapper ref={btnAreaRef}>
          <SubMenuText
            onClick={() => {
              onSelectClick();
              setIsOpen(false);
            }}
          >
            {t(`component.selectExcel`)}
          </SubMenuText>
          <SubMenuText
            onClick={() => {
              onAllClick();
              setIsOpen(false);
            }}
          >
            {t(`component.allExcel`)}
          </SubMenuText>
        </SubmenuWrapper>
      ) : (
        ""
      )}
    </div>
  );
}

export default ExcelBtn;

const ExcelBtnWrapper = styled.div.attrs((props) => {})`
  padding: 10px 14px;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 23px;
  background: #333;
  cursor: pointer;
`;

const SubmenuWrapper = styled.div.attrs((props) => {})`
  padding: 10px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 48px;
  z-index: 5;
  width: fit-content;
`;

const SubMenuText = styled(PretendardText).attrs((props) => {})`
  padding: 10px;
  color: #262c31;
  font-size: 15px;
  font-weight: 500;
  line-height: 21.5px;
  cursor: pointer;
  width: max-content;
  border-radius: 3px;

  &:hover {
    background-color: #f2f4f5;
  }
`;
