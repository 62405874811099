// 자주사용문구 - 한국어
export const toastMsg = {
  create: "저장되었습니다.",
  update: "저장되었습니다.",
  delete: "삭제되었습니다.",

  //입력창이 비어있을 때
  input_empty: "필수 요소를 전부 입력해주세요.",

  // 서버에러 500
  errorText_500: "서버에 문제가 생겼습니다. 잠시 후에 다시 시도해주세요",

  productAddToast: "상품이 등록되었습니다. 옵션을 설정해주세요.",
};
