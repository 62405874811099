import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
// import ExcelApi from "../../../api/excel.api";
// import MemberApi from "../../../api/memebr.api";
import DetailBtn from "../../../component/atoms/button/detailBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import TableDelBtn from "../../../component/atoms/button/tableDelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import Tab from "../../../component/templates/tab";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import CouponApi from "./_api/coupon.api";
import CouponModal from "./couponModal";
import ExcelApi from "../../../api/excel.api";
import { saveAs } from "file-saver";
import { isNull } from "../../../util/check";

const CouponHistory = () => {
  const { t, i18n } = useTranslation();
  const isVn = i18n.language === "VN";

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(
      isNull(searchParams.get("keyword")) ? "" : searchParams.get("keyword")
    );
    getMemberList();
  }, [location]);

  const [couponHistoryList, setCouponHistoryList] = useState([1]);

  const getMemberList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };
      let responseData = (await CouponApi.getCoupon(data)).data.data;
      setCouponHistoryList(
        responseData.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );
      setPagination({
        ...pagination,
        totalCount: responseData.totalCount ? responseData.totalCount : 0,
        totalPages: Math.ceil(responseData.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllCheck = useMemo(() => {
    const checkedList = couponHistoryList.filter((item) => item.checked);
    return (
      checkedList.length !== 0 &&
      checkedList.length === couponHistoryList.length
    );
  }, [couponHistoryList]);

  const [title, setTitle] = useState(`${t("coupon_history.title")}`);
  const [subTitle, setSubTitle] = useState(`${t("coupon_history.userCount")}`);

  // ----- 삭제
  const [delIdList, setDelIdList] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: delIdList,
      };
      await CouponApi.deleteCoupon(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      setDelIdList([]);
      getMemberList();
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 취소
  const [cancelIdList, setCancelIdList] = useState([]);
  const [canceleteModalOpen, setCanceleteModalOpen] = useState(false);

  const cancelFunc = async () => {
    try {
      const data = {
        ids: cancelIdList,
      };
      await CouponApi.cancelCoupon(data);
      toast(t("toastMsg.update"));
      setCanceleteModalOpen(false);
      setCancelIdList([]);
      getMemberList();
    } catch (error) {
      errorHandler(error);
    }
  };

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: t("coupon_history.email"), key: "email", width: 40 },
      { header: t("coupon_history.couponName"), key: "title", width: 40 },
      { header: t("coupon_history.type"), key: "type", width: 40 },
      { header: t("coupon_history.createdAt"), key: "createdAt", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${t("coupon_history.tab2")}.xlsx`);
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetCouponExcel({
          ids: [],
          allFlag: true,
        })
      ).data.data.content;

      response.map((item) => {
        const type =
          item.couponForm === "INDIVIDUAL"
            ? t("coupon_history.type1")
            : t("coupon_history.type2");
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          title: item.coupon?.title,
          createdAt: createDate,
          type: type,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = couponHistoryList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast(t("placeholder.selectItem"));
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetCouponExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
        })
      ).data.data.content;

      response.map((item) => {
        const type =
          item.couponForm === "INDIVIDUAL"
            ? t("coupon_history.type1")
            : t("coupon_history.type2");
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          title: item.coupon?.title,
          createdAt: createDate,
          type: type,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  const [selectedData, setSelectedData] = useState({});
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <Tab
            list={[
              { title: `${t("coupon_history.tab1")}`, url: route.coupon_add },
              {
                title: `${t("coupon_history.tab2")}`,
                url: route.coupon_issued,
              },
            ]}
            style={{ marginBottom: "20px" }}
          />
          <TableTitle>{title}</TableTitle>
          <TableTop>
            <TableCount text={subTitle} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(
                      `${route.coupon_issued}?page=1&keyword=${keyword}`,
                      {
                        replace: true,
                      }
                    );
                  }
                }}
                onClick={() => {
                  navigate(`${route.coupon_issued}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <ExcelBtn
                onSelectClick={getExcelChecked}
                onAllClick={getExcelAll}
              />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 영역 */}
              <col width={isVn ? "90px" : "90px"} />
              {/* 이메일 */}
              <col width={isVn ? "254px" : "338px"} />
              {/* 쿠폰명 */}
              <col width={isVn ? "200px" : "229px"} />
              {/* 형식 */}
              <col width={isVn ? "173px" : "97px"} />
              {/* 발급일 */}
              <col width={isVn ? "122px" : "130px"} />
              {/* 발급취소 */}
              <col width={isVn ? "111px" : "82px"} />
              {/* 상세보기 */}
              <col width={isVn ? "88px" : "78px"} />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCouponHistoryList(
                          couponHistoryList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setCouponHistoryList(
                          couponHistoryList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t("coupon_history.email")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("coupon_history.couponName")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("coupon_history.type")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("coupon_history.createdAt")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("coupon_history.del")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("coupon_history.more")}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {couponHistoryList.map((item, index) => {
                return (
                  <Tr
                    key={index}
                    style={{ height: "59px", background: "#fff" }}
                  >
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setCouponHistoryList([...couponHistoryList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText style={{ width: "284px" }}>
                        {item.email}
                      </TableText>
                    </Td>
                    <Td>
                      <TableText>{item.coupon?.title}</TableText>
                    </Td>
                    <Td>
                      <TableText>
                        {item.coupon?.couponForm === "INDIVIDUAL"
                          ? t("coupon_history.type1")
                          : t("coupon_history.type2")}
                      </TableText>
                    </Td>
                    <Td>
                      <TableText>
                        {moment(item.createdAt).format("YYYY/MM/DD")}
                      </TableText>
                    </Td>
                    <Td>
                      {item.couponStatus === "CANCEL" ||
                      item.couponStatus === "USED" ? (
                        <TableText>
                          {item.couponStatus === "USED"
                            ? t("coupon_detail.complete")
                            : t("coupon_detail.cancelComplete")}
                        </TableText>
                      ) : (
                        <TableDelBtn
                          text={t("component.cancel")}
                          style={{ marginLeft: "6px" }}
                          onClick={() => {
                            const cancelIdList = [item];
                            setCancelIdList(
                              cancelIdList.map((item) => item.id)
                            );
                            setCanceleteModalOpen(true);
                          }}
                        />
                      )}
                    </Td>
                    <Td>
                      <DetailBtn
                        onClick={() => {
                          setSelectedData(item);
                          setDetailModalOpen(true);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.coupon_issued}
            queryString={`keyword=${searchParams.get("keyword") || ""}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}

      {canceleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.couponCancel.title")}`}
          text={`${t("modalMsg.couponCancel.content")}`}
          setModalOpen={setCanceleteModalOpen}
          onClick={() => {
            cancelFunc();
          }}
        />
      ) : null}

      {detailModalOpen ? (
        <CouponModal data={selectedData} setModalOpen={setDetailModalOpen} listHandler={getMemberList}/>
      ) : (
        ""
      )}
    </>
  );
};

export default CouponHistory;
