import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import PromotionApi from "../../../api/exhibition.api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import DateInput from "../../../component/atoms/input/dateInput";
import ImgInput from "../../../component/atoms/input/imgInput";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import ImgPreview from "../../../component/molecules/imgPreview";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { isNull } from "../../../util/check";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import Radio from "../../../component/atoms/input/radio";
import PromotionApi from "../../../api/promotion.api";
import CommonApi from "../../../api/common.api";
import { fileUrl } from "../../../api/api";

const ExhibitionEdit = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();

  const [promotionInfo, setPromotionInfo] = useState({});

  useEffect(() => {
    getPromotionInfo();
  }, []);

  const getPromotionInfo = async () => {
    try {
      const response = (await PromotionApi.getById(id)).data.data.content;
      setPromotionInfo(response);
    } catch (error) {
      errorHandler(error);
    }
  };

  //이미지 추가, 삭제
  const inputFile = (e, type) => {
    const file = e.target.files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (value) => {
      if (type === "file") {
        setPromotionInfo({
          ...promotionInfo,
          file: file,
          fileUrl: value.target.result,
        });
      }
      if (type === "banner") {
        setPromotionInfo({
          ...promotionInfo,
          banner: file,
          bannerUrl: value.target.result,
        });
      }
      if (type === "mobileBanner") {
        setPromotionInfo({
          ...promotionInfo,
          mobileBanner: file,
          mobileBannerUrl: value.target.result,
        });
      }
    };
    e.target.value = "";
  };

  const deleteFile = (type) => {
    if (type === "file") {
      setPromotionInfo({
        ...promotionInfo,
        file: "",
        fileUrl: "",
        imageOriginFileName: "",
        imageServerFileName: "",
      });
    }
    if (type === "banner") {
      setPromotionInfo({
        ...promotionInfo,
        banner: "",
        bannerUrl: "",
        bannerImageOriginFileName: "",
        bannerImageServerFileName: "",
      });
    }
    if (type === "mobileBanner") {
      setPromotionInfo({
        ...promotionInfo,
        mobileBanner: "",
        mobileBannerUrl: "",
        mobileImageOriginFileName: "",
        mobileImageServerFileName: "",
      });
    }
  };

  // 파일 업로드
  const fileUpload = async (file, type) => {
    try {
      const data = {
        path: `promotion/${type}`,
        extension: file.name.split(".").pop(),
      };

      const response = (await CommonApi.fileUpload(data)).data.data;

      await cloudFileUpload(response.presignedUrl, file);

      return {
        serverFileName: response.serverFileName,
        originFileName: file.name,
      };
    } catch (error) {
      errorHandler(error);
    }
  };

  const cloudFileUpload = async (url, file) => {
    try {
      await CommonApi.cloudFileUpload(url, file);
    } catch (error) {
      errorHandler(error);
    }
  };

  //저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    if (
      isNull(promotionInfo.title) ||
      isNull(promotionInfo.startDate) ||
      isNull(promotionInfo.endDate) ||
      (isNull(promotionInfo.file) && isNull(promotionInfo.imageServerFileName)) ||
      (isNull(promotionInfo.banner) && isNull(promotionInfo.bannerImageServerFileName)) ||
      (isNull(promotionInfo.mobileBanner) && isNull(promotionInfo.mobileImageServerFileName))
    ) {
      return toast(`${t("toastMsg.input_empty")}`);
    }

    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      const [fileData, bannerData, mobileBannerData] = await Promise.all([
        fileUpload(promotionInfo.file, "file"),
        fileUpload(promotionInfo.banner, "banner"),
        fileUpload(promotionInfo.mobileBanner, "mobileBanner"),
      ]);

      const info = {
        title: promotionInfo.title,
        imageServerFileName: fileData ? fileData.serverFileName : promotionInfo.imageServerFileName,
        imageOriginFileName: fileData ? fileData.originFileName : promotionInfo.imageOriginFileName,
        bannerImageServerFileName: bannerData ? bannerData.serverFileName : promotionInfo.bannerImageServerFileName,
        bannerImageOriginFileName: bannerData ? bannerData.originFileName : promotionInfo.bannerImageOriginFileName,
        mobileImageServerFileName: mobileBannerData ? mobileBannerData.serverFileName : promotionInfo.mobileImageServerFileName,
        mobileImageOriginFileName: mobileBannerData ? mobileBannerData.originFileName : promotionInfo.mobileImageOriginFileName,
        startDate: promotionInfo.startDate,
        endDate: promotionInfo.endDate,
      };

      await PromotionApi.update(id, info);
      setSaveModalOpen(false);
      navigate(-1);
      toast(t("toastMsg.update"));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "660px" }}>
            <DetailPageTitle>{t("component.edit")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <DetailPageForm>
                <DetailFormTitle>{t("exhibition_detail.title")}</DetailFormTitle>
                <MainInput
                  style={{
                    width: "338px",
                  }}
                  type={"text"}
                  placeholder={t("placeholder.exhibitionPlaceholder")}
                  value={promotionInfo.title}
                  onChange={(e) =>
                    setPromotionInfo({
                      ...promotionInfo,
                      title: e.target.value,
                    })
                  }
                />
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <div>
                  <DetailFormTitle>{t("exhibition_detail.thumbnail")} *</DetailFormTitle>
                  <DetailFormTitle style={{ fontWeight: "400" }}>({t("exhibition_detail.recommendSize")} 576*320)</DetailFormTitle>
                </div>
                {promotionInfo.fileUrl || promotionInfo.imageServerFileName ? (
                  <ImgPreview
                    fileUrl={promotionInfo.fileUrl || fileUrl + promotionInfo.imageServerFileName}
                    onClick={() => {
                      deleteFile("file");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file01"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e, "file");
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <div>
                  <DetailFormTitle>{t("exhibition_detail.pcImg")} *</DetailFormTitle>
                  <DetailFormTitle style={{ fontWeight: "400" }}>({t("exhibition_detail.recommendSize")} 1180*356)</DetailFormTitle>
                </div>
                {promotionInfo.bannerUrl || promotionInfo.bannerImageServerFileName ? (
                  <ImgPreview
                    fileUrl={promotionInfo.bannerUrl || fileUrl + promotionInfo.bannerImageServerFileName}
                    onClick={() => {
                      deleteFile("banner");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file02"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e, "banner");
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <div>
                  <DetailFormTitle>{t("exhibition_detail.mobileImg")} *</DetailFormTitle>
                  <DetailFormTitle style={{ fontWeight: "400" }}>({t("exhibition_detail.recommendSize")} 335*220)</DetailFormTitle>
                </div>
                {promotionInfo.mobileBannerUrl || promotionInfo.mobileImageServerFileName ? (
                  <ImgPreview
                    fileUrl={promotionInfo.mobileBannerUrl || fileUrl + promotionInfo.mobileImageServerFileName}
                    onClick={() => {
                      deleteFile("mobileBanner");
                    }}
                  />
                ) : (
                  <ImgInput
                    id={"file03"}
                    accept={"image/*"}
                    onChange={(e) => {
                      inputFile(e, "mobileBanner");
                    }}
                  />
                )}
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("exhibition.period")} *</DetailFormTitle>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <DateInput
                    value={moment(promotionInfo.startDate).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      setPromotionInfo({
                        ...promotionInfo,
                        startDate: e.target.value,
                      })
                    }
                  />
                  <img src="/assets/admin/icons/ico_date_devider.svg" alt="" />
                  <DateInput
                    value={moment(promotionInfo.endDate).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      setPromotionInfo({
                        ...promotionInfo,
                        endDate: e.target.value,
                      })
                    }
                  />
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? <Modal title={`${t("modalMsg.update.title")}`} text={`${t("modalMsg.update.content")}`} setModalOpen={setSaveModalOpen} onClick={saveFunc} /> : null}
    </>
  );
};

export default ExhibitionEdit;
