import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import ManagerApi from "../../api/manager.api";
import SaveBtn from "../../component/atoms/button/saveBtn";
import MainInput from "../../component/atoms/input/mainInput";
import Radio from "../../component/atoms/input/radio";
import DetailPage from "../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../component/atoms/layout/mainLayout";
import DetailFormText from "../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../component/atoms/text/detail/detailPageTitle";
import PretendardText from "../../component/atoms/text/pretendardText";
import GoBack from "../../component/molecules/goBack";
import Header from "../../component/organisms/header";
import Sidebar from "../../component/organisms/sidebar";
import Modal from "../../component/templates/Modal";
import { route } from "../../router/route";
import { isNull } from "../../util/check";
import { errorHandler } from "../../util/errorHandler";
import { regExpression } from "../../util/regExpresstion";
import AuthorityCheckbox from "./_component/authorityCheckbox";

function RootEdit(props) {
  const { t } = useTranslation();
  // import
  const navigate = useNavigate();
  let { id } = useParams();

  // ----- 정보 가져오기
  const [managerInfo, setManagerInfo] = useState({
    name: "",
    email: "",
    password: "",
    passwordCheck: "",
    role: "",
    accessMenu: [],
    passwordChangeFlag: false,
  });

  // mounted
  useEffect(() => {
    getManagerInfo();
  }, []);

  const getManagerInfo = async () => {
    try {
      const response = (await ManagerApi.getById(id)).data.data.content;
      setManagerInfo({
        ...response,
        accessMenu: response.accessMenu ? JSON.parse(response.accessMenu) : [],
        passwordChangeFlag: false,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 비밀번호 변경
  const [pwEdit, setPwEdit] = useState(false);

  // 비밀번호 체크
  const pwErr = useMemo(() => {
    if (
      !isNull(managerInfo.password) &&
      !regExpression.password.test(managerInfo.password)
    ) {
      return true;
    }
    return false;
  }, [managerInfo.password]);

  // 비밀번호 확인 체크
  const pwCheckErr = useMemo(() => {
    if (
      !isNull(managerInfo.password) &&
      !isNull(managerInfo.passwordCheck) &&
      managerInfo.password !== managerInfo.passwordCheck
    ) {
      return true;
    }
    return false;
  }, [managerInfo.password, managerInfo.passwordCheck]);

  // 저장하기
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const saveFunc = async () => {
    try {
      if (isNull(managerInfo.name)) {
        setSaveModalOpen(false);
        return toast(t("toastMsg.input_empty"));
      }
      if (pwEdit) {
        if (isNull(managerInfo.password)) {
          setSaveModalOpen(false);
          return toast(t("toastMsg.input_pw"));
        }
        if (isNull(managerInfo.passwordCheck)) {
          setSaveModalOpen(false);
          return toast(t("toastMsg.input_pw"));
        }
        if (pwErr) {
          setSaveModalOpen(false);
          return toast(t("toastMsg.check_pwFormat"));
        }
        if (pwCheckErr) {
          setSaveModalOpen(false);
          return toast(t("toastMsg.check_pw"));
        }
      }

      const requestDto = {
        email: managerInfo.email,
        name: managerInfo.name,
        role: managerInfo.role,
        accessMenu: JSON.stringify(managerInfo.accessMenu),
        passwordChangeFlag: pwEdit,
        password: managerInfo.password,
      };

      await ManagerApi.update(id, requestDto);
      toast(t("toastMsg.update"));
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ paddingBottom: "83px", minHeight: "762px" }}>
            <DetailPageTitle>{t("manager.edit")}</DetailPageTitle>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <DetailPageForm>
                <DetailFormTitle>{t("manager.name")}</DetailFormTitle>
                <MainInput
                  style={{ width: "338px" }}
                  type={"text"}
                  placeholder={t("placeholder.namePlaceholder")}
                  value={managerInfo.name}
                  onChange={(e) => {
                    setManagerInfo({ ...managerInfo, name: e.target.value });
                  }}
                />
              </DetailPageForm>
              <DetailPageForm>
                <DetailFormTitle>{t("manager.email")}</DetailFormTitle>
                <DetailFormText>{managerInfo.email}</DetailFormText>
              </DetailPageForm>
              {pwEdit ? (
                <>
                  <DetailPageForm>
                    <DetailFormTitle>{t("manager.pw")}</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"password"}
                      placeholder={t("placeholder.pwPlaceholder")}
                      helperText={t("auth.pwCaption")}
                      onError={pwErr}
                      value={managerInfo.password}
                      onChange={(e) => {
                        setManagerInfo({
                          ...managerInfo,
                          password: e.target.value,
                        });
                      }}
                    />
                  </DetailPageForm>
                  <DetailPageForm>
                    <DetailFormTitle>{t("manager.pwCheck")}</DetailFormTitle>
                    <MainInput
                      style={{ width: "338px" }}
                      type={"password"}
                      placeholder={t("placeholder.pwCheckPlaceholder")}
                      helperText={t("auth.pwError")}
                      onError={pwCheckErr}
                      value={managerInfo.passwordCheck}
                      onChange={(e) => {
                        setManagerInfo({
                          ...managerInfo,
                          passwordCheck: e.target.value,
                        });
                      }}
                    />
                  </DetailPageForm>
                </>
              ) : (
                <DetailPageForm>
                  <DetailFormTitle>{t("manager.pw")}</DetailFormTitle>
                  <PwEditBtn
                    onClick={() => {
                      setPwEdit(true);
                    }}
                  >
                    {t("manager.pwEditBtn")}
                  </PwEditBtn>
                </DetailPageForm>
              )}

              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("manager.type")}</DetailFormTitle>
                <div style={{ display: "flex", gap: "19px" }}>
                  <Radio
                    id={"ROOT"}
                    valueText={"ROOT"}
                    name={"author"}
                    checked={managerInfo.role === "ROOT"}
                    onChange={() => {
                      setManagerInfo({
                        ...managerInfo,
                        role: "ROOT",
                        accessMenu: [],
                      });
                    }}
                  />
                  <Radio
                    id={"ADMIN"}
                    valueText={"ADMIN"}
                    name={"author"}
                    checked={managerInfo.role === "ADMIN"}
                    onChange={() => {
                      setManagerInfo({
                        ...managerInfo,
                        role: "ADMIN",
                        accessMenu: [],
                      });
                    }}
                  />
                </div>
              </DetailPageForm>
              <PretendardText
                style={{
                  color: "#FF4975",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "22.4px",
                  letterSpacing: "-0.408px",
                }}
              >
                {t("manager.rootCaption")}
              </PretendardText>
              <DetailPageForm
                style={{ alignItems: "flex-start", marginBottom: "79px" }}
              >
                <DetailFormTitle>{t("manager.authority")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "22px",
                  }}
                >
                  {/* ===== 사용자 관리 ===== */}
                  <div>
                    <CheckboxListTitle>{t("sidebar.user")}</CheckboxListTitle>
                    <div
                      style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                    >
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(
                            route.common_member
                          ) || managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.common_member);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.common_member
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.common_member")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(
                            route.influencer_member
                          ) || managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(
                              route.influencer_member
                            );
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.influencer_member
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.influencer_member")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(
                            route.approve_signup
                          ) || managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.approve_signup);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.approve_signup
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.approve_signup")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.calculate) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.calculate);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.calculate
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.calculate")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.leave_member) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.leave_member);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.leave_member
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.leave_member")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                    </div>
                  </div>

                  {/* ===== 영상 관리 ===== */}
                  <div>
                    <CheckboxListTitle>{t("sidebar.video")}</CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(
                            route.approve_video
                          ) || managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.approve_video);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.approve_video
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.approve_video")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                    </div>
                  </div>

                  {/* ===== 상품 관리 ===== */}
                  <div>
                    <CheckboxListTitle>
                      {t("sidebar.product")}
                    </CheckboxListTitle>
                    <div
                      style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
                    >
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.product_list) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.product_list);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.product_list
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.product_list")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.category) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.category);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.category
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.category")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.exhibition) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.exhibition);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.exhibition
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.exhibition")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.coupon) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.coupon);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.coupon
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.coupon")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.review) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.review);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.review
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.review")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                    </div>
                  </div>

                  {/* ===== 주문 관리 ===== */}
                  <div>
                    <CheckboxListTitle>{t("sidebar.order")}</CheckboxListTitle>
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.shipping) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.shipping);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.shipping
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.shipping")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.cancel) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.cancel);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.cancel
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.cancel")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.exchange) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.exchange);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.exchange
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.exchange")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.return) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.return);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.return
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.return")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                    </div>
                  </div>

                  {/* ===== 고객센터 ===== */}
                  <div>
                    <CheckboxListTitle>
                      {t("sidebar.customer")}
                    </CheckboxListTitle>
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.alarm) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.alarm);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.alarm
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.alarm")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.ask) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.ask);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.ask
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.ask")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.notice) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.notice);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.notice
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.notice")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.faq) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.faq);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.faq
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.faq")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                    </div>
                  </div>

                  {/* ===== 서비스 관리 ===== */}
                  <div>
                    <CheckboxListTitle>
                      {t("sidebar.service")}
                    </CheckboxListTitle>
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
                    >
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.setting) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.setting);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.setting
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.setting")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.terms) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.terms);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.terms
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.terms")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.basic) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.basic);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.basic
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.basic")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.popup) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.popup);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.popup
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.popup")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.banner) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.banner);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.banner
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.banner")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.main) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.main);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.main
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.main")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                    </div>
                  </div>

                  <div>
                    <CheckboxListTitle>
                      {t("sidebar.manager")}
                    </CheckboxListTitle>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <AuthorityCheckbox
                        checked={
                          managerInfo.accessMenu.includes(route.manager) ||
                          managerInfo.role === "ROOT"
                        }
                        onChange={(checked) => {
                          if (checked) {
                            managerInfo.accessMenu.push(route.manager);
                          } else {
                            managerInfo.accessMenu =
                              managerInfo.accessMenu.filter(
                                (item) => item !== route.manager
                              );
                          }
                          setManagerInfo({ ...managerInfo });
                        }}
                        text={t("sidebar.manager")}
                        disabled={managerInfo.role === "ROOT"}
                      />
                    </div>
                  </div>
                </div>
              </DetailPageForm>
            </div>
            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                setSaveModalOpen(true);
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={saveFunc}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default RootEdit;

const CheckboxWrapper = styled.div.attrs((props) => {})`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PwEditBtn = styled.button.attrs((props) => {})`
  width: 338px;
  height: 48px;
  display: flex;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #b8bfc4;
  background-color: transparent;
  color: #808991;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
`;

const CheckboxListTitle = styled(PretendardText).attrs((props) => {})`
  color: #808991;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.039px;
  margin-bottom: 5px;
`;
