import api from "../../../../api/api";

export default class TermsApi {
  static async create(data) {
    return await api.post(`admin/term`, data);
  }
  static async getList(params) {
    return await api.get(`admin/term`, params);
  }
  static async getById(termsId) {
    return await api.get(`admin/term/${termsId}`);
  }
  static async update(termsId, data) {
    return await api.patch(`admin/term/${termsId}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/term`, data);
  }
}
