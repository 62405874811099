import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import BasicApi from "../../../api/basic.api";
import SaveBtn from "../../../component/atoms/button/saveBtn";
import MainInput from "../../../component/atoms/input/mainInput";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import { useTranslation } from "react-i18next";
import BasicApi from "./_api/basic.api";
import AddressSearch from "../../../component/organisms/addressSearch";

const BasicEdit = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [ceo, setCeo] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");
  const [reportNumber, setReportNumber] = useState("");
  const [address, setAddress] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [csInfo1, setCsInfo1] = useState("");
  const [csInfo2, setCsInfo2] = useState("");
  const [csInfo3, setCsInfo3] = useState("");
  const [senderAddress, setSenderAddress] = useState();
  const [senderAddressDR, setSenderAddressDR] = useState();
  const [senderAddressWard, setSenderAddressWard] = useState();

  const getBasicInfo = async () => {
    try {
      let data = (await BasicApi.getBasicInfo()).data.data.content;
      setCompanyName(data.companyName);
      setCeo(data.ceo);
      setBusinessNumber(data.businessNumber);
      setReportNumber(data.reportNumber);
      setAddress(data.address);
      setTel(data.tel);
      setEmail(data.email);
      setCsInfo1(data.csInfo1);
      setCsInfo2(data.csInfo2);
      setCsInfo3(data.csInfo3);
      const senderAddressArray = JSON.parse(data.senderAddress);
      if (senderAddressArray[0]) {
        setSenderAddress(senderAddressArray[0]);
      }

      if (senderAddressArray[1]) {
        setSenderAddressDR(senderAddressArray[1]);
      }

      if (senderAddressArray[2]) {
        setSenderAddressWard(senderAddressArray[2]);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getBasicInfo();
  }, []);

  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const saveBtnHandler = () => {
    setSaveModalOpen(true);
  };

  const saveFunc = async () => {
    try {
      let senderAddressArray = [];
      if (senderAddress) {
        senderAddressArray.push(senderAddress);
      }

      if (senderAddressDR) {
        senderAddressArray.push(senderAddressDR);
      }

      if (senderAddressWard) {
        senderAddressArray.push(senderAddressWard);
      }
      const data = {
        companyName,
        ceo,
        businessNumber,
        reportNumber,
        address,
        tel,
        email,
        csInfo1,
        csInfo2,
        csInfo3,
        senderAddress: JSON.stringify(senderAddressArray),
      };

      await BasicApi.updateBasicInfo(data);
      setSaveModalOpen(false);
      navigate(-1);
      toast(t("toastMsg.update"));
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
          </DetailTop>
          <DetailPage style={{ minHeight: "1100px" }}>
            <DetailPageTitle>{t("basic.title")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 상호명 */}
              <DetailPageForm>
                <DetailFormTitle>{t("basic.name")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={""}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </DetailPageForm>

              {/* 대표자 */}
              <DetailPageForm>
                <DetailFormTitle>{t("basic.ceo")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={""}
                  value={ceo}
                  onChange={(e) => setCeo(e.target.value)}
                />
              </DetailPageForm>

              {/* 사업자등록번호 */}
              <DetailPageForm>
                <DetailFormTitle>{t("basic.businessNumber")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={""}
                  value={businessNumber}
                  onChange={(e) => setBusinessNumber(e.target.value)}
                />
              </DetailPageForm>

              {/* 통신판매 */}
              <DetailPageForm>
                <DetailFormTitle>{t("basic.mailOrderNumber")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={""}
                  value={reportNumber}
                  onChange={(e) => setReportNumber(e.target.value)}
                />
              </DetailPageForm>

              {/* 주소 */}
              <DetailPageForm>
                <DetailFormTitle>{t("basic.address")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={""}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </DetailPageForm>

              {/* 전화번호 */}
              <DetailPageForm>
                <DetailFormTitle>{t("basic.tel")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={""}
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                />
              </DetailPageForm>

              {/* 이메일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("basic.email")}*</DetailFormTitle>
                <MainInput
                  style={{
                    width: 338,
                  }}
                  type={"text"}
                  placeholder={""}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </DetailPageForm>

              {/* cs */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("basic.cs")}*</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <MainInput
                    style={{
                      width: 338,
                    }}
                    type={"text"}
                    placeholder={""}
                    value={csInfo1}
                    onChange={(e) => setCsInfo1(e.target.value)}
                  />
                  <MainInput
                    style={{
                      width: 338,
                    }}
                    type={"text"}
                    placeholder={""}
                    value={csInfo2}
                    onChange={(e) => setCsInfo2(e.target.value)}
                  />
                  <MainInput
                    style={{
                      width: 338,
                    }}
                    type={"text"}
                    placeholder={""}
                    value={csInfo3}
                    onChange={(e) => setCsInfo3(e.target.value)}
                  />
                </div>
              </DetailPageForm>

              {/* 보내는 곳 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("basic.senderAddress")}*</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <AddressSearch
                    address={senderAddress}
                    setAddress={setSenderAddress}
                    addressDR={senderAddressDR}
                    setAddressDR={setSenderAddressDR}
                    addressWard={senderAddressWard}
                    setAddressWard={setSenderAddressWard}
                  />
                </div>
              </DetailPageForm>
            </div>

            <SaveBtn
              style={{ bottom: "40px", right: "40px" }}
              onClick={() => {
                saveBtnHandler();
              }}
            />
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {saveModalOpen ? (
        <Modal
          title={`${t("modalMsg.update.title")}`}
          text={`${t("modalMsg.update.content")}`}
          setModalOpen={setSaveModalOpen}
          onClick={() => {
            saveFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default BasicEdit;
