import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
// import ExcelApi from "../../../api/excel.api";
import SettlementApi from "../../../api/settlement.api";
import DelBtn from "../../../component/atoms/button/delBtn";
import ExcelBtn from "../../../component/atoms/button/excelBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import SearchBar from "../../../component/atoms/input/searchBar";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import Table from "../../../component/atoms/layout/table/table";
import TableLayout from "../../../component/atoms/layout/table/tableLayout";
import TableSearchBtn from "../../../component/atoms/layout/table/tableSearchBtn";
import TableTop from "../../../component/atoms/layout/table/tableTop";
import Td from "../../../component/atoms/layout/table/td";
import Th from "../../../component/atoms/layout/table/th";
import Tr from "../../../component/atoms/layout/table/tr";
import PretendardText from "../../../component/atoms/text/pretendardText";
import TableText from "../../../component/atoms/text/table/tableText";
import TableTitle from "../../../component/atoms/text/table/tableTitle";
import TdText from "../../../component/atoms/text/table/tdText";
import Pagination from "../../../component/molecules/pagination";
import TableCount from "../../../component/molecules/tableCount";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import ExcelApi from "../../../api/excel.api";
import { saveAs } from "file-saver";

const Calculate = () => {
  const { t, i18n } = useTranslation();
  const isVn = i18n.language === "VN";

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState("");

  const page = useMemo(() => {
    return searchParams.get("page") ? searchParams.get("page") : 1;
  }, [searchParams]);

  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
  });

  // ----------------------------------- //
  // ---------- 마운트가 되면 ---------- //
  // --------------------------------- //
  useEffect(() => {
    setKeyword(searchParams.get("keyword") ? searchParams.get("keyword") : "");
    getSettlementList();
  }, [location]);

  const [settlementList, setSettlementList] = useState([]);

  const getSettlementList = async () => {
    try {
      const data = {
        keyword: searchParams.get("keyword"),
        page: searchParams.get("page"),
        size: pagination.size,
      };

      let response = (await SettlementApi.getList(data)).data.data;

      setSettlementList(
        response.content.map((item) => {
          return {
            ...item,
            checked: false,
          };
        })
      );

      setPagination({
        ...pagination,
        totalCount: response.totalCount ? response.totalCount : 0,
        totalPages: Math.ceil(response.totalCount / pagination.size),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllCheck = useMemo(() => {
    const checkedList = settlementList.filter((item) => item.checked);
    return checkedList.length !== 0 && checkedList.length === settlementList.length;
  }, [settlementList]);

  // ----- 정산 완료 처리
  const [settlementIdList, setSettlementIdList] = useState([]);
  const [settlementCompleteModalOpen, setSettlementCompleteModalOpen] = useState(false);

  const handleSettlementComplete = async () => {
    try {
      const data = {
        ids: settlementIdList,
      };
      await SettlementApi.update(data);
      toast(t("toastMsg.update"));
      setSettlementCompleteModalOpen(false);
      setSettlementIdList([]);
      getSettlementList();
    } catch (error) {
      errorHandler(error);
    }
  };

  //------------엑셀 생성
  const downloadExcel = async (excelData) => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();
    workbook.addWorksheet("Sheet One");
    const sheetOne = workbook.getWorksheet("Sheet One");

    sheetOne.columns = [
      { header: t("calculate.email"), key: "email", width: 40 },
      { header: t("calculate.bankname"), key: "bankName", width: 40 },
      { header: t("calculate.account"), key: "accountNumber", width: 40 },
      { header: t("calculate.point"), key: "amount", width: 40 },
      { header: t("calculate.createdAt"), key: "createdAt", width: 40 },
    ];

    excelData.map((item) => {
      // 행 추가
      sheetOne.addRow(item);
    });
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${t("calculate.title")}.xlsx`);
  };

  const getExcelAll = async () => {
    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetSettlementExcel({
          ids: [],
          allFlag: true,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getExcelChecked = async () => {
    const filter = settlementList.filter((item) => item.checked);
    if (filter.length <= 0) {
      return toast(t("placeholder.selectItem"));
    }

    try {
      const excelArr = [];
      const response = (
        await ExcelApi.GetSettlementExcel({
          ids: filter.map((item) => item.id),
          allFlag: false,
        })
      ).data.data.content;

      response.map((item) => {
        const createDate = moment(item.createdAt).format("YYYY/MM/DD");
        excelArr.unshift({
          ...item,
          createdAt: createDate,
        });
      });

      downloadExcel(excelArr);
    } catch (error) {
      errorHandler(error);
    }
  };
  //------------액셀 부분 끝

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <TableLayout>
          <TableTitle>{t("calculate.title")}</TableTitle>
          <TableTop>
            <TableCount text={t("calculate.calculateCount")} count={pagination.totalCount} />
            <TableSearchBtn>
              <SearchBar
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`${route.calculate}?page=1&keyword=${keyword}`, {
                      replace: true,
                    });
                  }
                }}
                onClick={() => {
                  navigate(`${route.calculate}?page=1&keyword=${keyword}`, {
                    replace: true,
                  });
                }}
              />
              <DelBtn
                text={t("calculate.checkedCompleteBtn")}
                onClick={() => {
                  const settlementCompleteList = [];

                  settlementList.forEach((item) => {
                    if (item.checked) {
                      settlementCompleteList.push(item.id);
                    }
                  });

                  if (settlementList.length <= 0) {
                    return;
                  }

                  setSettlementIdList(settlementList);
                  setSettlementCompleteModalOpen(true);
                }}
              />
              <ExcelBtn onSelectClick={getExcelChecked} onAllClick={getExcelAll} />
            </TableSearchBtn>
          </TableTop>
          <Table>
            <colgroup>
              {/* 체크박스 영역 */}
              <col width={isVn ? "90px" : "90px"} />
              {/* 이메일 */}
              <col width={isVn ? "130px" : "140px"} />
              {/* 은행명 */}
              <col width={isVn ? "120px" : "166px"} />
              {/* 예금주명 */}
              <col width={isVn ? "120px" : "118px"} />
              {/* 계좌번호 */}
              <col width={isVn ? "170px" : "206px"} />
              {/* 정산 포인트 */}
              <col width={isVn ? "120px" : "146px"} />
              {/* 요청일 */}
              <col width={isVn ? "100px" : "138px"} />
              {/* 완료 */}
              <col width={isVn ? "230px" : "158px"} />
            </colgroup>
            <thead>
              <Tr style={{ height: "40px", background: "#f2f4f5" }}>
                <Th style={{ paddingLeft: "20px" }}>
                  <Checkbox
                    checked={isAllCheck}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSettlementList(
                          settlementList.map((item) => {
                            return {
                              ...item,
                              checked: true,
                            };
                          })
                        );
                      } else {
                        setSettlementList(
                          settlementList.map((item) => {
                            return {
                              ...item,
                              checked: false,
                            };
                          })
                        );
                      }
                    }}
                  />
                </Th>
                <Th>
                  <TableText>{t("calculate.email")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("calculate.bankname")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("calculate.accountHolderName")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("calculate.account")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("calculate.point")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("calculate.createdAt")}</TableText>
                </Th>
                <Th>
                  <TableText>{t("calculate.complete")}</TableText>
                </Th>
              </Tr>
            </thead>
            <tbody>
              {settlementList.map((item, index) => {
                return (
                  <Tr key={index} style={{ height: "59px", background: "#fff" }}>
                    <Td style={{ paddingLeft: "20px" }}>
                      <Checkbox
                        checked={item.checked}
                        onChange={() => {
                          item.checked = !item.checked;
                          setSettlementList([...settlementList]);
                        }}
                      />
                    </Td>
                    <Td>
                      <TableText>{item.email}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.bankName}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.accountHolderName}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.accountNumber}</TableText>
                    </Td>
                    <Td>
                      <TableText>{item.amount}p</TableText>
                    </Td>
                    <Td>
                      <TableText>{moment(item.createdAt).format("YYYY/MM/DD")}</TableText>
                    </Td>
                    <Td>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "10px",
                          paddingRight: "10px",
                          flexFlow: "wrap",
                        }}
                      >
                        <TdText
                          style={{
                            color: `${item.completeFlag ? "#636C73" : "#EF594B"}`,
                          }}
                        >
                          {item.completeFlag ? `${t("calculate.complete")}` : `${t("calculate.notComplete")}`}
                        </TdText>
                        {item.completeFlag ? null : (
                          <PretendardText
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "22.75px",
                              color: "#262C31",
                              border: "1px solid #DFE1E4",
                              borderRadius: "4px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "3px 8px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSettlementIdList([item.id]);
                              setSettlementCompleteModalOpen(true);
                            }}
                          >
                            {t("calculate.completeBtn")}
                          </PretendardText>
                        )}
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            route={route.calculate}
            queryString={`keyword=${searchParams.get("keyword") || ""}`}
            currentPage={page}
            totalPages={pagination.totalPages}
            rangeSize={pagination.rangeSize}
            first={pagination.first}
            last={pagination.last}
          />
        </TableLayout>
      </MainLayout>

      {settlementCompleteModalOpen ? (
        <Modal
          title={`${t("modalMsg.approve")}`}
          setModalOpen={setSettlementCompleteModalOpen}
          onClick={() => {
            handleSettlementComplete();
          }}
        />
      ) : null}
    </>
  );
};

export default Calculate;
