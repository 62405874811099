import api from "./api.js";

export default class ReviewApi {
  // 영상 목록 조회
  static async getList(params) {
    return await api.get(`admin/review`, params);
  }

  // 영상 id별 조회
  static async getById(id) {
    return await api.get(`admin/review/${id}`);
  }

  // 영상 삭제
  static async delete(data) {
    return await api.del(`admin/review`, data);
  }

  // 영상 승인
  static async approve(id, data) {
    return await api.post(`admin/review/approval/${id}`, data);
  }
}
