import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import DelBtn from "../../../component/atoms/button/delBtn";
import EditBtn from "../../../component/atoms/button/editBtn";
import Checkbox from "../../../component/atoms/input/checkbox";
import DetailPage from "../../../component/atoms/layout/detail/detailPage";
import DetailPageForm from "../../../component/atoms/layout/detail/detailPageForm";
import DetailPageLayout from "../../../component/atoms/layout/detail/detailPageLayout";
import DetailTop from "../../../component/atoms/layout/detail/detailTop";
import MainLayout from "../../../component/atoms/layout/mainLayout";
import DetailFormText from "../../../component/atoms/text/detail/detailFormText";
import DetailFormTitle from "../../../component/atoms/text/detail/detailFormTitle";
import DetailPageTitle from "../../../component/atoms/text/detail/detailPageTitle";
import GoBack from "../../../component/molecules/goBack";
import Header from "../../../component/organisms/header";
import Sidebar from "../../../component/organisms/sidebar";
import Modal from "../../../component/templates/Modal";
import { route } from "../../../router/route";
import { errorHandler } from "../../../util/errorHandler";
import { modalMsg } from "../../../util/modalMsg";
import { toastMsg } from "../../../util/toastMsg";
import { useTranslation } from "react-i18next";
import MemberApi from "../../../api/member.api";
import { formatPhoneNumber } from "./../../../util/formatNumber";
import { isNull } from "../../../util/check";

const CommonMemberDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();

  // mounted
  useEffect(() => {
    getMemberInfo();
  }, []);

  const [memberInfo, setMemberInfo] = useState({});

  const getMemberInfo = async () => {
    try {
      const response = (await MemberApi.getById(id)).data.data.content;

      setMemberInfo({
        ...response,
        birth: response.birth ? response.birth.split("T")[0] : "",
        address: getSenderAddressString(JSON.parse(response.address)),
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  // ----- 삭제
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const deleteFunc = async () => {
    try {
      const data = {
        ids: [id],
      };
      await MemberApi.delete(data);
      toast(t("toastMsg.delete"));
      setDeleteModalOpen(false);
      navigate(-1);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getSenderAddressString = (senderAddressArray) => {
    let senderAddressString = "";

    if (senderAddressArray[0]) {
      senderAddressString += senderAddressArray[0].provinceName;
    }

    if (senderAddressArray[1]) {
      senderAddressString += `, ${senderAddressArray[1].districtName}`;
    }

    if (senderAddressArray[2]) {
      senderAddressString += `, ${senderAddressArray[2].wardName}`;
    }

    return senderAddressString;
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Sidebar />
        <DetailPageLayout>
          <DetailTop>
            <GoBack />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DelBtn onClick={() => setDeleteModalOpen(true)} />
              <EditBtn
                onClick={() => {
                  navigate(route.common_member_edit + `/${id}`);
                }}
              />
            </div>
          </DetailTop>
          <DetailPage style={{ minHeight: "495px" }}>
            <DetailPageTitle>{t("common_member_detail.userInfo")}</DetailPageTitle>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              {/* 이메일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("common_member_detail.email")}</DetailFormTitle>
                <DetailFormText>{memberInfo.email}</DetailFormText>
              </DetailPageForm>

              {/* 이름 */}
              <DetailPageForm style={{ alignItems: "flex-start" }}>
                <DetailFormTitle>{t("common_member_detail.name")}</DetailFormTitle>
                <DetailFormText>{memberInfo.name}</DetailFormText>
              </DetailPageForm>

              {/* 휴대폰 번호 */}
              <DetailPageForm>
                <DetailFormTitle>{t("common_member_detail.phone")}</DetailFormTitle>
                <DetailFormText>{formatPhoneNumber(memberInfo.phoneNumber)}</DetailFormText>
              </DetailPageForm>

              {/* 생년월일 */}
              <DetailPageForm>
                <DetailFormTitle>{t("common_member_detail.birth")}</DetailFormTitle>
                <DetailFormText>{memberInfo.birth}</DetailFormText>
              </DetailPageForm>

              {/* 성별 */}
              <DetailPageForm>
                <DetailFormTitle>{t("common_member_detail.gender")}</DetailFormTitle>
                <DetailFormText>
                  {memberInfo.gender === "" || memberInfo.gender === "NONE" ? t("placeholder.defaultPlaceholder") : memberInfo.gender === "WOMAN" ? t("common_member.woman") : t("common_member.man")}
                </DetailFormText>
              </DetailPageForm>

              {/* 주소 */}
              <DetailPageForm>
                <DetailFormTitle>{t("common_member_detail.address")}</DetailFormTitle>
                <DetailFormText>{`${memberInfo.address} ${memberInfo.detailAddress}`}</DetailFormText>
              </DetailPageForm>

              {/* 추천인 아이디 */}
              <DetailPageForm>
                <DetailFormTitle>{t("common_member_detail.recommender")}</DetailFormTitle>
                <DetailFormText>{memberInfo.recommenderID}</DetailFormText>
              </DetailPageForm>

              {/* 보유 포인트 */}
              <DetailPageForm>
                <DetailFormTitle>{t("common_member_detail.point")}</DetailFormTitle>
                <DetailFormText>{memberInfo.point}</DetailFormText>
              </DetailPageForm>

              {/* 약관 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("common_member_detail.terms")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <CheckboxWrapper>
                    <DetailFormText>{t("common_member_detail.useTerms")}</DetailFormText>
                    <Checkbox id="agreement" checked={true} disabled={true} />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>{t("common_member_detail.collect")}</DetailFormText>
                    <Checkbox id="privacy" checked={true} disabled={true} />
                  </CheckboxWrapper>
                  <CheckboxWrapper>
                    <DetailFormText>{t("common_member_detail.providing")}</DetailFormText>
                    <Checkbox id="privacy" checked={true} disabled={true} />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>

              {/* 알림 수신 동의 */}
              <DetailPageForm
                style={{
                  alignItems: "flex-start",
                }}
              >
                <DetailFormTitle>{t("common_member_detail.alarm")}</DetailFormTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  {/* 마케팅 목적의 개인정보 수집 및 이용 동의 */}
                  <CheckboxWrapper>
                    <DetailFormText>{t("common_member_detail.sms")}</DetailFormText>
                    <Checkbox disabled id="marketingAgreement" checked={memberInfo.marketingAgreement} />
                  </CheckboxWrapper>
                  {/* 광고성 정보 수신 */}
                  <CheckboxWrapper>
                    <DetailFormText>{t("common_member_detail.ad")}</DetailFormText>
                    <Checkbox disabled id="adAgreement" checked={memberInfo.adAgreement} />
                  </CheckboxWrapper>
                </div>
              </DetailPageForm>
            </div>
          </DetailPage>
        </DetailPageLayout>
      </MainLayout>

      {deleteModalOpen ? (
        <Modal
          negative={true}
          title={`${t("modalMsg.delete.title")}`}
          text={`${t("modalMsg.delete.content")}`}
          setModalOpen={setDeleteModalOpen}
          onClick={() => {
            deleteFunc();
          }}
        />
      ) : null}
    </>
  );
};

export default CommonMemberDetail;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
