import api from "../../../../api/api";

export default class PopupApi {
  static async create(data) {
    return await api.post(`admin/popup`, data);
  }
  static async getList(data) {
    return await api.get(`admin/popup`, data);
  }
  static async getById(id, data) {
    return await api.get(`admin/popup/${id}`, data);
  }
  static async update(id, data) {
    return await api.patch(`admin/popup/${id}`, data);
  }
  static async delete(data) {
    return await api.del(`admin/popup`, data);
  }
  static async updateActivation(id, data) {
    return await api.post(`admin/popup/activation/${id}`, data);
  }
}
